import { gql, useMutation, useQuery } from '@apollo/client'
import { Button, Card, Form, InputNumber, message } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  StageCapacitySystem,
  SystemConfigType,
  SystemStageCapacityQueryQuery,
  SystemStageCapacityQueryVariables,
  UpdateSystemMaxStageCapacityMutation,
  UpdateSystemMaxStageCapacityMutationVariables,
} from '../../../../graphql/types'

const systemStageCapacityQuery = gql`
  query SystemStageCapacityQuery {
    system(configType: MAX_WORK_CAPACITY) {
      id
      configType
      ... on StageCapacitySystem {
        stageCapacity {
          capacityPerType {
            stageType
            pointsPerUnit
            maxPoints
          }
          maxPoints
        }
      }
    }
  }
`

const updateSystemMaxStageCapacityMutation = gql`
  mutation UpdateSystemMaxStageCapacity(
    $configType: SystemConfigType!
    $payload: UpdateSystemMaxStageCapacityInput!
  ) {
    updateSystemMaxStageCapacity(configType: $configType, payload: $payload) {
      id
    }
  }
`

const SystemStageCapacity = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { data, loading } = useQuery<
    SystemStageCapacityQueryQuery,
    SystemStageCapacityQueryVariables
  >(systemStageCapacityQuery)
  const [updateSystemStageCapacity] = useMutation<
    UpdateSystemMaxStageCapacityMutation,
    UpdateSystemMaxStageCapacityMutationVariables
  >(updateSystemMaxStageCapacityMutation)

  const handleUpdate = async () => {
    try {
      const hideLoadingMessage = message.loading('更新工單產能設定中', 0)
      const fieldsValue =
        (await form.validateFields()) as UpdateSystemMaxStageCapacityMutationVariables['payload']
      await updateSystemStageCapacity({
        variables: {
          configType: SystemConfigType.MaxWorkCapacity,
          payload: fieldsValue,
        },
        update: async () => {
          hideLoadingMessage()
          await message.info('已更新系統設定中的工單產能上限')
        },
      })
    } catch (e) {
      if (e?.message) {
        /** graphQL errors */
        message.error(e.message)
      } else {
        /** form errors or other errors */
        message.error(e.toString())
      }
    }
  }

  if (loading) return <>讀取中</>

  const initialValues = data?.system as StageCapacitySystem

  return (
    <Card
      title='工單產能上限'
      style={{ width: 300, height: 600 }}
      extra={
        <Button danger onClick={handleUpdate}>
          更新
        </Button>
      }
    >
      <Form form={form} initialValues={initialValues}>
        <Form.Item
          label='全部每日點數上限'
          name={['stageCapacity', 'maxPoints']}
        >
          <InputNumber />
        </Form.Item>
        {initialValues.stageCapacity.capacityPerType.map((x, index) => {
          return (
            <div key={x.stageType}>
              <Form.Item>{t(`stage.type.${x.stageType}`)}</Form.Item>
              <Form.Item
                hidden
                name={['stageCapacity', 'capacityPerType', index, 'stageType']}
              ></Form.Item>
              <Form.Item
                label='每單產能點數'
                name={[
                  'stageCapacity',
                  'capacityPerType',
                  index,
                  'pointsPerUnit',
                ]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label='每日點數上限'
                name={['stageCapacity', 'capacityPerType', index, 'maxPoints']}
              >
                <InputNumber />
              </Form.Item>
            </div>
          )
        })}
      </Form>
    </Card>
  )
}

export default SystemStageCapacity

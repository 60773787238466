import { ReloadOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { ErrorHandling, TableQuery } from '@sov/common'
import { Button, Card } from 'antd'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import {
  PatientTracksQueryQuery,
  PatientTracksQueryQueryVariables,
} from '../../../graphql/types'
import Page from '../../components/layout/Page'
import TrackTable from '../../components/table/patient/TrackTable'
import {
  PhotoCategory,
  PhotoMapType,
} from '../../components/table/Track/PhotoThumbnailList'

/* ----- styled components ----- */
const TableTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
  display: inline-block;
`
const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const patientTracksQuery = gql`
  query PatientTracksQuery(
    $id: ID!
    $query: TracksQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    patient(id: $id) {
      id
      tracks(query: $query, page: $page, limit: $limit, sort: $sort) {
        docs {
          ...PatientTrackTable
        }
        limit
        total
        page
      }
    }
  }
  ${TrackTable.fragment}
`

/* ----- types and component ----- */
export type PatientTrackSorterField = 'created'

export interface TrackLightboxState {
  visible: boolean
  photoCategory: PhotoCategory
  photoMap: PhotoMapType
  initialIndex?: number
}

interface RouterProps {
  patientId: string
}

// 病患事件列表
const PatientTracksList = () => {
  const match = useRouteMatch<RouterProps>()
  const patientId = match.params.patientId

  const { tableQuery, paginateQuery, handleTableChange, handleTableReset } =
    TableQuery.useTableQuery<any, PatientTrackSorterField>({ limit: 10 })
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading } = useQuery<
    PatientTracksQueryQuery,
    PatientTracksQueryQueryVariables
  >(patientTracksQuery, {
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: patientId,
      query: {},
      ...paginateQuery,
    },
  })

  const tracks = data?.patient?.tracks?.docs || []
  const antdPagination = {
    page: data?.patient?.tracks?.page || 1,
    total: data?.patient?.tracks?.total || 0,
    limit: data?.patient?.tracks?.limit || 0,
  }

  if (loading) return <Page loading />

  return (
    <Card size='small'>
      <TitleRow>
        <TableTitle>追蹤列表</TableTitle>
        <Button onClick={handleTableReset}>
          <ReloadOutlined /> 重新整理
        </Button>
      </TitleRow>
      <TrackTable
        loading={loading}
        data={tracks}
        antdPagination={antdPagination}
        sortInfo={tableQuery.sort}
        handleTableChange={handleTableChange}
      />
    </Card>
  )
}

export default PatientTracksList

import { useMutation } from '@apollo/client'
import { Button, Form, Row, message } from 'antd'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import {
  createInvoiceWithStageMutation,
  createInvoiceWithoutStageMutation,
} from '../../../graphql/invoice/mutation/create'
import {
  AllPrivilege,
  CreateInvoiceWithStageMutation,
  CreateInvoiceWithStageVariables,
  CreateInvoiceWithoutStageMutation,
  CreateInvoiceWithoutStageVariables,
} from '../../../graphql/types'
import InvoiceTimeLine from '../../components/common/invoice/InvoiceTimeLine'
import {
  FormInvoice,
  FormInvoiceFields,
  FormInvoiceProps,
  InvoiceFormType,
  basicInvoicePayloadTransformer,
  loadingCompleteState,
  loadingState,
} from '../../components/form/invoice'
import BreadcrumbCreator from '../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../components/layout/Page'
import Title from '../../components/layout/Title'
import { authContext } from '../../context'
import { useLoadingLayer } from '../../helpers/hooks'
import { getAuthPrivileges } from '../../utils'
import { formInvoiceBasicDefaultValue } from './utils'

const initialValues: FormInvoiceProps['initialValues'] = {
  ...formInvoiceBasicDefaultValue,
  formType: InvoiceFormType.PATIENT_STAGE,
}

export const InvoiceCreate = () => {
  const history = useHistory()
  const auth = useContext(authContext)
  const privilegesOfUser = getAuthPrivileges(auth)
  const [form] = Form.useForm()

  const { loading, setLoadingLayer } = useLoadingLayer(loadingCompleteState)

  /** 新增出貨單 */
  const [createInvoiceWithStage] = useMutation<
    CreateInvoiceWithStageMutation,
    CreateInvoiceWithStageVariables
  >(createInvoiceWithStageMutation)
  const [createInvoiceWithoutStage] = useMutation<
    CreateInvoiceWithoutStageMutation,
    CreateInvoiceWithoutStageVariables
  >(createInvoiceWithoutStageMutation)
  const handleInvoiceCreate = async () => {
    try {
      const formValues = (await form.validateFields()) as FormInvoiceFields

      setLoadingLayer(loadingState)
      const formType = formValues.formType

      if (formType === InvoiceFormType.PATIENT_STAGE) {
        const payload = {
          ...basicInvoicePayloadTransformer(formValues),
          patient: formValues.patient,
          stage: formValues.stage,
        }

        await createInvoiceWithStage({
          variables: {
            payload,
          },
          update: (cache, { data }) => {
            if (data) {
              message.info('已新增出貨單')
              history.push(`/invoices`)
            }
          },
        })
      }

      if (formType === InvoiceFormType.CLINIC_ONLY) {
        const payload = {
          ...basicInvoicePayloadTransformer(formValues),
          clinic: formValues.clinic,
          doctor: formValues.doctor,
          sales: formValues.sales,
        }

        await createInvoiceWithoutStage({
          variables: {
            payload,
          },
          update: (cache, { data }) => {
            if (data) {
              message.info('已新增出貨單')
              history.push(`/invoices`)
            }
          },
        })
      }
    } catch (error) {
      if (error?.message) {
        /** graphQL errors */
        const hintMessage = `新增出貨單失敗: ${error.message}`
        message.error(hintMessage)
      } else {
        /** form errors or other errors */
        console.log(error)
      }
    } finally {
      setLoadingLayer(loadingCompleteState)
    }
  }

  return (
    <Page
      header={
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'InvoiceCreate' }]}
          />
          <Title route={{ key: 'InvoiceCreate' }} />
        </>
      }
    >
      <Section>
        <Row justify='space-between'>
          <h2>出貨單內容(尚未新增)</h2>
          {privilegesOfUser.includes(AllPrivilege.InvoiceCreate) && (
            <Button disabled={loading} onClick={handleInvoiceCreate}>
              新增
            </Button>
          )}
        </Row>
        <Row>
          <FormInvoice
            form={form}
            isFormTypeChangable
            initialValues={initialValues}
          />
          <Form form={form}>
            <Form.Item dependencies={['patient', 'formType']} noStyle>
              {(form) => {
                const { patient, formType } = form.getFieldsValue()
                return (
                  formType === InvoiceFormType.PATIENT_STAGE && (
                    <InvoiceTimeLine patientId={patient} />
                  )
                )
              }}
            </Form.Item>
          </Form>
        </Row>
      </Section>
    </Page>
  )
}

export default InvoiceCreate

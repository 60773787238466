import { gql, useQuery } from '@apollo/client'
import { filteredWorkingTaskTypeList } from '@sov/common'
import { Link } from '@sov/ui'
import { Form, Image, Modal, Row, Space, Spin, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form/Form'
import { isEmpty, isNil } from 'ramda'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import {
  StageTasksQueryCurrentTask,
  StageType,
  StartedEvalStagesQuery,
  StartedEvalStagesQueryVariables,
} from '../../../../../../graphql/types'
import { EmployeeSelect } from '../../../../../components/form/Select'
import Illustration from './illustration.png'
import TaskStatistics from './TaskStatistics'

const getNextOwnerLabel = (translatedTaskType: string) => {
  return (
    <span>
      下個任務 <span style={{ fontWeight: 'bold' }}>{translatedTaskType}</span>{' '}
      的負責人
    </span>
  )
}

const { StageLink } = Link

const startedEvalStages = gql`
  query StartedEvalStages($patientId: ID!) {
    evalStages(
      query: { status: [STARTED], patient: $patientId }
      sort: "serialNumber"
    ) {
      docs {
        id
        ...StageLink
      }
    }
  }
  ${StageLink.fragment}
`

interface ForwardTaskModalProps {
  form: FormInstance
  currentTask: StageTasksQueryCurrentTask
  visible: boolean
  stageType: StageType
  handleForward: (currentTaskId: string) => void
  handleCloseModal: () => void
  handleRefetch: () => void
}

const ForwardTaskModal: FC<ForwardTaskModalProps> = (props) => {
  const {
    form,
    visible,
    stageType,
    currentTask,
    handleForward,
    handleCloseModal,
  } = props
  const { t } = useTranslation()
  const history = useHistory()

  const {
    data: startedEvalStagesQueryData,
    loading: startedEvalStagesQueryloading,
  } = useQuery<StartedEvalStagesQuery, StartedEvalStagesQueryVariables>(
    startedEvalStages,
    {
      variables: { patientId: currentTask.patient.id },
      skip: stageType !== StageType.Mold,
    }
  )

  const isShowingTaskStatistic =
    currentTask.nextTask &&
    filteredWorkingTaskTypeList.includes(currentTask.nextTask?.type)

  const evalStages = startedEvalStagesQueryData?.evalStages?.docs ?? []
  const showEvalStageLinks =
    !isEmpty(evalStages) && stageType === StageType.Mold

  const handleOk = () => {
    handleForward(currentTask.id)
    if (isNil(currentTask.nextTask) && showEvalStageLinks) {
      history.push(
        `/patients/${currentTask.patient.id}/stages/${evalStages[0].id}`
      )
    }
  }

  const modalWidth = isShowingTaskStatistic ? 1000 : undefined

  return (
    <Modal
      visible={visible}
      title='任務完成'
      onCancel={handleCloseModal}
      onOk={handleOk}
      width={modalWidth}
      style={{ top: 30 }}
    >
      {isNil(currentTask.nextTask) ? (
        /** 最後一個任務 */
        <>
          <Row justify='center'>
            <Image width={200} src={Illustration} />
          </Row>
          <Row justify='center'>
            <Typography.Text>請確認任務已全部完成</Typography.Text>
          </Row>
          <Row justify='center'>
            <Typography.Text type='danger'>
              最後一個任務送出後，將無法退回
            </Typography.Text>
          </Row>
          <Row justify='center'>
            {startedEvalStagesQueryloading && <Spin />}
            {showEvalStageLinks && (
              <div>
                進行中報告：
                <Space>
                  {evalStages.map((evalStage) => (
                    <StageLink key={evalStage.id} item={evalStage} />
                  ))}
                </Space>
              </div>
            )}
          </Row>
        </>
      ) : (
        /** 不是最後一個任務 */
        <Form form={form}>
          <Form.Item
            name='nextOwner'
            label={getNextOwnerLabel(
              t(`task.type.${currentTask.nextTask.type}`)
            )}
            rules={[{ required: true, message: '必填欄位' }]}
          >
            <EmployeeSelect
              query={{
                taskOwner: [currentTask.nextTask.type],
              }}
            />
          </Form.Item>
          {isShowingTaskStatistic && (
            <TaskStatistics currentTask={currentTask} />
          )}
        </Form>
      )}
    </Modal>
  )
}

export default ForwardTaskModal

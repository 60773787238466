import { blue, grey } from '@ant-design/colors'
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form'
import { InfoCircleOutlined } from '@ant-design/icons'
import { AntSorterType, TableQuery } from '@sov/common'
import { Badge, Row, Table, Tooltip } from 'antd'
import { PresetStatusColorType } from 'antd/lib/_util/colors'
import { ColumnProps, TableProps } from 'antd/lib/table'
import { SortOrder } from 'antd/lib/table/interface'
import moment from 'moment'
import { always, cond, equals, includes, map, pick } from 'ramda'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  Appointment,
  BindingStagesQuery,
  OrderFragment,
  OrderStatus,
  OrderType,
} from '../../../../graphql/types'
import {
  OrderFilterType,
  OrderSorterField,
  TabTypes,
} from '../../../pages/order'
import ClinicLink from '../../link/clinic'
import EmployeeLink from '../../link/employee'
import PatientLink from '../../link/patient'
import BindingDisplayCell from './BindingDisplayCell'
import {
  DueDateDisplayCell,
  EditableCell,
  OrderForm,
  StageOrderDisplayCell,
} from './TableCells'
import EditableRow from './TableRow'
import { getDisplayColumns, getEditableColumns } from './utils'

const StyledTable = styled(Table)`
  ul.ant-pagination.ant-table-pagination {
    margin: 8px 0;
  }

  .new {
    &:hover {
      td {
        background-color: rgb(255, 251, 230) !important;
      }
    }
  }

  .editable-row {
    &:hover {
      .editable-cell {
        cursor: pointer;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 4px 10px;
      }
    }

    .column-action {
      display: flex;
      justify-content: space-around;

      span {
        font-size: 24px;

        &:hover {
          transform: scale(1.1);
        }
      }

      span.anticon-undo:hover {
        color: green;
      }

      span.anticon-copy:hover {
        color: #1890ff;
      }

      span.anticon-delete:hover {
        color: #e02020;
      }
    }
  }

  .ant-form-item {
    margin: 0;
  }
` as (props: TableProps<IOrder>) => JSX.Element

export enum ColumnKeys {
  CLINIC = 'clinic',
  PATIENT = 'patient',
  SALES = 'sales',
  APPOINTMENTDATE = 'appointmentDate',
  TYPE = 'type',
  DESCRIPTION = 'description',
  STAGEORDER = 'stageOrder',
  STAGEPROVIDE = 'stageProvide',
  DUEDATE = 'dueDate',
  STATUS = 'status',
  ACTION = 'action',
}

export type BindingCellStageList =
  Required<BindingStagesQuery>['stages']['docs']

export type IOrder = OrderFragment & {
  appointment?: Pick<Appointment, 'id' | 'startDate'>
  bindingStageList?: BindingCellStageList
  isBindingStageListLoading?: boolean
}
interface OrderTableProps {
  className?: string
  loading: boolean
  tab: TabTypes
  source?: {
    limit: number
    page: number
    total: number
  }
  filterInfo?: OrderFilterType
  sortInfo?: AntSorterType<OrderSorterField>
  appointmentSortOrder?: SortOrder
  handleSortAppointment: () => void
  handleTableChange: TableProps<IOrder>['onChange']

  // useOrder props
  orders: IOrder[]
  isOrderNew: (orderId: string) => boolean
  editOrder: (order: WrappedFormUtils<OrderForm>) => void
  undoOrder: (orderId: string) => void
  copyOrder: (orderId: string) => void
  deleteOrder: (orderId: string) => void
}

const defaultSource = {
  page: 1,
  total: 1,
  limit: 10,
}

const OrderTable = (props: OrderTableProps) => {
  const {
    className,
    loading,
    tab,
    source = defaultSource,
    filterInfo = {},
    sortInfo,
    appointmentSortOrder,
    handleSortAppointment,
    handleTableChange,
    // useOrder props
    orders,
    isOrderNew,
    editOrder,
    undoOrder,
    copyOrder,
    deleteOrder,
  } = props
  const { page, total, limit } = source
  const { t } = useTranslation()

  const orderTypeFilters = map(
    (type) => ({
      text: t(`order.type.${type}`),
      value: type,
    }),
    Object.values(OrderType)
  )

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }

  const customColumns: ColumnProps<IOrder>[] = [
    {
      title: '診所名稱',
      key: ColumnKeys.CLINIC,
      dataIndex: ColumnKeys.CLINIC,
      width: '115px',
      render: (text, record) =>
        record.patient.clinic.id ? (
          <ClinicLink
            target='_blank'
            style={{ color: blue[5] }}
            item={record.patient.clinic}
          />
        ) : (
          <div style={{ color: grey[0] }}>請選擇診所</div>
        ),
    },
    {
      title: '病患名稱',
      key: ColumnKeys.PATIENT,
      dataIndex: ColumnKeys.PATIENT,
      width: '80px',
      render: (text, record) =>
        record.patient.id ? (
          <PatientLink
            target='_blank'
            style={{ color: blue[5] }}
            item={record.patient}
          />
        ) : (
          <div style={{ color: grey[0] }}>請選擇病患</div>
        ),
    },
    {
      title: '綁定工單',
      key: ColumnKeys.STAGEPROVIDE,
      width: '115px',
      dataIndex: ColumnKeys.STAGEPROVIDE,
      render: (text, record) => <BindingDisplayCell order={record} />,
    },
    {
      title: '訂單類型',
      key: ColumnKeys.TYPE,
      width: tab === TabTypes.LATEST ? '150px' : '110px',
      dataIndex: ColumnKeys.TYPE,
      filters: orderTypeFilters,
      filteredValue: filterInfo.type ?? [],
      render: (text, record) => <div>{t(`order.type.${record.type}`)}</div>,
    },
    {
      title: '訂單詳情',
      key: ColumnKeys.STAGEORDER,
      width: '80px',
      dataIndex: ColumnKeys.STAGEORDER,
      render: (text, record) => <StageOrderDisplayCell order={record} />,
    },
    {
      title: '備註',
      key: ColumnKeys.DESCRIPTION,
      dataIndex: ColumnKeys.DESCRIPTION,
      width: '180px',
      render: (text, record) =>
        record.description ? (
          <div>{record.description}</div>
        ) : (
          <div style={{ color: grey[0] }}>請輸入備註</div>
        ),
    },
    {
      title: () => (
        <Row justify='center'>
          預計出貨
          <Tooltip title='上次出貨距今 30 天以上時，會標註成紅色'>
            <InfoCircleOutlined />
          </Tooltip>
        </Row>
      ),
      align: 'center',
      key: ColumnKeys.DUEDATE,
      dataIndex: ColumnKeys.DUEDATE,
      width: '90px',
      sortOrder:
        sortInfo?.field === ColumnKeys.DUEDATE ? sortInfo.order : undefined,
      sorter: true,
      render: (text, record) => <DueDateDisplayCell order={record} />,
    },
    {
      title: `預計回診日期${
        appointmentSortOrder
          ? appointmentSortOrder === 'ascend'
            ? '↑'
            : '↓'
          : ''
      }`,
      key: ColumnKeys.APPOINTMENTDATE,
      width: tab === TabTypes.LATEST ? '130px' : '100px',
      dataIndex: ColumnKeys.APPOINTMENTDATE,
      // local sorting, without refetch
      onHeaderCell: () => ({
        onClick: () => handleSortAppointment(),
        style: { cursor: 'pointer' },
      }),
      // 約診日期不會有時間區間，直接取 startDate 當作約診日
      render: (text, record) =>
        record.appointment ? (
          <div>
            <div>{moment(record.appointment.startDate).format('YYYYMMDD')}</div>
            <div>{moment(record.appointment.startDate).format('HH:mm')}</div>
          </div>
        ) : (
          <div style={{ color: grey[0] }}>請選擇日期</div>
        ),
    },
    {
      title: '負責業務',
      key: ColumnKeys.SALES,
      dataIndex: ColumnKeys.SALES,
      width: '100px',
      render: (text, record) => (
        <EmployeeLink
          target='_blank'
          style={{ color: blue[5] }}
          item={record.patient.sales}
        />
      ),
    },
    {
      title: '訂單狀態',
      key: ColumnKeys.STATUS,
      dataIndex: ColumnKeys.STATUS,
      width: '70px',
      align: 'center',
      filters: map(
        (status) => ({
          text: t(`order.status.${status}`),
          value: status,
        }),
        Object.values(OrderStatus)
      ),
      filteredValue: filterInfo.status ?? [],
      filterMultiple: true,
      render: (text) => {
        const status = cond<OrderStatus, PresetStatusColorType>([
          [
            equals<OrderStatus>(OrderStatus.Completed),
            always<PresetStatusColorType>('success'),
          ],
          [
            equals<OrderStatus>(OrderStatus.InProgress),
            always<PresetStatusColorType>('processing'),
          ],
          [
            equals<OrderStatus>(OrderStatus.Pending),
            always<PresetStatusColorType>('error'),
          ],
        ])(text)
        return (
          <div>
            <Badge status={status} />
            {t(`order.status.${text}`)}
          </div>
        )
      },
    },
    {
      title: '操作',
      width: '60px',
      align: 'center',
      key: ColumnKeys.ACTION,
      dataIndex: ColumnKeys.ACTION,
    },
  ]

  const displayColumns = customColumns.filter((col) =>
    includes(col.key, getDisplayColumns(tab))
  )
  // @ts-ignore
  const tableColumns: ColumnProps<IOrder>[] = displayColumns.map((col) => ({
    ...col,
    onCell: (record) => ({
      record,
      editable: includes(col.key, getEditableColumns(tab, record)),
      columnKey: col.key,
    }),
  }))

  const getRowProps = (order: IOrder) => ({
    order,
    loading,
    tab,
    isOrderNew,
    editOrder,
    undoOrder,
    copyOrder,
    deleteOrder,
  })

  return (
    <StyledTable
      className={className}
      rowKey='id'
      size='small'
      loading={loading}
      columns={tableColumns}
      components={components}
      // @ts-ignore
      onRow={(order) => getRowProps(order)}
      rowClassName={({ id }) =>
        isOrderNew(id) ? 'editable-row new' : 'editable-row'
      }
      dataSource={orders}
      locale={{ emptyText: '系統中無任何訂單' }}
      pagination={TableQuery.getAntdPagination({ page, total, limit })}
      onChange={handleTableChange}
    />
  )
}

const pickCompareProps = pick(['loading', 'tab', 'source', 'orders'])

export default memo(OrderTable, (prev, next) =>
  equals(pickCompareProps(prev), pickCompareProps(next))
)

import { PlusOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import {
  ErrorHandling,
  FiltersParam,
  FormQuery,
  MyStringParam,
  TableQuery,
} from '@sov/common'
import { Button, Card, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'

import {
  AccountsQueryQuery,
  AccountsQueryVariables,
} from '../../../../graphql/types'
import BreadcrumbCreator from '../../../components/layout/BreadcrumbCreator'
import Page from '../../../components/layout/Page'
import Title from '../../../components/layout/Title'
import AccountQueryForm, { FormInputType } from './AccountQueryForm'
import AccountTable, {
  AccountFilterType,
  AccountSorterField,
} from './AccountTable'

const CreateAccountButton = styled(Button)`
  justify-content: right;
`

const formInput = {
  entityName: MyStringParam,
  filters: FiltersParam<AccountFilterType>(),
}

export const accountsQuery = gql`
  query AccountsQuery(
    $query: AccountsQuery = {}
    $page: Int
    $limit: Int
    $sort: String
  ) {
    accounts(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...AccountTableInfo
      }
      total
      limit
      page
    }
  }
  ${AccountTable.fragments.AccountTableInfo}
`

const Account = () => {
  const { tableQuery, paginateQuery, handleTableChange } =
    TableQuery.useTableQuery<AccountFilterType, AccountSorterField>({
      limit: 30,
      sort: '-created',
    })
  const { formQuery, handleFormChange } = FormQuery.useFormQuery(formInput)

  const variables: AccountsQueryVariables = {
    query: {
      entityName: formQuery.entityName,
      ...formQuery.filters,
      ...tableQuery.filters,
    },
    ...paginateQuery,
  }

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading } = useQuery<
    AccountsQueryQuery,
    AccountsQueryVariables
  >(accountsQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables,
  })

  const handleSearch = (payload: FormInputType) => {
    handleFormChange(payload)
  }

  return (
    <Page
      header={
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'AccountList' }]}
          />
          <Title route={{ key: 'AccountList' }} />
        </>
      }
    >
      <Card size='small'>
        <Row justify='space-between'>
          <AccountQueryForm formQuery={formQuery} handleSearch={handleSearch} />
          <CreateAccountButton type='primary' href='/accounts/create'>
            <PlusOutlined />
            新增帳戶
          </CreateAccountButton>
        </Row>
        <br />
        <AccountTable
          source={data?.accounts}
          loading={loading}
          handleTableChange={handleTableChange}
          tableFilterInfo={tableQuery.filters}
          sortInfo={tableQuery.sort}
        />
      </Card>
    </Page>
  )
}

export default Account

import { gql } from '@apollo/client'
import { LeaveTools } from '@sov/common'
import { holidayUtils } from '@sov/utils'
import { DatePicker, Form, Input, InputNumber, Select, Tag } from 'antd'
import { FormInstance } from 'antd/lib/form/Form'
import baseMoment from 'moment'
import business from 'moment-business'
import { extendMoment } from 'moment-range'
import { filter } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CreateLeaveInput, LeaveType } from '../../../../graphql/types'

// @ts-ignore
const moment = extendMoment(baseMoment)

const TextArea = Input.TextArea
const Option = Select.Option

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
}

type FormGeneralField = Pick<CreateLeaveInput, 'startDate' | 'type' | 'reason'>

export interface FormGeneralInitialValues extends Partial<FormGeneralField> {
  from?: 'am' | 'pm'
  days?: number | undefined
}

interface Props {
  isApproved?: boolean
  initialValues: FormGeneralInitialValues
  maxRemainAnnual?: number
  form?: FormInstance
}

interface DateInterval {
  startDate: string
  endDate: string
}

const formatInputNumber = (number) => {
  if (number) {
    const remainder = number % 1
    if (remainder !== 0 && remainder < 0.5) {
      return number - remainder + 0.5
    } else if (remainder !== 0 && remainder > 0.5) {
      return number - remainder + 1
    }
  }
  return number
}

// 不能補請一個月前的假
const disabledDate = (current: moment.Moment | undefined) => {
  return current ? current < moment().subtract(1, 'month').endOf('day') : false
}

// transform leave dataInterval: {startDate, endDate} into leave date array [moment]
// @TODO 需要重構, 裡面有 imperitive
const dateIntervalToDateArray = ({ startDate, endDate }: DateInterval) => {
  // @ts-ignore
  const leaveDates = filter(
    (date) => business.isWeekDay(date) || holidayUtils.isForceBusinessDay(date),
    Array.from(moment.range(moment(startDate), moment(endDate)).by('days'))
  )

  console.log(leaveDates)
  return leaveDates
}

const DatePreview = (props) => {
  return (
    <div>
      {props.dateSource.map((date, index) => {
        return <Tag key={index}>{moment(date).format('M/D(dd)')}</Tag>
      })}
    </div>
  )
}

export const LeaveForm = ({
  form,
  isApproved,
  initialValues,
  maxRemainAnnual,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Form {...formItemLayout} form={form} initialValues={initialValues}>
      <Form.Item
        label='日期'
        name='startDate'
        rules={[{ required: true, message: '請選擇' }]}
      >
        <DatePicker
          disabled={isApproved}
          disabledDate={disabledDate}
          format='YYYY-MM-DD'
          placeholder='選擇日期'
        />
      </Form.Item>
      <Form.Item
        label='開始於'
        name='from'
        rules={[{ required: true, message: '請選擇' }]}
      >
        <Select
          disabled={isApproved}
          style={{ width: '100px' }}
          placeholder='選擇上午 / 下午'
        >
          <Option value='am'>上午</Option>
          <Option value='pm'>下午</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label='類型'
        name='type'
        rules={[{ required: true, message: '請輸入' }]}
      >
        <Select
          disabled={isApproved}
          style={{ width: 200 }}
          placeholder='選擇類型'
        >
          {Object.entries(LeaveType).map(([key, value]) => (
            <Option key={key} value={value}>
              {t(`leave.${value}`)}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item dependencies={['type']} noStyle>
        {(form) => {
          const isAnnualChoosed = form?.getFieldValue('type') === 'ANNUAL'
          const DaysInput = (props) => (
            <>
              <InputNumber
                {...props}
                disabled={isApproved}
                formatter={formatInputNumber}
                min={0.5}
                step={0.5}
                max={isAnnualChoosed ? maxRemainAnnual : 30}
              />
              {isAnnualChoosed && (
                <span style={{ color: 'rgba(0, 0, 0, 0.45)', marginLeft: 8 }}>
                  剩餘特休 {maxRemainAnnual} 天
                </span>
              )}
            </>
          )
          return (
            <>
              <Form.Item
                label='天數'
                name='days'
                rules={[{ required: true, message: '請輸入' }]}
                style={{ marginBottom: 0 }}
              >
                <DaysInput />
              </Form.Item>
            </>
          )
        }}
      </Form.Item>

      <Form.Item
        wrapperCol={{ offset: 6, span: 12 }}
        dependencies={['days', 'from', 'startDate']}
      >
        {(form) => {
          const dateInterval = LeaveTools.getDatesFromDateField(
            form.getFieldValue('startDate'),
            form.getFieldValue('days'),
            form.getFieldValue('from')
          )
          return (
            <Form.Item>
              <DatePreview dateSource={dateIntervalToDateArray(dateInterval)} />
            </Form.Item>
          )
        }}
      </Form.Item>
      <Form.Item
        label='事由'
        name='reason'
        rules={[{ required: true, message: '請輸入' }]}
      >
        <TextArea
          disabled={isApproved}
          rows={4}
          placeholder='請填寫事由 (特休填寫特休兩字即可)'
        />
      </Form.Item>
    </Form>
  )
}

LeaveForm.fragments = {
  EmployeeLeaveFormInfo: gql`
    fragment EmployeeLeaveFormInfo on Employee {
      id
      leaveStatus {
        remainAnnual
        usedSickLeave
        usedPersonalLeave
      }
    }
  `,
  LeaveFormInfo: gql`
    fragment LeaveFormInfo on Leave {
      id
      startDate
      endDate
      type
      employee {
        id
        name
      }
      reason
      isApproved
    }
  `,
}

export default LeaveForm

import { gql } from '@apollo/client'
import { Button } from 'antd'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'

import {
  CreateStageButtonGroupFragment,
  PatientStatus,
  StageType,
} from '../../../../graphql/types'
import CreateAccessoryStageModal from '../../../components/modal/CreateStage/CreateAccessoryStage'
import CreateDesignStageModal from '../../../components/modal/CreateStage/CreateDesignStage'
import CreateEvalStageModal from '../../../components/modal/CreateStage/CreateEvalStage'
import CreateMoldStageModal from '../../../components/modal/CreateStage/CreateMoldStage'
import CreatePrintStageModal from '../../../components/modal/CreateStage/CreatePrintStage'
import { authContext } from '../../../context'
import { isInternalRoles } from '../../../utils'

interface CreateStageButtonGroupProps {
  patientItem: CreateStageButtonGroupFragment
  handleSubmit: (newStageId: string) => void
  onRefresh: () => void
}

const CreateStageButtonGroup = (props: CreateStageButtonGroupProps) => {
  const { patientItem, handleSubmit, onRefresh } = props
  const auth = useContext(authContext)

  const [createStageType, setCreateStageType] = useState<StageType>()

  const handleOpenModal = (stageType: StageType) => () =>
    setCreateStageType(stageType)

  const handleCloseModal = () => {
    setCreateStageType(undefined)
  }

  const ButtonGroup = styled.div`
    button + button {
      margin-left: 8px;
    }
  `

  const handleCancel = (options?: { shouldRefresh: boolean }) => {
    handleCloseModal()
    if (options?.shouldRefresh) {
      onRefresh()
    }
  }

  const canDesign = patientItem.status === PatientStatus.OnPrint
  const canEvalAndPrint =
    patientItem.status !== PatientStatus.Completed &&
    patientItem.status !== PatientStatus.Inactive

  return (
    <ButtonGroup>
      {isInternalRoles(auth) && (
        <>
          <Button onClick={handleOpenModal(StageType.Mold)}>下建模單</Button>
          <CreateMoldStageModal
            visible={createStageType === StageType.Mold}
            patientId={patientItem.id}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
          />
          {canDesign && (
            <>
              <Button onClick={handleOpenModal(StageType.Design)}>
                下設計單
              </Button>
              <CreateDesignStageModal
                visible={createStageType === StageType.Design}
                patientId={patientItem.id}
                onCancel={handleCancel}
                onSubmit={handleSubmit}
              />
            </>
          )}
          <Button onClick={handleOpenModal(StageType.Accessory)}>
            下配件單
          </Button>
          <CreateAccessoryStageModal
            visible={createStageType === StageType.Accessory}
            patientId={patientItem.id}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
          />
        </>
      )}
      {canEvalAndPrint && (
        <Button danger onClick={handleOpenModal(StageType.Eval)}>
          下報告單
        </Button>
      )}
      <CreateEvalStageModal
        visible={createStageType === StageType.Eval}
        patientId={patientItem.id}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      />
      {canEvalAndPrint && (
        <Button type='primary' onClick={handleOpenModal(StageType.Print)}>
          下製造單
        </Button>
      )}
      <CreatePrintStageModal
        visible={createStageType === StageType.Print}
        patientId={patientItem.id}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      />
    </ButtonGroup>
  )
}

CreateStageButtonGroup.fragments = {
  CreateStageButtonGroup: gql`
    fragment CreateStageButtonGroup on Patient {
      id
      status
    }
  `,
}

export default CreateStageButtonGroup

import { getEmptyText } from '@sov/common'
import React from 'react'
import { Link } from 'react-router-dom'
import { DeepPartial } from 'utility-types'

import { Patient } from '../../../../graphql/types'

type IPatient = DeepPartial<Patient>

interface Props {
  item?: IPatient
  target?: string
  style?: { [key: string]: string | number }
  children?: React.ReactNode
}

const PatientLink = ({ item, target = '_self', style, children }: Props) => {
  const defaultStyle = { color: 'black' }

  if (item) {
    const id = item.id

    return (
      <Link
        target={target}
        style={{ ...defaultStyle, ...style }}
        to={`/patients/${id}`}
      >
        {children || item.name}
      </Link>
    )
  } else {
    return <div>{getEmptyText()}</div>
  }
}

export default PatientLink

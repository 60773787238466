import { gql, useMutation } from '@apollo/client'

import {
  StageType,
  UpdateExpectedShippingDateOfAccessoryStageMutation,
  UpdateExpectedShippingDateOfAccessoryStageMutationVariables,
  UpdateExpectedShippingDateOfDesignStageMutation,
  UpdateExpectedShippingDateOfDesignStageMutationVariables,
  UpdateExpectedShippingDateOfEvalStageMutation,
  UpdateExpectedShippingDateOfEvalStageMutationVariables,
  UpdateExpectedShippingDateOfMoldStageMutation,
  UpdateExpectedShippingDateOfMoldStageMutationVariables,
  UpdateExpectedShippingDateOfPrintStageMutation,
  UpdateExpectedShippingDateOfPrintStageMutationVariables,
} from '../../../../graphql/types'

const updateExpectedShippingDateOfAccessoryStage = gql`
  mutation UpdateExpectedShippingDateOfAccessoryStage(
    $id: ID!
    $payload: UpdateAccessoryStageInput!
  ) {
    updateAccessoryStage(id: $id, payload: $payload) {
      id
    }
  }
`

const updateExpectedShippingDateOfDesignStage = gql`
  mutation UpdateExpectedShippingDateOfDesignStage(
    $id: ID!
    $payload: UpdateDesignStageInput!
  ) {
    updateDesignStage(id: $id, payload: $payload) {
      id
    }
  }
`

const updateExpectedShippingDateOfEvalStage = gql`
  mutation UpdateExpectedShippingDateOfEvalStage(
    $id: ID!
    $payload: UpdateEvalStageInput!
  ) {
    updateEvalStage(id: $id, payload: $payload) {
      id
    }
  }
`

const updateExpectedShippingDateOfMoldStage = gql`
  mutation UpdateExpectedShippingDateOfMoldStage(
    $id: ID!
    $payload: UpdateMoldStageInput!
  ) {
    updateMoldStage(id: $id, payload: $payload) {
      id
    }
  }
`

const updateExpectedShippingDateOfPrintStage = gql`
  mutation UpdateExpectedShippingDateOfPrintStage(
    $id: ID!
    $payload: UpdatePrintStageInput!
  ) {
    updatePrintStage(id: $id, payload: $payload) {
      id
    }
  }
`

export const useUpdateStage = (stageType: StageType) => {
  const updateAccessoryStage = useMutation<
    UpdateExpectedShippingDateOfAccessoryStageMutation,
    UpdateExpectedShippingDateOfAccessoryStageMutationVariables
  >(updateExpectedShippingDateOfAccessoryStage)
  const updateDesignStage = useMutation<
    UpdateExpectedShippingDateOfDesignStageMutation,
    UpdateExpectedShippingDateOfDesignStageMutationVariables
  >(updateExpectedShippingDateOfDesignStage)
  const updateEvalStage = useMutation<
    UpdateExpectedShippingDateOfEvalStageMutation,
    UpdateExpectedShippingDateOfEvalStageMutationVariables
  >(updateExpectedShippingDateOfEvalStage)
  const updateMoldStage = useMutation<
    UpdateExpectedShippingDateOfMoldStageMutation,
    UpdateExpectedShippingDateOfMoldStageMutationVariables
  >(updateExpectedShippingDateOfMoldStage)
  const updatePrintStage = useMutation<
    UpdateExpectedShippingDateOfPrintStageMutation,
    UpdateExpectedShippingDateOfPrintStageMutationVariables
  >(updateExpectedShippingDateOfPrintStage)

  const updateStage = {
    [StageType.Accessory]: updateAccessoryStage,
    [StageType.Design]: updateDesignStage,
    [StageType.Eval]: updateEvalStage,
    [StageType.Mold]: updateMoldStage,
    [StageType.Print]: updatePrintStage,
  }

  return updateStage[stageType]
}

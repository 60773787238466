import { blue } from '@ant-design/colors'
import { gql } from '@apollo/client'
import { Link as CustomLink } from '@sov/ui'
import { Button, Modal, Typography } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import { UploadFileStatus } from 'antd/lib/upload/interface'
import { map } from 'ramda'
import React from 'react'
import styled from 'styled-components'

import {
  JawPosition,
  ViewerFilesOverviewFragment,
} from '../../../graphql/types'
import ViewerFilesTable from './ViewerFilesTable'

const { Text } = Typography

const StageInfo = styled(Text)`
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;

  .custom-link {
    margin-right: 8px;
    font-weight: bold;
  }
`

const ReferenceStage = styled.div`
  padding-bottom: 16px;
`

const ButtonGroup = styled.div`
  display: flex;
  padding-top: 32px;

  a {
    margin: 0 8px;
  }
  a:first-child {
    margin-left: 0;
  }
  a:last-child {
    margin-right: 0;
  }
`

interface ViewerFilesOverviewProps extends ModalProps {
  stageItem: ViewerFilesOverviewFragment
  handleCloseModal: () => void
}

const ViewerFilesOverview = (props: ViewerFilesOverviewProps) => {
  const { stageItem, handleCloseModal, ...restProps } = props

  if (
    stageItem.__typename === 'AccessoryStage' ||
    stageItem.__typename === 'PrintStage'
  ) {
    return null
  }

  const getFileCount = () => {
    let fileCount = stageItem.files?.teeth?.length || 0
    if (stageItem.files?.upperJawModel) {
      fileCount += 1
    }
    if (stageItem.files?.lowerJawModel) {
      fileCount += 1
    }
    return fileCount
  }

  const files = [
    ...map(
      (tooth) => ({
        position: tooth.position,
        status: 'done' as UploadFileStatus,
      }),
      stageItem.files?.teeth || []
    ),
    ...(stageItem.files?.upperJawModel
      ? [
          {
            position: JawPosition.Upper,
            status: 'done' as UploadFileStatus,
          },
        ]
      : []),
    ...(stageItem.files?.lowerJawModel
      ? [
          {
            position: JawPosition.Lower,
            status: 'done' as UploadFileStatus,
          },
        ]
      : []),
  ]

  return (
    <Modal
      {...restProps}
      width={800}
      closable={false}
      title={false}
      footer={false}
      onCancel={handleCloseModal}
    >
      <StageInfo>
        <span>
          <CustomLink.PatientLink
            className='custom-link'
            item={stageItem.patient}
            style={{ color: 'black' }}
          />
          <CustomLink.StageLink
            className='custom-link'
            item={stageItem}
            style={{ color: blue[5] }}
          />
          上傳的牙位檔案：
        </span>
        <span>
          共計 <strong>{getFileCount()}</strong> 個檔案
        </span>
      </StageInfo>
      {stageItem.__typename === 'EvalStage' && stageItem.moldStage && (
        <ReferenceStage>
          參考建模單：
          <CustomLink.StageLink
            item={stageItem.moldStage}
            style={{ color: blue[5] }}
          />
        </ReferenceStage>
      )}
      {stageItem.__typename === 'DesignStage' && stageItem.evalStage && (
        <ReferenceStage>
          參考報告單：
          <CustomLink.StageLink
            item={stageItem.evalStage}
            style={{ color: blue[5] }}
          />
        </ReferenceStage>
      )}
      <ViewerFilesTable files={files} />
      <ButtonGroup>
        <Button
          block
          href={`/patients/${stageItem.patient.id}/stages/${stageItem.id}`}
          target='_blank'
        >
          編輯檔案
        </Button>
        <Button
          type='primary'
          block
          href={`/viewer?patientId=${stageItem.patient.id}&stageId=${stageItem.id}`}
          target='_blank'
        >
          Viewer 檢視
        </Button>
      </ButtonGroup>
    </Modal>
  )
}

ViewerFilesOverview.fragments = {
  ViewerFilesOverview: gql`
    fragment ViewerFilesOverview on Stage {
      id
      __typename
      ...StageLink
      patient {
        ...PatientLink
      }

      ... on MoldStage {
        files {
          upperJawModel {
            path
          }
          lowerJawModel {
            path
          }
          teeth {
            position
            file {
              path
            }
          }
        }
      }

      ... on EvalStage {
        moldStage {
          ...StageLink
        }
        files {
          upperJawModel {
            path
          }
          lowerJawModel {
            path
          }
          teeth {
            position
            file {
              path
            }
          }
        }
      }

      ... on DesignStage {
        evalStage {
          ...StageLink
        }
        files {
          upperJawModel {
            path
          }
          lowerJawModel {
            path
          }
          teeth {
            position
            file {
              path
            }
          }
        }
      }
    }
    ${CustomLink.PatientLink.fragment}
    ${CustomLink.StageLink.fragment}
  `,
}

export default ViewerFilesOverview

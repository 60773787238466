import React from 'react'

import icons from './images'

const SocialIcon = (props) => {
  const { name, style, disabled } = props
  const data = icons[name]
  const { normal, gray } = data
  const defaultStyle = { width: '20px', height: '20px' }
  return disabled ? (
    <img style={{ ...defaultStyle, ...style }} src={gray} />
  ) : (
    <img style={{ ...defaultStyle, ...style }} src={normal} />
  )
}

export default SocialIcon

import { gql } from '@apollo/client'
import { AntSorterType, TableQuery } from '@sov/common'
import { TablePatientInfo } from '@sov/ui'
import { Button, Table } from 'antd'
import Badge from 'antd/lib/badge'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'

import { AppointmentTableFragment } from '../../../../graphql/types'

type Record = AppointmentTableFragment
type AppointmentFilterType = any
type AppointmentSorterField = 'startDate'

interface Props {
  source?: {
    docs: Record[]
    page: number
    total: number
    limit: number
  }
  sortInfo?: AntSorterType<AppointmentSorterField>
  filterInfo?: AppointmentFilterType
  handleTableChange: any
  loading: boolean
  handleSelect: (item: AppointmentTableFragment) => void
}

const AppointmentTable = (props: Props) => {
  const defaultSource = {
    docs: [],
    page: 1,
    total: 1,
    limit: 10,
  }
  const {
    source = defaultSource,
    handleTableChange,
    loading,
    handleSelect,
  } = props
  const { docs, page, total, limit } = source

  const columns: ColumnProps<AppointmentTableFragment>[] = [
    {
      align: 'center',
      title: '病患',
      width: 120,
      dataIndex: 'patient',
      key: 'patient',
      render: (_text, record) => {
        return <TablePatientInfo patient={record.patient} />
      },
    },
    {
      align: 'center',
      title: '約診日期',
      width: 120,
      dataIndex: 'startDate',
      key: 'startDate',
      render: (_text, record) => {
        return <div>{moment(record.startDate).format('YYYY-MM-DD')}</div>
      },
    },
    {
      align: 'center',
      title: '約診時間',
      width: 120,
      dataIndex: 'startDate',
      key: 'startDate2',
      render: (_text, record) => {
        return (
          <div>
            {moment(record.startDate).isSame(
              moment(record.startDate).startOf('d')
            )
              ? '-'
              : `${moment(record.startDate).format('HH:mm')} ~ ${moment(
                  record.endDate
                ).format('HH:mm')}`}
          </div>
        )
      },
    },
    {
      align: 'left',
      title: '備註',
      width: 200,
      dataIndex: 'description',
      key: 'description',
      render: (_text, record) => {
        return `${record.description}`
      },
    },
    {
      align: 'center',
      title: '新增人員',
      width: 60,
      dataIndex: 'creator',
      key: 'creator',
      render: (_text, record) => {
        return `${record.creator.name}`
      },
    },
    {
      align: 'center',
      title: '狀態',
      width: 60,
      dataIndex: 'isAttended',
      key: 'isAttended',
      render: (_text, record) => {
        return (
          <div>
            {record.isAttended ? (
              <>
                <Badge status='success' />
                已到診
              </>
            ) : (
              <>
                <Badge status='processing' />
                未到診
              </>
            )}
          </div>
        )
      },
    },
    {
      align: 'center',
      title: '操作',
      width: 60,
      dataIndex: 'action',
      key: 'action',
      render: (_text, record) => {
        return (
          <Button
            type='primary'
            size='small'
            onClick={() => handleSelect(record)}
          >
            編輯
          </Button>
        )
      },
    },
  ]

  return (
    <Table<Record>
      rowKey='id'
      columns={columns as any}
      dataSource={docs}
      loading={loading}
      locale={{ emptyText: '系統中無任何診所' }}
      pagination={TableQuery.getAntdPagination({ page, total, limit })}
      onChange={handleTableChange}
    />
  )
}

AppointmentTable.fragment = {
  AppointmentTable: gql`
    fragment AppointmentTable on Appointment {
      id
      startDate
      endDate
      description
      isAttended
      patient {
        ...TablePatientInfo
      }
      creator {
        id
        name
      }
    }
    ${TablePatientInfo.fragment}
  `,
}

export default AppointmentTable

import { gql } from '@apollo/client'
import { AntSorterType, getEmptyText, TableQuery } from '@sov/common'
import { Link as customLink } from '@sov/ui'
import { Button, Table, Tag } from 'antd'
import { ColumnProps, TableProps } from 'antd/lib/table'
import moment from 'moment'
import { identity } from 'ramda'
import React from 'react'
import { Link } from 'react-router-dom'

import { InvoiceTableFragment } from '../../../../graphql/types'
import DoctorLink from '../../link/doctor'
import EmployeeLink from '../../link/employee'

const { ClinicLink, PatientLink, StageLink } = customLink

export type InvoiceSortType =
  | 'autoIncrement'
  | 'middlemanFee'
  | 'totalPrice'
  | 'creditDate'

interface Props extends TableProps<InvoiceTableFragment> {
  handleChange?: TableProps<InvoiceTableFragment>['onChange']
  sortInfo?: AntSorterType<InvoiceSortType>
  source?: {
    docs: InvoiceTableFragment[]
    page?: number
    total: number
    limit: number
  }
}

const defaultSource = {
  docs: [],
  page: 1,
  total: 1,
  limit: 10,
}

export function InvoiceTable({
  handleChange = identity,
  loading,
  scroll,
  sortInfo,
  source = defaultSource,
}: Props) {
  const columns: ColumnProps<InvoiceTableFragment>[] = [
    {
      title: '流水號',
      width: 30,
      key: 'autoIncrement',
      dataIndex: 'autoIncrement',
      sortOrder:
        sortInfo?.field === 'autoIncrement' ? sortInfo.order : undefined,
      sorter: (a, b) => (a.autoIncrement > b.autoIncrement ? 1 : -1),
      render: (_, record) => <div>{record.autoIncrement}</div>,
    },
    {
      title: '診所',
      width: 60,
      key: 'clinic',
      render: (_, record) => <ClinicLink item={record.clinic} />,
    },
    {
      title: '醫師',
      width: 60,
      key: 'doctor',
      render: (_, record) => <DoctorLink item={record.doctor} />,
    },
    {
      title: '業務',
      width: 60,
      key: 'sales',
      render: (_, record) => <EmployeeLink item={record.sales} />,
    },
    {
      title: '客服',
      width: 60,
      key: 'customerService',
      render: (_, record) => <EmployeeLink item={record.customerService} />,
    },
    {
      title: '病患',
      width: 60,
      key: 'patient',
      render: (_, record) =>
        record.__typename === 'InvoiceWithStage' ? (
          <PatientLink item={record.patient} />
        ) : (
          <div>{getEmptyText()}</div>
        ),
    },
    {
      title: '工單',
      width: 100,
      key: 'stage',
      render: (_, record) =>
        record.__typename === 'InvoiceWithStage' ? (
          <StageLink item={record.stage} />
        ) : (
          <div>{getEmptyText()}</div>
        ),
    },
    {
      title: '出貨品項',
      width: 150,
      key: 'products',
      dataIndex: 'products',
      render: (_, record) => (
        <div>
          {record.products.map((item) => (
            <div key={item.productId}>
              <Tag key={item.productId} style={{ marginBottom: 4 }}>
                {`[${item.serialNumber}] ${item.name} ($${item.price} x ${item.count})`}
              </Tag>
            </div>
          ))}
        </div>
      ),
    },
    {
      title: '轉介費',
      width: 60,
      dataIndex: 'middlemanFee',
      key: 'middlemanFee',
      sortOrder:
        sortInfo?.field === 'middlemanFee' ? sortInfo.order : undefined,
      sorter: (a, b) => {
        if (isNaN(Number(a.middlemanFee)) || isNaN(Number(b.middlemanFee))) {
          return 1
        }
        return Number(a.middlemanFee) > Number(b.middlemanFee) ? 1 : -1
      },
    },
    {
      title: '總金額',
      width: 60,
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      sortOrder: sortInfo?.field === 'totalPrice' ? sortInfo.order : undefined,
      sorter: (a, b) => {
        if (isNaN(Number(a.totalPrice)) || isNaN(Number(b.totalPrice))) {
          return 1
        }
        return Number(a.totalPrice) > Number(b.totalPrice) ? 1 : -1
      },
    },
    {
      title: '遞延收入',
      width: 60,
      dataIndex: 'deferredRevenue',
      key: 'deferredRevenue',
      render: (_, record) => <div>{record.deferredRevenue ?? '-'}</div>,
    },
    {
      title: '出貨日',
      width: 100,
      dataIndex: 'shippingDate',
      key: 'shippingDate',
      sortOrder:
        sortInfo?.field === 'shippingDate' ? sortInfo.order : undefined,
      sorter: (a, b) => (a.shippingDate > b.shippingDate ? 1 : -1),
      render: (text) => (
        <div>{text ? moment(text).format('YYYY-MM-DD') : getEmptyText()}</div>
      ),
    },
    {
      title: '入帳日',
      width: 100,
      dataIndex: 'creditDate',
      key: 'creditDate',
      sortOrder: sortInfo?.field === 'creditDate' ? sortInfo.order : undefined,
      sorter: (a, b) => (a.creditDate > b.creditDate ? 1 : -1),
      render: (text) => (
        <div>{text ? moment(text).format('YYYY-MM-DD') : getEmptyText()}</div>
      ),
    },
    // {
    //   title: '付款情況',
    //   width: 100,
    //   dataIndex: 'payment',
    //   key: 'payment',
    //   render: (_, record) => {
    //     return <div>{`${t(`invoice.payment.${record.payment}`)}`}</div>
    //   },
    // },
    {
      title: '動作',
      width: 30,
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => {
        const linkUrl =
          record.__typename === 'InvoiceWithStage'
            ? `/patients/${record.patient.id}/stages/${record.stage.id}/invoice`
            : `/invoices/${record.id}`
        return (
          <div>
            <Button size='small' type='primary'>
              <Link to={linkUrl}>編輯</Link>
            </Button>
          </div>
        )
      },
    },
  ]

  const { docs, page, total, limit } = source

  const paginationConfig = scroll
    ? {
        current: page || 1,
        total: total || 1,
        pageSize: limit || 1,
      }
    : TableQuery.getAntdPagination({
        page: page || 1,
        total,
        limit,
      })

  return (
    <Table
      scroll={scroll}
      rowKey='id'
      size='small'
      columns={columns}
      dataSource={docs}
      loading={loading}
      locale={{ emptyText: '系統中無任何出貨單' }}
      onChange={handleChange}
      pagination={paginationConfig}
    />
  )
}

InvoiceTable.fragment = gql`
  fragment InvoiceTable on Invoice {
    id
    autoIncrement
    __typename
    middlemanFee
    totalPrice
    shippingDate
    creditDate
    payment
    deferredRevenue
    clinic {
      ...ClinicLink
    }
    doctor {
      id
      name
    }
    sales {
      id
      name
    }
    customerService {
      id
      name
    }
    products {
      productId
      serialNumber
      name
      price
      count
    }
    ... on InvoiceWithStage {
      patient {
        ...PatientLink
      }
      stage {
        id
        ...StageLink
      }
    }
  }
  ${ClinicLink.fragment}
  ${PatientLink.fragment}
  ${StageLink.fragment}
`

export default InvoiceTable

import { gql, useQuery } from '@apollo/client'
import { ErrorHandling, TableQuery } from '@sov/common'
import { Card } from 'antd'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import {
  OrdersQuery,
  PatientOrdersQueryQuery,
  PatientOrdersQueryQueryVariables,
} from '../../../graphql/types'
import OrderTable from '../../components/table/patient/OrderTable'

/* ----- query string ----- */
const patientOrdersQuery = gql`
  query PatientOrdersQuery(
    $id: ID!
    $query: OrdersQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    patient(id: $id) {
      id
      orders(query: $query, page: $page, limit: $limit, sort: $sort) {
        docs {
          ...PatientOrderTable
        }
        limit
        total
        page
      }
    }
  }
  ${OrderTable.fragment}
`

/* ----- types and component ----- */
export type OrderListFilter = Pick<OrdersQuery, 'status'>
export type OrderListSorterField = 'appointment'
interface Params {
  patientId: string
}

type Props = {
  isSidebarCollapsed: boolean
} & RouteComponentProps<Params>

const PatientOrderList = (props: Props) => {
  const { patientId } = props.match.params

  const { tableQuery, paginateQuery, handleTableChange } =
    TableQuery.useTableQuery<OrderListFilter, OrderListSorterField>({
      limit: 10,
      sort: '-created',
    })
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading } = useQuery<
    PatientOrdersQueryQuery,
    PatientOrdersQueryQueryVariables
  >(patientOrdersQuery, {
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: patientId,
      query: {
        status: tableQuery.filters?.status,
      },
      ...paginateQuery,
    },
  })

  const orders = data?.patient?.orders?.docs || []
  const antdPagination = {
    page: data?.patient?.orders?.page || 1,
    total: data?.patient?.orders?.total || 0,
    limit: data?.patient?.orders?.limit || 0,
  }

  return (
    <Card size='small'>
      <OrderTable
        loading={loading}
        data={orders}
        antdPagination={antdPagination}
        filterInfo={tableQuery.filters}
        sortInfo={tableQuery.sort}
        handleTableChange={handleTableChange}
      />
    </Card>
  )
}

export default PatientOrderList

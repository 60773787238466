import { grey, red } from '@ant-design/colors'
import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form'
import { CopyOutlined, DeleteOutlined, UndoOutlined } from '@ant-design/icons'
import { DatePicker, Input, InputNumber, Row, Select } from 'antd'
import moment from 'moment'
import { find, isNil, map, propEq, propOr, sort, sum, values } from 'ramda'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AccessoryCode,
  OrderStatus,
  OrderType,
  StageOrderContent,
  StageType,
} from '../../../../graphql/types'
import { isDueDateExpired } from '../../../pages/order/utils'
import { getSerializedStageOrderContent } from '../../../utils'
import { ClinicSelect, PatientSelect } from '../../form/Select'
import { ColumnKeys, IOrder } from '../Order'
import BindingCell from './BindingCell'
import { EditContext, EditContextType } from './TableRow'

export type StageOrderFieldType = {
  [key in StageType]: number | AccessoryCode[]
}
export type StageProvideFieldType = { [key in StageType]: string[] }

export interface OrderForm {
  id: string
  clinic: string
  patient: string
  appointmentDate: moment.Moment
  type: OrderType
  description: string
  stageOrder: StageOrderFieldType
  stageProvide: StageProvideFieldType
  dueDate: moment.Moment
}

export interface TableCellProps {
  record: IOrder
  children: JSX.Element[] | JSX.Element
}

interface EditableCellProps extends TableCellProps {
  columnKey: ColumnKeys
  editable?: boolean
}

export const EditableCell = (props: EditableCellProps) => {
  const { columnKey, record, editable, children } = props
  const { form, editing, handleToggle, handleUnDo, handleCopy, handleDelete } =
    useContext(EditContext)

  const tableCellProps = {
    record,
    children,
    // EditContextType
    form,
    editing,
    handleToggle,
    handleUnDo,
    handleCopy,
    handleDelete,
  }

  const getCell = (editable?: boolean) => {
    if (!editable) {
      return children
    }
    switch (columnKey) {
      case ColumnKeys.CLINIC:
        return <ClinicCell {...tableCellProps} />
      case ColumnKeys.PATIENT:
        return <PatientCell {...tableCellProps} />
      case ColumnKeys.APPOINTMENTDATE:
        return <AppointmentDateCell {...tableCellProps} />
      case ColumnKeys.TYPE:
        return <OrderTypeCell {...tableCellProps} />
      case ColumnKeys.DESCRIPTION:
        return <DescriptionCell {...tableCellProps} />
      case ColumnKeys.STAGEORDER:
        return <StageOrderCell {...tableCellProps} />
      case ColumnKeys.ACTION:
        return <ActionCell {...tableCellProps} />
      case ColumnKeys.STAGEPROVIDE:
        return <BindingCell {...tableCellProps} />
      default:
        console.error(`可編輯的欄位 "${columnKey}"，未給予編輯用元件`)
        return children
    }
  }

  return <td>{getCell(editable)}</td>
}

const ClinicCell = (props: TableCellProps & EditContextType) => {
  const { form, children, editing, handleToggle } = props

  return editing ? (
    <Form.Item>
      {form.getFieldDecorator(`${ColumnKeys.CLINIC}`, {
        initialValue: null,
        rules: [{ required: true, message: '必填欄位' }],
      })(
        <ClinicSelect
          style={{ width: '100px' }}
          allowClear
          showArrow={false}
          dropdownMatchSelectWidth={false}
        />
      )}
    </Form.Item>
  ) : (
    <div className='editable-cell' onClick={handleToggle}>
      {children}
    </div>
  )
}

const PatientCell = (props: TableCellProps & EditContextType) => {
  const { form, children, editing, handleToggle } = props
  const selectedClinic = form.getFieldValue(ColumnKeys.CLINIC)

  return editing ? (
    <Form.Item>
      {form.getFieldDecorator(`${ColumnKeys.PATIENT}`, {
        initialValue: null,
        rules: [{ required: true, message: '必填欄位' }],
      })(
        <PatientSelect
          style={{ width: '70px' }}
          query={{
            clinic: selectedClinic,
          }}
          allowClear
          disabled={isNil(selectedClinic)}
          dropdownMatchSelectWidth={false}
          showArrow={false}
        />
      )}
    </Form.Item>
  ) : (
    <div className='editable-cell' onClick={handleToggle}>
      {children}
    </div>
  )
}

const OrderTypeCell = (props: TableCellProps & EditContextType) => {
  const { form, record, children, editing, handleToggle } = props
  const { t } = useTranslation()

  const handleChange = (type: OrderType) => {
    switch (type) {
      case OrderType.NormalPrint:
        form.setFieldsValue({
          stageOrder: {
            [StageType.Print]: 1,
            [StageType.Eval]: 0,
          },
        })
        break
      case OrderType.Report:
        form.setFieldsValue({
          stageOrder: {
            [StageType.Print]: 0,
            [StageType.Eval]: 1,
          },
        })
        break
      case OrderType.Remodel:
        form.setFieldsValue({
          stageOrder: {
            [StageType.Print]: 1,
            [StageType.Eval]: 1,
          },
        })
        break
      case OrderType.Accessory:
        form.setFieldsValue({
          stageOrder: {
            [StageType.Accessory]: [],
          },
        })
        break
      default:
        break
    }
  }

  return editing ? (
    <Form.Item>
      {form.getFieldDecorator(`${ColumnKeys.TYPE}`, {
        initialValue: record.type,
        rules: [{ required: true, message: '必填欄位' }],
      })(
        <Select
          dropdownMatchSelectWidth={false}
          onChange={handleChange}
          allowClear
        >
          {map<OrderType, JSX.Element>(
            (type) => (
              <Select.Option key={type} value={type}>
                {t(`order.type.${type}`)}
              </Select.Option>
            ),
            Object.values(OrderType)
          )}
        </Select>
      )}
    </Form.Item>
  ) : (
    <div className='editable-cell' onClick={handleToggle}>
      {children}
    </div>
  )
}

const AppointmentDateCell = (props: TableCellProps & EditContextType) => {
  const { form, record, children, editing, handleToggle } = props

  const disabledDate = (current?: moment.Moment) =>
    isNil(current) || current < moment().endOf('day')

  return editing ? (
    <Form.Item>
      {form.getFieldDecorator(`${ColumnKeys.APPOINTMENTDATE}`, {
        initialValue: isNil(record.appointment)
          ? null
          : moment(record.appointment.startDate),
      })(
        <DatePicker
          format='YYYYMMDD HH:mm'
          showTime
          disabledDate={disabledDate}
          allowClear
        />
      )}
    </Form.Item>
  ) : (
    <div className='editable-cell' onClick={handleToggle}>
      {children}
    </div>
  )
}

const DescriptionCell = (props: TableCellProps & EditContextType) => {
  const { form, record, children, editing, handleToggle } = props

  return editing ? (
    <Form.Item>
      {form.getFieldDecorator(`${ColumnKeys.DESCRIPTION}`, {
        initialValue: record.description,
      })(<Input.TextArea autoSize placeholder='請輸入備註' />)}
    </Form.Item>
  ) : (
    <div className='editable-cell' onClick={handleToggle}>
      {children}
    </div>
  )
}

const getAccessoryInitialItems = (stageOrder: StageOrderContent[]) =>
  propOr<undefined, StageOrderContent | undefined, string[] | undefined>(
    undefined,
    'items',
    find(propEq('type', StageType.Accessory), stageOrder)
  )

const AccessorySelect = ({
  form,
  order,
}: { order: IOrder } & FormComponentProps<OrderForm>) => {
  const { t } = useTranslation()
  return (
    <Form.Item>
      {form.getFieldDecorator(
        `${ColumnKeys.STAGEORDER}.${StageType.Accessory}`,
        {
          initialValue: getAccessoryInitialItems(order.stageOrder),
          rules: [{ required: true, message: '必填欄位' }],
        }
      )(
        <Select style={{ width: 120 }} placeholder='選擇配件' mode='multiple'>
          {map(
            (value) => (
              <Select.Option key={value} value={value}>
                {t(`stage.accessoryCode.${value}`)}
              </Select.Option>
            ),
            Object.values(AccessoryCode)
          )}
        </Select>
      )}
    </Form.Item>
  )
}

export const StageOrderDisplayCell = (props: { order: IOrder }) => {
  const { order } = props
  const { t } = useTranslation()
  const sortStageOrder = (a: StageOrderContent, b: StageOrderContent) =>
    a.stageType > b.stageType ? -1 : 1

  return (
    <Row style={{ flexDirection: 'column' }}>
      {map<StageOrderContent, JSX.Element>(
        (stageOrderContent) => (
          <div key={stageOrderContent.stageType}>
            {getSerializedStageOrderContent({ stageOrderContent, t })}
          </div>
        ),
        sort(sortStageOrder, order.stageOrder)
      )}
    </Row>
  )
}

const StageOrderNumberInputs = (
  props: { order: IOrder } & FormComponentProps<OrderForm>
) => {
  const { form, order } = props
  const { t } = useTranslation()
  const orderType = form.getFieldValue(ColumnKeys.TYPE)

  const getDefaultStageOrderNumber = (stageType: StageType) => {
    const defaultOrder = find(propEq('stageType', stageType), order.stageOrder)
    return isNil(defaultOrder) ? 0 : defaultOrder.number
  }

  const stageOrdersValidator = (rule, value, cb) => {
    const stageOrder = form.getFieldValue(ColumnKeys.STAGEORDER)
    sum(values(stageOrder)) === 0 ? cb('必填欄位') : cb()
  }

  return (
    <div>
      {map(
        (key) => (
          <Form.Item
            key={key}
            label={t(`stage.type.${key}`)}
            style={{ margin: 0 }}
          >
            {form.getFieldDecorator(`${ColumnKeys.STAGEORDER}.${key}`, {
              initialValue: getDefaultStageOrderNumber(key),
              rules: [
                {
                  validator: stageOrdersValidator,
                },
              ],
            })(
              <InputNumber
                min={0}
                max={
                  key === StageType.Eval && orderType !== OrderType.Report
                    ? 1
                    : undefined
                }
                step={1}
                style={{ width: 50 }}
              />
            )}
          </Form.Item>
        ),
        [StageType.Eval, StageType.Print]
      )}
    </div>
  )
}

const StageOrderCell = (props: TableCellProps & EditContextType) => {
  const { form, record, children, editing, handleToggle } = props
  const type = form.getFieldValue(ColumnKeys.TYPE) || record.type

  return editing ? (
    type === OrderType.Accessory ? (
      <AccessorySelect form={form} order={record} />
    ) : (
      <StageOrderNumberInputs form={form} order={record} />
    )
  ) : (
    <div className='editable-cell' onClick={handleToggle}>
      {children}
    </div>
  )
}

export const DueDateDisplayCell = (props: { order: IOrder }) => {
  const { order } = props

  return order.dueDate ? (
    <div
      style={
        isDueDateExpired(order.dueDate, order.stageOrder)
          ? { color: red[5] }
          : {}
      }
    >
      {moment(order.dueDate).format('YYYYMMDD')}
    </div>
  ) : (
    <div style={{ color: grey[0] }}>未綁定工單</div>
  )
}

const ActionCell = (props: TableCellProps & EditContextType) => {
  const { record, handleUnDo, handleCopy, handleDelete } = props
  const orderId = record.id

  return (
    <div className='column-action'>
      <UndoOutlined
        data-testid={`undo-${orderId}`}
        onClick={() => handleUnDo()}
      />
      {record.status === OrderStatus.Completed && (
        <CopyOutlined
          data-testid={`copy-${orderId}`}
          onClick={() => handleCopy(orderId)}
        />
      )}
      {record.status !== OrderStatus.Completed && (
        <DeleteOutlined
          data-testid={`delete-${orderId}`}
          onClick={() => handleDelete(orderId)}
        />
      )}
    </div>
  )
}

import { Radio, Space } from 'antd'
import { RadioGroupProps } from 'antd/lib/radio'
import React from 'react'
import styled from 'styled-components'

import NoteTooltipTitle from './NoteTooltipTitle'
import QuestionIconWithTooltip from './QuestionIconWithTooltip'
import { HandleInstructionChange } from '.'

const WithInstructionTooltipTitle = () => {
  return (
    <div>
      <div>修改治療計畫:</div>
      <div>1. 贗覆物修改（說明牙位）</div>
      <div>2. 咬合狀況</div>
      <div>3. 臨床問題</div>
      <div>4. 病患配戴狀況與回饋</div>
      <div>5. 牙齒移動進度</div>
      <br />
      <div>其他:</div>
      <div>1. 填寫輸出階段數</div>
      <div>2. 是否需要配件（Retainer / Aligner / Attachment）</div>
      <div>3. 如需直接寄送至病患住家，請填寫地址</div>
    </div>
  )
}

enum InstructionType {
  Normal = 'NORMAL',
  NoInstruction = 'NO_INSTRUCTION',
  HasInstruction = 'HAS_INSTRUCTION',
}

const instructionMap: Record<InstructionType, string> = {
  [InstructionType.Normal]: '療程正常純比對進度，維持上一次治療計畫繼續進行',
  [InstructionType.NoInstruction]:
    '療程不合，第 X Step 失敗，醫生無指示，照原療程或由 SOV 牙技師依現行口內狀況調整',
  [InstructionType.HasInstruction]: '療程不合，第 X Step 失敗醫生有指示:',
}

const Content = styled.div`
  color: rgba(0, 0, 0, 0.65);
`

const ExplanationContainer = styled.div`
  display: flex;
  align-items: center;
  line-height: 18px;
  margin-bottom: 8px;
`

const Explanation = styled.div`
  margin-left: 3px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
`

const CustomizedRadioGroup = styled(Radio.Group)`
  margin: 8px 0;
`

const CustomizedRadio = styled(Radio)`
  display: flex;
  flex-direction: row;
  color: rgba(0, 0, 0, 0.65);

  .ant-radio {
    padding-top: 4px;
  }
  span:not(.ant-radio) {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: pre-line;
    padding-left: 4px;
    padding-right: 0;
  }
`

interface RemodelRadioButtonGroupProps {
  handleInstructionChange: HandleInstructionChange
}

const RemodelRadioButtonGroup = (props: RemodelRadioButtonGroupProps) => {
  const { handleInstructionChange } = props

  const handleRadioButtonChange: RadioGroupProps['onChange'] = (e) => {
    const selectedInstructionType: InstructionType = e.target.value
    const updatedInstruction = instructionMap[selectedInstructionType]
    handleInstructionChange(updatedInstruction)
  }

  return (
    <>
      <Content>重取模後，請填寫醫師重取模原因及臨床指示。</Content>
      <ExplanationContainer>
        <Explanation>
          請盡量將指示填寫詳實，相關文字指示可參考注意事項。
        </Explanation>
        <QuestionIconWithTooltip title={<NoteTooltipTitle />} />
      </ExplanationContainer>
      <CustomizedRadioGroup onChange={handleRadioButtonChange}>
        <Space direction='vertical' size={8}>
          {Object.keys(instructionMap).map((instructionType) => (
            <CustomizedRadio key={instructionType} value={instructionType}>
              {instructionMap[instructionType]}
              {instructionType === InstructionType.HasInstruction && (
                <QuestionIconWithTooltip
                  title={<WithInstructionTooltipTitle />}
                />
              )}
            </CustomizedRadio>
          ))}
        </Space>
      </CustomizedRadioGroup>
    </>
  )
}

export default RemodelRadioButtonGroup

import React from 'react'
const Icon = (props) => {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      {props.children}
    </svg>
  )
}

export default Icon

import { Tooltip } from 'antd'
import { identity } from 'ramda'
import React from 'react'
import styled from 'styled-components'

import { AuxiliaryType } from '../../../../graphql/types'
import { AttachmentIcon, ButtonIcon, StrippingIcon, TADIcon } from './icons'

// 個別 icon 根據 state 以及種類的 style
const IconContainer = styled.div<{ isSelected: boolean }>`
  background-color: ${(props) => (props.isSelected ? '#e6f7ff' : 'inherit')};
  border: ${(props) =>
    props.isSelected ? 'solid 1px rgba(24, 144, 255, 0.5)' : 'none'};
  svg {
    fill: ${(props) => (props.isSelected ? props.color : 'black')};
    fill-opacity: ${(props) => (props.isSelected ? 1 : 0.45)};
  }
  :hover,
  :active {
    svg {
      fill: ${(props) => props.color};
      fill-opacity: 1;
    }
  }
`

// 共同擁有的 hover/active 等 style
const StyledGroup = styled.div`
  ${IconContainer} {
    width: 32px;
    height: 32px;
    padding: 4px;
    border-radius: 4px;
    margin-right: 8px;
    :hover,
    :active {
      background-color: #e6f7ff;
      cursor: pointer;
    }
    :active {
      border: solid 1px rgba(24, 144, 255, 0.5);
    }
  }
`

const AuxiliaryRadioGroup = (props: any) => {
  const { value, onChange = identity, ...restProps } = props

  return (
    <StyledGroup {...restProps}>
      <Tooltip title='Attachment' placement='bottom'>
        <IconContainer
          isSelected={value === AuxiliaryType.Attachment}
          color='#f5222d'
          onClick={() => onChange(AuxiliaryType.Attachment)}
        >
          <AttachmentIcon />
        </IconContainer>
      </Tooltip>
      <Tooltip title='Stripping' placement='bottom'>
        <IconContainer
          isSelected={value === AuxiliaryType.StrippingAll}
          color='#1890FF'
          onClick={() => onChange(AuxiliaryType.StrippingAll)}
        >
          <StrippingIcon />
        </IconContainer>
      </Tooltip>
      <Tooltip title='Button' placement='bottom'>
        <IconContainer
          isSelected={value === AuxiliaryType.Button}
          color='#a56716'
          onClick={() => onChange(AuxiliaryType.Button)}
        >
          <ButtonIcon />
        </IconContainer>
      </Tooltip>
      <Tooltip title='TAD 骨釘' placement='bottom'>
        <IconContainer
          isSelected={value === AuxiliaryType.Tad}
          color='#52C41A'
          onClick={() => onChange(AuxiliaryType.Tad)}
        >
          <TADIcon />
        </IconContainer>
      </Tooltip>
    </StyledGroup>
  )
}

export default AuxiliaryRadioGroup

import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons'
import { gql, useApolloClient, useMutation } from '@apollo/client'
import { GqlUpload } from '@sov/ui'
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Progress,
  message,
} from 'antd'
import { FormInstance, FormProps } from 'antd/lib/form'
import { evolve, values } from 'ramda'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  Clinic,
  CreateTrackInput,
  CreateTrackMutation,
  CreateTrackMutationVariables,
  Patient,
  PatientSelectDocs,
  StageType,
  TrackIssue,
} from '../../../graphql/types'
import { takeLastFromFileList } from '../../utils'
import { PatientSelect, PatientStageSelect } from '../form/Select'
import { photosWithBrace, photosWithoutBrace } from './util/trackPhotoMapping'

const SelectGroupContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const createTrack = gql`
  mutation CreateTrack($payload: CreateTrackInput!) {
    createTrack(payload: $payload) {
      id
    }
  }
`

interface PhotoCardProps {
  key: string
  aspect: number
  image?: string
  placeholder?: string
  width: number
}

const PhotoCard = styled.div<PhotoCardProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${(props) => props.width}px;
  height: ${(props) => props.width / props.aspect}px;
  margin: 0;
  padding: 8px;
  color: white;
  cursor: pointer;

  .title {
    visibility: hidden;
    font-size: 14px;
    line-height: 1;
    z-index: 1;
  }

  .icon-list {
    visibility: hidden;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    z-index: 1;

    .anticon {
      font-size: 18px;
      color: white;

      &.anticon-delete {
        color: red;
      }
    }
  }
  &:after {
    content: '';
    ${(props) =>
      props.image
        ? `background-image: url(${props.image});`
        : `background-image: url(${props.placeholder});`}
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

const TrackPhotoUploadContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 720px;

  .ant-upload.ant-upload-select {
    display: block;
  }

  ${PhotoCard} {
    &:hover {
      .title,
      .icon-list {
        visibility: initial;
      }

      &:after {
        filter: brightness(0.8);
      }
    }
  }
`

interface PhotoInfo {
  key: string
  title: string
  aspect: number
  placeholder: string
}
interface TrackPhotoUploadProps {
  /** pass by Form.Item */
  photoInfo: PhotoInfo
  form: FormInstance
  width: number
  formItemName: string
}
const TrackPhotoUpload = (props: TrackPhotoUploadProps) => {
  const { photoInfo, form, formItemName, width, ...restProps } = props
  const { aspect, title, key, placeholder } = photoInfo
  const { getFieldValue, setFieldsValue } = form
  const client = useApolloClient()

  const file = getFieldValue(['photo', formItemName, photoInfo.key])?.[0]
    ?.response
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  const handleLoading = (val) => {
    setLoading(val)
  }
  const handleProgress = (val) => {
    setProgress(val)
  }

  const handleDownload = (e: any) => {
    window.open(file?.path)
    e.stopPropagation()
  }
  const handleRemove = (e: any) => {
    setFieldsValue({
      photo: { [formItemName]: { [photoInfo.key]: undefined } },
    })
    e.stopPropagation()
  }

  return (
    <GqlUpload.Upload
      {...restProps}
      client={client}
      fileType='model'
      uploadOptions={{ needThumbnail: true, prefix: 'patient' }}
      cropOption={{ aspect, shape: 'rect', modalTitle: title }}
      showUploadList={false}
      handleLoading={handleLoading}
      handleProgress={handleProgress}
    >
      <PhotoCard
        key={key}
        aspect={aspect}
        image={file?.thumbnailPath}
        placeholder={placeholder}
        width={width}
      >
        {loading && <Progress percent={progress} size='small' />}
        {!loading && file && (
          <>
            <div className='title'>{title}</div>
            <div className='icon-list'>
              <DownloadOutlined onClick={handleDownload} />
              <DeleteOutlined onClick={handleRemove} />
            </div>
          </>
        )}
      </PhotoCard>
    </GqlUpload.Upload>
  )
}

type SelectedPatient = Pick<Patient, 'id'> & {
  clinic: Pick<Clinic, 'id' | 'name'>
}
interface TrackReplyProps extends FormProps {
  visible?: boolean
  handleClose: () => void
  refetch?: () => void
}
const TrackCreateModal = (props: TrackReplyProps) => {
  const { visible, handleClose, refetch } = props
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [selectedPatient, setSelectedPatient] = useState<
    SelectedPatient | undefined
  >(undefined)
  const [create] = useMutation<
    CreateTrackMutation,
    CreateTrackMutationVariables
  >(createTrack)
  const handleModalClose = () => {
    handleClose()
    form.resetFields()
  }
  const handleSubmit = async () => {
    try {
      const payload = await form.validateFields()

      const photoWithBraceList = evolve({
        frontLoose: (photo) => photo?.[0]?.response.id ?? null,
        frontTight: (photo) => photo?.[0]?.response.id ?? null,
        leftTight: (photo) => photo?.[0]?.response.id ?? null,
        rightTight: (photo) => photo?.[0]?.response.id ?? null,
      })(payload.photo.withBrace)
      const photoWithoutBraceList = evolve({
        frontLoose: (photo) => photo?.[0]?.response.id ?? null,
        frontTight: (photo) => photo?.[0]?.response.id ?? null,
        leftTight: (photo) => photo?.[0]?.response.id ?? null,
        rightTight: (photo) => photo?.[0]?.response.id ?? null,
      })(payload.photo.withoutBrace)

      if (!selectedPatient) throw new Error('請選擇病患')

      const createTrackPayload: CreateTrackInput = {
        ...(payload as CreateTrackInput),
        clinic: selectedPatient.clinic.id,
        photo: {
          withBrace: photoWithBraceList,
          withoutBrace: photoWithoutBraceList,
        },
      }
      await create({
        variables: {
          payload: {
            ...createTrackPayload,
          },
        },
        update: async (cache, { data }) => {
          if (data) {
            message.success('已成功送出')
            refetch && refetch()
            handleModalClose()
          }
        },
      })
    } catch (error) {
      console.error(error)
    }
  }
  const handlePatientSelect = (selectedPatient: PatientSelectDocs) => {
    setSelectedPatient({
      id: selectedPatient.id,
      clinic: selectedPatient.clinic,
    })
  }
  return (
    <Modal
      width={736}
      title='生理追蹤報告'
      visible={visible}
      onCancel={handleModalClose}
      footer={[
        <Button
          key='cancel'
          onClick={handleModalClose}
          style={{ color: 'rgba(0, 0, 0, 0.45)' }}
        >
          取消
        </Button>,
        <Button
          key='submit'
          type='primary'
          onClick={() => {
            handleSubmit()
          }}
        >
          儲存送出
        </Button>,
      ]}
    >
      <Form form={form}>
        <SelectGroupContainer>
          <Form.Item
            label='病患姓名'
            name='patient'
            rules={[{ required: true, message: '請選擇' }]}
          >
            <PatientSelect
              placeholder='病患姓名'
              allowClear
              handleItemSelect={handlePatientSelect}
            />
          </Form.Item>
          <Form.Item
            label='配戴階段'
            name='stage'
            rules={[{ required: true, message: '請選擇' }]}
          >
            <PatientStageSelect
              placeholder='Step'
              allowClear
              disabled={!selectedPatient?.id}
              query={{ patient: selectedPatient?.id, type: [StageType.Print] }}
            />
          </Form.Item>
          <Form.Item
            label='上傳日期'
            name='created'
            rules={[{ required: true, message: '請選擇' }]}
          >
            <DatePicker />
          </Form.Item>
        </SelectGroupContainer>
      </Form>
      <Form form={form} layout='vertical' requiredMark>
        <Form.Item
          label='戴牙套照片'
          required
          rules={[{ required: true, message: '請上傳圖片' }]}
        >
          <TrackPhotoUploadContainer>
            {photosWithBrace.map((photoInfo) => (
              <Form.Item
                rules={[{ required: true, message: '請上傳圖片' }]}
                key={photoInfo.key}
                name={['photo', 'withBrace', photoInfo.key]}
                valuePropName='fileList'
                getValueFromEvent={takeLastFromFileList}
              >
                <TrackPhotoUpload
                  photoInfo={photoInfo}
                  form={form}
                  width={160}
                  formItemName='withBrace'
                />
              </Form.Item>
            ))}
          </TrackPhotoUploadContainer>
        </Form.Item>

        <Form.Item label='未戴牙套照片' required>
          <TrackPhotoUploadContainer>
            {photosWithoutBrace.map((photoInfo) => (
              <Form.Item
                rules={[{ required: true, message: '請上傳圖片' }]}
                key={photoInfo.key}
                name={['photo', 'withoutBrace', photoInfo.key]}
                valuePropName='fileList'
                getValueFromEvent={takeLastFromFileList}
              >
                <TrackPhotoUpload
                  photoInfo={photoInfo}
                  form={form}
                  width={160}
                  formItemName='withoutBrace'
                />
              </Form.Item>
            ))}
          </TrackPhotoUploadContainer>
        </Form.Item>
        <Form.Item label='配戴此階段時，是否遇到以下情況？' name='mainIssues'>
          <Checkbox.Group>
            {values(TrackIssue).map((issue) => (
              <Checkbox key={issue} value={issue}>
                {t(`track.issue.${issue}`)}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>
        <Form.Item
          label='並非以上情況？'
          name='extraIssue'
          style={{ marginBottom: 0 }}
        >
          <Input.TextArea placeholder='請您以文字說明您所遇到的問題' />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default TrackCreateModal

import React from 'react'

import AvatarAddIcon from './AvatarAddIcon'
import AvatarInBookIcon from './AvatarInBookIcon'
import CheckIcon from './CheckIcon'
import DocumentIcon from './DocumentIcon'

export type IconType = 'AVATAR_ADD' | 'AVATAR_IN_BOOK' | 'CHECK' | 'DOCUMENT'

interface IconProps {
  type: IconType
}

const CardIcon = (props: IconProps) => {
  const { type, ...restProps } = props

  switch (type) {
    case 'AVATAR_ADD':
      return <AvatarAddIcon {...restProps} />
    case 'AVATAR_IN_BOOK':
      return <AvatarInBookIcon {...restProps} />
    case 'CHECK':
      return <CheckIcon {...restProps} />
    case 'DOCUMENT':
      return <DocumentIcon {...restProps} />
  }
}

export default CardIcon

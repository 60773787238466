import { UploadFile } from 'antd/lib/upload/interface'
import { has, head, last, reduce, split } from 'ramda'

import { JawPosition } from '../../../../graphql/types'
import { CustomFileType } from '../ViewerFilesUpload'
import notationTransformer from './notationTransformer'

/** 區分原有檔案及新上傳檔案 */
export const isNewUploaded = has('originFileObj')

/** 檢查檔名是否符合規定 */
export const handleCheckFileName = (
  file: UploadFile,
  callback: (file: UploadFile) => void
) => {
  /** 以美式牙位為標準 Tooth_1 到 Tooth_32 */
  const viewerFileNameRegex =
    /^Tooth_([1-9]|[1-2][0-9]|3[0-2]|UpperJaw|LowerJaw).stl$/
  if (!viewerFileNameRegex.test(file.name)) {
    callback(file)
    return new Promise<void>((resolve, reject) =>
      reject(Error('File name incorrect'))
    )
  }
  return true
}

export const getPosition = (file: UploadFile | CustomFileType) => {
  const isNewFile = isNewUploaded(file)
  /** 新檔案取出檔名 `Tooth_${code}.stl` 中的 code，並轉換成 FDI 牙位或是牙肉 */
  if (isNewFile) {
    const newFile = file as UploadFile
    const splitedName = last(split('_', head(split('.', newFile.name)) || ''))

    if (splitedName === 'UpperJaw') {
      return JawPosition.Upper
    }
    if (splitedName === 'LowerJaw') {
      return JawPosition.Lower
    }
    return notationTransformer(Number(splitedName))
  }
  /** 原有檔案檔名即為 position */
  const initialFile = file as CustomFileType
  return initialFile.position
}

export const getUpdateViewerFilesPayload = reduce<CustomFileType, any>(
  (acc, file) => {
    if (file.position === JawPosition.Upper) {
      return {
        ...acc,
        upperJawModel: file.response?.id,
      }
    } else if (file.position === JawPosition.Lower) {
      return {
        ...acc,
        lowerJawModel: file.response?.id,
      }
    }
    if (file.response) {
      return {
        ...acc,
        teeth: [
          ...(acc.teeth || []),
          { position: file.position, file: file.response.id },
        ],
      }
    }
    return acc
  },
  {
    upperJawModel: null,
    lowerJawModel: null,
    teeth: [],
  }
)

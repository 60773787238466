import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Layout } from './Layout'

const FormTitle = styled.div`
  font-size: 28px;
`

const Description = styled.div`
  margin-top: 24px;
  margin-bottom: 48px;
  font-size: 16px;
`

const InvalidResetPasswordUrl = () => (
  <Layout>
    <FormTitle>網址已經失效</FormTitle>
    <Description>
      <div>更改密碼的網址已經失效</div>
      <div>請重新寄送驗證信</div>
    </Description>
    <Link to='/login'>{'<重新寄送驗證信'}</Link>
  </Layout>
)

export default InvalidResetPasswordUrl

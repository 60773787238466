import { DownOutlined, WarningOutlined } from '@ant-design/icons'
import { Button, Dropdown, Input, Menu, Modal } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { always, any, cond, equals, includes, map, propOr } from 'ramda'
import React, { ReactNode, useContext, useState } from 'react'
import styled from 'styled-components'

import { AllPrivilege } from '../../../../graphql/types'
import { authContext } from '../../../context'
import { getAuthPrivileges } from '../../../utils'

export type ConfirmButtonType =
  | 'update'
  | 'remove'
  | 'completePatient'
  | 'inactivePatient'
  | 'activePatient'
type ConfirmButtonMode = 'normal' | 'input'

const ContentWrapper = styled.div`
  display: inline-flex;
`
const ConfirmDescription = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.5;
  margin-left: 16px;
`
const ConfirmInputText = styled.span`
  font-weight: bold;
  color: red;
`
const ConfirmInput = styled(Input)`
  margin-top: 16px;
`

interface ModalProps {
  onOk: () => void
  onCancel?: () => void
}

interface InputContentProps {
  actionText: string
  requiredInputText: string
  setOkButtonDisabled: (disabled: boolean) => void
}

const InputContent = (props: InputContentProps) => {
  const { actionText, requiredInputText, setOkButtonDisabled } = props
  const handleInputChange = (e) => {
    const isInputValid = e.target.value === requiredInputText
    setOkButtonDisabled(!isInputValid)
  }

  return (
    <>
      <ContentWrapper>
        <WarningOutlined style={{ fontSize: '36px', color: 'red' }} />
        <ConfirmDescription>
          <div>{actionText}之後將無法復原 ！！！</div>
          <div>
            輸入 <ConfirmInputText>{requiredInputText}</ConfirmInputText> 來
            {actionText}這筆資料
          </div>
        </ConfirmDescription>
      </ContentWrapper>
      <ConfirmInput placeholder='請輸入紅色文字' onChange={handleInputChange} />
    </>
  )
}

interface ContentProps {
  description: string
}

const Content = (props: ContentProps) => {
  const { description } = props

  return (
    <ContentWrapper>
      <WarningOutlined style={{ fontSize: '36px', color: 'red' }} />
      <ConfirmDescription>
        <div>{description}</div>
      </ConfirmDescription>
    </ContentWrapper>
  )
}

interface BaseConfirmButtonProps {
  confirmButtonType?: ConfirmButtonType
  description?: string
  isMenuItem?: boolean
  label: string
  modal?: ReactNode
  modalProps: ModalProps
  mode?: ConfirmButtonMode
  requiredInputText?: string
  requiredPrivilege?: AllPrivilege
  title?: string
}

export type ConfirmButtonProps = ButtonProps & BaseConfirmButtonProps

const ConfirmButton = (props: ConfirmButtonProps) => {
  const {
    confirmButtonType = 'remove',
    description = '',
    isMenuItem = false,
    label,
    modal = false,
    modalProps,
    mode = 'input',
    requiredInputText = '刪除',
    requiredPrivilege,
    title = '確定要送出?',
  } = props
  const { onOk, onCancel } = modalProps

  const auth = useContext(authContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [okButtonDisabled, setOkButtonDisabled] = useState(mode === 'input')

  const privilegesOfUser = getAuthPrivileges(auth)
  const hasRequiredPrivilege = requiredPrivilege
    ? includes(requiredPrivilege, privilegesOfUser)
    : true

  if (!hasRequiredPrivilege) {
    return null
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    if (onCancel) {
      onCancel()
    }
  }

  const handleOk = () => {
    setIsModalOpen(false)
    if (onOk) {
      onOk()
    }
  }

  const actionText = cond<ConfirmButtonType, string>([
    [equals<ConfirmButtonType>('update'), always('更新')],
    [equals<ConfirmButtonType>('remove'), always('刪除')],
    [equals<ConfirmButtonType>('completePatient'), always('設為已完結')],
    [equals<ConfirmButtonType>('completePatient'), always('設為不追蹤')],
    [equals<ConfirmButtonType>('activePatient'), always('重新追蹤')],
  ])(confirmButtonType)

  return (
    <>
      <Button
        {...props}
        {...(isMenuItem ? { type: 'link' } : { danger: true })}
        onClick={() => setIsModalOpen(true)}
      >
        {label}
      </Button>
      {modal || (
        <Modal
          title={title || `確定要${actionText}嗎？`}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{
            disabled: okButtonDisabled,
            danger: true,
          }}
          visible={isModalOpen}
          width='360px'
        >
          {mode === 'input' && (
            <InputContent
              actionText={actionText}
              requiredInputText={requiredInputText}
              setOkButtonDisabled={setOkButtonDisabled}
            />
          )}
          {mode === 'normal' && <Content description={description} />}
        </Modal>
      )}
    </>
  )
}

interface ConfirmMenuProps {
  confirmButtonDropdownMenuItemsProps: ConfirmButtonProps[]
  handleMenuClose: () => void
}

const ConfirmMenu = (props: ConfirmMenuProps) => {
  const { confirmButtonDropdownMenuItemsProps, handleMenuClose } = props

  return (
    <Menu selectable={false}>
      {map(
        ({ style, ...props }) => (
          <Menu.Item
            onClick={handleMenuClose}
            style={{ border: '1px solid #d9d9d9', padding: '0px' }}
          >
            <ConfirmButton
              {...props}
              isMenuItem
              size='small'
              style={{ ...style }}
            />
          </Menu.Item>
        ),
        confirmButtonDropdownMenuItemsProps
      )}
    </Menu>
  )
}

interface ConfirmButtonDropdownMenuProps {
  confirmButtonDropdownMenuItemsProps: ConfirmButtonProps[]
}

const ConfirmButtonDropdownMenu = (props: ConfirmButtonDropdownMenuProps) => {
  const auth = useContext(authContext)
  const [isDropdownMenuVisible, setIsDropdownMenuVisible] = useState(false)

  const { confirmButtonDropdownMenuItemsProps } = props
  const requiredPrivileges = map(
    propOr(undefined, 'requiredPrivilege'),
    confirmButtonDropdownMenuItemsProps
  )
  const hasAnyPrivilege = any(
    (requiredPrivilege) => includes(requiredPrivilege, getAuthPrivileges(auth)),
    requiredPrivileges
  )

  const handleMenuClose = () => {
    setIsDropdownMenuVisible(false)
  }

  return hasAnyPrivilege ? (
    <Dropdown
      onVisibleChange={(visible) => setIsDropdownMenuVisible(visible)}
      overlay={() => (
        <ConfirmMenu
          confirmButtonDropdownMenuItemsProps={
            confirmButtonDropdownMenuItemsProps
          }
          handleMenuClose={handleMenuClose}
        />
      )}
      trigger={['click', 'hover']}
      visible={isDropdownMenuVisible}
    >
      <Button style={{ color: 'gray', marginLeft: 16 }}>
        其他操作 <DownOutlined />
      </Button>
    </Dropdown>
  ) : null
}

export { ConfirmButton, ConfirmButtonDropdownMenu }

import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { EntityType, Role } from '../../../graphql/types'
import { authContext } from '../../context'
import { getUserEntityIdFromAuth, isRole } from '../../utils'

const Home = () => {
  const history = useHistory()
  const auth = useContext(authContext)

  useEffect(() => {
    if (auth) {
      if (auth.entityType !== EntityType.Employee) {
        history.push(`/patients`)
      } else if (isRole(Role.Sales, auth)) {
        history.push(`/patients`)
      } else {
        history.push(`/employees/${getUserEntityIdFromAuth(auth)}/tasks`)
      }
    }
  }, [])

  return null
}

export default Home

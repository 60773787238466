import { gql, useQuery } from '@apollo/client'
import { Card, Col, Row, Space, Statistic } from 'antd'
import moment from 'moment'
import React from 'react'

import {
  StatisticIndicatorType,
  StatisticQueryQuery,
  StatisticQueryVariables,
} from '../../../graphql/types'
import Page from '../../components/layout/Page'

const indicatorTitle = {
  [StatisticIndicatorType.Revenue]: '銷貨收入',
  [StatisticIndicatorType.Cost]: '銷貨成本',
  [StatisticIndicatorType.PatientCountStartEval]: '評估病患數',
  [StatisticIndicatorType.PatientCountStartPrint]: '成交病患數',
  [StatisticIndicatorType.PatientCountProcessing]: '療程中病患數',
  [StatisticIndicatorType.PatientCountFinished]: '已完結病患數',
  [StatisticIndicatorType.StageCountAdvancedDesign]: '超前設計數',
  [StatisticIndicatorType.StageCountAccruedDesign]: '待處理設計數',
  [StatisticIndicatorType.StageCountDiscardedDesign]: '廢棄設計數',
}

const statisticQuery = gql`
  query StatisticQuery($query: StatisticQuery!) {
    statistics(query: $query) {
      startDate
      endDate
      indicators {
        type
        value
      }
    }
  }
`

const Last30DayCard = React.memo(() => {
  const indicatorTypes = [
    StatisticIndicatorType.Revenue,
    StatisticIndicatorType.Cost,
    StatisticIndicatorType.PatientCountStartEval,
    StatisticIndicatorType.PatientCountStartPrint,
    StatisticIndicatorType.StageCountAccruedDesign,
    StatisticIndicatorType.StageCountAdvancedDesign,
    StatisticIndicatorType.StageCountDiscardedDesign,
  ]

  const { data, loading } = useQuery<
    StatisticQueryQuery,
    StatisticQueryVariables
  >(statisticQuery, {
    variables: {
      query: {
        dateSegments: [
          {
            startDate: moment().startOf('day').subtract(30, 'day'),
            endDate: moment().startOf('day'),
          },
          {
            startDate: moment()
              .startOf('day')
              .subtract(30, 'day')
              .subtract(1, 'month'),
            endDate: moment().startOf('day').subtract(1, 'month'),
          },
          {
            startDate: moment()
              .startOf('day')
              .subtract(30, 'day')
              .subtract(1, 'year'),
            endDate: moment().startOf('day').subtract(1, 'year'),
          },
        ],
        indicatorTypes: indicatorTypes,
      },
    },
  })

  return (
    <Card loading={loading} size='small' title='近 30 天累計數據'>
      <Row gutter={[8, 8]}>
        {indicatorTypes.map((indicatorType) => {
          const current =
            data?.statistics?.[0].indicators.find(
              (x) => x.type === indicatorType
            )?.value ?? 0
          const mom =
            data?.statistics?.[1].indicators.find(
              (x) => x.type === indicatorType
            )?.value ?? 0
          const yoy =
            data?.statistics?.[2].indicators.find(
              (x) => x.type === indicatorType
            )?.value ?? 0

          return (
            <Col span={6} key={indicatorType}>
              <Statistic
                title={indicatorTitle[indicatorType]}
                value={current}
              />
              <div>前一期 {new Intl.NumberFormat().format(mom)}</div>
              <div>去年同期 {new Intl.NumberFormat().format(yoy)}</div>
            </Col>
          )
        })}
      </Row>
    </Card>
  )
})

const Last360DayCard = React.memo(() => {
  const indicatorTypes = [
    StatisticIndicatorType.Revenue,
    StatisticIndicatorType.Cost,
    StatisticIndicatorType.PatientCountStartEval,
    StatisticIndicatorType.PatientCountStartPrint,
  ]

  const { data, loading } = useQuery<
    StatisticQueryQuery,
    StatisticQueryVariables
  >(statisticQuery, {
    variables: {
      query: {
        dateSegments: [
          {
            startDate: moment().startOf('day').subtract(360, 'day'),
            endDate: moment().startOf('day'),
          },
          {
            startDate: moment()
              .startOf('day')
              .subtract(360, 'day')
              .subtract(1, 'year'),
            endDate: moment().startOf('day').subtract(1, 'year'),
          },
        ],
        indicatorTypes: indicatorTypes,
      },
    },
  })

  return (
    <Card loading={loading} size='small' title='近 360 天累計數據'>
      <Row gutter={[8, 8]}>
        {indicatorTypes.map((indicatorType) => {
          const current =
            data?.statistics?.[0].indicators.find(
              (x) => x.type === indicatorType
            )?.value ?? 0
          const yoy =
            data?.statistics?.[1].indicators.find(
              (x) => x.type === indicatorType
            )?.value ?? 0

          return (
            <Col span={6} key={indicatorType}>
              <Statistic
                title={indicatorTitle[indicatorType]}
                value={current}
              />
              <div>前一期 {new Intl.NumberFormat().format(yoy)}</div>
            </Col>
          )
        })}
      </Row>
    </Card>
  )
})

const HistoryCard = React.memo(() => {
  const indicatorTypes = [
    StatisticIndicatorType.PatientCountStartEval,
    StatisticIndicatorType.PatientCountStartPrint,
    StatisticIndicatorType.PatientCountProcessing,
    StatisticIndicatorType.PatientCountFinished,
    StatisticIndicatorType.StageCountAccruedDesign,
    StatisticIndicatorType.StageCountAdvancedDesign,
    StatisticIndicatorType.StageCountDiscardedDesign,
  ]

  const { data, loading } = useQuery<
    StatisticQueryQuery,
    StatisticQueryVariables
  >(statisticQuery, {
    variables: {
      query: {
        dateSegments: [
          {
            startDate: moment('2017-01-01'),
            endDate: moment().startOf('day'),
          },
        ],
        indicatorTypes: indicatorTypes,
      },
    },
  })

  return (
    <Card loading={loading} size='small' title='歷史累積數據'>
      <Row gutter={[8, 8]}>
        {indicatorTypes.map((indicatorType) => {
          const current =
            data?.statistics?.[0].indicators.find(
              (x) => x.type === indicatorType
            )?.value ?? 0

          return (
            <Col span={6} key={indicatorType}>
              <Statistic
                title={indicatorTitle[indicatorType]}
                value={current}
              />
            </Col>
          )
        })}
      </Row>
    </Card>
  )
})

const StatisticIndex = () => {
  return (
    <>
      <Page header={null}>
        <Space direction='vertical'>
          <HistoryCard />
          <Last30DayCard />
          <Last360DayCard />
        </Space>
      </Page>
    </>
  )
}

export default StatisticIndex

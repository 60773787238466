import { gql, useMutation, useQuery, useSubscription } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { isEmpty, pathOr } from 'ramda'
import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { updateConversationReadMessageRecordOfMemberMutation } from '../../../../graphql/conversation/mutation/conversationMutation'
import { conversationMessagesQuery } from '../../../../graphql/conversation/query/conversationQuerys'
import { onMessageAddedSubscription } from '../../../../graphql/message/subscription/onMessageAdded'
import {
  ConversationInfoQueryQuery,
  ConversationInfoQueryQueryVariables,
  ConversationMessagesQueryQuery,
  ConversationMessagesQueryVariables,
  MessageEdge,
  OnMessageAddedSubscriptionSubscription,
  OnMessageAddedSubscriptionSubscriptionVariables,
  SubscriptionType,
  UpdateConversationReadMessageRecordOfMemberMutationMutation,
  UpdateConversationReadMessageRecordOfMemberMutationMutationVariables,
} from '../../../../graphql/types'
import Conversation, {
  ScrollType,
} from '../../../components/common/conversation/Conversation'
import {
  getMessageNodes,
  groupSameCreatedDay,
  isNotEmpty,
} from '../../../components/common/conversation/utils'
import { authContext } from '../../../context'
import { getUserEntityIdFromAuth, isItemOwner } from '../../../utils'
import ConversationPatientInfo from './ConversationPatientInfo'

interface LatestMessageDrawerProps {
  selectedConversation: string
}

const LatestMessageDrawerContainer = styled.div`
  margin-left: 5px;
  height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
  width: calc(100% - 560px);
  position: relative;
  display: flex;
  flex-direction: column;
`

export const conversationInfoQuery = gql`
  query conversationInfoQuery($id: ID!) {
    conversation(id: $id) {
      id
      type
      members {
        ...conversationMemberInfo
      }
      ... on PatientConversation {
        patient {
          ...ConversationPatientInfo
        }
      }
    }
  }
  ${ConversationPatientInfo.fragment}
  ${Conversation.fragments.conversationMemberInfo}
`
const LatestMessageDrawer = (props: LatestMessageDrawerProps) => {
  const { selectedConversation } = props
  const auth = useContext(authContext)
  if (!auth) {
    return null
  }
  const selectedPatientRef = useRef('')

  const [conversationMessagesData, setConversationMessagesData] = useState<
    ConversationMessagesQueryQuery | undefined
  >(undefined)
  // smooth scroll 目前會產生樣式方面 bug，先都用 auto
  const [scrollSmooth, setScrollSmooth] = useState(false)
  const [subscriptionData, setSubscriptionData] = useState<MessageEdge[]>([])
  const [hasNewMessage, setHasNewMessage] = useState(false)
  const [newMessageShouldScrollState, setNewMessageShouldScrollState] =
    useState({
      type: ScrollType.Subscription,
    })

  const [updateUserMessageRecord] = useMutation<
    UpdateConversationReadMessageRecordOfMemberMutationMutation,
    UpdateConversationReadMessageRecordOfMemberMutationMutationVariables
  >(updateConversationReadMessageRecordOfMemberMutation)

  // 切換聊天室 loading 狀態的 data 會維持上一個聊天室的內容，這邊先另外維護 state 處理
  // @TODO 找出原因或者比較好的寫法
  const {
    loading: conversationMessagesLoading,
    fetchMore,
    refetch,
  } = useQuery<
    ConversationMessagesQueryQuery,
    ConversationMessagesQueryVariables
  >(conversationMessagesQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id: selectedConversation,
      first: 20,
    },
    skip: isEmpty(selectedConversation),
    onCompleted: (data) => {
      if (data) {
        setConversationMessagesData(data)
        setSubscriptionData([])
      }
    },
  })
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { loading: conversationInfoLoading, data: conversationInfo } = useQuery<
    ConversationInfoQueryQuery,
    ConversationInfoQueryQueryVariables
  >(conversationInfoQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: selectedConversation,
    },
    skip: isEmpty(selectedConversation),
  })

  useSubscription<
    OnMessageAddedSubscriptionSubscription,
    OnMessageAddedSubscriptionSubscriptionVariables
  >(onMessageAddedSubscription, {
    variables: {
      payload: {
        subscriptionType: SubscriptionType.Specific,
        conversation: pathOr(null, ['conversation', 'id'], conversationInfo),
      },
    },
    fetchPolicy: 'network-only',
    onSubscriptionData: (data) => {
      if (data?.subscriptionData?.data?.onMessageAdded) {
        const onMessageAdded = data.subscriptionData.data.onMessageAdded
        // only update if message's conversation is same as selectedConversation
        if (onMessageAdded.node.conversation.id === selectedConversation) {
          setSubscriptionData([
            onMessageAdded,
            ...subscriptionData,
          ] as MessageEdge[])
        }
        const { creator } = onMessageAdded.node
        const notSelfCreatedMessage = !isItemOwner(creator.id, auth)
        if (notSelfCreatedMessage) {
          setScrollSmooth(false)
          setHasNewMessage(true)
        }
      }
    },
  })

  const messageNodes = getMessageNodes({
    conversationMessagesData,
    subscriptionData,
  })
  const messageNodesGroup = groupSameCreatedDay(messageNodes)

  useEffect(() => {
    setConversationMessagesData(undefined)
  }, [selectedConversation])

  useEffect(() => {
    return () => {
      // when component unmount and patient is selected, update message record with new time
      if (isNotEmpty(selectedConversation)) {
        updateUserMessageRecord({
          variables: {
            entity: getUserEntityIdFromAuth(auth),
            id: selectedConversation,
          },
        })
      }
    }
  }, [])

  useEffect(() => {
    selectedPatientRef.current = selectedConversation
    setSubscriptionData([])
    setScrollSmooth(false)
    return () => {
      if (isNotEmpty(selectedConversation)) {
        updateUserMessageRecord({
          variables: {
            entity: getUserEntityIdFromAuth(auth),
            id: selectedConversation,
          },
        })
      }
    }
  }, [selectedConversation])

  // when conversation scroll to top and has next page, fetch more
  const handleConversationScrollToTop = (e) => {
    const conversation = e.target
    const scrollbarAtTopConversation = conversation.scrollTop === 0
    if (
      scrollbarAtTopConversation &&
      conversationMessagesData &&
      conversationMessagesData.conversationMessages &&
      conversationMessagesData.conversationMessages.pageInfo.hasNextPage
    ) {
      fetchMore({
        variables: {
          id: selectedConversation,
          first: 10,
          after:
            conversationMessagesData.conversationMessages.pageInfo.endCursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (
            fetchMoreResult &&
            fetchMoreResult.conversationMessages &&
            previousResult.conversationMessages
          ) {
            const { edges: oldEdges } = previousResult.conversationMessages
            const { pageInfo, edges: moreEdges } =
              fetchMoreResult.conversationMessages
            const updatedConversatonMessage = {
              conversationMessages: {
                __typename: previousResult.conversationMessages.__typename,
                edges: [...oldEdges, ...moreEdges],
                pageInfo,
              },
            }
            setScrollSmooth(false)
            setNewMessageShouldScrollState({
              type: ScrollType.Query,
            })
            // refetch 不能觸發 onComplete，所以必須手動 set state
            setConversationMessagesData(updatedConversatonMessage)
            return updatedConversatonMessage
          }
          return previousResult
        },
      })
    }
  }

  const handleReadMessage = () => {
    setHasNewMessage(false)
  }
  const handleNewMessageNotificationOnClick = () => {
    setScrollSmooth(true)
    setNewMessageShouldScrollState({
      type: ScrollType.Subscription,
    })
  }

  return (
    <>
      {isNotEmpty(selectedConversation) && conversationInfo?.conversation && (
        <>
          <LatestMessageDrawerContainer>
            <Conversation
              title={`病患：${conversationInfo.conversation.patient.name}(${conversationInfo.conversation.members.length})`}
              members={conversationInfo.conversation.members}
              messageNodesGroup={messageNodesGroup}
              newMessageShouldScrollState={newMessageShouldScrollState}
              handleNewMessageNotificationOnClick={
                handleNewMessageNotificationOnClick
              }
              conversationId={selectedConversation}
              loading={conversationInfoLoading || conversationMessagesLoading}
              scrollToTopCallback={handleConversationScrollToTop}
              scrollSmooth={scrollSmooth}
              scrollToBottomCallback={handleReadMessage}
              hasNewMessage={hasNewMessage}
              entity={auth.entity}
              onReload={refetch}
            />
          </LatestMessageDrawerContainer>
          <ConversationPatientInfo
            patient={conversationInfo.conversation.patient}
          />
        </>
      )}
    </>
  )
}

export default LatestMessageDrawer

import { Tooltip } from 'antd'
import { TooltipProps } from 'antd/lib/tooltip'
import React, { FC } from 'react'

type CustomizedTooltipProps = TooltipProps & {
  hasTooltip: boolean
}

const CustomizedTooltip: FC<CustomizedTooltipProps> = (props) => {
  const { children, hasTooltip } = props

  if (!hasTooltip) {
    return <>{children}</>
  }

  return <Tooltip {...props}>{children}</Tooltip>
}

export default CustomizedTooltip

import { gql, useQuery } from '@apollo/client'
import { ErrorHandling, getStageName } from '@sov/common'
import { StageName } from '@sov/ui'
import { Select } from 'antd'
import { SelectProps, SelectValue } from 'antd/lib/select'
import {
  complement,
  filter,
  find,
  includes,
  map,
  prop,
  propEq,
  union,
} from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Stage,
  StageSelectDocs,
  StageSelectQuery,
  StageSelectQueryVariables,
  StagesQuery,
} from '../../../../graphql/types'
import CustomSelect from './CustomSelect'

const fragment = gql`
  fragment StageInStageSelect on Stage {
    id
    status
    ...StageName
  }
  ${StageName.fragment}
`

const stageSelectQuery = gql`
  query StageSelect(
    $query: StagesQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    stages(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...StageInStageSelect
      }
    }
  }
  ${fragment}
`

type StageSelectProps = SelectProps<StageSelectDocs> & {
  handleItemSelect?: (selectedItem: StageSelectDocs | undefined) => void
  initItem?: StageSelectDocs
  invalidStageItems?: Stage[]
  query?: StagesQuery
}

const StageSelect = (props: StageSelectProps) => {
  const {
    handleItemSelect,
    initItem,
    invalidStageItems = [],
    query = {},
    ...restProps
  } = props
  const { t } = useTranslation()
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data } = useQuery<StageSelectQuery, StageSelectQueryVariables>(
    stageSelectQuery,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      onError: (error) => {
        toErrorPage(error.message)
      },
      variables: {
        query,
        page: 1,
        limit: 100,
        sort: '-expectedShippingDate',
      },
    }
  )
  const fetchedItems = data?.stages ? data.stages.docs : []
  const items = initItem ? union(fetchedItems, [initItem]) : fetchedItems
  const filteredItems = filter(
    complement((x) =>
      includes<any>(prop('id', x), map(prop('id'), invalidStageItems))
    ),
    items
  )

  const handleSelect = (id: SelectValue) => {
    if (fetchedItems) {
      const selectedItem = find(propEq('id', id), fetchedItems)
      if (handleItemSelect) {
        handleItemSelect(selectedItem)
      }
    }
  }

  const getStageLabel = (item: StageSelectDocs) => {
    const [label, affix, serialNumber] = getStageName(t, item)
    return `${label} ${affix}${serialNumber} - ${t(
      `stage.status.${item.status}`
    )}`
  }

  return (
    <CustomSelect onSelect={handleSelect} {...restProps}>
      {map(
        (item) => (
          <Select.Option value={item.id} key={item.id}>
            {getStageLabel(item)}
          </Select.Option>
        ),
        filteredItems
      )}
    </CustomSelect>
  )
}

StageSelect.fragments = {
  StageInStageSelect: fragment,
}

export default StageSelect

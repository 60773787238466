import './index.less'

import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form'
import { gql, useQuery } from '@apollo/client'
import {
  ErrorHandling,
  FormQuery,
  TableQuery,
  getDateIntervalQueryString,
} from '@sov/common'
import { InstructionCardPreview } from '@sov/ui'
import { InputNumber, Modal } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import business from 'moment-business'
import {
  T,
  compose,
  concat,
  cond,
  filter,
  identity,
  includes,
  indexOf,
  isNil,
  length,
  map,
  omit,
  pathOr,
  propSatisfies,
  reject,
  sort,
} from 'ramda'
import React, { useEffect, useRef, useState } from 'react'

import {
  QcTasksQueryDocs,
  QcTasksQueryQuery,
  QcTasksQueryVariables,
  TaskType,
} from '../../../../../graphql/types'
import { useInstructionRemove } from '../../../../helpers/hooks/useInstruction'
import BreadcrumbCreator from '../../../layout/BreadcrumbCreator'
import Page, { Section } from '../../../layout/Page'
import Title from '../../../layout/Title'
import QCListTable from '../../../table/QCList'
import { BagLabels, BagLabelsPreviewModal } from './BagLabel'
import { BoxLabels, BoxLabelsPreview } from './BoxLabel'
import { NewBagLabels, NewBagLabelsPreviewModal } from './NewBagLabel'
import QCForm, { QCFormFields, formInput } from './QCForm'
import {
  addZeroPrefix,
  getBagLabelPrintData,
  getBoxLabelPrintData,
  getNewBagLabelPrintData,
} from './utils'

export enum PrintType {
  bagLabel = 'bagLabel',
  boxLabel = 'boxLabel',
  instruction = 'instruction',
  stageBrief = 'stageBrief',
  table = 'table',
  newBagLabel = 'newBagLabel',
}

export interface BagLabelPrintData {
  taskId: string
  groupIndex: number
  clinicName: string
  patientName: string
  stageCode: string
  isSpecialContract: boolean
  note: string
}

export interface BoxLabelPrintData {
  taskId: string
  labelId: string
  clinicName: string
  patientName: string
  firstStep: string
  lastStep: string
}

export interface StepDescription {
  upper: string | undefined
  lower: string | undefined
}

export interface NewBagLabelPrintData {
  taskId: string
  clinicName: string
  patientName: string
  stageCode: string
  patientCode: string
  serialNumber: number
  currentStep: StepDescription | string
  nextStep: StepDescription | string
  expectedShippingDate: Date
}

type Label = BagLabelPrintData | BoxLabelPrintData | NewBagLabelPrintData
type LabelList = Label[]

export const lastDayInterval: [moment.Moment, moment.Moment] = [
  // @ts-ignore
  business.subtractWeekDays(moment().startOf('day'), 1),
  moment().startOf('day'),
]

const qcTasksQuery = gql`
  query QcTasksQuery(
    $query: TasksQuery = {}
    $page: Int = 1
    $limit: Int = 50
    $sort: String = "-created"
  ) {
    tasks(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        startedByEmployee
        patient {
          id
          name
          patientCode
          cabinetCode
          clinic {
            id
            name
            specialContract
          }
          currentEvalStage {
            id
            analysis {
              steps {
                upperStep
                lowerStep
              }
            }
          }
        }
        stage {
          id
          expectedShippingDate
          ... on PrintStage {
            serialNumber
            instructionCard {
              ...InstructionCardPreview
            }
            designStage {
              id
              serialNumber
              step {
                upperStep
                lowerStep
              }
            }
          }
          ... on AccessoryStage {
            instructionCard {
              ...InstructionCardPreview
            }
          }
        }
        ...QCListTable
      }
      total
      limit
      page
    }
  }
  ${QCListTable.fragment}
  ${InstructionCardPreview.fragments.InstructionCardPreview}
`

type Props = FormComponentProps

const QCList = ({ form }: Props) => {
  /** state */
  const componentRef = useRef<any>()
  const [selectedTaskIds, setSelectedTaskIds] = useState<(string | number)[]>(
    []
  )
  const [isShowingOnlySelected, setIsShowingOnlySelected] = useState(false)
  const [hiddenColumnKeys, setHiddenColumnKeys] = useState<
    ColumnProps<any>['key'][]
  >([])
  const [isOpeningModal, setIsOpeningModal] = useState(false)
  const [isShowingBar, setIsShowingBar] = useState(false)
  const [printData, setPrintData] = useState<LabelList>([])

  const { tableQuery, paginateQuery, handleTableChange, handleTableReset } =
    TableQuery.useTableQuery<any, 'cabinetCode'>({
      limit: 300,
      sort: '-startedByEmployee',
    })
  const {
    formQuery: rawFormQuery,
    handleFormChange,
    handleFormReset,
  } = FormQuery.useFormQuery(formInput)
  const formQuery = {
    printType:
      (rawFormQuery.printType as PrintType | undefined) || PrintType.table,
    startedByEmployeeInterval:
      rawFormQuery.startedByEmployeeInterval || map(moment, lastDayInterval),
  } as QCFormFields

  const sorterInfo = tableQuery.sort
  const printType = formQuery.printType

  const queryTaskType =
    printType === PrintType.stageBrief
      ? [TaskType.CompleteBraces]
      : [TaskType.CompleteRp]

  /**
   * 因為病患塔位 cabinetCode 是 client sort
   * 當 url 中 query 的 sort 包含 cabinetCode 時
   * useQuery 的 pagination varible 不改變
   */
  const paginateQueryWithUpdatedSort = cond<
    typeof paginateQuery,
    typeof paginateQuery
  >([
    // @ts-ignore
    [propSatisfies(includes('cabinetCode'), 'sort'), omit(['sort'])],
    [T, identity],
  ])(paginateQuery)

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, refetch, loading } = useQuery<
    QcTasksQueryQuery,
    QcTasksQueryVariables
  >(qcTasksQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {
        startedByEmployeeInterval: getDateIntervalQueryString(
          formQuery.startedByEmployeeInterval
        ),
        type: queryTaskType,
      },
      sort: '-startedByEmployee',
      ...paginateQueryWithUpdatedSort,
    },
  })
  const { removeStageInstructionCard } = useInstructionRemove({
    onRemoved: refetch,
  })

  const taskItems = data?.tasks ? (data.tasks.docs as QcTasksQueryDocs[]) : []
  const selectedTaskItems = isShowingOnlySelected
    ? filter<QcTasksQueryDocs>(
        (x) => indexOf(x.id, map(String, selectedTaskIds)) !== -1,
        taskItems
      )
    : taskItems

  // 需要前端排序
  const sortedSelectedTaskItems =
    sorterInfo?.field === 'cabinetCode'
      ? sort((a, b) => {
          const predicate =
            sorterInfo.order === 'ascend'
              ? a.patient.cabinetCode > b.patient.cabinetCode
              : a.patient.cabinetCode < b.patient.cabinetCode
          return predicate ? 1 : -1
        }, selectedTaskItems)
      : selectedTaskItems

  const cursor = data?.tasks
    ? {
        limit: data.tasks.limit,
        page: data.tasks.page,
        total: data.tasks.total,
      }
    : {
        limit: 0,
        page: 1,
        total: 0,
      }

  useEffect(() => {
    if (printType === PrintType.boxLabel) {
      const printData = getBoxLabelPrintData(sortedSelectedTaskItems)
      setPrintData(printData)
      setIsOpeningModal(true)
      setIsShowingBar(false)
    } else if (printType === PrintType.bagLabel) {
      const printData = getBagLabelPrintData(sortedSelectedTaskItems)
      setPrintData(printData)
      setIsOpeningModal(true)
      setIsShowingBar(false)
    } else if (printType === PrintType.newBagLabel) {
      const printData = getNewBagLabelPrintData(sortedSelectedTaskItems)
      setPrintData(printData)
      setIsOpeningModal(true)
      setIsShowingBar(false)
    } else {
      setIsShowingBar(true)
    }
  }, [printType, sortedSelectedTaskItems.length])

  const handleColumn = () => {
    if (hiddenColumnKeys.length > 0) {
      setHiddenColumnKeys([])
    } else {
      setHiddenColumnKeys(['startedByEmployee', 'instructionCard'])
    }
  }

  const handleIsShowingOnlySelectedToggle = () => {
    setIsShowingOnlySelected((isShowingOnlySelected) => !isShowingOnlySelected)
  }

  // 將手動表單值填入
  const handleOk = () => {
    const { getFieldValue } = form
    const emptyBoxCount = getFieldValue('emptyLabelBox') as number
    const updatedPrintData = map((label: any) => {
      let newOptions
      switch (printType) {
        case PrintType.boxLabel:
          newOptions = {
            firstStep: getFieldValue(
              `${pathOr('', ['taskId'], label)}-firstStep`
            ),
            lastStep: getFieldValue(
              `${pathOr('', ['taskId'], label)}-lastStep`
            ),
          }
          break
        case PrintType.bagLabel:
          newOptions = {
            note: getFieldValue(
              `${pathOr('', ['taskId'], label)}-note-${pathOr(
                '',
                ['groupIndex'],
                label
              )}`
            ),
            stageCode: addZeroPrefix(
              getFieldValue(`${pathOr('', ['taskId'], label)}-stageCode`)
            ),
          }
          break
        case PrintType.newBagLabel:
          newOptions = {
            stageCode: addZeroPrefix(
              getFieldValue(`${pathOr('', ['taskId'], label)}-stageCode`)
            ),
            currentStep: getFieldValue(
              `${pathOr('', ['taskId'], label)}-step-current`
            ),
            nextStep: getFieldValue(
              `${pathOr('', ['taskId'], label)}-step-next`
            ),
          }
          break
        default:
          break
      }
      return { ...label, ...newOptions }
    })(printData)
    setPrintData(concat(Array(emptyBoxCount).fill({}), updatedPrintData))
    setIsShowingBar(false)
    setIsOpeningModal(false)
  }

  const handleCancel = () => setIsOpeningModal(false)

  const handleTypeChange = (printType: string) =>
    handleFormChange({ printType }, 'pushIn')

  const handleSearch = (form: WrappedFormUtils<QCFormFields>) =>
    handleFormChange(
      {
        startedByEmployeeInterval: form.getFieldValue(
          'startedByEmployeeInterval'
        ),
      },
      'pushIn'
    )

  const handleRefresh = () => {
    handleTableReset()
    handleFormReset()
  }

  const { getFieldDecorator } = form

  const taskText = printType === PrintType.stageBrief ? '牙套' : '快速成型'
  const rowSelection = {
    selectedRowKeys: selectedTaskIds,
    onChange: setSelectedTaskIds,
  }
  return (
    <Page
      header={
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'StageReportQc' }]}
          />
          <Title
            route={{
              key: 'StageReportQc',
              renderSubtitle: () => (
                <>
                  {length(formQuery.startedByEmployeeInterval) !== 2
                    ? `搜索條件：請輸入搜索範圍`
                    : loading
                    ? `搜索條件：讀取中`
                    : `搜索條件：${taskText}任務且開始時間為
                    ${moment(formQuery.startedByEmployeeInterval[0]).format(
                      'YYYY-MM-DD HH:mm'
                    )}
                    ~
                    ${moment(formQuery.startedByEmployeeInterval[1]).format(
                      'YYYY-MM-DD HH:mm'
                    )}
                    `}
                  ，
                  {sorterInfo?.field === 'cabinetCode'
                    ? `排序條件：病患塔位 ${
                        sorterInfo.order === 'ascend' ? 'A 到 Z' : 'Z 到 A'
                      }`
                    : '排序條件：開始時間'}
                </>
              ),
            }}
          />
        </>
      }
    >
      <Section>
        <div className={`report-qc-container ${printType}`}>
          <div className={isShowingBar ? 'form-bar active' : 'form-bar'}>
            <QCForm
              form={form}
              formQuery={formQuery}
              selectedTaskIds={selectedTaskIds}
              componentRef={componentRef}
              isShowingOnlySelected={isShowingOnlySelected}
              hiddenColumnKeys={hiddenColumnKeys}
              handleSearch={handleSearch}
              handleRefresh={handleRefresh}
              handleTypeChange={handleTypeChange}
              handleIsShowingOnlySelectedToggle={
                handleIsShowingOnlySelectedToggle
              }
              handleColumn={handleColumn}
            />
          </div>
          {includes(printType, [
            PrintType.table,
            PrintType.stageBrief,
            undefined,
          ]) && (
            <div
              style={{ flex: 1, marginTop: '12px' }}
              className='qc-table-container'
            >
              <QCListTable
                title={() => (
                  <h3>
                    包裝 QC 大單，區間：
                    {moment(formQuery.startedByEmployeeInterval[0]).format(
                      'YYYY-MM-DD HH:mm'
                    )}{' '}
                    ~{' '}
                    {moment(formQuery.startedByEmployeeInterval[1]).format(
                      'YYYY-MM-DD HH:mm'
                    )}
                  </h3>
                )}
                hiddenColumnKeys={hiddenColumnKeys}
                data={sortedSelectedTaskItems}
                handleChange={handleTableChange}
                removeStageInstructionCard={removeStageInstructionCard}
                loading={loading}
                cursor={cursor}
                rowSelection={rowSelection}
                sortInfo={tableQuery.sort}
              />
            </div>
          )}
          {printType === PrintType.bagLabel && (
            <BagLabels printData={printData as BagLabelPrintData[]} />
          )}
          {printType === PrintType.boxLabel && (
            <BoxLabels printData={printData as BoxLabelPrintData[]} />
          )}
          {printType === PrintType.newBagLabel && (
            <NewBagLabels printData={printData as NewBagLabelPrintData[]} />
          )}
          {printType === PrintType.instruction && (
            <div className='report-qc-instruction-print' ref={componentRef}>
              {map<QcTasksQueryDocs, JSX.Element>(
                (task) => {
                  if (
                    task.stage.__typename === 'PrintStage' &&
                    task.stage.designStage &&
                    task.stage.instructionCard
                  ) {
                    const designStageId = task.stage.designStage.id
                    return (
                      <InstructionCardPreview
                        instructionItem={task.stage.instructionCard}
                        handleRemove={() =>
                          removeStageInstructionCard(designStageId)
                        }
                        key={`${task.stage.id}-instruction`}
                      />
                    )
                  }
                  if (
                    task.stage.__typename === 'AccessoryStage' &&
                    task.stage.instructionCard
                  ) {
                    return (
                      <InstructionCardPreview
                        instructionItem={task.stage.instructionCard}
                        handleRemove={() =>
                          removeStageInstructionCard(task.stage.id)
                        }
                        key={`${task.stage.id}-instruction`}
                      />
                    )
                  }
                  return <div />
                },
                reject(
                  (task) =>
                    isNil(
                      task.stage.__typename === 'PrintStage' ||
                        task.stage.__typename === 'AccessoryStage'
                        ? task.stage.instructionCard
                        : null
                    ),
                  sortedSelectedTaskItems
                )
              )}
            </div>
          )}
          <Modal
            title={
              <>
                <span>預覽, 空白標籤數：</span>
                <span>
                  {getFieldDecorator('emptyLabelBox', {
                    initialValue: 0,
                  })(<InputNumber min={0} />)}
                </span>
              </>
            }
            visible={isOpeningModal}
            onOk={handleOk}
            onCancel={handleCancel}
            style={{ top: '30px' }}
            width={printType === PrintType.newBagLabel ? '95vw' : undefined}
          >
            {printType === PrintType.bagLabel && (
              <BagLabelsPreviewModal
                getFieldDecorator={getFieldDecorator}
                loading={loading}
                printData={printData as BagLabelPrintData[]}
              />
            )}
            {printType === PrintType.boxLabel && (
              <BoxLabelsPreview
                getFieldDecorator={getFieldDecorator}
                loading={loading}
                printData={printData as BoxLabelPrintData[]}
              />
            )}
            {printType === PrintType.newBagLabel && (
              <NewBagLabelsPreviewModal
                getFieldDecorator={getFieldDecorator}
                loading={loading}
                printData={printData as NewBagLabelPrintData[]}
              />
            )}
          </Modal>
        </div>
      </Section>
    </Page>
  )
}

export { QCList }

export default compose(Form.create())(QCList)

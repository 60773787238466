import { gold } from '@ant-design/colors'
import { gql, useMutation } from '@apollo/client'
import { CheckPoint } from '@sov/ui'
import { CheckPointProgressProps } from '@sov/ui/src/components/CheckPoint/CheckPointProgress'
import {
  Button,
  DatePicker,
  Form,
  FormProps,
  InputNumber,
  Modal,
  ModalProps,
  Row,
  Space,
  Tooltip,
  Typography,
  message,
} from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'

import {
  BatchCreateDesignStageMutation,
  BatchCreateDesignStageMutationVariables,
  ModalBatchCreateDesignDesignStageInlineFragment,
  ModalBatchCreateDesignFragment,
  StageStatus,
  StageType,
} from '../../../../graphql/types'

const StyledCheckPointProgress = styled(CheckPoint.CheckPointProgress)`
  margin: 24px 0;
`
const RoundTooltipCorner = createGlobalStyle`
  .ant-tooltip-inner {
    border-radius: 8px !important; 
  }
`
const AnchorPoint = styled.div<{ right: number }>`
  border: solid 1px ${gold.primary};
  background-color: ${gold.primary};
  width: 12px;
  height: 12px;
  border-radius: 50%;

  position: absolute;
  right: ${(props) => props.right}%;
`

const batchCreateDesignStage = gql`
  mutation BatchCreateDesignStage($payload: CreateDesignStageInput!) {
    createDesignStage(payload: $payload) {
      id
    }
  }
`

const DESIGN_ANCHOR_POINT_CLASS_NAME = 'design-anchor-point'
const DesignAnchor: CheckPointProgressProps['Anchor'] = (props) => {
  const { currentStep, right } = props

  const getPopupContainer = () =>
    document.querySelector(`.${DESIGN_ANCHOR_POINT_CLASS_NAME}`) as HTMLElement

  return (
    <>
      <RoundTooltipCorner />
      <Tooltip
        title={`D${currentStep}`}
        color='gold'
        placement='bottom'
        visible
        overlayStyle={{ zIndex: 700 }}
        getPopupContainer={getPopupContainer}
      >
        <AnchorPoint className={DESIGN_ANCHOR_POINT_CLASS_NAME} right={right} />
      </Tooltip>
    </>
  )
}

interface FormBatchCreateDesign {
  count: number
  latestDesignSerialNumber?: number
  expectedShippingDate: moment.Moment
}

interface ModalBatchCreateDesignProps extends ModalProps {
  item: ModalBatchCreateDesignFragment
  currentCheckPointGoal: number
  onCancel: () => void
  handleRefetch: () => Promise<void>
}

const ModalBatchCreateDesign = (props: ModalBatchCreateDesignProps) => {
  const { item, currentCheckPointGoal, onCancel, handleRefetch, ...restProps } =
    props

  const [loading, setLoading] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [createDesignForm] = Form.useForm<FormBatchCreateDesign>()
  const [createDesign] = useMutation<
    BatchCreateDesignStageMutation,
    BatchCreateDesignStageMutationVariables
  >(batchCreateDesignStage)

  const checkPoints = item?.currentEvalStage?.checkPoints ?? []
  const latestDesign = item.designStages?.docs[0] as
    | ModalBatchCreateDesignDesignStageInlineFragment
    | undefined
  const latestDesignSerialNumber = latestDesign?.serialNumber ?? 0
  const isCPMode = item.currentEvalStage!.isCPMode
  const maxCount = currentCheckPointGoal - latestDesignSerialNumber

  const handleClose = () => {
    createDesignForm.resetFields()
    onCancel()
  }

  const handleSubmitForm = async () => {
    try {
      const formValues = await createDesignForm.validateFields()
      const serialNumberList = new Array(formValues.count)
        .fill(null)
        .map((_, index) => latestDesignSerialNumber + index + 1)
      const promiseList = serialNumberList.map((serialNumber) =>
        createDesign({
          variables: {
            payload: {
              patient: item.id,
              expectedShippingDate: formValues.expectedShippingDate.toDate(),
              ...(isCPMode
                ? { serialNumber }
                : { stepIndex: serialNumber - 1 }),
            },
          },
        })
      )
      setLoading(true)
      await Promise.all(promiseList)
      await handleRefetch()
      setLoading(false)
      handleClose()
    } catch (error) {
      setLoading(false)
      if (error?.message) {
        /** graphQL errors */
        message.error(error.message)
      } else {
        /** form errors or other errors */
        console.log(error)
      }
    }
  }

  const handleValuesChange: FormProps<FormBatchCreateDesign>['onValuesChange'] =
    (changedValues, values) => {
      setSubmitDisabled(values.count === 0)
    }

  const Footer = (
    <Row justify='space-between'>
      <Button disabled={loading} onClick={handleClose}>
        取消
      </Button>
      <Button
        type='primary'
        loading={loading}
        disabled={submitDisabled}
        onClick={handleSubmitForm}
      >
        確定
      </Button>
    </Row>
  )

  return (
    <Modal
      {...restProps}
      width='800px'
      title='批次新增設計工單'
      footer={Footer}
      onCancel={handleClose}
      destroyOnClose
    >
      <Space>
        <Typography.Title level={5} style={{ margin: 0 }}>
          Check Point
        </Typography.Title>
        {latestDesignSerialNumber ? (
          <Typography.Text type='secondary'>
            目前進行到 D{latestDesignSerialNumber}
          </Typography.Text>
        ) : (
          <Typography.Text type='secondary'>目前尚無設計單</Typography.Text>
        )}
      </Space>
      <Form
        form={createDesignForm}
        layout='inline'
        initialValues={{ count: 0, latestDesignSerialNumber }}
        onValuesChange={handleValuesChange}
      >
        <Form.Item dependencies={['count']} noStyle>
          {() => {
            const expectedStep =
              latestDesignSerialNumber +
              createDesignForm.getFieldValue(['count'])
            return (
              <StyledCheckPointProgress
                checkPoints={checkPoints}
                currentStep={expectedStep}
                showAnchor
                Anchor={DesignAnchor}
              />
            )
          }}
        </Form.Item>
        <Form.Item name='latestDesignSerialNumber' hidden />
        <Form.Item
          label='新增工單數量'
          name='count'
          tooltip={`上限為${maxCount}張，如需調整上限，請修改CP目標`}
          required
        >
          <InputNumber min={0} max={maxCount} />
        </Form.Item>
        <Form.Item
          label='預計出貨'
          name='expectedShippingDate'
          required
          rules={[{ required: true }]}
        >
          <DatePicker />
        </Form.Item>
      </Form>
    </Modal>
  )
}

ModalBatchCreateDesign.fragments = {
  ModalBatchCreateDesign: gql`
    fragment ModalBatchCreateDesign on Patient {
      id
      currentEvalStage {
        isCPMode
        checkPoints {
          ...CheckPointProgress
        }
      }
      designStages: stages(query: { type: [${StageType.Design}], status: [${StageStatus.Completed}, ${StageStatus.Started}] }, limit: 1, sort: "-serialNumber") {
        docs {
          id
          __typename
          ...on DesignStage {
            serialNumber
          }
        }
      }
    }
    ${CheckPoint.CheckPointProgress.fragments.CheckPointProgress}
  `,
}

export default ModalBatchCreateDesign

import { AlertOutlined, LoadingOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { ImageCard } from '@sov/ui'
import { Avatar, Button, Dropdown, Menu, Modal, Spin, message } from 'antd'
import moment, { Moment } from 'moment'
import { includes, map } from 'ramda'
import React, { forwardRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import {
  AllPrivilege,
  ConversationMessagesQueryNode,
  ImageMessage,
  MessageType,
  TextMessage,
} from '../../../../graphql/types'
import { authContext } from '../../../context'
import { getEntityTitle } from '../../../helpers/entity/getEntityTitle'
import { getAuthPrivileges, isItemOwner } from '../../../utils'
import { MessageNodesGroup } from './Conversation'
import { isNotEmpty } from './utils'

const { confirm } = Modal

interface ContentProps {
  isMyMessage: boolean
}

interface ContainerProps {
  scrollSmooth: boolean
}

const ConversationContentContainer = styled.div<ContainerProps>`
  padding: 8px 12px;
  overflow: scroll;
  overscroll-behavior: none;
  scroll-behavior: ${(props) => (props.scrollSmooth ? 'smooth' : 'auto')};
  flex-grow: 1;
  min-height: 0;
`

const MessageRowContainer = styled.div`
  margin: 15px 0px;
  display: flex;
`

const CreatorTitle = styled.div`
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 12px;
  align-items: center;
`

const ContentContainer = styled.div`
  margin-top: 8px;
`

const TextContentContainer = styled(ContentContainer as any)<ContentProps>`
  padding: 8px 16px;
  border-radius: 0px 15px 15px 15px;
  max-width: 350px;
  font-size: 14px;
  background-color: ${(props) =>
    props.isMyMessage ? '#1890ff' : 'rgba(0, 0, 0, 0.05)'};
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
`

const ContentCol = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  color: ${(props) => (props.isMyMessage ? 'white' : 'rgba(0, 0, 0, 0.65)')};
`

const MessageTimeStamp = styled.div`
  margin-top: 8px;
  align-self: flex-end;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
`

const DateRow = styled.div`
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
`

const NewMessageButtonContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top: 20px;
`

interface NewMessageButtonProps {
  handleOnClick: (e: any) => void
}

const NewMessageButton = ({ handleOnClick }: NewMessageButtonProps) => (
  <NewMessageButtonContainer>
    <Button
      style={{ margin: 'auto' }}
      shape='round'
      icon={<AlertOutlined />}
      onClick={handleOnClick}
    >
      有新訊息
    </Button>
  </NewMessageButtonContainer>
)

const removeMessageMutation = gql`
  mutation RemoveMessageMutation($id: ID!) {
    removeMessage(id: $id) {
      id
      message
    }
  }
`

interface MessageRowProps {
  node: ConversationMessagesQueryNode
  onReload: () => void
}

const MessageRow = ({ node, onReload }: MessageRowProps) => {
  const { id, creator, content, image, type, created } = node as TextMessage &
    ImageMessage
  const auth = useContext(authContext)
  const [removeMessage] = useMutation(removeMessageMutation)
  const { t } = useTranslation()

  const { id: creatorId, name } = creator
  const isMyMessage = isItemOwner(creatorId, auth)
  const hasMessageDeletePrivilege = includes(
    AllPrivilege.MessageDelete,
    getAuthPrivileges(auth) ?? []
  )
  const handleRemove = async () => {
    try {
      await removeMessage({
        variables: {
          id,
        },
        update: async (cache, { data }) => {
          if (data) {
            message.success(data.removeMessage.message)
            onReload()
          }
        },
      })
    } catch (error) {
      message.error(error)
    }
  }

  const onClickRemove = () => {
    confirm({
      title: '刪除訊息',
      content: '將會永久刪除該訊息，是否要繼續？',
      cancelText: '取消',
      okText: '確定',
      onOk: handleRemove,
    })
  }
  const RemoveMenu = () => (
    <Menu>
      <Menu.Item key='0' onClick={onClickRemove}>
        <div>移除訊息</div>
      </Menu.Item>
    </Menu>
  )
  return (
    <MessageRowContainer>
      <Avatar
        size={36}
        style={{ marginRight: '12px', flexGrow: 0, flexShrink: 0 }}
      >
        {name[0]}
      </Avatar>
      <ContentCol isMyMessage={isMyMessage}>
        <CreatorTitle>
          <div>{`${getEntityTitle(creator, t)} ${name}`}</div>
        </CreatorTitle>
        <Dropdown
          overlay={RemoveMenu}
          trigger={['contextMenu']}
          disabled={!hasMessageDeletePrivilege}
        >
          {type === MessageType.Text ? (
            <TextContentContainer isMyMessage={isMyMessage}>
              {content}
            </TextContentContainer>
          ) : (
            <ContentContainer>
              <ImageCard imageFile={image} />
            </ContentContainer>
          )}
        </Dropdown>
        <MessageTimeStamp>
          <div>{moment(created).locale('en').format('LT')}</div>
        </MessageTimeStamp>
      </ContentCol>
    </MessageRowContainer>
  )
}

interface MessageGroupProps {
  date?: Moment | string
  nodes: ConversationMessagesQueryNode[]
  onReload: () => void
}

const MessageGroup = ({ nodes, date, onReload }: MessageGroupProps) => {
  return (
    <div>
      <DateRow>{isNotEmpty(date) && moment(date).format('YYYY-MM-DD')}</DateRow>
      {map((node) => {
        return <MessageRow key={uuidv4()} node={node} onReload={onReload} />
      }, nodes)}
    </div>
  )
}

interface ConversationContentProps {
  scrollSmooth: boolean
  onScroll: (e: any) => void
  messageNodesGroup: MessageNodesGroup[]
  newMessageButtonVisible: boolean | undefined
  handleNewMessageNotificationClick: () => void
  loading: boolean
  onReload: () => void
}
export const ConversationContent = forwardRef(
  (props: ConversationContentProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      scrollSmooth,
      onScroll,
      messageNodesGroup,
      newMessageButtonVisible,
      handleNewMessageNotificationClick,
      loading,
      onReload,
    } = props

    return (
      <ConversationContentContainer
        ref={ref}
        scrollSmooth={scrollSmooth}
        onScroll={onScroll}
      >
        {messageNodesGroup && (
          <div style={{ position: 'relative' }}>
            {newMessageButtonVisible && (
              <NewMessageButton
                handleOnClick={handleNewMessageNotificationClick}
              />
            )}
            {loading && (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                style={{ display: 'block', marginBottom: '10px' }}
              />
            )}
            {messageNodesGroup.map((group, index) => (
              <MessageGroup
                key={index}
                date={group.date}
                nodes={group.nodes}
                onReload={onReload}
              />
            ))}
          </div>
        )}
      </ConversationContentContainer>
    )
  }
)

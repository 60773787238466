import { gql, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Menu } from 'antd'
import { keys, map } from 'ramda'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import {
  AccountMenuQueryQuery,
  AccountMenuQueryVariables,
} from '../../../../../graphql/types'
import BreadcrumbCreator from '../../../../components/layout/BreadcrumbCreator'
import Title from '../../../../components/layout/Title'
import AccountLink from '../../../../components/link/account'
import { TabKey, accountSubRoutes } from './TabConfig'

const accountMenuQuery = gql`
  query AccountMenuQuery($id: ID!) {
    account(id: $id) {
      id
      nickname
    }
  }
`
// @TODO account migration
interface Props {
  accountId: string
  selectedKeys: TabKey[]
}

const AccountMenu = (props: Props) => {
  const { accountId, selectedKeys } = props
  const { pathname } = useLocation()
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data } = useQuery<AccountMenuQueryQuery, AccountMenuQueryVariables>(
    accountMenuQuery,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      onCompleted: (data) => {
        if (!data?.account) {
          toErrorPage({
            message: '不存在的帳戶',
            redirect: {
              name: '帳戶總覽',
              url: '/accounts',
            },
          })
        }
      },
      onError: (error) => {
        toErrorPage({
          message: error.message,
          redirect: {
            name: '帳戶總覽',
            url: '/accounts',
          },
        })
      },
      variables: {
        id: accountId,
      },
    }
  )
  const account = data?.account

  return (
    <div>
      <BreadcrumbCreator
        routes={[
          { key: 'Home' },
          { key: 'AccountList' },
          {
            key: 'AccountDetail',
            render: () => <AccountLink item={account} />,
          },
        ]}
      />
      <Title
        route={{ key: 'AccountDetail', render: () => account?.nickname }}
      />
      <Menu
        mode='horizontal'
        selectedKeys={selectedKeys}
        style={{ marginBottom: -16 }}
      >
        {map(
          (key) => (
            <Menu.Item key={key}>
              <Link to={`${pathname}#${key}`}>
                {accountSubRoutes[key].text}
              </Link>
            </Menu.Item>
          ),
          keys(accountSubRoutes)
        )}
      </Menu>
    </div>
  )
}

export default AccountMenu

import { CaretRightOutlined, QuestionCircleFilled } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { CheckPoint, MultiLine } from '@sov/ui'
import {
  Button,
  Collapse,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Typography,
  message,
} from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React from 'react'

import {
  AuxiliaryType,
  ClearStepsMutation,
  ClearStepsMutationVariables,
  PredesignCollapseFragment,
  PredesignCollapseSteps,
} from '../../../../graphql/types'

const clearStepsMutation = gql`
  mutation ClearSteps($id: ID!, $payload: UpdateAnalysisInput!) {
    updateAnalysis(id: $id, payload: $payload) {
      id
    }
  }
`

interface Props {
  evalStage: PredesignCollapseFragment
  handleRefetch: () => Promise<void>
}

const PredesignCollapse = (props: Props) => {
  const { evalStage, handleRefetch } = props

  const analysis = evalStage.analysis
  const steps = analysis?.steps ?? []

  if (steps.length === 0) return null

  const columns: ColumnProps<PredesignCollapseSteps>[] = [
    {
      title: '',
      width: '72px',
      render: (_text, record, index) => `STEP${index + 1}`,
    },
    {
      title: '上顎',
      width: 300,
      render: (_text, record) => <MultiLine text={record.upperStep} />,
    },
    {
      title: '下顎',
      width: 300,
      render: (_text, record) => <MultiLine text={record.lowerStep} />,
    },
    {
      title: '',
      width: '100px',
      render: (_text, record) => (
        <div>
          {record.attachment && (
            <CheckPoint.InstructionTag instruction={AuxiliaryType.Attachment} />
          )}
          {record.button && (
            <CheckPoint.InstructionTag instruction={AuxiliaryType.Button} />
          )}
          {record.ipr && (
            <CheckPoint.InstructionTag
              instruction={AuxiliaryType.StrippingAll}
            />
          )}
          {record.retrieve && (
            <CheckPoint.InstructionTag instruction='retrieve' />
          )}
        </div>
      ),
    },
  ]

  const [clearSteps] = useMutation<
    ClearStepsMutation,
    ClearStepsMutationVariables
  >(clearStepsMutation)

  const handleConfirm = async () => {
    await clearSteps({
      variables: {
        id: evalStage.id,
        payload: {
          steps: [],
        },
      },
      update: async (_cache, { data }) => {
        if (data?.updateAnalysis) {
          message.info('已刪除預設計，並改為 CP 報告', 3)
          await handleRefetch()
        }
      },
    })
  }

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={['1']}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      ghost
      style={{ margin: '0 -16px 24px -16px' }}
    >
      <Collapse.Panel
        key='1'
        header={
          <Space>
            <Typography.Title level={5} style={{ margin: 0 }}>
              參考過去預設計
            </Typography.Title>
            <Typography.Text type='secondary'>
              共 {steps.length} 個 預設計
            </Typography.Text>
            <Tooltip
              placement='right'
              title='預設計內容僅供參考，在重取模後請以 CP 中的內容為主。'
            >
              <QuestionCircleFilled
                style={{
                  marginLeft: '8px',
                  fontSize: '16px',
                  color: 'rgba(0, 0, 0, 0.2)',
                }}
              />
            </Tooltip>
          </Space>
        }
        extra={
          <Popconfirm
            title='此操作無法回復，將永久刪除此報告中的預設計內容，並將報告轉為 CP 版本。'
            okType='danger'
            onConfirm={handleConfirm}
          >
            <Button
              type='ghost'
              danger
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              全部刪除
            </Button>
          </Popconfirm>
        }
      >
        <Table<PredesignCollapseSteps>
          columns={columns}
          dataSource={steps}
          pagination={false}
        />
      </Collapse.Panel>
    </Collapse>
  )
}

PredesignCollapse.fragments = {
  PredesignCollapse: gql`
    fragment PredesignCollapse on EvalStage {
      id
      analysis {
        steps {
          upperStep
          lowerStep
          attachment
          ipr
          button
          retrieve
        }
      }
    }
  `,
}

export default PredesignCollapse

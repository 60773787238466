import { Role } from '@sov/common/src/types'
import { Form, Row, message } from 'antd'
import React, { useState } from 'react'

import {
  ClinicSelectDocs,
  DoctorSelectDocs,
  EmployeeSelectDocs,
} from '../../../../../graphql/types'
import ClinicLink from '../../../link/clinic'
import DoctorLink from '../../../link/doctor'
import EmployeeLink from '../../../link/employee'
import { ClinicDoctorSelect, ClinicSelect, EmployeeSelect } from '../../Select'

type Props = {
  form: any
  initialValues?: any
}

const PatientSelectGroup = (props: Props) => {
  const { form, initialValues = {} } = props

  const [selectedClinic, setSelectedClinic] = useState<
    ClinicSelectDocs | undefined
  >(initialValues?.clinicInfo)
  const [selectedDoctor, setSelectedDoctor] = useState<
    DoctorSelectDocs | undefined
  >(initialValues?.doctorInfo)
  const [selectedAccountManager, setSelectedAccountManager] = useState<
    EmployeeSelectDocs | undefined
  >(initialValues?.accountManagerInfo)
  const [selectedTechnician, setSelectedTechnician] = useState<
    EmployeeSelectDocs | undefined
  >(initialValues?.technicianInfo)
  const [selectedSales, setSelectedSales] = useState<
    EmployeeSelectDocs | undefined
  >(initialValues?.salesInfo)
  const [selectedCustomerService, setSelectedCustomerService] = useState<
    EmployeeSelectDocs | undefined
  >(initialValues?.customerServiceInfo)

  // 選擇診所
  const handleClinicSelect = (selectedClinic: ClinicSelectDocs) => {
    setSelectedClinic({
      ...selectedClinic,
      accountManagers: selectedClinic.accountManagers,
      technicians: selectedClinic.technicians,
      salesList: selectedClinic.salesList,
      customerService: selectedClinic.customerService,
    })

    if (selectedClinic.accountManagers.length > 0) {
      setSelectedAccountManager(selectedClinic.accountManagers[0])
      form.setFieldsValue({
        accountManager: selectedClinic.accountManagers[0].id,
      })
    } else {
      form.setFieldsValue({ accountManager: undefined })
      message.warning('診所尚未有對應的AM，請先至「診所編輯頁面」新增AM')
    }

    if (selectedClinic.technicians.length > 0) {
      setSelectedTechnician(selectedClinic.technicians[0])
      form.setFieldsValue({ technician: selectedClinic.technicians[0].id })
    } else {
      form.setFieldsValue({ technician: undefined })
      message.warning('診所尚未有對應的技師，請先至「診所編輯頁面」新增技師')
    }

    if (selectedClinic.salesList.length > 0) {
      setSelectedSales(selectedClinic.salesList[0])
      form.setFieldsValue({ sales: selectedClinic.salesList[0].id })
    } else {
      form.setFieldsValue({ sales: undefined })
      message.warning('診所尚未有對應的業務，請先至「診所編輯頁面」新增業務')
    }

    if (selectedClinic.customerService) {
      setSelectedCustomerService(selectedClinic.customerService)
      form.setFieldsValue({
        customerService: selectedClinic.customerService.id,
      })
    } else {
      form.setFieldsValue({ customerService: undefined })
    }
  }

  return (
    <>
      <Row>
        <Form.Item label='診所' required>
          <Form.Item
            name='clinic'
            rules={[{ required: true, message: '請輸入' }]}
            noStyle
          >
            <ClinicSelect handleItemSelect={handleClinicSelect} />
          </Form.Item>
          {selectedClinic && (
            <ClinicLink item={selectedClinic} style={{ color: 'black' }}>
              <i className='fa fa-external-link' />
            </ClinicLink>
          )}
        </Form.Item>
        <Form.Item label='醫生' required>
          <Form.Item
            name='doctor'
            rules={[{ required: true, message: '請輸入' }]}
            noStyle
          >
            <ClinicDoctorSelect
              disabled={!selectedClinic?.id}
              handleItemSelect={setSelectedDoctor}
              query={{ clinic: selectedClinic?.id }}
            />
          </Form.Item>
          {selectedDoctor && (
            // @ts-ignore
            <DoctorLink item={selectedDoctor} style={{ color: 'black' }}>
              <i className='fa fa-external-link' />
            </DoctorLink>
          )}
        </Form.Item>
      </Row>
      <Row>
        <Form.Item label='AM'>
          <Form.Item name='accountManager' noStyle>
            <EmployeeSelect
              disabled={!selectedClinic?.id}
              handleItemSelect={setSelectedAccountManager}
            />
          </Form.Item>
          {selectedAccountManager && (
            <EmployeeLink
              item={selectedAccountManager}
              style={{ color: 'black' }}
            >
              <i className='fa fa-external-link' />
            </EmployeeLink>
          )}
        </Form.Item>
        <Form.Item label='技師'>
          <Form.Item name='technician' noStyle>
            <EmployeeSelect
              disabled={!selectedClinic?.id}
              handleItemSelect={setSelectedTechnician}
              query={{ technicianClinic: selectedClinic?.id }}
            />
          </Form.Item>
          {selectedTechnician && (
            <EmployeeLink item={selectedTechnician} style={{ color: 'black' }}>
              <i className='fa fa-external-link' />
            </EmployeeLink>
          )}
        </Form.Item>
        <Form.Item label='業務'>
          <Form.Item
            name='sales'
            rules={[{ required: true, message: '請輸入' }]}
            noStyle
          >
            <EmployeeSelect
              disabled={!selectedClinic?.id}
              handleItemSelect={setSelectedSales}
              query={{ salesClinic: selectedClinic?.id }}
            />
          </Form.Item>
          {selectedSales && (
            <EmployeeLink item={selectedSales} style={{ color: 'black' }}>
              <i className='fa fa-external-link' />
            </EmployeeLink>
          )}
        </Form.Item>
        <Form.Item label='客服'>
          <Form.Item name='customerService' noStyle>
            <EmployeeSelect
              handleItemSelect={setSelectedCustomerService}
              query={{ roles: [Role.CustomerService] }}
            />
          </Form.Item>
          {selectedCustomerService && (
            <EmployeeLink
              item={selectedCustomerService}
              style={{ color: 'black' }}
            >
              <i className='fa fa-external-link' />
            </EmployeeLink>
          )}
        </Form.Item>
      </Row>
    </>
  )
}

export { PatientSelectGroup }

import { exhaustiveCheck } from './exhaustiveCheck'
import { StageType, TaskType } from './types'

export const getStageTypeFromTaskType = (taskType: TaskType) => {
  switch (taskType) {
    case TaskType.StartScan:
    case TaskType.AssignScan:
    case TaskType.CompleteScan:
    case TaskType.CompleteBase:
    case TaskType.CompleteSplit:
    case TaskType.SubmitFile:
      return StageType.Mold

    case TaskType.StartEval:
    case TaskType.ConfirmMold:
    case TaskType.AmCheck:
    case TaskType.AssignAlign:
    case TaskType.CompleteAlign:
    case TaskType.AssignPredesign:
    case TaskType.CompletePredesign:
    case TaskType.SubmitPredesign:
    case TaskType.AssignViewer:
    case TaskType.CompleteViewer:
    case TaskType.SubmitViewer:
      return StageType.Eval

    case TaskType.AssignDesign:
    case TaskType.CompleteDesign:
    case TaskType.SubmitDesign:
      return StageType.Design

    case TaskType.StartPrint:
    case TaskType.ConfirmDesign:
    case TaskType.CompleteRp:
    case TaskType.CompleteBraces:
    case TaskType.CreateInvoice:
    case TaskType.ConfirmShip:
      return StageType.Print

    /** 待移除 */
    case TaskType.AssignBraces:
    case TaskType.AssignQc:
    case TaskType.AssignRp:
    case TaskType.AssignSplit:
    case TaskType.AssignUpload:
    case TaskType.CompleteQc:
    case TaskType.CompleteUpload:
    case TaskType.StartDesign:
    case TaskType.SubmitBraces:
    case TaskType.SubmitQc:
    case TaskType.SubmitRp:
    case TaskType.SubmitScan:
    case TaskType.SubmitSplit:
      return undefined

    default: {
      exhaustiveCheck(taskType)
      return undefined
    }
  }
}

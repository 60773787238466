import { gql } from '@apollo/client'
import StageName from '@sov/ui/src/components/StageName'
import React from 'react'
import styled from 'styled-components'

import { OrderDetailStageLinkFragment } from '../../../../graphql/types'

const Link = styled.a`
  color: #1890ff;
`

interface OrderDetailStageLinkProps {
  stageItem: OrderDetailStageLinkFragment
}

const OrderDetailStageLink = (props: OrderDetailStageLinkProps) => {
  const { stageItem } = props

  const stageId = stageItem.id

  if (stageItem.__typename === 'PrintStage') {
    return <StageName item={stageItem} showType={false} />
  }

  return (
    <Link href={`/stages/${stageId}`}>
      <StageName item={stageItem} showType={false} />
    </Link>
  )
}

OrderDetailStageLink.fragments = {
  OrderDetailStageLink: gql`
    fragment OrderDetailStageLink on Stage {
      id
      __typename
      ...StageName
    }
    ${StageName.fragment}
  `,
}

export default OrderDetailStageLink

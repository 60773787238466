import { gql, useMutation, useQuery, useSubscription } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Drawer } from 'antd'
import { pathOr } from 'ramda'
import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { updateConversationReadMessageRecordOfMemberMutation } from '../../../../graphql/conversation/mutation/conversationMutation'
import {
  conversationMessagesQuery,
  conversationQuery,
  numberOfUnreadMessagesQuery,
} from '../../../../graphql/conversation/query/conversationQuerys'
import { onMessageAddedSubscription } from '../../../../graphql/message/subscription/onMessageAdded'
import {
  ConversationMessagesQueryQuery,
  ConversationMessagesQueryVariables,
  ConversationQueryQuery,
  ConversationQueryQueryVariables,
  MessageEdge,
  NumberOfUnreadMessagesQueryQuery,
  NumberOfUnreadMessagesQueryVariables,
  OnMessageAddedSubscriptionSubscription,
  OnMessageAddedSubscriptionSubscriptionVariables,
  Patient,
  SubscriptionType,
  UpdateConversationReadMessageRecordOfMemberMutationMutation,
  UpdateConversationReadMessageRecordOfMemberMutationVariables,
} from '../../../../graphql/types'
import { authContext } from '../../../context'
import { getUserEntityIdFromAuth, isItemOwner } from '../../../utils'
import Conversation, { ScrollType } from './Conversation'
import ConversationIcon from './ConversationIcon'
import { getMessageNodes, groupSameCreatedDay } from './utils'

const PatientComplaintContainer = styled.div`
  padding: 16px 24px;
  cursor: pointer;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.5),
      #e6f7ff
    ),
    linear-gradient(to bottom, #f0f2f5, #f0f2f5);
`
const PatientComplaintTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
`

const PatientComplaintContent = styled.div`
  margin-top: 16px;
  display: flex;
`
interface PatientMainComplaintProps {
  patient: Pick<Patient, 'id' | 'name' | 'chiefComplaint'>
}
const PatientMainComplaint = (props: PatientMainComplaintProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const { patient } = props
  const toggleCollapse = () => {
    setIsCollapsed((prevState) => !prevState)
  }
  return (
    <PatientComplaintContainer onClick={toggleCollapse}>
      <PatientComplaintTitle>病患：{patient.name}</PatientComplaintTitle>
      {!isCollapsed && (
        <PatientComplaintContent>
          <div style={{ minWidth: '60px' }}>
            <span style={{ color: 'red' }}>＊</span>主訴：
          </div>
          <div style={{ wordWrap: 'break-word' }}>{patient.chiefComplaint}</div>
        </PatientComplaintContent>
      )}
    </PatientComplaintContainer>
  )
}

interface Props {
  conversationId: string
}

const ConversationDrawer = (props: Props) => {
  const { conversationId } = props
  const { toErrorPage } = ErrorHandling.useErrorHandling()

  const isDrawerOpeningRef = useRef(false)
  const isFirstEffect = useRef(true)
  const auth = useContext(authContext)
  if (!auth) {
    return null
  }

  const [isDrawerOpening, setIsDrawerOpening] = useState(false)
  const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState(0)
  const [hasNewMessage, setHasNewMessage] = useState(false)
  const [newMessageShouldScrollState, setNewMessageShouldScrollState] =
    useState({
      type: ScrollType.Subscription,
    })
  // smooth scroll 目前會產生樣式方面 bug，先都用 auto
  const [scrollSmooth, setScrollSmooth] = useState(false)

  const [subscriptionData, setSubscriptionData] = useState<MessageEdge[]>([])

  const [updateUserMessageRecord] = useMutation<
    UpdateConversationReadMessageRecordOfMemberMutationMutation,
    UpdateConversationReadMessageRecordOfMemberMutationVariables
  >(updateConversationReadMessageRecordOfMemberMutation)

  const {
    data: conversationMessagesData,
    loading: conversationMessagesLoading,
    fetchMore,
    refetch,
  } = useQuery<
    ConversationMessagesQueryQuery,
    ConversationMessagesQueryVariables
  >(conversationMessagesQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id: conversationId,
      first: 20,
    },
    onError: (error) => {
      toErrorPage(error.message)
    },
    onCompleted: (data) => {
      if (data) {
        setSubscriptionData([])
      }
    },
  })

  const { data: conversationInfo } = useQuery<
    ConversationQueryQuery,
    ConversationQueryQueryVariables
  >(conversationQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    variables: {
      id: conversationId,
    },
    onError: (error) => {
      toErrorPage(error.message)
    },
  })

  const { refetch: refetchNumberOfUreadMessages } = useQuery<
    NumberOfUnreadMessagesQueryQuery,
    NumberOfUnreadMessagesQueryVariables
  >(numberOfUnreadMessagesQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    variables: {
      id: conversationId,
    },
    skip: !auth,
    onCompleted: (data) => {
      if (data?.numberOfUnreadMessages) {
        setNumberOfUnreadMessages(data.numberOfUnreadMessages)
      }
    },
    onError: (error) => {
      toErrorPage(error.message)
    },
  })

  useSubscription<
    OnMessageAddedSubscriptionSubscription,
    OnMessageAddedSubscriptionSubscriptionVariables
  >(onMessageAddedSubscription, {
    variables: {
      payload: {
        subscriptionType: SubscriptionType.Specific,
        conversation: pathOr(null, ['conversation', 'id'], conversationInfo),
      },
    },
    fetchPolicy: 'network-only',
    onSubscriptionData: (data) => {
      if (data?.subscriptionData?.data?.onMessageAdded) {
        const onMessageAdded = data.subscriptionData.data.onMessageAdded
        const { creator } = onMessageAdded.node
        const notSelfCreatedMessage = !isItemOwner(creator.id, auth)
        setScrollSmooth(false)
        setSubscriptionData([
          onMessageAdded,
          ...subscriptionData,
        ] as MessageEdge[])
        if (notSelfCreatedMessage) {
          setHasNewMessage(true)
        }
        if (!isDrawerOpening) {
          refetchNumberOfUreadMessages()
        }
      }
    },
  })
  const messageNodes = getMessageNodes({
    conversationMessagesData,
    subscriptionData,
  })
  const messageNodesGroup = groupSameCreatedDay(messageNodes)

  useEffect(() => {
    return () => {
      // when component unmount and drawer is opening, update message record with new time
      if (isDrawerOpeningRef.current) {
        updateUserMessageRecord({
          variables: {
            entity: getUserEntityIdFromAuth(auth),
            id: conversationId,
          },
        })
      }
    }
  }, [])

  useEffect(() => {
    // get ref for component unmount effect
    isDrawerOpeningRef.current = isDrawerOpening
    if (isFirstEffect.current) {
      isFirstEffect.current = false
      return
    }
    if (isDrawerOpening) {
      setNumberOfUnreadMessages(0)
      setHasNewMessage(false)
    } else {
      setScrollSmooth(false)
      updateUserMessageRecord({
        variables: {
          entity: getUserEntityIdFromAuth(auth),
          id: conversationId,
        },
        update: async () => {
          refetchNumberOfUreadMessages()
        },
      })
    }
  }, [isDrawerOpening])

  // when conversation scroll to top and hasNextPage, fetch more
  const handleConversationScrollToTop = (e) => {
    const conversation = e.target
    const scrollbarAtTopConversation = conversation.scrollTop === 0
    if (
      scrollbarAtTopConversation &&
      conversationMessagesData?.conversationMessages?.pageInfo?.hasNextPage
    ) {
      setScrollSmooth(false)
      fetchMore({
        query: conversationMessagesQuery,
        variables: {
          id: conversationId,
          first: 10,
          after:
            conversationMessagesData.conversationMessages.pageInfo.endCursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (
            fetchMoreResult &&
            fetchMoreResult.conversationMessages &&
            previousResult.conversationMessages
          ) {
            const { edges: oldEdges } = previousResult.conversationMessages
            const { pageInfo, edges: moreEdges } =
              fetchMoreResult.conversationMessages
            setNewMessageShouldScrollState({
              type: ScrollType.Query,
            })
            return {
              conversationMessages: {
                __typename: previousResult.conversationMessages.__typename,
                edges: [...oldEdges, ...moreEdges],
                pageInfo,
              },
            }
          }
          return previousResult
        },
      })
    }
  }

  const handleReadMessage = () => {
    setHasNewMessage(false)
  }
  const handleNewMessageNotificationOnClick = () => {
    setScrollSmooth(true)
    setNewMessageShouldScrollState({
      type: ScrollType.Subscription,
    })
  }

  return (
    <>
      <ConversationIcon
        numberOfUnreadMessages={numberOfUnreadMessages}
        handleOnClick={() => setIsDrawerOpening(!isDrawerOpening)}
        isDrawerOpening={isDrawerOpening}
        drawerWidth={500}
      />
      {conversationInfo && conversationInfo.conversation && (
        <Drawer
          mask
          maskClosable
          width={500}
          height='100vh'
          closable={false}
          onClose={() => setIsDrawerOpening(false)}
          visible={isDrawerOpening}
          zIndex={900}
          bodyStyle={{ padding: 0 }}
        >
          <Conversation
            title='討論'
            members={conversationInfo.conversation.members}
            messageNodesGroup={messageNodesGroup}
            newMessageShouldScrollState={newMessageShouldScrollState}
            handleNewMessageNotificationOnClick={
              handleNewMessageNotificationOnClick
            }
            conversationId={conversationInfo.conversation.id}
            loading={conversationMessagesLoading}
            scrollSmooth={scrollSmooth}
            height='100vh'
            scrollToTopCallback={handleConversationScrollToTop}
            scrollToBottomCallback={handleReadMessage}
            hasNewMessage={hasNewMessage}
            entity={auth.entity}
            extraInfo={
              <PatientMainComplaint
                patient={conversationInfo.conversation.patient}
              />
            }
            onReload={refetch}
          />
        </Drawer>
      )}
    </>
  )
}

ConversationDrawer.fragment = {
  PatientConversation: gql`
    fragment PatientConversation on Patient {
      conversation {
        id
      }
    }
  `,
}

export default ConversationDrawer

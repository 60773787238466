import { getEmptyText } from '@sov/common'
import { pathOr } from 'ramda'
import React from 'react'
import { Link } from 'react-router-dom'
import { DeepPartial } from 'utility-types'

import { Doctor } from '../../../../graphql/types'

interface Props {
  item?: DeepPartial<Doctor>
  style?: { [key: string]: string | number }
  children?: React.ReactNode
}

const DoctorLink = ({ item, style = { color: 'black' }, children }: Props) => {
  if (item) {
    const id = pathOr('', ['id'], item) || pathOr('', ['_id'], item)

    return (
      <Link to={`/doctors/${id}`} style={style}>
        {children || item.name}
      </Link>
    )
  } else {
    return <span style={style}>{children || getEmptyText()}</span>
  }
}

export default DoctorLink

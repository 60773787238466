import {
  flatten,
  groupBy,
  isNil,
  map,
  max,
  min,
  pathOr,
  range,
  reduce,
  reject,
  splitEvery,
  values,
} from 'ramda'

import { QcTasksQueryDocs } from '../../../../../graphql/types'
import {
  BagLabelPrintData,
  BoxLabelPrintData,
  NewBagLabelPrintData,
} from '../QC'

const isNumeric = (n: string) => !isNaN(Number(n))

export const addZeroPrefix = (stageCode: string) => {
  if (!isNumeric(stageCode)) {
    return stageCode
  }
  const parsedNumber = parseInt(stageCode, 10)

  return parsedNumber < 10 && parsedNumber > 0
    ? `0${parsedNumber}`
    : `${parsedNumber}`
}

export const getBoxLabelPrintData = (selectedTaskItems: QcTasksQueryDocs[]) => {
  const taskItemsObjectGroupedByPatient = groupBy(
    pathOr('', ['patient', 'id']),
    selectedTaskItems
  )
  const taskItemsGroupedByPatient = values(taskItemsObjectGroupedByPatient)
  const boxLabels = map<QcTasksQueryDocs[], BoxLabelPrintData[]>(
    (groupedTasksPerPatient) => {
      if (groupedTasksPerPatient.length === 1) {
        const taskItem = groupedTasksPerPatient[0]
        return [
          {
            taskId: taskItem.id,
            labelId: `box-label-${taskItem.patient.id}`,
            clinicName: taskItem.patient.clinic.name,
            patientName: taskItem.patient.name,
            firstStep:
              taskItem.stage.__typename === 'PrintStage'
                ? addZeroPrefix(taskItem.stage.serialNumber.toString())
                : '',
            lastStep:
              taskItem.stage.__typename === 'PrintStage'
                ? addZeroPrefix(taskItem.stage.serialNumber.toString())
                : '',
          },
        ]
      } else {
        const maxBraceSetPerBox = 2
        return map<QcTasksQueryDocs[], BoxLabelPrintData>((groupedTasks) => {
          const boxLabelDataPerPatient = reduce(
            (acc, cur) => {
              const tempBoxLabelData = {
                taskId: cur.id,
                labelId: `box-label-${cur.patient.id}`,
                clinicName: cur.patient.clinic.name,
                patientName: cur.patient.name,
                firstStep:
                  cur.stage.__typename === 'PrintStage'
                    ? addZeroPrefix(
                        min(cur.stage.serialNumber.toString(), acc.firstStep)
                      )
                    : '',
                lastStep:
                  cur.stage.__typename === 'PrintStage'
                    ? addZeroPrefix(
                        max(cur.stage.serialNumber.toString(), acc.lastStep)
                      )
                    : '',
              }
              return tempBoxLabelData
            },
            {
              taskId: '',
              labelId: '',
              clinicName: '',
              patientName: '',
              firstStep: 'zzz',
              lastStep: '0',
            },
            groupedTasks
          )
          return boxLabelDataPerPatient
        }, splitEvery(maxBraceSetPerBox, groupedTasksPerPatient))
      }
    },
    taskItemsGroupedByPatient
  )
  const boxLabelPrintData = flatten<BoxLabelPrintData>(boxLabels)
  return boxLabelPrintData
}

export const getBagLabelPrintData = (selectedTaskItems: QcTasksQueryDocs[]) => {
  const bagLabelPrintDataGroupedByRow = map<
    QcTasksQueryDocs,
    BagLabelPrintData[]
  >((taskItem) => {
    return map<number, BagLabelPrintData>(
      (groupIndex) => ({
        taskId: taskItem.id,
        groupIndex,
        clinicName: taskItem.patient.clinic.name,
        patientName: taskItem.patient.name,
        stageCode:
          taskItem.stage.__typename === 'PrintStage'
            ? taskItem.stage.serialNumber.toString()
            : '',
        isSpecialContract: taskItem.patient.clinic.specialContract,
        note:
          taskItem.patient.clinic.specialContract && groupIndex === 2
            ? '掃掃 QR 碼，神秘好康在這裡！\n幫忙介紹新朋友，立享 3%回饋金！'
            : 'SOV回娘家 好禮二選一\n快上FB搜尋"SOV牙套家族"',
      }),
      range(1, 4)
    )
  })(selectedTaskItems)
  const bagLabelPrintData = flatten<BagLabelPrintData>(
    bagLabelPrintDataGroupedByRow
  )
  return bagLabelPrintData
}

export const getNewBagLabelPrintData = (
  selectedTaskItems: QcTasksQueryDocs[]
) => {
  const result = map<QcTasksQueryDocs, NewBagLabelPrintData>((taskItem) => {
    const printStageItem =
      taskItem.stage.__typename === 'PrintStage' ? taskItem.stage : undefined

    return {
      taskId: taskItem.id,
      clinicName: taskItem.patient.clinic.name,
      patientName: taskItem.patient.name,
      patientCode: taskItem.patient.patientCode,
      serialNumber: printStageItem?.serialNumber as any,
      stageCode: printStageItem?.serialNumber.toString() ?? '',
      expectedShippingDate: printStageItem?.expectedShippingDate,
      currentStep: {
        upper: printStageItem?.designStage?.step.upperStep ?? '',
        lower: printStageItem?.designStage?.step.lowerStep ?? '',
      },
      nextStep: {
        upper:
          taskItem.patient.currentEvalStage?.analysis?.steps?.[
            printStageItem?.serialNumber ?? ''
          ]?.upperStep ?? '',
        lower:
          taskItem.patient.currentEvalStage?.analysis?.steps?.[
            printStageItem?.serialNumber ?? ''
          ]?.lowerStep ?? '',
      },
    }
  })(selectedTaskItems)
  return reject((item) => isNil(item.serialNumber), result)
}

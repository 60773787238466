import { Button, DatePicker, Space } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import { RangePickerProps } from 'antd/lib/date-picker'
import moment, { Moment } from 'moment'
import React, { useState } from 'react'
import styled from 'styled-components'

type UnitOfTime = 'week' | 'month' | 'quarter' | 'year'

const Cotainer = styled.div`
  /** 覆寫 ant design 預設樣式 */
  .ant-picker {
    input {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-btn-text:hover,
  .ant-btn-text.active {
    color: #1890ff;
    background: white;
  }
`

type DateInterval = [Moment, Moment]

interface CustomizedRangePickerProps {
  value: DateInterval
  onChange: (dateInterval: DateInterval) => void
}

const CustomizedRangePicker = (props: CustomizedRangePickerProps) => {
  const { value, onChange } = props
  const [unitOfTime, setUnitOfTime] = useState<UnitOfTime>()

  const handleRangePickerChange: RangePickerProps['onChange'] = (dates) => {
    onChange(dates as DateInterval)
  }

  const handleIntervalChange = (unitOfTime: UnitOfTime) => {
    const dateInterval: DateInterval = [
      moment().startOf(unitOfTime),
      moment().endOf(unitOfTime),
    ]
    setUnitOfTime(unitOfTime)
    onChange(dateInterval)
  }

  return (
    <Cotainer>
      <Space size={24}>
        <Space size={16}>
          <ButtonGroup>
            <Button
              className={unitOfTime === 'week' ? 'active' : ''}
              type='text'
              onClick={() => handleIntervalChange('week')}
            >
              本周
            </Button>
            <Button
              className={unitOfTime === 'month' ? 'active' : ''}
              type='text'
              onClick={() => handleIntervalChange('month')}
            >
              本月
            </Button>
            <Button
              className={unitOfTime === 'quarter' ? 'active' : ''}
              type='text'
              onClick={() => handleIntervalChange('quarter')}
            >
              本季
            </Button>
            <Button
              className={unitOfTime === 'year' ? 'active' : ''}
              type='text'
              onClick={() => handleIntervalChange('year')}
            >
              今年
            </Button>
          </ButtonGroup>
        </Space>
        <DatePicker.RangePicker
          value={value}
          onChange={handleRangePickerChange}
        />
      </Space>
    </Cotainer>
  )
}

export default CustomizedRangePicker

import { all, includes, map, values } from 'ramda'
import React, { useContext, useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import { AllPrivilege } from '../../graphql/types'
import Home from '../components/auth/Home'
import Layout from '../components/layout'
import { authContext } from '../context'
import { requiredPrivilegesOfPages } from '../helpers/requiredPrivilegesOfPages'
import ErrorPage from '../pages/error/index'
import { getAuthPrivileges } from '../utils'
import GMPRoutes from './GMPRoutes'
import LabRoutes from './LabRoutes'
import PatientRoutes from './PatientRoutes'
import { routeMap } from './routes'

// 頁面跳轉時會回到最上面
const ScrollToTop = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return null
}

const AppRoutes = () => {
  const auth = useContext(authContext)

  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route
          exact
          path='/'
          render={() => (
            <Layout>
              <Home />
            </Layout>
          )}
        />
        <Route path='/patients'>
          <PatientRoutes />
        </Route>
        <Route path='/gmp'>
          <GMPRoutes />
        </Route>
        <Route path='/lab'>
          <LabRoutes />
        </Route>
        {map((props) => {
          const { path, component: Component, isLayoutVisible = true } = props
          const userPrivileges = getAuthPrivileges(auth)
          const requiredPrivileges: AllPrivilege[] | undefined =
            requiredPrivilegesOfPages[path]
          const hasRequiredPrivileges = requiredPrivileges
            ? all(
                (requiredPrivilege) =>
                  includes(requiredPrivilege, userPrivileges),
                requiredPrivileges
              )
            : true
          /** 使用者的 privileges 中沒有完全包含 requiredPrivileges，導向到「權限不足」的錯誤頁面 */
          if (!hasRequiredPrivileges) {
            const error = {
              title: '權限不足',
              message: '你的權限不足以瀏覽此頁面',
            }

            const location = {
              pathname: path,
              search: '',
              hash: '',
              state: {
                error,
              },
            }
            return (
              <Route
                exact
                key={path}
                path={path}
                render={() => (
                  <Layout>
                    <ErrorPage location={location} />
                  </Layout>
                )}
              />
            )
          }

          /**
           * @todo: 補上正確的 type
           * 被 Form.create() 包起來的 component，loadable 的 type 會判斷 component 的 props 必須要包含 form
           * 這邊先用 any 去跳過這些 type 檢查，等之後有想到解法再補上正確的 type
           */
          return (
            <Route
              exact
              key={path}
              path={path}
              render={(props: any) => {
                if (!isLayoutVisible) {
                  return <Component {...props} />
                }

                return (
                  <Layout>
                    <Component {...props} />
                  </Layout>
                )
              }}
            />
          )
        }, values(routeMap))}
      </Switch>
    </>
  )
}

export default AppRoutes

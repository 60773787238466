import { EntityType } from '../../../../../graphql/types'
import Clinic from './clinic.png'
import Doctor from './doctor.png'
import Employee from './employee.png'
import Patient from './patient.png'
export default {
  [EntityType.Clinic]: Clinic,
  [EntityType.Doctor]: Doctor,
  [EntityType.Employee]: Employee,
  [EntityType.Patient]: Patient,
}

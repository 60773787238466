import { gql } from '@apollo/client'
import { CheckPoint } from '@sov/ui'
import { Space, Typography } from 'antd'
import { isNil, prop, uniqBy } from 'ramda'
import React from 'react'

import { InstructionTagListFragment } from '../../../graphql/types'

interface InstructionTagListProps {
  instructionCard?: InstructionTagListFragment
}

const InstructionTagList = (props: InstructionTagListProps) => {
  const { instructionCard } = props

  // 無指示卡
  if (isNil(instructionCard)) {
    return (
      <Typography.Text type='secondary'>
        尚未生成指示卡，請新增指示卡並新增此階段臨床指示
      </Typography.Text>
    )
  }

  // 有指示卡，無指示
  if (
    instructionCard.auxiliaries.length === 0 &&
    instructionCard.retrieve === false
  ) {
    return <Typography.Text type='secondary'>此階段無臨床指示</Typography.Text>
  }

  // 有指示卡，有指示
  return (
    <Space>
      {uniqBy(prop('type'), instructionCard.auxiliaries).map(({ type }) => (
        <CheckPoint.InstructionTag key={type} instruction={type} />
      ))}
      {instructionCard.retrieve && (
        <CheckPoint.InstructionTag instruction='retrieve' />
      )}
    </Space>
  )
}

InstructionTagList.fragments = {
  InstructionTagList: gql`
    fragment InstructionTagList on InstructionCard {
      auxiliaries {
        type
      }
      retrieve
    }
  `,
}

export default InstructionTagList

import { map } from 'ramda'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Layout } from '../components/auth/Layout'
import Login from '../components/auth/Login'
import { authRoutes } from './routes'

const EntranceRoute = () => {
  return (
    <Layout>
      <Switch>
        {map((props) => {
          const { path, component: Component } = props
          return (
            <Route
              exact
              key={path}
              path={path}
              render={(props: any) => <Component {...props} />}
            />
          )
        }, authRoutes)}
        <Route path='/' component={Login} />
      </Switch>
    </Layout>
  )
}

export default EntranceRoute

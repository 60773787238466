import {
  DeleteOutlined,
  DownloadOutlined,
  ZoomInOutlined,
} from '@ant-design/icons'
import { gql, useApolloClient } from '@apollo/client'
import { GqlUpload } from '@sov/ui'
import { Progress } from 'antd'
import Form, { FormInstance } from 'antd/lib/form'
import { UploadFile } from 'antd/lib/upload/interface'
import { map } from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'

import { File, PhotosInput } from '../../../../graphql/types'
import { takeLastFromFileList } from '../../../utils'
import { formItemLayout } from './utils/layouts'
import {
  extraOralPhotos,
  intraOralPhotos,
  xRayPhotos,
} from './utils/patientPhotoMapping'
import PreviewModal from './utils/PreviewModal'

export interface FormPhotoFields {
  photos: {
    [key in keyof PhotosInput]: UploadFile<File>
  }
}
export type FormPhotoInitialValues = Partial<FormPhotoFields>

export const mapItemPhotosToResponses = map<UploadFile<File>, string>(
  (photo) => photo?.[0]?.response.id || null
)

interface PhotoCardProps {
  key: string
  aspect: number
  image?: string
  placeholder?: string
  width: number
}

const PhotoCard = styled.div<PhotoCardProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${(props) => props.width}px;
  height: ${(props) => props.width / props.aspect}px;
  margin: 0 16px 16px 0;
  padding: 8px;
  color: white;
  cursor: pointer;

  .title {
    visibility: hidden;
    font-size: 14px;
    line-height: 1;
    z-index: 1;
  }

  .icon-list {
    visibility: hidden;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    z-index: 1;

    .anticon {
      font-size: 18px;
      color: white;

      &.anticon-delete {
        color: red;
      }
    }
  }

  &:after {
    content: '';
    ${(props) =>
      props.image
        ? `background-image: url(${props.image});`
        : `background-image: url(${props.placeholder});`}
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

const PatientPhotoUploadContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 720px;

  .ant-upload.ant-upload-select {
    display: block;
  }

  ${PhotoCard} {
    &:hover {
      .title,
      .icon-list {
        visibility: initial;
      }

      &:after {
        filter: brightness(0.8);
      }
    }
  }
`

interface PatientPhotoUploadProps {
  form: FormInstance
  photo: {
    key: string
    title: string
    placeholder: string
    aspect: number
  }
  showPreview?: any
  disabled?: boolean
  width: number
}

const PatientPhotoUpload = (props: PatientPhotoUploadProps) => {
  const { photo, form, showPreview, width, ...restProps } = props
  const { setFieldsValue, getFieldValue } = form
  const client = useApolloClient()

  const file = getFieldValue(['photos', photo.key])?.[0]?.response
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  const handleLoading = (val) => {
    setLoading(val)
  }
  const handleProgress = (val) => {
    setProgress(val)
  }
  const handlePrview = (e: any) => {
    showPreview(file?.path)
    e.stopPropagation()
  }
  const handleDownload = (e: any) => {
    window.open(file?.path)
    e.stopPropagation()
  }
  const handleRemove = (e: any) => {
    setFieldsValue({ photos: { [photo.key]: undefined } })
    e.stopPropagation()
  }

  return (
    <GqlUpload.Upload
      {...restProps}
      client={client}
      fileType='image'
      accept='image/*'
      uploadOptions={{ needThumbnail: true, prefix: 'patient' }}
      cropOption={{
        aspect: photo.aspect,
        shape: 'rect',
        modalTitle: photo.title,
      }}
      showUploadList={false}
      handleLoading={handleLoading}
      handleProgress={handleProgress}
    >
      <PhotoCard
        key={photo.key}
        aspect={photo.aspect}
        image={file?.thumbnailPath}
        placeholder={photo.placeholder}
        width={width}
      >
        {loading && <Progress percent={progress} size='small' />}
        {!loading && file && (
          <>
            <div className='title'>{photo.title}</div>
            <div className='icon-list'>
              <ZoomInOutlined onClick={handlePrview} />
              <DownloadOutlined onClick={handleDownload} />
              <DeleteOutlined onClick={handleRemove} />
            </div>
          </>
        )}
      </PhotoCard>
    </GqlUpload.Upload>
  )
}

PatientPhotoUpload.fragment = gql`
  fragment PatientPhotoUpload on Patient {
    photos {
      # 口外照(外部照片)
      # 正面照
      frontFace {
        id
        filename
        path
        thumbnailPath
      }
      # 露齒照
      frontFaceWithTeeth {
        id
        filename
        path
        thumbnailPath
      }
      # 45度側面照
      sideFace45 {
        id
        filename
        path
        thumbnailPath
      }
      sideFace45WithTeeth {
        id
        filename
        path
        thumbnailPath
      }
      # 側面照
      sideFace {
        id
        filename
        path
        thumbnailPath
      }
      sideFaceWithTeeth {
        id
        filename
        path
        thumbnailPath
      }

      # 口內照(口腔內部)
      # 正面觀
      frontInside {
        id
        filename
        path
        thumbnailPath
      }
      # 左面觀
      leftInside {
        id
        filename
        path
        thumbnailPath
      }
      # 右面觀
      rightInside {
        id
        filename
        path
        thumbnailPath
      }
      # 上顎咬合面
      upperJawInside {
        id
        filename
        path
        thumbnailPath
      }
      # 下顎咬合面
      lowerJawInside {
        id
        filename
        path
        thumbnailPath
      }

      # X光照
      # Pano X光片
      pano {
        id
        filename
        path
        thumbnailPath
      }
      # Ceph X光片
      ceph {
        id
        filename
        path
        thumbnailPath
      }
    }
  }
`

interface Props {
  form: FormInstance<FormPhotoFields>
  initialValues?: FormPhotoInitialValues
}

const FormPatientPhoto = (props: Props) => {
  const { form, initialValues } = props

  const [previewModalVisible, setPreviewModalVisible] = useState(false)
  const [previewImageSrc, setPreviewImageSrc] = useState('')

  const showPreview = (imageUrl: string) => {
    if (imageUrl) {
      setPreviewImageSrc(imageUrl)
      setPreviewModalVisible(true)
    }
  }

  return (
    <Form {...formItemLayout} form={form} initialValues={initialValues}>
      <Form.Item label='口外照'>
        <PatientPhotoUploadContainer>
          {extraOralPhotos.map((photo) => (
            <Form.Item
              key={photo.key}
              name={['photos', photo.key]}
              valuePropName='fileList'
              getValueFromEvent={takeLastFromFileList}
            >
              <PatientPhotoUpload
                photo={photo}
                form={form}
                showPreview={showPreview}
                width={180}
              />
            </Form.Item>
          ))}
        </PatientPhotoUploadContainer>
      </Form.Item>

      <Form.Item label='口內照'>
        <PatientPhotoUploadContainer>
          {intraOralPhotos.map((photo) => (
            <Form.Item
              key={photo.key}
              name={['photos', photo.key]}
              valuePropName='fileList'
              getValueFromEvent={takeLastFromFileList}
            >
              <PatientPhotoUpload
                photo={photo}
                form={form}
                showPreview={showPreview}
                width={180}
              />
            </Form.Item>
          ))}
        </PatientPhotoUploadContainer>
      </Form.Item>

      <Form.Item label='Ceph'>
        <PatientPhotoUploadContainer>
          <Form.Item
            key={xRayPhotos[0].key}
            name={['photos', 'ceph']}
            valuePropName='fileList'
            getValueFromEvent={takeLastFromFileList}
          >
            <PatientPhotoUpload
              photo={xRayPhotos[0]}
              form={form}
              showPreview={showPreview}
              width={180}
            />
          </Form.Item>
        </PatientPhotoUploadContainer>
      </Form.Item>

      <Form.Item label='Pano'>
        <PatientPhotoUploadContainer>
          <Form.Item
            key={xRayPhotos[1].key}
            name={['photos', 'pano']}
            valuePropName='fileList'
            getValueFromEvent={takeLastFromFileList}
          >
            <PatientPhotoUpload
              photo={xRayPhotos[1]}
              form={form}
              showPreview={showPreview}
              width={376}
            />
          </Form.Item>
        </PatientPhotoUploadContainer>
      </Form.Item>

      <PreviewModal
        visible={previewModalVisible}
        src={previewImageSrc}
        onCancel={() => setPreviewModalVisible(false)}
        footer={null}
        maskClosable
        centered
      />
    </Form>
  )
}

FormPatientPhoto.fragments = {
  FormPatientPhoto: gql`
    fragment FormPatientPhoto on Patient {
      id
      ...PatientPhotoUpload
    }
    ${PatientPhotoUpload.fragment}
  `,
}

export { FormPatientPhoto }

export default FormPatientPhoto

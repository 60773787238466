import { gql } from '@apollo/client'
import { AntSorterType, TableQuery, getEmptyText } from '@sov/common'
import { Link, TablePatientInfo } from '@sov/ui'
import { Table } from 'antd'
import { ColumnProps, TableProps } from 'antd/lib/table'
import moment from 'moment'
import { last, map, sort } from 'ramda'
import React from 'react'

import {
  MoldStagesTrackQueryDocs,
  MoldStagesTrackQueryQuery,
} from '../../../graphql/types'
import {
  MoldStageFilterType,
  MoldStageSortFields,
} from '../../pages/stage/MoldStageList'
import EmployeeLink from '../link/employee'

interface Props {
  source?: MoldStagesTrackQueryQuery['moldStagesTrack']
  handleTableChange: TableProps<MoldStagesTrackQueryDocs>['onChange']
  loading?: boolean
  sortInfo?: AntSorterType<MoldStageSortFields>
  filterInfo?: MoldStageFilterType
}

const defaultSource = {
  docs: [],
  page: 1,
  total: 0,
  limit: 10,
}

const TableMoldStage = ({
  source = defaultSource,
  loading,
  handleTableChange,
  filterInfo = {},
  sortInfo,
}: Props) => {
  const columns: ColumnProps<MoldStagesTrackQueryDocs>[] = [
    {
      title: '病患',
      width: 120,
      align: 'center',
      dataIndex: 'patient',
      key: 'patient',
      render: (_value, record) => {
        return <TablePatientInfo patient={record.patient} />
      },
    },
    {
      title: 'AM',
      width: 60,
      align: 'center',
      dataIndex: 'accountManager',
      key: 'accountManager',
      render: (_value, record) => {
        const accountManager = record.patient.accountManager
        return <EmployeeLink item={accountManager} />
      },
    },
    {
      title: '業務',
      width: 60,
      align: 'center',
      dataIndex: 'sales',
      key: 'sales',
      render: (_value, record) => {
        const sales = record.patient.sales
        return <EmployeeLink item={sales} />
      },
    },
    {
      title: '建模',
      align: 'center',
      width: 60,
      dataIndex: 'moldStage',
      key: 'moldStage',
      render: (text, record) => <Link.StageLink item={record} />,
    },

    {
      title: '收模日',
      align: 'center',
      width: 60,
      dataIndex: 'created',
      key: 'created',
      sortOrder: sortInfo?.field === 'created' ? sortInfo.order : undefined,
      sorter: (a, b) => moment(a.created).unix() - moment(b.created).unix(),
      render: (text) => {
        return <div>{moment(text).format('YYYY-MM-DD')}</div>
      },
    },

    {
      title: '報告',
      align: 'center',
      width: 60,
      dataIndex: 'stage',
      key: 'hasEvalStage',
      filters: [
        {
          text: '無報告',
          value: 'false',
        },
        {
          text: '有報告',
          value: 'true',
        },
      ],
      filterMultiple: false,
      filteredValue:
        typeof filterInfo.hasEvalStage === 'boolean'
          ? [`${filterInfo.hasEvalStage}`]
          : [],
      render: (text, record) => {
        return map(
          (evalStage) => <Link.StageLink item={evalStage} />,
          record.evalStages || []
        )
      },
    },
    {
      title: '報告預計出貨',
      align: 'center',
      width: 60,
      dataIndex: 'expectedShippingDate',
      key: 'expectedShippingDate',
      render: (text, record) => {
        const latestEvalStage = last(
          sort(
            (a, b) => moment(a.created).unix() - moment(b.created).unix(),
            record.evalStages || []
          )
        )
        return (
          <div>
            {latestEvalStage
              ? moment(latestEvalStage.expectedShippingDate).format(
                  'YYYY-MM-DD'
                )
              : getEmptyText()}
          </div>
        )
      },
    },
    {
      title: '備註',
      align: 'center',
      width: 120,
      dataIndex: 'note',
      key: 'note',
      render: (text, record) => <div>{record.note}</div>,
    },
  ]

  const { docs, page, total, limit } = source

  return (
    <Table<MoldStagesTrackQueryDocs>
      rowKey='id'
      size='small'
      columns={columns}
      dataSource={docs}
      loading={loading}
      onChange={handleTableChange}
      pagination={TableQuery.getAntdPagination({
        page: page || 1,
        total,
        limit,
      })}
    />
  )
}

TableMoldStage.fragment = gql`
  fragment TableMoldStage on MoldStage {
    ...StageLink
    note
    created
    patient {
      ...TablePatientInfo
      accountManager {
        id
        name
      }
      sales {
        id
        name
      }
    }
    evalStages {
      expectedShippingDate
      created
      ...StageLink
    }
  }
  ${TablePatientInfo.fragment}
  ${Link.StageLink.fragment}
`

export default TableMoldStage

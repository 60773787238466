import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form'
import { useMutation } from '@apollo/client'
import { Button, Spin, message } from 'antd'
import { GraphQLError } from 'graphql'
import { includes } from 'ramda'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { createProductMutation } from '../../../graphql/product/mutation/create'
import { productsQuery } from '../../../graphql/product/query/list'
import {
  AllPrivilege,
  CreateProductInput,
  CreateProductMutation,
  CreateProductVariables,
} from '../../../graphql/types'
import { useLoadingLayer } from '../../helpers/hooks'
import { OnceButton } from '../common/button'
import FormProduct from '../form/product'
import BreadcrumbCreator from '../layout/BreadcrumbCreator'
import Page, { Section } from '../layout/Page'
import Title from '../layout/Title'

const FormItem = Form.Item

type Props = FormComponentProps<CreateProductInput>

const ProductCreate = (props: Props) => {
  const { form } = props
  const history = useHistory()
  const { loading, tip, setLoadingLayer } = useLoadingLayer()
  const [create] = useMutation<CreateProductMutation, CreateProductVariables>(
    createProductMutation
  )

  const handleSubmit = () => {
    const { validateFieldsAndScroll } = form
    validateFieldsAndScroll(async (err, formValues) => {
      if (err) {
        console.log(err, formValues)
        return
      }

      try {
        setLoadingLayer({ loading: true, tip: '新增中...' })
        await create({
          variables: {
            payload: formValues,
          },
          refetchQueries: [
            {
              query: productsQuery,
              variables: {
                query: {},
                page: 1,
                limit: 100,
                sort: 'serialNumber',
              },
            },
          ],
          update: async (cache, { data }) => {
            setLoadingLayer({ loading: false, tip: '' })
            if (data) {
              message.info('已新增出貨品項')
              history.push('/products')
            }
          },
        })
      } catch (error) {
        const e = error as GraphQLError
        setLoadingLayer({ loading: false, tip: '' })
        const isDuplicatedProductSerailNumberError = includes(
          '已有相同編號的商品',
          e.message
        )
        if (isDuplicatedProductSerailNumberError) {
          message.error('品項編號重複，請填寫其他數字')
        } else {
          message.error(`新增出貨品項失敗: ${e.message}`)
        }
      }
    })
  }

  return (
    <Page
      header={
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'ProductCreate' }]}
          />
          <Title route={{ key: 'ProductCreate' }} />
        </>
      }
      loading={loading}
      loadingComponent={<Spin size='large' tip={tip} />}
    >
      <Section>
        <Form>
          <FormProduct form={form} />
          <FormItem
            wrapperCol={{ span: 16, offset: 6 }}
            style={{ marginTop: 24 }}
          >
            <OnceButton
              label='新增資料'
              onClick={handleSubmit}
              requiredPrivilege={AllPrivilege.ProductCreate}
              type='primary'
            />
            <Button style={{ marginLeft: 24 }}>
              <Link to='/products'>回出貨品項清單</Link>
            </Button>
          </FormItem>
        </Form>
      </Section>
    </Page>
  )
}

export { ProductCreate }

export default Form.create<Props>()(ProductCreate)

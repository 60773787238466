import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const FormTitle = styled.div`
  font-size: 28px;
`

const Description = styled.div`
  margin-top: 24px;
  margin-bottom: 48px;
  font-size: 16px;
`

const ResetPasswordEmailSent = () => (
  <>
    <FormTitle>密碼重設信已寄出</FormTitle>
    <Description>
      <div>更改密碼已寄到您的信箱</div>
      <div>請至信箱收信件</div>
    </Description>
    <Link to='/login'>{'<返回'}</Link>
  </>
)

export default ResetPasswordEmailSent

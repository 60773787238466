import { Form } from '@ant-design/compatible'
import {
  FormComponentProps,
  FormItemProps,
} from '@ant-design/compatible/lib/form'
import { gql, useApolloClient } from '@apollo/client'
import { GqlUpload } from '@sov/ui'
import { Typography } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import React from 'react'

import {
  FileInfoFragment,
  FinalCheckPointFragment,
  FormFileFragment,
  FormRpFilesFragment,
} from '../../../../graphql/types'
import { takeLastFromFileList } from '../../../utils'
import FormViewerFiles from './FormViewerFiles'

// components
const FormItem = Form.Item

const formItemLayout: FormItemProps = {
  labelCol: { span: 3, offset: 3 },
  wrapperCol: { span: 18 },
  labelAlign: 'left',
}

export interface StageUpdateFileFields {
  files: {
    upperRP: UploadFile<FileInfoFragment>[]
    lowerRP: UploadFile<FileInfoFragment>[]
  }
}

interface FormRPFilesProps extends FormComponentProps {
  files?: FormRpFilesFragment
  isReadOnly?: boolean
}

const FormRPFiles = (props: FormRPFilesProps) => {
  const {
    form: { getFieldDecorator },
    files,
    isReadOnly,
  } = props
  const client = useApolloClient()

  return (
    <>
      <FormItem {...formItemLayout} label='上顎 RP 模型'>
        {getFieldDecorator('files.upperRP', {
          valuePropName: 'fileList',
          initialValue: GqlUpload.initialValueTransformer(files?.upperRP),
          getValueFromEvent: takeLastFromFileList,
        })(
          client ? (
            <GqlUpload.Upload
              client={client}
              fileType='model'
              uploadOptions={{ prefix: 'stage', needThumbnail: false }}
              accept='.stl'
              disabled={isReadOnly}
            />
          ) : null
        )}
      </FormItem>
      <FormItem {...formItemLayout} label='下顎 RP 模型'>
        {getFieldDecorator('files.lowerRP', {
          valuePropName: 'fileList',
          initialValue: GqlUpload.initialValueTransformer(files?.lowerRP),
          getValueFromEvent: takeLastFromFileList,
        })(
          client ? (
            <GqlUpload.Upload
              client={client}
              fileType='model'
              uploadOptions={{ prefix: 'stage', needThumbnail: false }}
              accept='.stl'
              disabled={isReadOnly}
            />
          ) : null
        )}
      </FormItem>
    </>
  )
}

FormRPFiles.fragment = gql`
  fragment FormRPFiles on DesignStageFiles {
    upperRP {
      ...fileInfo
    }
    lowerRP {
      ...fileInfo
    }
  }
  ${GqlUpload.Upload.fragment}
`

interface FormFileProps extends FormComponentProps {
  item: FormFileFragment
}
/**
 * - Mold Stage
 * 顯示 root stage (mold) 資料
 * - Eval Stage
 * 顯示 root stage (eval) 及關聯的 moldStage 資料
 * - Design Stage
 * 顯示 root stage (design) 及關聯的 evalStage 及再關聯的 moldStage 資料
 * - Print Stage
 * 顯示關聯的 designStage 及再關聯的 evalStage 及再關聯的 moldStage 資料
 *
 * @todo 待 stage 用 interface type 拆分後，這邊的 query 及 component 可拆分
 */
const FormFile = (props: FormFileProps) => {
  const { form, item } = props

  const getViewerUrl = (stageId: string) =>
    `/viewer?patientId=${item.patient.id}&stageId=${stageId}`
  const getFinalFiles = (evalStage?: FinalCheckPointFragment) => {
    if (!evalStage) return undefined
    const final = evalStage.checkPoints.find((checkPoint) => checkPoint.isFinal)
    return final?.files
  }

  switch (item.__typename) {
    case 'MoldStage':
      return (
        <>
          <FormItem>建模工單</FormItem>
          <FormViewerFiles
            patientId={item.patient.id}
            stage={item}
            files={item.files}
            viewerUrl={getViewerUrl(item.id)}
          />
        </>
      )
    case 'EvalStage':
      return (
        <>
          <FormItem>建模工單</FormItem>
          {item.moldStage ? (
            <FormViewerFiles
              patientId={item.patient.id}
              stage={item.moldStage}
              files={item.moldStage.files}
              viewerUrl={getViewerUrl(item.moldStage.id)}
              isReadOnly
            />
          ) : (
            <FormItem>無對應建模</FormItem>
          )}
          <Typography.Text type='secondary'>報告工單</Typography.Text>
          <FormViewerFiles
            patientId={item.patient.id}
            stage={item}
            files={getFinalFiles(item)}
            viewerUrl={getViewerUrl(item.id)}
            isReadOnly
          />
        </>
      )
    case 'DesignStage':
      return (
        <>
          <Typography.Text type='secondary'>建模工單</Typography.Text>
          {item.evalStage?.moldStage ? (
            <FormViewerFiles
              patientId={item.patient.id}
              stage={item.evalStage.moldStage}
              files={item.evalStage.moldStage.files}
              viewerUrl={getViewerUrl(item.evalStage.moldStage.id)}
              isReadOnly
            />
          ) : (
            <FormItem>無對應建模</FormItem>
          )}
          <Typography.Text type='secondary'>報告工單</Typography.Text>
          {item.evalStage ? (
            <FormViewerFiles
              patientId={item.patient.id}
              stage={item.evalStage}
              files={getFinalFiles(item.evalStage)}
              viewerUrl={getViewerUrl(item.evalStage.id)}
              isReadOnly
            />
          ) : (
            <FormItem>無對應報告</FormItem>
          )}
          <Typography.Text type='secondary'>設計工單</Typography.Text>
          <FormRPFiles form={form} files={item.files} />
          <FormViewerFiles
            patientId={item.patient.id}
            stage={item}
            files={item.files}
            viewerUrl={getViewerUrl(item.id)}
          />
        </>
      )
    case 'PrintStage':
      return (
        <>
          <Typography.Text type='secondary'>建模工單</Typography.Text>
          {item.designStage?.evalStage?.moldStage ? (
            <FormViewerFiles
              patientId={item.patient.id}
              stage={item.designStage.evalStage.moldStage}
              files={item.designStage.evalStage.moldStage.files}
              viewerUrl={getViewerUrl(item.designStage.evalStage.moldStage.id)}
              isReadOnly
            />
          ) : (
            <FormItem>無對應建模</FormItem>
          )}
          <Typography.Text type='secondary'>報告工單</Typography.Text>
          {item.designStage?.evalStage ? (
            <FormViewerFiles
              patientId={item.patient.id}
              stage={item.designStage.evalStage}
              files={getFinalFiles(item.designStage.evalStage)}
              viewerUrl={getViewerUrl(item.designStage.evalStage.id)}
              isReadOnly
            />
          ) : (
            <FormItem>無對應報告</FormItem>
          )}
          <Typography.Text type='secondary'>設計工單</Typography.Text>
          {item.designStage ? (
            <>
              <FormRPFiles
                form={form}
                files={item.designStage.files}
                isReadOnly
              />
              <FormViewerFiles
                patientId={item.patient.id}
                stage={item.designStage}
                files={item.designStage.files}
                viewerUrl={getViewerUrl(item.designStage.id)}
                isReadOnly
              />
            </>
          ) : (
            <FormItem>無對應設計</FormItem>
          )}
        </>
      )
    case 'AccessoryStage':
    default:
      return null
  }
}

FormFile.fragment = gql`
  fragment FormFile on Stage {
    __typename
    patient {
      id
    }
    ... on MoldStage {
      ...ViewerStageInfo
      files {
        ...MoldStageViewerFiles
      }
    }

    ... on EvalStage {
      ...ViewerStageInfo
      ...FinalCheckPoint
      moldStage {
        ...ViewerStageInfo
        files {
          ...MoldStageViewerFiles
        }
      }
    }

    ... on DesignStage {
      ...ViewerStageInfo
      files {
        ...DesignStageViewerFiles
        ...FormRPFiles
      }
      evalStage {
        ...ViewerStageInfo
        ...FinalCheckPoint
        moldStage {
          ...ViewerStageInfo
          files {
            ...MoldStageViewerFiles
          }
        }
      }
    }

    ... on PrintStage {
      ...ViewerStageInfo
      designStage {
        ...ViewerStageInfo
        files {
          ...DesignStageViewerFiles
          ...FormRPFiles
        }
        evalStage {
          ...ViewerStageInfo
          ...FinalCheckPoint
          moldStage {
            ...ViewerStageInfo
            files {
              ...MoldStageViewerFiles
            }
          }
        }
      }
    }
  }
  fragment FinalCheckPoint on EvalStage {
    checkPoints {
      isFinal
      files {
        ...EvalStageViewerFiles
      }
    }
  }
  ${FormRPFiles.fragment}
  ${FormViewerFiles.fragment}
`

export default FormFile

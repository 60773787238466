import { gql } from '@apollo/client'
import { Spin } from 'antd'
import { filter, map, prop } from 'ramda'
import React from 'react'

import { StageType } from '../../../../graphql/types'
import BindingDisplayCell from './BindingDisplayCell'
import BindingStageSelect from './BindingStageSelect'
import { TableCellProps } from './TableCells'
import { EditContextType } from './TableRow'

const BindingCell = (props: TableCellProps & EditContextType) => {
  const { form, record, editing, handleToggle } = props
  const orderStageTypes = map(prop('stageType'), record.stageOrder)

  const loading = record.isBindingStageListLoading ?? true
  const stages = record.bindingStageList
  /** 該病患可綁定工單的 type 有出現在訂單的 stageOrder 中 */
  const availableBindStageCount = stages?.filter((stage) =>
    record.stageOrder.map((stage) => stage.stageType).includes(stage.type)
  )?.length

  const getSpecificTypeStages = (stageType: StageType) =>
    stages ? filter((stage) => stage.type === stageType, stages) : []

  if (loading) {
    return <Spin />
  } else if (editing) {
    return (
      <div>
        {map(
          (stageType) => (
            <BindingStageSelect
              form={form}
              order={record}
              stageType={stageType}
              stages={getSpecificTypeStages(stageType)}
              loading={loading}
            />
          ),
          orderStageTypes
        )}
      </div>
    )
  } else if (availableBindStageCount && availableBindStageCount > 0) {
    return (
      <div className='editable-cell' onClick={handleToggle}>
        <BindingDisplayCell order={record} />
      </div>
    )
  } else {
    return <span>X</span>
  }
}

BindingCell.fragments = {
  BindingCell: gql`
    fragment BindingCell on Stage {
      ...BindingStageSelect
      ...BindingDisplayCell
    }
    ${BindingStageSelect.fragments.BindingStageSelect}
    ${BindingDisplayCell.fragments.BindingDisplayCell}
  `,
}

export default BindingCell

import { gql } from '@apollo/client'
import { DisplayPatientBrand } from '@sov/ui'
import { Checkbox, Divider, Form, InputNumber, Radio } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { values } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Patient,
  PatientBrand,
  PatientPayment,
  PatientPaymentType,
  PatientSource,
} from '../../../../graphql/types'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
}

export type PaymentFormFields = Pick<Patient, 'treatArches'> & {
  payment: Pick<
    PatientPayment,
    | 'brand'
    | 'source'
    | 'type'
    | 'patientPrice'
    | 'base'
    | 'referral'
    | 'extension'
    | 'discount'
  >
}
export type PaymentFormInitialValues = Partial<PaymentFormFields>

interface Props {
  form: FormInstance<PaymentFormFields>
  initialValues?: PaymentFormInitialValues
}

const FormPatientPayment = (props: Props) => {
  const { form, initialValues } = props
  const { getFieldsValue, setFieldsValue } = form
  const { t } = useTranslation()

  return (
    <Form {...formItemLayout} form={form} initialValues={initialValues}>
      <Form.Item label='治療之牙弓'>
        <Form.Item
          name={['treatArches', 'upper']}
          noStyle
          valuePropName='checked'
        >
          <Checkbox>上顎</Checkbox>
        </Form.Item>
        <Form.Item
          name={['treatArches', 'lower']}
          noStyle
          valuePropName='checked'
        >
          <Checkbox>下顎</Checkbox>
        </Form.Item>
      </Form.Item>

      <Form.Item label='品牌' name={['payment', 'brand']}>
        <Radio.Group>
          {values(PatientBrand).map((brand) => (
            <Radio key={brand} value={brand}>
              <DisplayPatientBrand value={brand} />
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item label='客源' name={['payment', 'source']}>
        <Radio.Group
          onChange={() => {
            const formValues = getFieldsValue()
            const source = formValues.payment?.source ?? PatientSource.Unknown
            const patientPrice = formValues.payment?.patientPrice ?? 0
            const base = formValues.payment?.base ?? 0
            const referral =
              source === PatientSource.Sov && patientPrice * 0.5 >= base
                ? patientPrice / 2 - base
                : 0

            setFieldsValue({
              payment: {
                referral,
              },
            })
          }}
        >
          {values(PatientSource).map((source) => (
            <Radio key={source} value={source}>
              {t(`patient.source.${source}`)}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item label='模式' name={['payment', 'type']}>
        <Radio.Group>
          {values(PatientPaymentType).map((type) => (
            <Radio key={type} value={type}>
              {t(`patient.type.${type}`)}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item label='方案結構'>
        <Form.Item
          label='病患端最終收費'
          name={['payment', 'patientPrice']}
          rules={[{ type: 'number', required: true, min: 0 }]}
        >
          <InputNumber
            min={0}
            onChange={() => {
              const formValues = getFieldsValue()
              const source = formValues.payment?.source ?? PatientSource.Unknown
              const patientPrice = formValues.payment?.patientPrice ?? 0
              const base = formValues.payment?.base ?? 0
              const referral =
                source === PatientSource.Sov && patientPrice * 0.5 >= base
                  ? patientPrice / 2 - base
                  : 0

              setFieldsValue({
                payment: {
                  referral,
                },
              })
            }}
          />
        </Form.Item>
        <Divider style={{ margin: '0 0 24px 0' }} />
        <Form.Item
          label='技工費'
          name={['payment', 'base']}
          rules={[{ type: 'number', required: true, min: 0 }]}
        >
          <InputNumber
            min={0}
            onChange={() => {
              const formValues = getFieldsValue()
              const source = formValues.payment?.source ?? PatientSource.Unknown
              const patientPrice = formValues.payment?.patientPrice ?? 0
              const base = formValues.payment?.base ?? 0
              const referral =
                source === PatientSource.Sov && patientPrice * 0.5 >= base
                  ? patientPrice / 2 - base
                  : 0

              setFieldsValue({
                payment: {
                  referral,
                },
              })
            }}
          />
        </Form.Item>
        <Form.Item
          label='轉介費'
          name={['payment', 'referral']}
          rules={[{ type: 'number', required: true, min: 0 }]}
          tooltip='僅轉介病患適用 (終端費/2 - 技工費)'
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          label='延長費'
          name={['payment', 'extension']}
          rules={[{ type: 'number', required: true, min: 0 }]}
          tooltip='超過技工費保障年限的額外費用'
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          label='折扣費'
          name={['payment', 'discount']}
          rules={[{ type: 'number', required: true, min: 0 }]}
          tooltip='藝人折扣或是其他折扣'
        >
          <InputNumber min={0} />
        </Form.Item>
      </Form.Item>
    </Form>
  )
}

FormPatientPayment.fragments = {
  Payment: gql`
    fragment Payment on Patient {
      id
      payment {
        brand
        source
        type
        patientPrice
        base
        referral
        extension
        discount
      }
    }
  `,
}

export { FormPatientPayment }

export default FormPatientPayment

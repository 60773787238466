import Icon from '@ant-design/icons'
import React from 'react'

const Svg = () => (
  <svg
    width='64'
    height='64'
    viewBox='0 0 64 64'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g opacity='0.3'>
      <circle cx='32' cy='32' r='32' fill='#FAAD14' />
      <circle
        cx='32'
        cy='32'
        r='32'
        fill='url(#paint0_linear)'
        fillOpacity='0.8'
      />
    </g>
    <g opacity='0.5'>
      <circle cx='32' cy='32' r='29.2683' fill='#FAAD14' />
      <circle
        cx='32'
        cy='32'
        r='29.2683'
        fill='url(#paint1_linear)'
        fillOpacity='0.8'
      />
    </g>
    <circle cx='32' cy='32.0001' r='26.1463' fill='#FAAD14' />
    <circle
      cx='32'
      cy='32.0001'
      r='26.1463'
      fill='url(#paint2_linear)'
      fillOpacity='0.8'
    />
    <g filter='url(#filter0_d)'>
      <rect
        x='19.5122'
        y='16.3901'
        width='24.9756'
        height='31.2195'
        rx='2'
        fill='white'
      />
      <rect
        x='19.5122'
        y='16.3901'
        width='24.9756'
        height='31.2195'
        rx='2'
        fill='url(#paint3_linear)'
      />
    </g>
    <defs>
      <filter
        id='filter0_d'
        x='11.5122'
        y='12.3901'
        width='40.9756'
        height='47.2195'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='4' />
        <feGaussianBlur stdDeviation='4' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow'
          result='shape'
        />
      </filter>
      <linearGradient
        id='paint0_linear'
        x1='8.19512'
        y1='64'
        x2='40.7805'
        y2='-1.29525e-07'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.571675' stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0.5' />
      </linearGradient>
      <linearGradient
        id='paint1_linear'
        x1='10.2272'
        y1='61.2683'
        x2='107.134'
        y2='-22.7888'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint2_linear'
        x1='12.5497'
        y1='58.1464'
        x2='99.1196'
        y2='-16.9446'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint3_linear'
        x1='32'
        y1='16.3901'
        x2='32'
        y2='47.6096'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0' />
        <stop offset='1' stopColor='#F0F0F0' />
        <stop offset='1' stopColor='#DADADA' />
      </linearGradient>
    </defs>
  </svg>
)

const OrangeCircleIcon = (props) => <Icon {...props} component={Svg} />

export default OrangeCircleIcon

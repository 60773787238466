import { gql, useQuery } from '@apollo/client'
import { Spin, Timeline } from 'antd'
import moment from 'moment'
import { map } from 'ramda'
import React, { FC } from 'react'
import styled from 'styled-components'

import {
  InvoiceTimeLineQuery,
  InvoiceTimeLineQueryVariables,
} from '../../../../graphql/types'
import InvoiceLink from '../../link/invoice'
import StageInvoiceLink from './StageInvoiceLink'

const invoiceTimeLineQuery = gql`
  query InvoiceTimeLine($id: ID!) {
    patient(id: $id) {
      id
      invoices(sort: "-created") {
        docs {
          id
          __typename
          creditDate
          totalPrice
          ... on InvoiceWithStage {
            stage {
              ...StageInvoiceLink
            }
          }
        }
      }
    }
  }
  ${StageInvoiceLink.fragment}
`

const Wrapper = styled.div`
  min-width: 150px;
  margin-left: 24px;
`

interface Props {
  className?: string
  patientId?: string
}

const InvoiceTimeLine: FC<Props> = (props) => {
  const { className, patientId } = props

  const { data, loading } = useQuery<
    InvoiceTimeLineQuery,
    InvoiceTimeLineQueryVariables
  >(invoiceTimeLineQuery, {
    variables: {
      id: patientId as string,
    },
    skip: !patientId,
  })

  if (loading) {
    return (
      <Wrapper className={className}>
        <h2>歷史入帳紀錄</h2>
        <Spin />
      </Wrapper>
    )
  }

  const invoiceItems = data?.patient?.invoices.docs || []
  const hasInvoices = invoiceItems.length > 0

  if (!hasInvoices) {
    return (
      <Wrapper className={className}>
        <h2>歷史入帳紀錄</h2>
        <div>無出貨紀錄</div>
      </Wrapper>
    )
  }

  return (
    <Wrapper className={className}>
      <h2>歷史入帳紀錄</h2>
      <Timeline>
        {map((invoiceItem) => {
          const date = moment(invoiceItem.creditDate).format('YYYY/MM/DD')
          const totalPrice = invoiceItem.totalPrice
          return (
            <Timeline.Item key={invoiceItem.id}>
              <div>
                {invoiceItem.__typename === 'InvoiceWithStage' ? (
                  <StageInvoiceLink stageItem={invoiceItem.stage} />
                ) : (
                  <InvoiceLink invoiceItem={invoiceItem} />
                )}
              </div>
              <div>{date}</div>
              <div>${totalPrice}</div>
            </Timeline.Item>
          )
        }, invoiceItems)}
      </Timeline>
    </Wrapper>
  )
}

export default InvoiceTimeLine

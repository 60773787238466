import { QuestionCircleOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import {
  DateIntervalParam,
  ErrorHandling,
  FormQuery,
  getDateIntervalQueryString,
  MyStringParam,
  TableQuery,
} from '@sov/common'
import { Button, Card, Col, Form, Input, Row, Space, Tooltip } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { useForm } from 'antd/lib/form/Form'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import {
  EmployeePatientListQueryQuery,
  EmployeePatientListQueryQueryVariables,
} from '../../../../graphql/types'
import RangePicker from '../../../components/common/RangePicker'
import Page from '../../../components/layout/Page'
import EmployeeMenu, {
  EmployeeMenuKey,
} from '../../../components/pageHeader/employee'
import PatientTable, {
  PatientFilterType,
  PatientSorterField,
} from '../../../components/table/Patient'

const employeePatientListQuery = gql`
  query EmployeePatientListQuery(
    $id: ID!
    $query: PatientsQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    employee(id: $id) {
      id
      name
      patients(query: $query, page: $page, limit: $limit, sort: $sort) {
        docs {
          ...PatientTable
        }
        total
        limit
        page
      }
    }
  }
  ${PatientTable.fragment}
`

export interface PatientFormInput {
  name?: string
  clinicName?: string
  doctorName?: string
  accountManagerName?: string
  technicianName?: string
  salesName?: string
  customerServiceName?: string
  patientCode?: string
  cabinetCode?: string
  lastEvalStageAtInterval?: [moment.Moment, moment.Moment]
  lastPrintStageAtInterval?: [moment.Moment, moment.Moment]
}

const formInput = {
  name: MyStringParam,
  clinicName: MyStringParam,
  doctorName: MyStringParam,
  accountManagerName: MyStringParam,
  technicianName: MyStringParam,
  salesName: MyStringParam,
  customerServiceName: MyStringParam,
  patientCode: MyStringParam,
  cabinetCode: MyStringParam,
  lastEvalStageAtInterval: DateIntervalParam,
  lastPrintStageAtInterval: DateIntervalParam,
}

export const SearchButton = ({ onClick }: { onClick: () => void }) => (
  <Button onClick={onClick} type='primary'>
    搜索
  </Button>
)
export const ClearButton = ({ onClick }: { onClick: () => void }) => (
  <Button onClick={onClick}>重置</Button>
)

interface PatientFormProps {
  form: FormInstance<PatientFormInput>
  formQuery: PatientFormInput
  handleSearch: () => void
  handleReset: () => void
}

export const PatientForm = (props: PatientFormProps) => {
  const { form, formQuery, handleSearch, handleReset } = props
  const [advanced, setAdvanced] = useState(false)

  return (
    <Form form={form} initialValues={formQuery}>
      <Row gutter={16}>
        <Col flex='1'>
          <Row gutter={16}>
            <Col>
              <Form.Item label='病患' name='name'>
                <Input style={{ width: 100 }} onPressEnter={handleSearch} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label='診所' name='clinicName'>
                <Input style={{ width: 100 }} onPressEnter={handleSearch} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label='AM' name='accountManagerName'>
                <Input style={{ width: 100 }} onPressEnter={handleSearch} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label='代號' name='patientCode'>
                <Input style={{ width: 100 }} onPressEnter={handleSearch} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label='塔位' name='cabinetCode'>
                <Input style={{ width: 100 }} onPressEnter={handleSearch} />
              </Form.Item>
            </Col>
            {advanced && (
              <Col>
                <Form.Item label='醫師' name='doctorName'>
                  <Input style={{ width: 100 }} onPressEnter={handleSearch} />
                </Form.Item>
              </Col>
            )}
            {advanced && (
              <Col>
                <Form.Item label='技師' name='technicianName'>
                  <Input style={{ width: 100 }} onPressEnter={handleSearch} />
                </Form.Item>
              </Col>
            )}
            {advanced && (
              <Col>
                <Form.Item label='業務' name='salesName'>
                  <Input style={{ width: 100 }} onPressEnter={handleSearch} />
                </Form.Item>
              </Col>
            )}
            {advanced && (
              <Col>
                <Form.Item label='客服' name='customerServiceName'>
                  <Input style={{ width: 100 }} onPressEnter={handleSearch} />
                </Form.Item>
              </Col>
            )}
            {advanced && (
              <Col>
                <Form.Item
                  label={
                    <Tooltip title='搜索上次報告的出貨時間區間'>
                      上次報告 <QuestionCircleOutlined />
                    </Tooltip>
                  }
                  name='lastEvalStageAtInterval'
                >
                  <RangePicker
                    ranges={{
                      '過去 ~ 一個月前': [
                        moment('2017-01-01'),
                        moment().subtract(1, 'month'),
                      ],
                      '過去 ~ 二個月前': [
                        moment('2017-01-01'),
                        moment().subtract(2, 'month'),
                      ],
                      '過去 ~ 三個月前': [
                        moment('2017-01-01'),
                        moment().subtract(3, 'month'),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
            )}
            {advanced && (
              <Col>
                <Form.Item
                  label={
                    <Tooltip title='搜索上次 Step 的出貨時間區間'>
                      上次 Step <QuestionCircleOutlined />
                    </Tooltip>
                  }
                  name='lastPrintStageAtInterval'
                >
                  <RangePicker
                    ranges={{
                      '過去 ~ 一個月前': [
                        moment('2017-01-01'),
                        moment().subtract(1, 'month'),
                      ],
                      '過去 ~ 二個月前': [
                        moment('2017-01-01'),
                        moment().subtract(2, 'month'),
                      ],
                      '過去 ~ 三個月前': [
                        moment('2017-01-01'),
                        moment().subtract(3, 'month'),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Col>
        <Col flex='160px'>
          <Space>
            <SearchButton onClick={handleSearch} />
            <ClearButton onClick={handleReset} />
          </Space>
        </Col>
        <Col flex='60px'>
          <Form.Item>
            <Button type='link' onClick={() => setAdvanced(!advanced)}>
              {advanced ? '收合' : '展開'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

const PatientList = () => {
  const {
    params: { employeeId },
  } = useRouteMatch<{ employeeId: string }>()
  const [form] = useForm<PatientFormInput>()
  const { tableQuery, paginateQuery, handleTableChange, handleTableReset } =
    TableQuery.useTableQuery<PatientFilterType, PatientSorterField>({
      limit: 30,
    })
  const { formQuery, handleFormChange, handleFormReset } =
    FormQuery.useFormQuery(formInput)
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading } = useQuery<
    EmployeePatientListQueryQuery,
    EmployeePatientListQueryQueryVariables
  >(employeePatientListQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: employeeId,
      query: {
        name: formQuery.name,
        clinicName: formQuery.clinicName,
        doctorName: formQuery.doctorName,
        accountManagerName: formQuery.accountManagerName,
        technicianName: formQuery.technicianName,
        salesName: formQuery.salesName,
        customerServiceName: formQuery.customerServiceName,
        patientCode: formQuery.patientCode,
        cabinetCode: formQuery.cabinetCode,
        lastEvalStageAtInterval: getDateIntervalQueryString(
          formQuery.lastEvalStageAtInterval
        ),
        lastPrintStageAtInterval: getDateIntervalQueryString(
          formQuery.lastPrintStageAtInterval
        ),
        ...tableQuery.filters,
      },
      ...paginateQuery,
    },
  })

  const handleSearch = () => {
    const value = form.getFieldsValue()
    handleFormChange(value)
  }

  const handleReset = () => {
    handleFormReset()
    handleTableReset()
  }

  useEffect(() => {
    form.setFieldsValue(formQuery)
  }, [formQuery])

  const employee = data?.employee
  const patients = employee?.patients

  return (
    <Page
      header={
        <EmployeeMenu
          item={employee}
          selectedKeys={[EmployeeMenuKey.EMPLOYEE_PATIENTS]}
          title={employee?.name}
          subtitle='正在追蹤的病患'
        />
      }
      loading={loading}
    >
      <Card>
        <PatientForm
          form={form}
          formQuery={formQuery}
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
        <PatientTable
          source={patients}
          sortInfo={tableQuery.sort}
          filterInfo={tableQuery.filters}
          handleChange={handleTableChange}
          loading={loading}
        />
      </Card>
    </Page>
  )
}

export default PatientList

import { getEmptyText } from '@sov/common'
import React from 'react'
import { Link } from 'react-router-dom'

import { Account } from '../../../../graphql/types'

interface Props {
  item?: Pick<Account, 'id' | 'nickname'>
  style?: { [key: string]: string | number }
  children?: React.ReactNode
}

const AccountLink = ({ item, style = { color: 'black' }, children }: Props) => {
  if (item) {
    const { id, nickname } = item

    return (
      <Link to={`/accounts/${id}`} style={style}>
        {children || nickname}
      </Link>
    )
  } else {
    return <span style={style}>{children || getEmptyText()}</span>
  }
}

export default AccountLink

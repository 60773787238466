import { useApolloClient, useMutation } from '@apollo/client'
import { GqlUpload } from '@sov/ui'
import { Typography, message } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { isNil } from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'

import {
  FileInfoFragment,
  UploadStepsOverViewMutation,
  UploadStepsOverViewMutationVariables,
} from '../../../../graphql/types'
import { takeLastFromFileList } from '../../../utils'
import { uploadStepsOverViewMutation } from '../PatientReportEdit'

const Wrapper = styled.div`
  background-color: white;
  padding: 24px;
  min-height: 100%;
`

interface PanelStepsOverviewProps {
  stageId: string
  stepsOverview?: FileInfoFragment
}

const PanelStepsOverview = (props: PanelStepsOverviewProps) => {
  const { stepsOverview, stageId } = props

  const client = useApolloClient()
  const [fileList, setFileList] = useState(
    GqlUpload.initialValueTransformer(stepsOverview)
  )
  const [uploadStepsOverView] = useMutation<
    UploadStepsOverViewMutation,
    UploadStepsOverViewMutationVariables
  >(uploadStepsOverViewMutation)

  const handleUploadStepsOverview = async (
    params: UploadChangeParam<UploadFile<File>>
  ) => {
    const fileList = takeLastFromFileList(params)
    const file = fileList[0]
    setFileList(fileList)

    /**
     * 允許 mutate 的狀況
     * - 已完成建檔（有 response）
     * - 亦移除檔案（file 為 undefined）
     */
    if (file?.response || isNil(file)) {
      try {
        await uploadStepsOverView({
          variables: {
            id: stageId,
            payload: {
              stepsOverview: file?.response ? file.response.id : null,
            },
          },
          update: async (_cache, { data }) => {
            if (data?.updateEvalStage) {
              message.info('已上傳齒列移動表')
            }
          },
        })
      } catch (error) {
        message.error(error)
      }
    }
  }

  return (
    <Wrapper>
      <Typography.Title level={4}>齒列移動表</Typography.Title>
      <GqlUpload.Upload
        client={client}
        fileList={fileList}
        fileType='any'
        uploadOptions={{ prefix: 'report', needThumbnail: false }}
        onChange={handleUploadStepsOverview}
      />
    </Wrapper>
  )
}

export default PanelStepsOverview

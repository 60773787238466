import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form'
import { MultiLine } from '@sov/ui'
import { Input, Spin } from 'antd'
import { addIndex, defaultTo, isEmpty, map, reject, splitEvery } from 'ramda'
import React from 'react'
import styled from 'styled-components'

import { BagLabelPrintData } from './index'
import wrapperLogo from './sov_wrapper_logo.png'
import wrapperNumberBg from './wrapper_number_logo.png'
import wrapperQRCode from './wrapper_qr_code.png'

interface BagLabelsProps {
  printData: BagLabelPrintData[]
}

interface BagLabelsPreviewModalProps {
  getFieldDecorator: WrappedFormUtils['getFieldDecorator']
  className?: string
  loading: boolean
  printData: BagLabelPrintData[]
}

const { TextArea } = Input

const QRcode = () => (
  <>
    <section className='qr-code'>
      <img src={wrapperQRCode} />
    </section>
    <section className='qr-text'>
      <h4>
        <b>SOV病患追蹤系統</b>
      </h4>
      <h4>請於開始佩戴之前完成資料上傳</h4>
      <h4>SOV將依生理分析結果製作牙套</h4>
    </section>
  </>
)

const BracesNotes = ({ note }) => (
  <>
    <div className='item-notification'>
      <h4>
        <b>使用注意事項</b>
      </h4>
      <ul>
        <li>確保治療效果請每日至少配戴20小時</li>
        <li>用餐前取下避免咀嚼造成產品毀損</li>
        <li>取下時置入收納盒以免當做垃圾處理</li>
        <li>每日以牙刷清潔內面或浸泡牙錠</li>
        <li>嚴禁浸泡熱水以免造成產品變形無效</li>
      </ul>
    </div>
    <section className='item-note'>
      <p>備註</p>
      <section>
        <MultiLine text={note} />
      </section>
    </section>
  </>
)

const BagLabel = ({ obj }: { obj: BagLabelPrintData }) => {
  const packNumberWithQRcode = 3
  const isSpecialContract = obj.isSpecialContract
  const isShowingQRcode =
    obj.groupIndex === packNumberWithQRcode && isSpecialContract

  const stageNumber = obj.groupIndex
  const clinicName = obj.clinicName
  const patientName = obj.patientName
  const stageCode = obj.stageCode
  const note = obj.note
  return (
    <div className='qc-item'>
      <div className='item-header'>
        <div className='header-left'>
          <img className='wrapper-logo' src={wrapperLogo} />
          <div className='wrapper-info'>
            <section>
              <h4>診所名稱：</h4>
              <h4>患者姓名：</h4>
              <h4>Step：</h4>
            </section>
            <section>
              <h4>{clinicName}</h4>
              <h4>{patientName}</h4>
              <h4>{stageCode}</h4>
            </section>
          </div>
        </div>
        <div className='header-right'>
          <img className='wrapper-number-bg' src={wrapperNumberBg} />
          <span className='wrapper-number-text'>{stageNumber}</span>
        </div>
      </div>
      {isShowingQRcode ? <QRcode /> : <BracesNotes note={note} />}
    </div>
  )
}

const BagLabelsPerRow = ({
  dataPerRow,
  rowIndex,
}: {
  dataPerRow: BagLabelPrintData[]
  rowIndex: number
}) => {
  const mapIndex = addIndex<BagLabelPrintData, JSX.Element>(map)
  return (
    <div className='qc-item-container'>
      {mapIndex(
        (obj, itemIndex) => (
          <BagLabel
            obj={obj}
            key={`qc-item-${rowIndex}-${itemIndex}-${obj.taskId}`}
          />
        ),
        dataPerRow
      )}
    </div>
  )
}

const BagLabelsPerPrintedPage = ({
  dataPerPage,
}: {
  dataPerPage: BagLabelPrintData[]
}) => {
  const itemsPerRow = 4
  const splitedData = splitEvery(itemsPerRow, dataPerPage)
  return (
    <div className='transform-container'>
      {splitedData.map((dataPerRow, rowIndex) => (
        <BagLabelsPerRow
          dataPerRow={dataPerRow}
          key={`item-container-${rowIndex}`}
          rowIndex={rowIndex}
        />
      ))}
    </div>
  )
}

const BagLabels = ({ printData }: BagLabelsProps) => {
  const filteredData = reject(isEmpty)(printData)
  const itemsPerPrintedPage = 8
  const splitedData = splitEvery(itemsPerPrintedPage, filteredData)
  return (
    <div className='qc-wrapper-container'>
      {splitedData.map((dataPerPage, pageIndex) => (
        <BagLabelsPerPrintedPage
          dataPerPage={dataPerPage}
          key={`transform-container-${pageIndex}`}
        />
      ))}
    </div>
  )
}

const UnStyledBagLabelsPreviewModal = (props: BagLabelsPreviewModalProps) => {
  const { getFieldDecorator, className, loading, printData } = props
  const mapIndex = addIndex<BagLabelPrintData, JSX.Element>(map)
  return (
    <div className={className}>
      <section>
        <h4 className='patient'>病患</h4>
        <h4 className='stage'>Step</h4>
        <h4>備註</h4>
      </section>
      {loading && (
        <div className='spin'>
          <Spin />
        </div>
      )}
      {mapIndex(
        ({ taskId, patientName, stageCode, groupIndex, note }, index) => (
          <section key={`${index}-${taskId}-${groupIndex}`}>
            <h4 className='patient'>
              {defaultTo('', patientName)}({groupIndex})
            </h4>
            {getFieldDecorator(`${taskId}-stageCode`, {
              initialValue:
                parseInt(stageCode, 10) < 10 ? `0${stageCode}` : stageCode,
            })(<Input className='stage' />)}
            {getFieldDecorator(`${taskId}-note-${groupIndex}`, {
              initialValue: note,
            })(<TextArea rows={1} />)}
          </section>
        ),
        printData
      )}
    </div>
  )
}

const BagLabelsPreviewModal = styled(UnStyledBagLabelsPreviewModal)`
  section {
    display: flex;

    & > * {
      flex: 1;
      margin: 0 6px 6px 0;
    }

    .patient,
    .stage {
      max-width: 80px;
    }
  }

  .spin {
    text-align: center;
  }
`

export { BagLabels, BagLabelsPreviewModal }

import { grey } from '@ant-design/colors'
import { gql } from '@apollo/client'
import StageLink from '@sov/ui/src/components/Link/Stage'
import { Spin } from 'antd'
import { flatten, isEmpty, map, prop } from 'ramda'
import React from 'react'

import { OrderStages } from '../../../../graphql/types'
import { IOrder } from '../Order'

interface BindingDisplayCellProps {
  order: IOrder
  loading?: boolean
}

const BindingDisplayCell = (props: BindingDisplayCellProps) => {
  const { order, loading = false } = props
  const stageList = flatten<OrderStages>(
    map(prop('stages'), order.stageProvide)
  )

  if (loading) return <Spin />

  return isEmpty(stageList) ? (
    <div style={{ color: grey[0] }}>請綁定工單</div>
  ) : (
    <>
      {map(
        (stage) => (
          <div>
            <StageLink key={stage.id} target='_blank' item={stage} />
          </div>
        ),
        stageList
      )}
    </>
  )
}

BindingDisplayCell.fragments = {
  BindingDisplayCell: gql`
    fragment BindingDisplayCell on Stage {
      ...StageLink
    }
    ${StageLink.fragment}
  `,
}

export default BindingDisplayCell

import { gql, useQuery } from '@apollo/client'
import { ErrorHandling, TableQuery } from '@sov/common'
import { Card } from 'antd'
import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import {
  PatientAppointmentTableFragment,
  PatientAppointmentsQueryQuery,
  PatientAppointmentsQueryQueryVariables,
} from '../../../graphql/types'
import Page from '../../components/layout/Page'
import AppointmentModal from '../../components/modal/AppointmentModal'
import AppointmentsTable from '../../components/table/patient/AppointmentsTable'
import {
  PhotoCategory,
  PhotoMapType,
} from '../../components/table/Track/PhotoThumbnailList'

const patientAppointmentsQuery = gql`
  query PatientAppointmentsQuery(
    $id: ID!
    $query: AppointmentsQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    patient(id: $id) {
      id
      appointments(query: $query, page: $page, limit: $limit, sort: $sort) {
        docs {
          ...PatientAppointmentTable
        }
        limit
        total
        page
      }
    }
  }
  ${AppointmentsTable.fragment}
`

/* ----- types and component ----- */
export type PatientTrackSorterField = 'startDate'

export interface TrackLightboxState {
  visible: boolean
  photoCategory: PhotoCategory
  photoMap: PhotoMapType
  initialIndex?: number
}

export type HandleOpenLightboxParams = Omit<TrackLightboxState, 'visible'>

interface RouterProps {
  patientId: string
}

// 病患事件列表
const PatientTracksList = () => {
  const match = useRouteMatch<RouterProps>()
  const patientId = match.params.patientId

  const [selectedAppointment, setSelectedAppointment] =
    useState<PatientAppointmentTableFragment>()
  const [modalVisible, setModalVisible] = useState<boolean>(false)

  const { tableQuery, paginateQuery, handleTableChange } =
    TableQuery.useTableQuery<any, PatientTrackSorterField>({
      limit: 30,
      sort: '-startDate',
    })
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading, refetch } = useQuery<
    PatientAppointmentsQueryQuery,
    PatientAppointmentsQueryQueryVariables
  >(patientAppointmentsQuery, {
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: patientId,
      query: {},
      ...paginateQuery,
    },
  })
  const reset = () => {
    setSelectedAppointment(undefined)
    setModalVisible(false)
  }
  const appointments = data?.patient?.appointments?.docs || []
  const antdPagination = {
    page: data?.patient?.appointments?.page || 1,
    total: data?.patient?.appointments?.total || 0,
    limit: data?.patient?.appointments?.limit || 0,
  }

  if (loading) return <Page loading />

  return (
    <Card>
      <AppointmentsTable
        loading={loading}
        data={appointments}
        antdPagination={antdPagination}
        sortInfo={tableQuery.sort}
        handleTableChange={handleTableChange}
        handleSelect={(appointment) => {
          setSelectedAppointment(appointment)
          setModalVisible(true)
        }}
      />
      <AppointmentModal
        item={selectedAppointment}
        visible={modalVisible}
        reset={reset}
        refetch={refetch}
      />
    </Card>
  )
}

export default PatientTracksList

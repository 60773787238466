import { useMutation, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { GqlUpload } from '@sov/ui'
import { Button, Col, Form, Radio, Row, Space, Spin, message } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'
import { all, has, lensPath, map, values } from 'ramda'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'

import { removeFeedbackMutation } from '../../../graphql/feedback/mutation/remove'
import { updateFeedbackMutation } from '../../../graphql/feedback/mutation/update'
import { feedbackQuery } from '../../../graphql/feedback/query/item'
import {
  AllPrivilege,
  FeedbackQueryQuery,
  FeedbackQueryVariables,
  FeedbackStatus,
  RemoveFeedbackMutation,
  RemoveFeedbackVariables,
  Role,
  UpdateFeedbackInput,
  UpdateFeedbackMutation,
  UpdateFeedbackVariables,
} from '../../../graphql/types'
import { authContext } from '../../context'
import { useLoadingLayer } from '../../helpers/hooks'
import { isRole } from '../../utils'
import { ConfirmButton, OnceButton } from '../common/button'
import FormFeedback from '../form/feedback'
import Page, { Section } from '../layout/Page'
import FeedbackMenu from '../pageHeader/feedback'

const BackLink = () => <Link to='/feedbacks'>回饋清單</Link>

interface RouteProps {
  feedbackId: string
}

const Feedback = () => {
  const [submitDisabled, setSubmitDisabled] = useState(false)

  const { t } = useTranslation()
  const auth = useContext(authContext)
  const history = useHistory()
  const match = useRouteMatch<RouteProps>()
  const feedbackId = match.params.feedbackId

  const { loading: layerLoading, tip, setLoadingLayer } = useLoadingLayer()
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const [form] = Form.useForm()

  const [update] = useMutation<UpdateFeedbackMutation, UpdateFeedbackVariables>(
    updateFeedbackMutation
  )
  const [remove] = useMutation<RemoveFeedbackMutation, RemoveFeedbackVariables>(
    removeFeedbackMutation
  )

  const { data, loading } = useQuery<
    FeedbackQueryQuery,
    FeedbackQueryVariables
  >(feedbackQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage({
        message: error.message,
        redirect: {
          name: '回饋總覽',
          url: '/feedbacks',
        },
      })
    },
    variables: {
      id: feedbackId,
    },
  })

  if (loading) {
    return <Page loading />
  }

  if (!data?.feedback) {
    toErrorPage({
      message: '不存在的回饋',
      redirect: {
        name: '回饋總覽',
        url: '/feedbacks',
      },
    })
    return null
  }

  const feedback = data.feedback
  const initialValues = {
    ...feedback,
    entity: feedback.entity.id,
    screenshotList: GqlUpload.initialValueTransformer(feedback.screenshotList),
  }

  const handleUploadScreenshots = (info: UploadChangeParam) => {
    if (all(has('response'), info.fileList)) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }

  const handleSubmit = async () => {
    setLoadingLayer({ loading: true, tip: '更新中...' })

    const formValues = form.getFieldsValue()
    const payload = GqlUpload.filesFieldTransformer<UpdateFeedbackInput>(
      lensPath(['screenshotList']),
      formValues
    )

    try {
      await update({
        variables: {
          id: feedbackId,
          payload,
        },
        update: (cache, { data }) => {
          if (data?.updateFeedback) {
            message.info('已更新回饋')
          }
        },
      })
    } catch (error) {
      message.error(error.message)
    }

    setLoadingLayer({ loading: false, tip: '' })
  }

  const handleRemove = async () => {
    setLoadingLayer({ loading: true, tip: '刪除中...' })

    try {
      await remove({
        variables: {
          id: feedbackId,
        },
        update: (cache, { data }) => {
          if (data) {
            message.info('已刪除回饋')
            history.push('/feedbacks')
          }
        },
      })
    } catch (error) {
      message.error(error.message)
    }
  }

  return (
    <Page
      header={<FeedbackMenu item={feedbackId} />}
      loading={layerLoading}
      loadingComponent={<Spin size='large' tip={tip} />}
    >
      <Section>
        <FormFeedback
          form={form}
          initialValues={initialValues}
          entityName={feedback.entity.name}
          onUploadScreenShots={handleUploadScreenshots}
        />
        <Form form={form}>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 12 }}
            name='status'
            label='處理狀態'
            initialValue={feedback.status}
          >
            <Radio.Group disabled={!isRole(Role.God, auth)}>
              {map(
                (value) => (
                  <Radio key={value} value={value}>
                    {t(`feedback.status.${value}`)}
                  </Radio>
                ),
                values(FeedbackStatus)
              )}
            </Radio.Group>
          </Form.Item>
        </Form>
        <Col offset='6'>
          <Row justify='space-between'>
            <Space size='large'>
              <OnceButton
                label='更新資料'
                disabled={submitDisabled}
                requiredPrivilege={AllPrivilege.FeedbackUpdate}
                type='primary'
                onClick={handleSubmit}
              />
              <Button>
                <BackLink />
              </Button>
            </Space>
            <ConfirmButton
              label='刪除'
              type='primary'
              modalProps={{
                onOk: handleRemove,
              }}
              requiredInputText='刪除回饋'
              requiredPrivilege={AllPrivilege.FeedbackDelete}
              danger
            />
          </Row>
        </Col>
      </Section>
    </Page>
  )
}

export default Feedback

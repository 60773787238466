import { LinkOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { Enums, getStageName } from '@sov/common'
import ClinicLink from '@sov/ui/src/components/Link/Clinic'
import PatientLink from '@sov/ui/src/components/Link/Patient'
import StageLink from '@sov/ui/src/components/Link/Stage'
import {
  Badge,
  BadgeProps,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  List,
  Row,
  Space,
} from 'antd'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { sort, uniq } from 'ramda'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  DeliveryMethod,
  StageCalendarQueryQuery,
  StageCalendarQueryQueryVariables,
  StageStatus,
  StageType,
  TaskType,
} from '../../../../graphql/types'
import BreadcrumbCreator from '../../../components/layout/BreadcrumbCreator'
import Page from '../../../components/layout/Page'
import Title from '../../../components/layout/Title'

// @ts-ignore
const moment = extendMoment(Moment)

const query = gql`
  query StageCalendarQuery($query: StagesQuery, $limit: Int = 1000) {
    stages(query: $query, limit: $limit) {
      docs {
        id
        expectedShippingDate
        shippingDate
        status
        ...StageLink
        patient {
          ...PatientLink
          clinic {
            ...ClinicLink
            deliveryMethod
          }
          technician {
            id
            name
          }
        }
        currentTask {
          type
        }
      }
    }
  }
  ${PatientLink.fragment}
  ${StageLink.fragment}
  ${ClinicLink.fragment}
`

interface StageListProps {
  date: Moment.Moment
  type: StageType[]
  taskType: TaskType[]
  status: StageStatus[]
  currentDate: any
  setCurrentDate: any
}

const StageList = (props: StageListProps) => {
  const { t } = useTranslation()
  const { date, type, taskType, status, currentDate, setCurrentDate } = props
  const expectedShippingDateInterval = [
    moment(date).startOf('day'),
    moment(date).endOf('day'),
  ]
  const { data, loading } = useQuery<
    StageCalendarQueryQuery,
    StageCalendarQueryQueryVariables
  >(query, {
    variables: {
      query: {
        expectedShippingDateInterval,
        type,
        status,
      },
    },
  })

  const stages =
    data?.stages.docs.filter((x) =>
      taskType.includes(x.currentTask?.type as TaskType)
    ) ?? []
  const stagesSorted = sort((a, b) => {
    const statusToNumber = (status: StageStatus) => {
      if (status === StageStatus.Started) {
        return 1
      } else {
        return 0
      }
    }

    return statusToNumber(b.status) > statusToNumber(a.status) ? 1 : -1
  }, stages)
  const evalCount = stagesSorted.filter((x) => x.type === StageType.Eval).length
  const printCount = stagesSorted.filter(
    (x) => x.type === StageType.Print
  ).length
  const accessoryCount = stagesSorted.filter(
    (x) => x.type === StageType.Accessory
  ).length

  return (
    <List
      style={{
        borderLeft: '1px solid #f0f0f0',
        borderRight: '1px solid #f0f0f0',
      }}
      loading={loading}
      size='small'
      header={
        <div
          style={{ cursor: 'pointer', paddingLeft: 16, textAlign: 'left' }}
          onClick={() => {
            setCurrentDate(currentDate ? null : moment(date))
          }}
        >
          <h1>{moment(date).format('MM-DD ddd')}</h1>
          <Space>
            <div>報告 {evalCount}</div>
            <div>製造 {printCount}</div>
            <div>配件 {accessoryCount}</div>
          </Space>
        </div>
      }
      dataSource={stagesSorted}
      renderItem={(stage) => {
        const stageName = getStageName(t, stage)[1] + getStageName(t, stage)[2]

        let status: BadgeProps['status'] = 'default'
        let backgroundColor = ''
        if (stage.status === StageStatus.Completed) {
          status = 'success'
          backgroundColor = '#f6ffed'
        } else if (stage.status === StageStatus.Started) {
          status = 'processing'
          backgroundColor = '#e6f7ff'
        }

        return (
          <List.Item
            style={{
              backgroundColor: backgroundColor,
              borderRight: '1 solid #fefefe',
            }}
          >
            <List.Item.Meta
              title={
                <>
                  <Badge status={status} />
                  <Space>
                    <span
                      style={{
                        backgroundColor:
                          stage.patient.clinic.deliveryMethod ===
                          DeliveryMethod.Sales
                            ? ''
                            : 'yellow',
                      }}
                    >
                      {stage.patient.clinic.name}
                    </span>
                    <span>{stage.patient.name}</span>
                    <span>({stage.patient.technician?.name})</span>
                  </Space>
                  <Link
                    style={{ marginLeft: 8 }}
                    key={stage.id}
                    to={`/patients/${stage.patient.id}/stages/${stage.id}`}
                    target='_blank'
                  >
                    <LinkOutlined></LinkOutlined>
                  </Link>
                </>
              }
              description={
                <Space>
                  <span>{stageName}</span>
                  {stage.currentTask && (
                    <span>{t(`task.type.${stage.currentTask.type}`)}</span>
                  )}
                </Space>
              }
            />
          </List.Item>
        )
      }}
    />
  )
}

const getTaskTypes = (stageTypes: StageType[]) =>
  uniq(stageTypes.flatMap((stageType) => Enums.stage.taskType[stageType]))

const StageCalendar = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const defaultStageTypes = [
    StageType.Eval,
    StageType.Print,
    StageType.Accessory,
  ]
  const initialValues = {
    date: moment().startOf('day'),
    type: defaultStageTypes,
    taskType: getTaskTypes(defaultStageTypes),
    status: [StageStatus.Completed, StageStatus.Started],
    showWeekend: false,
  }
  const [formState, setFormState] = useState(initialValues)
  const [currentDate, setCurrentDate] = useState<Moment.Moment | null>(null)

  const dateRange = currentDate
    ? [currentDate]
    : Array.from(
        moment
          .range([
            moment(formState.date).startOf('week').startOf('day'),
            moment(formState.date).endOf('week').startOf('day'),
          ])
          .by('day')
      ).filter((x) => {
        if (formState.showWeekend) {
          return true
        } else {
          return x.day() !== 0 && x.day() !== 6
        }
      })

  return (
    <Page
      header={
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'StageCalendar' }]}
          />
          <Title
            route={{
              key: 'StageCalendar',
              renderSubtitle: () =>
                '點擊日期可進入單日模式，再次點擊可進入周模式，任務篩選為前台篩選',
            }}
          />
        </>
      }
    >
      <Card size='small'>
        <Space direction='vertical' style={{ width: '100%' }}>
          <Form
            layout='inline'
            form={form}
            initialValues={initialValues}
            onChange={() => setFormState(form.getFieldsValue())}
          >
            <Form.Item>
              <Button.Group>
                <Button
                  onClick={() => {
                    form.setFieldsValue({
                      date: formState.date.subtract(1, 'week'),
                    })
                    setFormState(form.getFieldsValue())
                    setCurrentDate(null)
                  }}
                >
                  前一週
                </Button>
                <Button
                  onClick={() => {
                    form.setFieldsValue({ date: moment() })
                    setFormState(form.getFieldsValue())
                    setCurrentDate(null)
                  }}
                >
                  本週
                </Button>
                <Button
                  onClick={() => {
                    form.setFieldsValue({
                      date: formState.date.add(1, 'week'),
                    })
                    setFormState(form.getFieldsValue())
                    setCurrentDate(null)
                  }}
                >
                  後一週
                </Button>
              </Button.Group>
            </Form.Item>
            <Form.Item label='區間' name='date'>
              <DatePicker
                picker='week'
                format={(value) =>
                  `${moment(value)
                    .startOf('week')
                    .format('YYYY-MM-DD')} ~ ${moment(value)
                    .endOf('week')
                    .format('YYYY-MM-DD')}`
                }
              ></DatePicker>
            </Form.Item>
            <Form.Item
              label='顯示週末'
              name='showWeekend'
              valuePropName='checked'
            >
              <Checkbox />
            </Form.Item>
            <Form.Item label='類別' name='type'>
              <Checkbox.Group>
                {[StageType.Eval, StageType.Print, StageType.Accessory].map(
                  (x) => (
                    <Checkbox value={x} key={x}>
                      {t(`stage.type.${x}`)}
                    </Checkbox>
                  )
                )}
              </Checkbox.Group>
            </Form.Item>
            <Form.Item label='類別' name='taskType'>
              <Checkbox.Group>
                {getTaskTypes(formState.type).map((x) => (
                  <Checkbox value={x} key={x}>
                    {t(`task.type.${x}`)}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
            <Form.Item label='狀態' name='status'>
              <Checkbox.Group>
                {[StageStatus.Started, StageStatus.Completed].map((x) => (
                  <Checkbox value={x} key={x}>
                    {t(`stage.status.${x}`)}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
          </Form>
          <Row style={{ width: '100%', border: '1px solid #f0f0f0' }}>
            {dateRange.map((date, index) => {
              return (
                <Col flex={`${100 / dateRange.length}%`} key={index}>
                  <StageList
                    date={date}
                    type={formState.type}
                    taskType={formState.taskType}
                    status={formState.status}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                  />
                </Col>
              )
            })}
          </Row>
        </Space>
      </Card>
    </Page>
  )
}

export default StageCalendar

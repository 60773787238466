import { TableQuery } from '@sov/common'
import { Button, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { map } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ClinicsQueryDocs } from '../../../graphql/types'
import ClinicLink from '../link/clinic'
import DoctorLink from '../link/doctor'
import EmployeeLink from '../link/employee'

interface Props {
  source:
    | {
        docs: ClinicsQueryDocs[]
        page: number
        total: number
        limit: number
      }
    | undefined
  handleTableChange: any
  loading: boolean
}

const ClinicTable = (props: Props) => {
  const defaultSource = {
    docs: [],
    page: 1,
    total: 1,
    limit: 10,
  }
  const { source = defaultSource, handleTableChange, loading } = props
  const { docs, page, total, limit } = source
  const { t } = useTranslation()

  const columns: ColumnProps<ClinicsQueryDocs>[] = [
    {
      title: '區域',
      align: 'center',
      width: 60,
      dataIndex: ['location', 'district'],
      key: 'district',
      render: (_text, record) => {
        return <div>{t(`district.${record.location.district}`)}</div>
      },
    },
    {
      title: '類別',
      align: 'center',
      width: 60,
      dataIndex: ['type'],
      key: 'type',
      render: (_text, record) => {
        return <div>{t(`clinic.type.${record.type}`)}</div>
      },
    },
    {
      title: '名稱',
      align: 'center',
      width: 60,
      dataIndex: 'name',
      key: 'name',
      render: (_text, record) => {
        return <ClinicLink item={record} />
      },
    },
    {
      title: '電話',
      align: 'center',
      width: 80,
      dataIndex: 'phone',
      key: 'phone',
      render: (text) => <div>{text}</div>,
    },
    {
      title: '地址',
      align: 'left',
      width: 120,
      key: 'address',
      render: (_text, record) => {
        return (
          <div>
            <div>{record.location.address}</div>
            <div>{record.email}</div>
          </div>
        )
      },
    },
    {
      title: '醫師',
      align: 'center',
      width: 120,
      dataIndex: 'doctors',
      key: 'doctors',
      render: (_text, record) => {
        return record.doctors.map((item) => (
          <DoctorLink
            style={{ margin: '0 6px 6px 0' }}
            key={item.id}
            item={item}
          />
        ))
      },
    },
    {
      title: 'AM',
      align: 'center',
      width: 120,
      dataIndex: 'accountManagers',
      key: 'accountManagers',
      render: (_text, record) => {
        return record.accountManagers.map((item) => (
          <EmployeeLink
            style={{ margin: '0 6px 6px 0' }}
            key={item.id}
            item={item}
          />
        ))
      },
    },
    {
      title: '技師',
      align: 'center',
      width: 120,
      dataIndex: 'technitians',
      key: 'technitians',
      render: (_text, record) => {
        return record.technicians.map((item) => (
          <EmployeeLink
            style={{ margin: '0 6px 6px 0' }}
            key={item.id}
            item={item}
          />
        ))
      },
    },
    {
      title: '業務',
      align: 'center',
      width: 60,
      dataIndex: 'sales',
      key: 'sales',
      render: (text, record) => {
        const salesItem = record.sales
        return map(
          (item) => (
            <EmployeeLink
              key={item.id}
              item={item}
              style={{ display: 'block' }}
            />
          ),
          salesItem
        )
      },
    },
    {
      title: '客服',
      align: 'center',
      width: 60,
      dataIndex: 'customerService',
      key: 'customerService',
      render: (_text, record) => {
        return <EmployeeLink item={record.customerService} />
      },
    },
    {
      title: '特約',
      align: 'center',
      width: 40,
      dataIndex: 'specialContract',
      key: 'specialContract',
      render: (text) => {
        return <div>{text ? 'V' : ''}</div>
      },
    },
    {
      title: '操作',
      align: 'center',
      width: 40,
      key: 'actions',
      render: (_text, record) => {
        return (
          <Button size='small' type='primary'>
            <Link to={`/clinics/${record.id}`}>編輯</Link>
          </Button>
        )
      },
    },
  ]

  return (
    <Table<ClinicsQueryDocs>
      rowKey='id'
      size='small'
      columns={columns as any}
      dataSource={docs}
      loading={loading}
      locale={{ emptyText: '系統中無任何診所' }}
      pagination={TableQuery.getAntdPagination({ page, total, limit })}
      onChange={handleTableChange}
    />
  )
}

export default ClinicTable

import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Enums, ErrorHandling } from '@sov/common'
import { Card, Steps, message } from 'antd'
import { includes, keys, map, pipe, reduce, union, values } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { EmployeeInfoFragment } from '../../../graphql/employee/fragment'
import {
  AllPrivilege,
  EmployeesByTaskOwnerQueryQuery,
  EmployeesByTaskOwnerQueryVariables,
  StageType,
  TaskType,
  UpdateEmployeeBatchMutation,
  UpdateEmployeeBatchVariables,
} from '../../../graphql/types'
import { ConfirmButton } from '../../components/common/button/ConfirmButton'
import { EmployeeSelect } from '../../components/form/Select'
import BreadcrumbCreator from '../../components/layout/BreadcrumbCreator'
import Page from '../../components/layout/Page'
import Title from '../../components/layout/Title'

const { Step } = Steps

export const updateEmployeeMutation = gql`
  mutation UpdateEmployeeBatch($payload: [UpdateEmployeeBatchInput!]!) {
    updateEmployeeBatch(payload: $payload) {
      ...EmployeeInfo
    }
  }
  ${EmployeeInfoFragment}
`

const employeesByTaskOwnerQuery = gql`
  query EmployeesByTaskOwnerQuery(
    $query: EmployeesQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    employees(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        name
        role
        taskOwner
      }
    }
  }
`

type Props = FormComponentProps

const EmployeesByTaskOwner = ({ form }: Props) => {
  const { getFieldDecorator, getFieldValue } = form
  const { t } = useTranslation()

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading, refetch } = useQuery<
    EmployeesByTaskOwnerQueryQuery,
    EmployeesByTaskOwnerQueryVariables
  >(employeesByTaskOwnerQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      page: 1,
      limit: 500,
      sort: '-updated',
    },
  })
  const [update] = useMutation<
    UpdateEmployeeBatchMutation,
    UpdateEmployeeBatchVariables
  >(updateEmployeeMutation)

  if (loading) {
    return <div>讀取中</div>
  }

  const employeeItems = data?.employees ? data.employees.docs : []
  const stageTypes = values(StageType)
  const getEmployeeIdsByTaskOwner = (taskType: TaskType) =>
    employeeItems
      .filter((item) => includes(taskType, item.taskOwner))
      .map((item) => item.id)

  const handleUpdate = async () => {
    const taskTypesMatrix = getFieldValue('taskTypes')
    const getTaskOwner = (id) =>
      reduce(
        // @ts-ignore
        (pre, cur) => {
          if (includes(id, taskTypesMatrix[cur])) {
            // @ts-ignore
            return union(pre, [cur])
          } else {
            return pre
          }
        },
        [],
        keys(taskTypesMatrix)
      )

    const payload = pipe(
      map((item) => ({
        // @ts-ignore
        id: item.id,
        // @ts-ignore
        taskOwner: getTaskOwner(item.id),
      }))
    )(employeeItems)

    try {
      await update({
        variables: {
          payload,
        },
        update: async (cache, { data }) => {
          if (data) {
            await message.info('更新成功')
            await refetch()
          }
        },
      })
    } catch (e) {
      await message.error(`更新失敗: ${e.message}`)
    }
  }

  return (
    <Page
      header={
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'EmployeeTaskOwnerSetting' }]}
          />
          <Title route={{ key: 'EmployeeTaskOwnerSetting' }} />
        </>
      }
    >
      <Card
        size='small'
        title='更改工單底下流程人員'
        extra={
          <ConfirmButton
            confirmButtonType='update'
            label='批次更新'
            modalProps={{ onOk: handleUpdate }}
            requiredInputText='批次更新'
            requiredPrivilege={AllPrivilege.EmployeeUpdate}
          />
        }
      >
        <div style={{ display: 'flex' }}>
          {map((stageType) => {
            const taskTypes = Enums.stage.taskType[stageType]
            return (
              <div key={stageType} style={{ flex: 1 }}>
                <h3>{t(`stage.type.${stageType}`)}</h3>
                <Steps
                  size='small'
                  direction='vertical'
                  labelPlacement='vertical'
                >
                  {map((taskType) => {
                    return (
                      <Step
                        key={taskType}
                        status='wait'
                        title={t(`task.type.${taskType}`)}
                        description={
                          <div>
                            {getFieldDecorator(`taskTypes.${taskType}`, {
                              initialValue: getEmployeeIdsByTaskOwner(taskType),
                              rules: [{ required: true, message: '請輸入' }],
                            })(
                              <EmployeeSelect
                                mode='multiple'
                                style={{ width: '90%' }}
                              />
                            )}
                          </div>
                        }
                      />
                    )
                  }, taskTypes)}
                </Steps>
                <br />
              </div>
            )
          }, stageTypes)}
        </div>
      </Card>
    </Page>
  )
}

export default Form.create()(EmployeesByTaskOwner)

import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import React from 'react'

const CustomSelect = (props: SelectProps<any>) => (
  <Select
    notFoundContent='無符合資料'
    optionFilterProp='children'
    placeholder='輸入關鍵字'
    showSearch
    style={{ width: 120, marginRight: 8 }}
    {...props}
  />
)

export default CustomSelect

import { InstructionUtils } from '@sov/ui'
import { Button, Form, InputNumber, Modal, Radio } from 'antd'
import { FormInstance, FormProps } from 'antd/lib/form'
import React from 'react'

import {
  Auxiliary,
  AuxiliaryPosition,
  AuxiliaryType,
  ToothPosition,
} from '../../../../graphql/types'

const { getQuadrant, getIndex } = InstructionUtils
const FormItem = Form.Item
const RadioGroup = Radio.Group

const strippingPositionOptions = [
  { label: '近心', value: AuxiliaryPosition.Near },
  { label: '遠心', value: AuxiliaryPosition.Far },
]

const strippingTypeOptions = [
  { label: 'All', value: AuxiliaryType.StrippingAll },
  { label: 'Half', value: AuxiliaryType.StrippingHalf },
]

export interface ModalState {
  visible: boolean
  toothPosition: ToothPosition
}

interface StrippingModalProps extends FormProps {
  modalState: ModalState
  form: FormInstance
  handleAddStripping: (item: Auxiliary) => void
  handleCloseModal: () => void
}

// 選 stripping 時跳出的 modal
// @TODO 其實可以直接整併回去原本的 select
const StrippingModal = (props: StrippingModalProps) => {
  const { modalState, form, handleAddStripping, handleCloseModal } = props
  const { visible, toothPosition } = modalState
  const [quadrant, index] = [
    getQuadrant(toothPosition),
    getIndex(toothPosition),
  ]

  const onSubmit = () => {
    const { auxiliaryPosition, type, value } = form.getFieldValue('stripping')
    const newStripping: Auxiliary = {
      toothPosition,
      auxiliaryPosition,
      type,
      value,
    }
    handleAddStripping(newStripping)
  }
  const initialValues = {
    stripping: {
      auxiliaryPosition: AuxiliaryPosition.Near,
      type: AuxiliaryType.StrippingAll,
      value: 0.1,
    },
  }
  return (
    <Modal
      visible={visible}
      title={`Stripping ${quadrant}${index}`}
      onOk={onSubmit}
      onCancel={handleCloseModal}
      footer={[
        <Button key='back' onClick={handleCloseModal}>
          取消
        </Button>,
        <Button key='submit' type='primary' onClick={onSubmit}>
          確認
        </Button>,
      ]}
    >
      <Form form={form} initialValues={initialValues}>
        <div className='modal-option-container'>
          <section>
            <h4>位置</h4>
            <FormItem name={['stripping', 'auxiliaryPosition']}>
              <RadioGroup options={strippingPositionOptions} />
            </FormItem>
          </section>
          <section>
            <h4>類型</h4>
            <FormItem name={['stripping', 'type']}>
              <RadioGroup options={strippingTypeOptions} />
            </FormItem>
          </section>
          <section>
            <h4>距離 (mm)</h4>
            <FormItem name={['stripping', 'value']}>
              <InputNumber min={0} max={6} step={0.1} />
            </FormItem>
          </section>
        </div>
      </Form>
    </Modal>
  )
}

export default StrippingModal

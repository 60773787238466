import { gql, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import {
  OrderDetailQuery,
  OrderDetailQueryVariables,
} from '../../../../graphql/types'
import ConversationDrawer from '../../../components/common/conversation/ConversationDrawer'
import BreadcrumbCreator from '../../../components/layout/BreadcrumbCreator'
import Page from '../../../components/layout/Page'
import Title from '../../../components/layout/Title'
import { useLoadingLayer } from '../../../helpers/hooks'
import ButtonList from './ButtonList'
import EditOrderModal from './EditOrderModal'
import OrderDetailCard from './OrderDetailCard'
import PatientOrderListLink from './PatientOrderListLink'

interface OrderLinkProps {
  orderId: string
}

const OrderLink = (props: OrderLinkProps) => {
  const { orderId } = props

  /**
   * @todo: 顯示訂單編號
   * 等訂單編號 api 實作完成後，這邊改成顯示訂單編號
   */
  const serialNumber = '訂單詳情'

  return <Link to={`/orders/${orderId}`}>{serialNumber}</Link>
}

interface GetPageHeaderArgs {
  orderId: string
}

const getPageHeader = (args: GetPageHeaderArgs) => {
  const { orderId } = args

  return (
    <Row>
      <Col>
        <BreadcrumbCreator
          routes={[
            { key: 'Home' },
            { key: 'OrderList' },
            {
              key: 'OrderDetail',
              render: () => <OrderLink orderId={orderId} />,
            },
          ]}
        />
        <Title route={{ key: 'OrderDetail' }} />
      </Col>
    </Row>
  )
}

const Container = styled.div`
  color: 'rgba(0, 0, 0, 0.65)';
  line-height: 24px;
  margin-top: 24px;
`

const fragment = gql`
  fragment OrderDetailPage on Order {
    ...OrderDetailCard
    patient {
      ...PatientConversation
    }
  }
  ${OrderDetailCard.fragments.OrderDetailCard}
  ${ConversationDrawer.fragment.PatientConversation}
`

const orderDetailQuery = gql`
  query OrderDetail($id: ID!) {
    order(id: $id) {
      ...OrderDetailPage
    }
  }
  ${fragment}
`

interface RouteParams {
  orderId: string
}

const OrderDetailPage = () => {
  const match = useRouteMatch<RouteParams>()
  const orderId = match.params.orderId

  const { setLoadingLayer } = useLoadingLayer({
    loading: true,
    tip: '載入中...',
  })

  const variables = {
    id: orderId,
  }

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading: queryLoading } = useQuery<
    OrderDetailQuery,
    OrderDetailQueryVariables
  >(orderDetailQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables,
  })
  const [isModalVisible, setIsModalVisible] = useState(false)
  const handleModalClose = () => {
    setIsModalVisible(false)
  }

  const handleOrderEditButtonClick = () => {
    setIsModalVisible(true)
  }
  const order = data?.order
  console.log(data, orderId)
  useEffect(() => {
    setLoadingLayer({ loading: false, tip: '' })
  }, [queryLoading])

  if (queryLoading || !order) {
    return <Page loading />
  }

  const patient = order.patient

  return (
    <Page header={getPageHeader({ orderId })}>
      <>
        <Container>
          <Row gutter={16}>
            <Col span={12} offset={4}>
              <OrderDetailCard orderItem={order} />
            </Col>
            <Col span={4}>
              <ButtonList
                handleOrderEditButtonClick={handleOrderEditButtonClick}
              />

              <PatientOrderListLink patientId={patient.id} />
            </Col>
          </Row>
        </Container>
        <ConversationDrawer conversationId={order.patient.conversation.id} />

        <EditOrderModal
          handleModalClose={handleModalClose}
          isModalVisible={isModalVisible}
        />
      </>
    </Page>
  )
}

OrderDetailPage.fragements = {
  OrderDetailPage: fragment,
}

export default OrderDetailPage

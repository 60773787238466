import { gql, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { AnalysisPreview, AnalysisPreviewHeader } from '@sov/ui'
import { SwitchChangeEventHandler } from 'antd/lib/switch'
import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import {
  StageAnalysisPreviewQuery,
  StageAnalysisPreviewQueryVariables,
} from '../../../../graphql/types'
import ConversationDrawer from '../../../components/common/conversation/ConversationDrawer'

const stageAnalysisPreviewQuery = gql`
  query StageAnalysisPreview($id: ID!) {
    evalStage(id: $id) {
      ...AnalysisPreview
      patient {
        ...PatientConversation
      }
    }
  }
  ${AnalysisPreview.fragment}
  ${ConversationDrawer.fragment.PatientConversation}
`

const NotPrint = styled.div`
  @media print {
    display: none;
  }
`

const StageAnalysisPreview = () => {
  const match = useRouteMatch<{ stageId: string }>()
  const stageId = match.params.stageId
  const { data } = useQuery<
    StageAnalysisPreviewQuery,
    StageAnalysisPreviewQueryVariables
  >(stageAnalysisPreviewQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      const { toErrorPage } = ErrorHandling.useErrorHandling()
      toErrorPage(error.message)
    },
    fetchPolicy: 'no-cache',
    variables: {
      id: stageId,
    },
  })

  const stage = data?.evalStage
  const [isShowingPhotos, setIsShowingPhotos] = useState(true)

  if (!stage) {
    return null
  }

  const handleSwitchChange: SwitchChangeEventHandler = (checked) => {
    setIsShowingPhotos(checked)
  }

  return (
    <>
      <AnalysisPreviewHeader
        handleSwitchChange={handleSwitchChange}
        isCRM={false}
        linkOfStage={`/patients/${stage.patient.id}/stages/${stageId}/analysis`}
        stage={stage}
      />
      <AnalysisPreview isShowingPhotos={isShowingPhotos} stage={stage} />
      <NotPrint>
        <ConversationDrawer conversationId={stage.patient.conversation.id} />
      </NotPrint>
    </>
  )
}

export default StageAnalysisPreview

import { LoadingOutlined } from '@ant-design/icons'
import { message, notification } from 'antd'
import { ArgsProps } from 'antd/lib/notification'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

const defaultErrorHandler = (error) => {
  if (error?.message) {
    /** graphQL errors */
    message.error(error.message)
  } else {
    /** form errors or other errors */
    console.log(error)
  }
}

interface UseDebounceSaveParams<Params extends any[]> {
  notificationKey?: string
  notificationArgs?: ArgsProps
  time?: number
  onError?: (error: any) => void
  /** 外部的更新邏輯 */
  onSave: (...params: Params) => void | Promise<void>
}

const useDebounceSave = <Params extends any[]>(
  params: UseDebounceSaveParams<Params>
) => {
  const {
    notificationKey: customKey,
    notificationArgs: customArgs = {},
    time = 2000,
    onError = defaultErrorHandler,
    onSave,
  } = params

  const defaultNotificationKey = String(moment().unix())
  const notificationKey = customKey || defaultNotificationKey

  const defaultNotificationArgs = {
    key: notificationKey,
    message: '尚有未儲存的更動，請勿離開頁面',
    duration: null,
    icon: <LoadingOutlined />,
    closeIcon: <div />,
  }
  const notificationArgs = {
    ...defaultNotificationArgs,
    ...customArgs,
  }

  const $Sub = new Subject<Params>()
  const [hasUnsavedChange, setHasUnsavedChange] = useState(false)

  /** 重置狀態 */
  const handleFinish = () => {
    /** 改動狀態 -> 初始狀態 */
    setHasUnsavedChange(false)
    /** 回到初始狀態，關閉 notification */
    notification.close(notificationKey)
  }
  const handleChange: UseDebounceSaveParams<Params>['onSave'] = (...params) => {
    /** 初始狀態下，呼叫 handleChange，會開啟 notification */
    if (!hasUnsavedChange) notification.open(notificationArgs)
    $Sub.next(params)
  }

  $Sub.pipe(debounceTime(time)).subscribe(async (params) => {
    /** 初始狀態下，才能呼叫 onSave */
    if (hasUnsavedChange) return
    /** 初始狀態 -> 改動狀態 */
    setHasUnsavedChange(true)
    try {
      await onSave(...params)
    } catch (error) {
      onError(error)
    } finally {
      /** 無論更新是否完成，都重置狀態 */
      handleFinish()
    }
  })

  useEffect(() => {
    const unload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChange) {
        e.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', unload)
    return () => window.removeEventListener('beforeunload', unload)
  }, [hasUnsavedChange])

  return {
    hasUnsavedChange,
    handleChange,
    handleFinish,
  }
}

export default useDebounceSave

import { gql, useQuery } from '@apollo/client'
import {
  AntSorterType,
  ErrorHandling,
  FormQuery,
  TableQuery,
  encodeSorter,
  exhaustiveCheck,
} from '@sov/common'
import { Radio } from 'antd'
import moment from 'moment'
import { isNil, keys, without } from 'ramda'
import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { StringParam, withDefault } from 'use-query-params'

import {
  EmployeeOrdersQuery,
  EmployeeOrdersQueryVariables,
  OrderStatus,
  OrderType,
  OrdersQuery,
  StageType,
} from '../../../../graphql/types'
import Page, { Section } from '../../../components/layout/Page'
import CreateEvalStageModal from '../../../components/modal/CreateStage/CreateEvalStage'
import CreatePrintStageModal from '../../../components/modal/CreateStage/CreatePrintStage'
import OrderInstructionModal, {
  SelectedOrder,
} from '../../../components/modal/OrderInstructionModal'
import EmployeeMenu, {
  EmployeeMenuKey,
} from '../../../components/pageHeader/employee'
import FilterCondition, { FilterConditionProps } from './FilterCondition'
import OrderTable, {
  HandleInstructionModalOpen,
  HandleModalButtonClick,
} from './OrderTable'

export { SelectedOrder }
export type CreateStageType = StageType.Eval | StageType.Print

export type OrderFilterType = Pick<OrdersQuery, 'status' | 'type'>
export type OrderSorterField = 'created' | 'updated'

const employeeOrdersQuery = gql`
  query EmployeeOrders(
    $id: ID!
    $query: OrdersQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    employee(id: $id) {
      id
      name
      orders(query: $query, page: $page, limit: $limit, sort: $sort) {
        docs {
          ...OrderTable
        }
        total
        limit
        page
      }
    }
  }
  ${OrderTable.fragments.OrderTable}
`

const CustomizedRadioGroup = styled(Radio.Group)`
  margin-bottom: 16px;
`

export type FilterType =
  | 'appointmentIn15BussinessDays'
  | 'moldRelated'
  | 'noAppointment'
  | 'all'

const defaultTableFilter = {
  status: [OrderStatus.Pending],
  type: [OrderType.NormalPrint, OrderType.Remodel, OrderType.Report],
}

const emptyTableFilter = {
  status: [],
  type: [],
}

const getFixedQuery = (filterType: FilterType) => {
  const defaultAppointmentInterval = [
    moment().startOf('d').toISOString(),
    moment().add(21, 'days').startOf('d').toISOString(),
  ]

  if (!filterType) {
    return defaultAppointmentInterval
  }

  switch (filterType) {
    case 'appointmentIn15BussinessDays':
      return {
        appointmentInterval: defaultAppointmentInterval,
      }
    case 'noAppointment':
      return { hasAppointment: false }
    case 'moldRelated':
    case 'all':
      return {}
  }
  exhaustiveCheck(filterType)
}

const getFormQueryFilters = (filterType: FilterType) => {
  if (!filterType) {
    return defaultTableFilter
  }

  switch (filterType) {
    case 'appointmentIn15BussinessDays':
    case 'noAppointment':
      return defaultTableFilter
    case 'moldRelated':
      return {
        ...defaultTableFilter,
        type: [OrderType.Remodel, OrderType.Report],
      }
    case 'all':
      return emptyTableFilter
  }
  exhaustiveCheck(filterType)
}

const filterTypeLabelMap: Record<FilterType, string> = {
  appointmentIn15BussinessDays: '15 工作天內預計回診',
  noAppointment: '尚無預計回診日',
  moldRelated: '注意收模時間',
  all: '全部追蹤訂單',
}

const defaultTableSort: AntSorterType<OrderSorterField> = {
  field: 'created',
  order: 'descend',
}

const defaultTableCursor = {
  page: 1,
  limit: 100,
  sort: encodeSorter(defaultTableSort)!,
}

const queryParams = {
  filterType: withDefault(StringParam, 'appointmentIn15BussinessDays'),
}

const EmployeeOrderList = () => {
  const match = useRouteMatch<{ employeeId: string }>()
  const employeeId = match.params.employeeId

  const { formQuery, handleFormChange } = FormQuery.useFormQuery(queryParams)
  const filterType = formQuery.filterType as FilterType
  const { tableQuery, paginateQuery, handleTableChange } =
    TableQuery.useTableQuery<OrderFilterType, OrderSorterField>(
      defaultTableCursor,
      defaultTableFilter
    )

  /**
   * useTableQuery 有傳入 defaultTableFilter，filters 不會是 undefined。
   * 這邊為了避免 type error 再 fallback 一次
   */
  const tableFilterInfo = tableQuery.filters ?? defaultTableFilter
  const query: OrdersQuery = {
    ...tableFilterInfo,
    ...getFixedQuery(filterType),
  }

  const { toErrorPage } = ErrorHandling.useErrorHandling()

  const [createStagePatientId, setCreateStagePatientId] = useState<string>()
  const [createStageType, setCreateStageType] = useState<CreateStageType>()

  const [selectedOrder, setSelectedOrder] = useState<
    SelectedOrder | undefined
  >()
  const [isInstructionModalVisible, setIsInstructionModalVisible] =
    useState(false)

  const { data, loading, refetch } = useQuery<
    EmployeeOrdersQuery,
    EmployeeOrdersQueryVariables
  >(employeeOrdersQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: employeeId,
      query,
      ...paginateQuery,
    },
  })

  const handleTagRemove: FilterConditionProps['handleTagRemove'] = (
    tagInfo
  ) => {
    const originalQueryFieldValue =
      query?.[tagInfo.key] ?? defaultTableFilter[tagInfo.key]
    const updatedValue = without([tagInfo.value], originalQueryFieldValue)

    const oritinalTableFilters = {
      status: isNil(query.status) ? [] : query.status,
      type: isNil(query.type) ? [] : query.type,
    }

    const updatedTableFilters = {
      ...oritinalTableFilters,
      [tagInfo.key]: updatedValue,
    }
    handleTableChange(
      { current: 1 },
      updatedTableFilters,
      tableQuery.sort ?? [],
      {} as any
    )
  }

  const handleFilterClear = () => {
    /**
     * handleFormChange 預設更新 url 行為是 push
     * 需要在 handleTableChange 之前呼叫才能正確更新 url
     */
    handleFormChange({ filterType: 'all' })
    handleTableChange(
      { current: 1 },
      emptyTableFilter,
      tableQuery.sort ?? [],
      {} as any
    )
  }

  const handleClear = () => {
    setCreateStagePatientId(undefined)
    setCreateStageType(undefined)
  }

  const handleCancel = (options?: { shouldRefresh: boolean }) => {
    handleClear()
    if (options?.shouldRefresh) {
      refetch()
    }
  }

  const handleSubmit = () => {
    handleClear()
    refetch()
  }

  const handleOrderInstuctionModalOk = () => {
    setIsInstructionModalVisible(false)
    setSelectedOrder(undefined)
    refetch()
  }

  const handleOrderInstuctionModalCancel = () => {
    setIsInstructionModalVisible(false)
    setSelectedOrder(undefined)
  }

  const handleModalButtonClick: HandleModalButtonClick = (args) => {
    const { order, patientId, stageType } = args
    setSelectedOrder(order)
    setCreateStagePatientId(patientId)
    setCreateStageType(stageType)
  }

  const handleInstructionModalOpen: HandleInstructionModalOpen = (args) => {
    const { order } = args
    setSelectedOrder(order)
    setIsInstructionModalVisible(true)
  }

  const getRadioClickHandler = (selectedFilterType: FilterType) => {
    /**
     * handleFormChange 預設更新 url 行為是 push
     * 需要在 handleTableChange 之前呼叫才能正確更新 url
     */
    handleFormChange({ filterType: selectedFilterType })
    const updatedDescend: AntSorterType<OrderSorterField> = {
      field: 'updated',
      order: 'descend',
    }
    const sort =
      selectedFilterType === 'all' ? updatedDescend : defaultTableSort
    handleTableChange(
      { current: 1 },
      getFormQueryFilters(selectedFilterType),
      sort,
      {} as any
    )
  }

  const header = (
    <EmployeeMenu
      item={data?.employee}
      selectedKeys={[EmployeeMenuKey.EMPLOYEE_ORDERS]}
      title={`${data?.employee?.name} 追蹤訂單列表`}
      subtitle='來自業務助理及CRM 的訂單，請將列表內容轉為工單'
    />
  )

  return (
    <Page header={header}>
      <Section>
        <CustomizedRadioGroup value={filterType ?? 'all'}>
          {keys(filterTypeLabelMap).map((selectedFilterType) => (
            <Radio.Button
              key={selectedFilterType}
              onClick={() => getRadioClickHandler(selectedFilterType)}
              value={selectedFilterType}
            >
              {filterTypeLabelMap[selectedFilterType]}
            </Radio.Button>
          ))}
        </CustomizedRadioGroup>
        <FilterCondition
          fixedConditionInfo={{
            appointmentIntervalEndDate: query.appointmentInterval?.[1],
            filterType,
          }}
          tableFilterInfo={tableFilterInfo}
          handleTagRemove={handleTagRemove}
          handleFilterClear={handleFilterClear}
        />
        <OrderTable
          filterInfo={tableFilterInfo}
          source={data?.employee?.orders}
          loading={loading}
          handleTableChange={handleTableChange}
          handleModalButtonClick={handleModalButtonClick}
          handleInstructionModalOpen={handleInstructionModalOpen}
          refetch={refetch}
        />
      </Section>
      {createStagePatientId && (
        <CreatePrintStageModal
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          visible={createStageType === StageType.Print}
          orderId={selectedOrder?.id}
          patientId={createStagePatientId}
        />
      )}
      {createStagePatientId && (
        <CreateEvalStageModal
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          visible={createStageType === StageType.Eval}
          orderId={selectedOrder?.id}
          patientId={createStagePatientId}
        />
      )}
      <OrderInstructionModal
        onOk={handleOrderInstuctionModalOk}
        onCancel={handleOrderInstuctionModalCancel}
        selectedOrder={selectedOrder}
        visible={isInstructionModalVisible}
      />
    </Page>
  )
}

export default EmployeeOrderList

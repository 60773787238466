import { gql, useQuery } from '@apollo/client'
import { Space } from 'antd'
import React from 'react'

import {
  EntityTypeToPrivilegesListSystem,
  MultipleSystemQuery,
  MultipleSystemQueryVariables,
  RoleToPrivilegesListSystem,
} from '../../../../graphql/types'
import BreadcrumbCreator from '../../../components/layout/BreadcrumbCreator'
import Page from '../../../components/layout/Page'
import Title from '../../../components/layout/Title'
import SystemEntityTypeToPrivilegesListFormCard from './SystemEntityTypeToPrivilegesListFormCard'
import SystemRoleToPrivilegesListFormCard from './SystemRoleToPrivilegesListFormCard'

const multipleSystemQuery = gql`
  query MultipleSystem {
    roleToPrivilegesListSystem: system(configType: ROLE_TO_PRIVILEGES) {
      id
      configType
      ... on RoleToPrivilegesListSystem {
        roleToPrivilegesList {
          role
          privileges
        }
      }
    }
    entityTypeToPrivilegesListSystem: system(
      configType: ENTITY_TYPE_TO_PRIVILEGES
    ) {
      id
      configType
      ... on EntityTypeToPrivilegesListSystem {
        entityTypeToPrivilegesList {
          entityType
          privileges
        }
      }
    }
  }
`

const PrivilegesConfig = () => {
  const { data, loading } = useQuery<
    MultipleSystemQuery,
    MultipleSystemQueryVariables
  >(multipleSystemQuery)

  const roleToPrivilegesListSystem =
    data?.roleToPrivilegesListSystem as RoleToPrivilegesListSystem
  const entityTypeToPrivilegesListSystem =
    data?.entityTypeToPrivilegesListSystem as EntityTypeToPrivilegesListSystem

  return (
    <Page
      header={
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'PrivilegesConfig' }]}
          />
          <Title route={{ key: 'PrivilegesConfig' }} />
        </>
      }
    >
      {!loading && (
        <Space direction='vertical'>
          <SystemRoleToPrivilegesListFormCard
            initialValues={roleToPrivilegesListSystem}
          />
          <SystemEntityTypeToPrivilegesListFormCard
            initialValues={entityTypeToPrivilegesListSystem}
          />
        </Space>
      )}
    </Page>
  )
}

export default PrivilegesConfig

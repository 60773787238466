import { gql, useQuery } from '@apollo/client'
import { ErrorHandling, TableQuery } from '@sov/common'
import { Button } from 'antd'
import { any, filter, includes } from 'ramda'
import React, { Key, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'

import {
  EmployeeTaskOwnerQueryQuery,
  EmployeeTaskOwnerQueryVariables,
  TasksQuery,
  TasksQueryQuery,
  TasksQueryVariables,
  TaskStatus,
  TaskType,
} from '../../../graphql/types'
import Page, { Section } from '../../components/layout/Page'
import ModalTaskUpdateBatch, {
  taskUpdateBatchFragment,
} from '../../components/modal/TaskUpdateBatch'
import EmployeeMenu, {
  EmployeeMenuKey,
} from '../../components/pageHeader/employee'
import TableTask from '../../components/table/Task'

const employeeTasksQuery = gql`
  query EmployeeTasksQuery(
    $query: TasksQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    tasks(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...TableTask
        ...TaskUpdateBatch
      }
      total
      limit
      page
    }
  }
  ${TableTask.fragment}
  ${taskUpdateBatchFragment}
`

const employeeTaskOwnerQuery = gql`
  query EmployeeTaskOwnerQuery($id: ID!) {
    employee(id: $id) {
      id
      name
      taskOwner
    }
  }
`

const batchableTaskType = [
  // 報告
  TaskType.AssignViewer,
  TaskType.CompleteViewer,
  TaskType.SubmitViewer,
  // 製造
  TaskType.CompleteRp,
  TaskType.CompleteBraces,
  TaskType.CreateInvoice,
  TaskType.ConfirmShip,
]

const ClearBtn = (props) => (
  <Button {...props} style={{ marginLeft: 16 }} onClick={props.onClick}>
    清除篩選排序
  </Button>
)

type TaskFilterType = Pick<TasksQuery, 'type' | 'status'>
type TaskSortType = 'startedByEmployee' | 'expectedShippingDate'

const EmployeeTask = () => {
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const match = useRouteMatch<{ employeeId: string }>()
  const employeeId = match.params.employeeId
  const { t } = useTranslation()

  const employeeQueryResult = useQuery<
    EmployeeTaskOwnerQueryQuery,
    EmployeeTaskOwnerQueryVariables
  >(employeeTaskOwnerQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: employeeId,
    },
  })
  const employee = employeeQueryResult?.data?.employee

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const { tableQuery, paginateQuery, handleTableChange } =
    TableQuery.useTableQuery<TaskFilterType, TaskSortType>(
      {
        limit: 100,
        sort: '-expectedShippingDate',
      },
      { status: [TaskStatus.Working], type: employee?.taskOwner ?? undefined }
    )

  const { data, loading } = useQuery<TasksQueryQuery, TasksQueryVariables>(
    employeeTasksQuery,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      onError: (error) => {
        toErrorPage(error.message)
      },
      variables: {
        query: {
          owner: employeeId,
          status: tableQuery.filters?.status,
          type: tableQuery.filters?.type,
        },
        ...paginateQuery,
      },
    }
  )

  const handleSelectChange = (selectedRowKeys: Key[]) =>
    setSelectedRowKeys(selectedRowKeys)

  const handleBatchButtonClick = () => {
    setSelectedRowKeys([])
    setIsModalVisible(false)
  }

  const isBatchable = (task: any) =>
    includes(task.type, batchableTaskType) && task.status === TaskStatus.Working
  const rowSelection = {
    selectedRowKeys,
    onChange: handleSelectChange,
    getCheckboxProps: (record) => ({ disabled: !isBatchable(record) }),
  }

  const tasks = data?.tasks?.docs ?? []
  const selectedTasks = filter(
    (task) => includes(task.id, selectedRowKeys),
    tasks
  )
  const showBatch = any((value) => isBatchable(value), tasks)

  const header = (
    <EmployeeMenu
      item={employee}
      selectedKeys={[EmployeeMenuKey.EMPLOYEE_TASKS]}
    />
  )

  return (
    <Page header={header}>
      <Section>
        {showBatch && (
          <>
            <Button
              type='primary'
              disabled={selectedRowKeys.length === 0}
              onClick={() => {
                setIsModalVisible(true)
              }}
            >
              批次更新
            </Button>
            <br />
          </>
        )}
        <TableTask
          loading={loading}
          locale={{ emptyText: '目前無任何任務' }}
          handleTableChange={handleTableChange}
          rowSelection={rowSelection}
          filterInfo={tableQuery.filters}
          sortInfo={tableQuery.sort}
          source={data?.tasks}
          taskTypes={employee?.taskOwner}
        />
        <ModalTaskUpdateBatch
          handleBatchButtonClick={handleBatchButtonClick}
          handleModalClose={() => {
            setIsModalVisible(false)
          }}
          isModalVisible={isModalVisible}
          selectedTasks={selectedTasks}
          t={t}
        />
      </Section>
    </Page>
  )
}

export { ClearBtn }
export default EmployeeTask

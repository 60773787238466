import { getEmptyText } from '@sov/common'
import { Button, Form, Space } from 'antd'
import React, { FC, ReactNode, useState } from 'react'
import styled from 'styled-components'

const Clickable = styled.div`
  cursor: pointer;
`

interface EditableProps {
  defaultPlaceholder?: ReactNode
  name: string
  value: any
  handleSubmit: (formValues: any) => void
}

const Editable: FC<EditableProps> = (props) => {
  const { defaultPlaceholder, name, value, handleSubmit, children } = props
  const [form] = Form.useForm()
  const [editing, setEditing] = useState(false)

  const submit = () => {
    handleSubmit(form.getFieldValue(name))
    setEditing(false)
  }

  const cancel = () => {
    setEditing(false)
  }

  return (
    <Space direction='vertical' size={0}>
      <Form
        form={form}
        initialValues={{ [name]: value }}
        onClick={() => setEditing(true)}
      >
        {editing ? (
          <Form.Item name={name}>{children}</Form.Item>
        ) : (
          defaultPlaceholder || <Clickable>{getEmptyText(value)}</Clickable>
        )}
      </Form>
      {editing && (
        <Space size={8}>
          <Button size='small' type='primary' onClick={submit}>
            更改
          </Button>
          <Button size='small' onClick={cancel}>
            取消
          </Button>
        </Space>
      )}
    </Space>
  )
}

export default Editable

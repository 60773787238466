import { gql, useMutation, useQuery } from '@apollo/client'
import { Button, Form, Input, message } from 'antd'
import React from 'react'
import styled from 'styled-components'

import {
  MinimumRequiredAppVersionQuery,
  MinimumRequiredAppVersionQueryVariables,
  UpdateMinimumRequiredAppVersionSystemMutation,
  UpdateMinimumRequiredAppVersionSystemMutationVariables,
} from '../../../../graphql/types'
import BreadcrumbCreator from '../../../components/layout/BreadcrumbCreator'
import Page from '../../../components/layout/Page'
import Title from '../../../components/layout/Title'

type FormFields = UpdateMinimumRequiredAppVersionSystemMutationVariables
type FormInitialValues = FormFields

const CustomizedForm = styled(Form)`
  margin: 16px;
`

const CustomizedInput = styled(Input)`
  width: 130px;
`

const minimumRequiredAppVersionQuery = gql`
  query MinimumRequiredAppVersion {
    minimumRequiredAppVersion
  }
`

const updateMinimumRequiredAppVersionSystemMutation = gql`
  mutation UpdateMinimumRequiredAppVersionSystem($version: String!) {
    updateMinimumRequiredAppVersionSystem(version: $version) {
      minimumRequiredAppVersion
    }
  }
`

const MinimumRequiredAppVersion = () => {
  const { data, loading } = useQuery<
    MinimumRequiredAppVersionQuery,
    MinimumRequiredAppVersionQueryVariables
  >(minimumRequiredAppVersionQuery)
  const [updateMinimumRequiredAppVersionSystem] = useMutation<
    UpdateMinimumRequiredAppVersionSystemMutation,
    UpdateMinimumRequiredAppVersionSystemMutationVariables
  >(updateMinimumRequiredAppVersionSystemMutation)

  const [form] = Form.useForm<FormFields>()

  const minimumRequiredAppVersion = data?.minimumRequiredAppVersion ?? '1.0.0'

  const initialValues: FormInitialValues = {
    version: minimumRequiredAppVersion,
  }

  const handleSubmit = async () => {
    try {
      const formValues = (await form.validateFields()) as FormFields
      await updateMinimumRequiredAppVersionSystem({
        variables: formValues,
        update: () => {
          message.success('成功更新 APP 最低需求版本！')
        },
      })
    } catch (error) {
      if (error?.message) {
        /** graphQL errors */
        message.error(error?.message)
      } else {
        /** form errors or other errors */
        message.error(error)
      }
    }
  }

  return (
    <Page
      header={
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'MinimumRequiredAppVersion' }]}
          />
          <Title route={{ key: 'MinimumRequiredAppVersion' }} />
        </>
      }
      loading={loading}
    >
      <CustomizedForm form={form} initialValues={initialValues}>
        <Form.Item
          label='APP 最低需求版本'
          name='version'
          rules={[{ required: true }]}
        >
          <CustomizedInput />
        </Form.Item>
        <Button onClick={handleSubmit}>更新</Button>
      </CustomizedForm>
    </Page>
  )
}

export default MinimumRequiredAppVersion

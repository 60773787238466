import { gql, useMutation, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Button, Form, Spin, message } from 'antd'
import { GraphQLError } from 'graphql'
import { includes, pick } from 'ramda'
import React, { useEffect } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'

import { removeClinicMutation } from '../../../graphql/clinic/mutation/remove'
import { updateClinicMutation } from '../../../graphql/clinic/mutation/update'
import {
  AllPrivilege,
  ClinicDetailQueryQuery,
  ClinicDetailQueryVariables,
  RemoveClinicMutation,
  RemoveClinicVariables,
  UpdateClinicMutation,
  UpdateClinicVariables,
} from '../../../graphql/types'
import {
  ConfirmButtonDropdownMenu,
  ConfirmButtonProps,
  OnceButton,
} from '../../components/common/button'
import FormClinic, {
  FormGeneralFields,
  FormInitialValues,
  FormPickedFields,
  formPickedFields,
} from '../../components/form/clinic'
import Page, { Section } from '../../components/layout/Page'
import ClinicMenu from '../../components/pageHeader/clinic'
import { useLoadingLayer } from '../../helpers/hooks'

const FormItem = Form.Item

const BackLink = () => <Link to='/clinics'>回診所清單</Link>

const clinicDetailQuery = gql`
  query ClinicDetailQuery($id: ID!) {
    clinic(id: $id) {
      id
      ...ClinicForm
      ...ClinicMenu
    }
  }
  ${FormClinic.fragments.ClinicForm}
  ${ClinicMenu.fragments.ClinicMenu}
`

interface RouteProps {
  clinicId: string
}

export const ClinicDetail = () => {
  const [form] = Form.useForm<FormGeneralFields>()
  const history = useHistory()
  const match = useRouteMatch<RouteProps>()
  const clinicId = match.params.clinicId
  const { loading, tip, setLoadingLayer } = useLoadingLayer({
    loading: true,
    tip: '載入中...',
  })
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const {
    data,
    refetch,
    loading: queryLoading,
  } = useQuery<ClinicDetailQueryQuery, ClinicDetailQueryVariables>(
    clinicDetailQuery,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      onCompleted: (data) => {
        if (!data?.clinic) {
          toErrorPage({
            message: '不存在的診所',
            redirect: {
              name: '診所總覽',
              url: '/clinics',
            },
          })
        }
      },
      onError: (error) => {
        toErrorPage({
          message: error.message,
          redirect: {
            name: '診所總覽',
            url: '/clinics',
          },
        })
      },
      variables: {
        id: clinicId,
      },
    }
  )
  const [update] = useMutation<UpdateClinicMutation, UpdateClinicVariables>(
    updateClinicMutation
  )
  const [remove] = useMutation<RemoveClinicMutation, RemoveClinicVariables>(
    removeClinicMutation
  )

  const handleSubmit = async () => {
    setLoadingLayer({ loading: true, tip: '更新中...' })
    const payload = form.getFieldsValue()
    try {
      await update({
        variables: {
          id: clinicId,
          payload,
        },
        update: (cache, { data }) => {
          if (data) {
            setLoadingLayer({ loading: false, tip: '' })

            message.info('已更新診所')
          }
        },
      })
    } catch (error) {
      const e = error as GraphQLError
      setLoadingLayer({ loading: false, tip: '' })
      const isDuplicatedClinicNameError = includes(
        'duplicated clinic name',
        e.message
      )
      if (isDuplicatedClinicNameError) {
        message.error(`診所名稱重複，建議在診所名之後加上(地區)做區隔`)
      } else {
        message.error(`更新診所失敗: ${e.message}`)
      }
    }
  }

  const handleRemove = async () => {
    setLoadingLayer({ loading: true, tip: '刪除中...' })
    try {
      await remove({
        variables: {
          id: clinicId,
        },
        update: (cache, { data }) => {
          setLoadingLayer({ loading: false, tip: '' })
          if (data) {
            message.info('已刪除診所')
            history.push('/clinics')
          }
        },
      })
    } catch (error) {
      const e = error as GraphQLError
      setLoadingLayer({ loading: false, tip: '' })
      message.error(`刪除診所失敗: ${e.message}`)
    }
  }

  useEffect(() => {
    refetch({ id: clinicId })
  }, [clinicId])

  useEffect(() => {
    setLoadingLayer({ loading: false, tip: '' })
  }, [queryLoading])

  if (queryLoading) {
    return <Page loading />
  }

  const clinic = data?.clinic

  if (!clinic) {
    return null
  }

  const confirmButtonDropdownMenuItemsProps: ConfirmButtonProps[] = [
    {
      label: '刪除',
      modalProps: {
        onOk: handleRemove,
      },
      requiredInputText: '刪除診所',
      requiredPrivilege: AllPrivilege.ClinicDelete,
    },
  ]

  /** 不需額外轉換的 field 的 value */
  const pickedValue: FormPickedFields = pick(formPickedFields, clinic)

  /** 最後傳入 From 的 InitialValues
   * 有些field 接的value 是傳array
   * 所以將不需處理及需要額外處理的分開撰寫
   */
  const formInitialValues: FormInitialValues = {
    ...pickedValue,
    doctors: clinic.doctors.map((x) => x.id),
    accountManagers: clinic.accountManagers.map((x) => x.id),
    technicians: clinic.technicians.map((x) => x.id),
    sales: clinic.sales.map((x) => x.id),
    customerService: clinic?.customerService?.id,
  }

  return (
    <Page
      header={<ClinicMenu item={clinic} selectedKeys={['clinic-detail']} />}
      loading={loading}
      loadingComponent={<Spin size='large' tip={tip} />}
    >
      <Section>
        {clinic && (
          <>
            <Form>
              <FormClinic form={form} initialValues={formInitialValues} />
              <FormItem
                wrapperCol={{ span: 16, offset: 6 }}
                style={{ marginTop: 24 }}
              >
                <OnceButton
                  label='更新資料'
                  onClick={handleSubmit}
                  requiredPrivilege={AllPrivilege.ClinicUpdate}
                  style={{ marginRight: 24 }}
                  type='primary'
                />
                <Button>
                  <BackLink />
                </Button>
                <ConfirmButtonDropdownMenu
                  confirmButtonDropdownMenuItemsProps={
                    confirmButtonDropdownMenuItemsProps
                  }
                />
              </FormItem>
            </Form>
          </>
        )}
      </Section>
    </Page>
  )
}

export default ClinicDetail

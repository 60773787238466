import { gql } from '@apollo/client'
import { getEmptyText } from '@sov/common'
import { StageName } from '@sov/ui'
import React from 'react'
import { Link } from 'react-router-dom'

import { StageInvoiceLinkFragment } from '../../../../graphql/types'

interface Props {
  stageItem?: StageInvoiceLinkFragment
  style?: { [key: string]: string | number }
}

export const StageInvoiceLink = (props: Props) => {
  const { stageItem, style } = props
  if (!stageItem) {
    return <div>{getEmptyText()}</div>
  }
  return (
    <Link
      style={style}
      to={`/patients/${stageItem.patient.id}/stages/${stageItem.id}/invoice`}
    >
      <StageName item={stageItem} /> - 出貨單
    </Link>
  )
}

StageInvoiceLink.fragment = gql`
  fragment StageInvoiceLink on Stage {
    id
    patient {
      id
    }
    ...StageName
  }
  ${StageName.fragment}
`

export default StageInvoiceLink

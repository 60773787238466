import { useQuery } from '@apollo/client'
import { ErrorHandling, TableQuery } from '@sov/common'
import { Button, Row } from 'antd'
import { includes } from 'ramda'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { productsQuery } from '../../../graphql/product/query/list'
import {
  AllPrivilege,
  ProductsQueryDocs,
  ProductsQueryQuery,
  ProductsQueryVariables,
} from '../../../graphql/types'
import { authContext } from '../../context'
import { getAuthPrivileges } from '../../utils'
import BreadcrumbCreator from '../layout/BreadcrumbCreator'
import Page, { Section } from '../layout/Page'
import Title from '../layout/Title'
import ProductTable from '../table/Product'

const CreateButtonLink = () => {
  const auth = useContext(authContext)

  if (!includes(AllPrivilege.ProductCreate, getAuthPrivileges(auth))) {
    return null
  }

  return (
    <Button style={{ marginLeft: 16 }}>
      <Link to='/products/create'>新增出貨品項</Link>
    </Button>
  )
}

export const ProductIndex = () => {
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { paginateQuery, handleTableReset, handleTableChange } =
    TableQuery.useTableQuery<any, any>({ sort: 'serialNumber', limit: 500 })

  const { data, loading } = useQuery<
    ProductsQueryQuery,
    ProductsQueryVariables
  >(productsQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {},
      ...paginateQuery,
    },
  })

  const products = data?.products

  return (
    <Page
      header={
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'ProductList' }]}
          />
          <Title route={{ key: 'ProductList' }} />
        </>
      }
    >
      <Section>
        <Row justify='end'>
          <Button onClick={handleTableReset}>刷新</Button>
          <CreateButtonLink />
        </Row>
        <ProductTable<ProductsQueryDocs>
          source={products}
          loading={loading}
          handleTableChange={handleTableChange}
        />
      </Section>
    </Page>
  )
}

export default ProductIndex

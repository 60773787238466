import { gql, useMutation, useQuery } from '@apollo/client'
import { ErrorHandling, LeaveTools } from '@sov/common'
import { Button, Form, message, Spin } from 'antd'
import moment from 'moment'
import { pathOr } from 'ramda'
import React, { useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { createLeaveMutation } from '../../../graphql/leave/mutation/create'
import {
  AllPrivilege,
  CreateLeaveMutation,
  CreateLeaveVariables,
  EmployeeLeaveQueryQuery,
  EmployeeLeaveQueryVariables,
  LeaveType,
} from '../../../graphql/types'
import { OnceButton } from '../../components/common/button'
import FormLeave, {
  FormGeneralInitialValues,
} from '../../components/form/leave'
import BreadcrumbCreator from '../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../components/layout/Page'
import Title from '../../components/layout/Title'
import EmployeeLink from '../../components/link/employee'
import { authContext } from '../../context'
import { useLoadingLayer } from '../../helpers/hooks'
import { getUserEntityFromAuth, getUserEntityIdFromAuth } from '../../utils'

export const BackLinkButton = ({ employeeId }) => (
  <Button>
    <Link to={`/employees/${employeeId}/leaves`}>回請假清單</Link>
  </Button>
)

const employeeLeaveQuery = gql`
  query EmployeeLeaveQuery($id: ID!) {
    employee(id: $id) {
      ...EmployeeLeaveFormInfo
    }
  }
  ${FormLeave.fragments.EmployeeLeaveFormInfo}
`

export const CreateLeave = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const auth = useContext(authContext)
  const { loading, tip, setLoadingLayer } = useLoadingLayer({
    loading: true,
    tip: '載入中...',
  })

  const employeeId = getUserEntityIdFromAuth(auth)
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading: employeeLoading } = useQuery<
    EmployeeLeaveQueryQuery,
    EmployeeLeaveQueryVariables
  >(employeeLeaveQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: employeeId,
    },
  })
  const [create] = useMutation<CreateLeaveMutation, CreateLeaveVariables>(
    createLeaveMutation
  )

  useEffect(() => {
    setLoadingLayer({ loading: employeeLoading, tip: '' })
  }, [employeeLoading])

  const handleSubmit = async () => {
    setLoadingLayer({ loading: true, tip: '新增中...' })
    try {
      const value = await form.validateFields()
      const { startDate, from, days, type, reason } = value as any
      const payload: any = {
        ...LeaveTools.getDatesFromDateField(startDate, days, from),
        type,
        reason,
        employee: employeeId,
      }
      await create({
        variables: { payload },
        update: async (cache, { data }) => {
          if (data) {
            message.info('已新增假單')
            history.push(`/employees/${employeeId}/leaves`)
          }
        },
      })
    } catch (error) {
      message.error(error.message)
      console.error(error)
    }
    setLoadingLayer({ loading: false, tip: '' })
  }

  const maxRemainAnnual = pathOr(
    30,
    ['employee', 'leaveStatus', 'remainAnnual'],
    data
  )
  const formInitialValues: FormGeneralInitialValues = {
    startDate: moment(),
    type: LeaveType.Personal,
    from: 'am',
    days: 1,
    reason: '',
  }

  return (
    <Page
      header={
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'LeaveCreate' }]}
          />
          <Title route={{ key: 'LeaveCreate' }} />
        </>
      }
      loading={loading}
      loadingComponent={<Spin size='large' tip={tip} />}
    >
      <Section>
        <Form>
          <Form.Item label='員工'>
            <EmployeeLink item={getUserEntityFromAuth(auth)} />
          </Form.Item>
          <FormLeave
            form={form}
            initialValues={formInitialValues}
            maxRemainAnnual={maxRemainAnnual}
          />
          <Form.Item
            wrapperCol={{ span: 16, offset: 6 }}
            style={{ marginTop: 24 }}
          >
            <OnceButton
              disabled={loading}
              label='新增假單'
              onClick={handleSubmit}
              requiredPrivilege={AllPrivilege.LeaveCreate}
              style={{ marginRight: 24 }}
              type='primary'
            />
            <BackLinkButton employeeId={employeeId} />
          </Form.Item>
        </Form>
      </Section>
    </Page>
  )
}

export default CreateLeave

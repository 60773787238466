import { EditFilled, QuestionCircleFilled } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import {
  AntSorterType,
  TableQuery,
  exhaustiveCheck,
  isEmptyOrNil,
} from '@sov/common'
import { MultiLine, Link as SOVLink, StageName } from '@sov/ui'
import { Button, Input, Space, Table, Tooltip, message } from 'antd'
import Badge, { BadgeProps } from 'antd/lib/badge'
import { ColumnProps, TableProps } from 'antd/lib/table'
import moment from 'moment'
import { cond, find, isEmpty, map, range } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  OrderStatus,
  OrderTableFragment,
  OrderType,
  StageType,
  UpdateOrderInstructionMutation,
  UpdateOrderInstructionMutationVariables,
} from '../../../../../graphql/types'
import Editable from '../../../../components/editable'
import {
  CreateStageType,
  OrderFilterType,
  OrderSorterField,
  SelectedOrder,
} from '../index'
import CustomizedTooltip from './CustomizedTooltip'
import DefaultEditable from './DefaultEditable'

const { ClinicLink, PatientWithDoctorLink } = SOVLink

export enum ColumnKey {
  Patient = 'patient',
  Clinic = 'clinic',
  Type = 'type',
  Status = 'status',
  AppointmentDate = 'appointmentDate',
  Description = 'description',
  Instruction = 'instruction',
  Created = 'created',
  Action = 'action',
  StageProvide = 'stageProvide',
}

export const columnkeyTranslationMap: Record<ColumnKey, string> = {
  [ColumnKey.Patient]: '病患姓名',
  [ColumnKey.Clinic]: '診所',
  [ColumnKey.Type]: '訂單種類',
  [ColumnKey.Status]: '訂單狀態',
  [ColumnKey.AppointmentDate]: '預計回診日',
  [ColumnKey.Description]: '備註',
  [ColumnKey.Instruction]: '訂單指示',
  [ColumnKey.Created]: '訂單日期',
  [ColumnKey.Action]: '項目操作',
  [ColumnKey.StageProvide]: '綁定工單',
}

const getButtonDisabledTooltipMessage = (orderType: OrderType) => {
  switch (orderType) {
    case OrderType.Remodel:
      return '重取模訂單請填寫指示內容，再進行轉工單動作。'
    case OrderType.Report:
      return '治療報告訂單請填寫指示內容，再進行轉工單動作。'
    case OrderType.Accessory:
    case OrderType.NormalPrint:
      return ''
    default:
      exhaustiveCheck(orderType)
      return ''
  }
}

const StageProvideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > :not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`

const StageProvideRow = styled.div`
  padding: 10px 0px;
`

const UnProvidedText = styled.span`
  color: rgba(0, 0, 0, 0.25);
`

const updateOrderInstructionMutation = gql`
  mutation UpdateOrderInstruction($id: ID!, $payload: UpdateOrderInput!) {
    updateOrder(id: $id, payload: $payload) {
      id
    }
  }
`

const defaultSource = {
  docs: [],
  page: 1,
  total: 0,
  limit: 100,
}

interface HandleModalButtonClickArgs {
  order: SelectedOrder
  patientId: string
  stageType: CreateStageType
}
export type HandleModalButtonClick = (args: HandleModalButtonClickArgs) => void

interface HandleInstructionModalOpenArgs {
  order: SelectedOrder
}
export type HandleInstructionModalOpen = (
  args: HandleInstructionModalOpenArgs
) => void

interface OrderTableProps {
  filterInfo?: OrderFilterType
  sortInfo?: AntSorterType<OrderSorterField>
  source?: {
    docs: OrderTableFragment[]
    total: number
    page: number
    limit: number
  }
  loading: boolean
  handleTableChange: TableProps<OrderTableFragment>['onChange']
  handleModalButtonClick: HandleModalButtonClick
  handleInstructionModalOpen: HandleInstructionModalOpen
  refetch: () => void
}

const OrderTable = (props: OrderTableProps) => {
  const {
    filterInfo,
    loading,
    source = defaultSource,
    handleTableChange,
    handleModalButtonClick,
    handleInstructionModalOpen,
    refetch,
  } = props
  const { docs, page, total, limit } = source
  const { t } = useTranslation()

  const [updateOrderInstruction] = useMutation<
    UpdateOrderInstructionMutation,
    UpdateOrderInstructionMutationVariables
  >(updateOrderInstructionMutation)

  const columns: ColumnProps<OrderTableFragment>[] = [
    {
      title: columnkeyTranslationMap[ColumnKey.Patient],
      key: ColumnKey.Patient,
      dataIndex: ColumnKey.Patient,
      width: 50,
      render: (_text, record) =>
        record.patient.id && (
          <PatientWithDoctorLink target='_blank' item={record.patient} />
        ),
    },
    {
      title: columnkeyTranslationMap[ColumnKey.Clinic],
      key: ColumnKey.Clinic,
      dataIndex: ColumnKey.Clinic,
      width: 60,
      render: (_text, record) =>
        record.patient.clinic.id && (
          <ClinicLink target='_blank' item={record.patient.clinic} />
        ),
    },
    {
      title: columnkeyTranslationMap[ColumnKey.Type],
      key: ColumnKey.Type,
      dataIndex: ColumnKey.Type,
      filters: map(
        (type) => ({
          text: t(`order.type.${type}`),
          value: type,
        }),
        Object.values(OrderType)
      ),
      filteredValue: filterInfo?.type ?? [],
      width: 60,
      render: (_text, record) => <div>{t(`order.type.${record.type}`)}</div>,
    },
    {
      title: columnkeyTranslationMap[ColumnKey.Status],
      width: 60,
      key: ColumnKey.Status,
      dataIndex: ColumnKey.Status,
      filters: map(
        (status) => ({
          text: t(`order.status.${status}`),
          value: status,
        }),
        Object.values(OrderStatus)
      ),
      filteredValue: filterInfo?.status ?? [],
      render: (text, record) => {
        const orderStatus = record.status

        const badgeStatus = cond<OrderStatus, BadgeProps['status']>([
          [(status) => status === OrderStatus.Completed, () => 'success'],
          [(status) => status === OrderStatus.InProgress, () => 'processing'],
          [(status) => status === OrderStatus.Pending, () => 'error'],
        ])(orderStatus)

        return (
          <div>
            <Badge status={badgeStatus} />
            {t(`order.status.${orderStatus}`)}
          </div>
        )
      },
    },
    {
      title: columnkeyTranslationMap[ColumnKey.AppointmentDate],
      key: ColumnKey.AppointmentDate,
      dataIndex: ColumnKey.AppointmentDate,
      defaultSortOrder: 'ascend',
      sorter: (a, b) =>
        moment(a.appointment?.startDate).isAfter(
          moment(b.appointment?.startDate)
        )
          ? 1
          : -1,
      width: 60,
      render: (_text, record) =>
        record.appointment?.startDate && (
          <div>
            {moment(record.appointment?.startDate).format('YYYY-MM-DD')}
          </div>
        ),
    },
    {
      title: columnkeyTranslationMap[ColumnKey.Description],
      key: ColumnKey.Description,
      dataIndex: ColumnKey.Description,
      width: 120,
      align: 'center',
      render: (_text, record) => <div>{record.description}</div>,
    },
    {
      title: () => {
        return (
          <Space>
            <>{columnkeyTranslationMap[ColumnKey.Instruction]}</>
            <Tooltip title='把滑鼠移到文字上點擊進行編輯'>
              <EditFilled />
            </Tooltip>
          </Space>
        )
      },
      key: ColumnKey.Instruction,
      dataIndex: ColumnKey.Instruction,
      width: 120,
      align: 'center',
      render: (text, record) => {
        const update = async (value) => {
          try {
            if (record) {
              await updateOrderInstruction({
                variables: {
                  id: record.id,
                  payload: {
                    instruction: value,
                  },
                },
                update: (_cache, { data }) => {
                  if (data) {
                    message.info(`已更新訂單指示: ${data.updateOrder?.id}`)
                    refetch()
                  }
                },
              })
            }
          } catch (e) {
            message.error(`更新失敗: ${e.message}`)
          }
        }

        const flexibleInstructionOrderTypes = [
          OrderType.Accessory,
          OrderType.NormalPrint,
        ]

        if (flexibleInstructionOrderTypes.includes(record.type)) {
          return (
            <Editable
              defaultPlaceholder={
                <DefaultEditable hasText={!isEmptyOrNil(text)}>
                  <MultiLine text={text} />
                </DefaultEditable>
              }
              name='instruction'
              value={text}
              handleSubmit={update}
            >
              <Input onPressEnter={update} />
            </Editable>
          )
        }

        /** record.type === OrderType.Remodel || record.type === OrderType.Report */
        return (
          <DefaultEditable
            onClick={() => handleInstructionModalOpen({ order: record })}
            hasText={!isEmptyOrNil(text)}
          >
            <MultiLine text={text} />
          </DefaultEditable>
        )
      },
    },
    {
      title: columnkeyTranslationMap[ColumnKey.Created],
      key: ColumnKey.Created,
      dataIndex: ColumnKey.Created,
      width: 60,
      render: (_text, record) =>
        record.created && (
          <div>{moment(record.created).format('YYYY-MM-DD')}</div>
        ),
    },
    {
      title: () => (
        <Tooltip title='[已下單的數量]/[訂單須滿足的數量]'>
          <div>
            {columnkeyTranslationMap[ColumnKey.Action]}{' '}
            <QuestionCircleFilled style={{ color: 'rgba(0, 0, 0, 0.2)' }} />
          </div>
        </Tooltip>
      ),
      key: ColumnKey.Action,
      width: 80,
      render: (_text, record) => {
        const { instruction, stageProvide, stageOrder } = record
        const evalOrder = find(
          (stage) => stage.stageType === StageType.Eval,
          stageOrder
        )
        const printOrder = find(
          (stage) => stage.stageType === StageType.Print,
          stageOrder
        )
        const evalProvideCount =
          find((stage) => stage.stageType === StageType.Eval, stageProvide)
            ?.stages.length ?? 0
        const printProvideCount =
          find((stage) => stage.stageType === StageType.Print, stageProvide)
            ?.stages.length ?? 0

        const buttonDisabledTooltipMessage = getButtonDisabledTooltipMessage(
          record.type
        )

        /** 有「無指示不能下工單」限制的訂單種類 */
        const requireNonEmptyInstructionOrderTypes = [
          OrderType.Remodel,
          OrderType.Report,
        ]
        const isOrderInstructionRequired =
          requireNonEmptyInstructionOrderTypes.includes(record.type)
        const isOrderInstructionFulfilled = isOrderInstructionRequired
          ? !isEmpty(instruction)
          : true

        /** 是否有綁定過工單 */
        const hasAnyBoundStage = stageProvide.length > 0
        /**
         * 滿足以下條件需顯示訂單指示相關 tooltip
         * 1. 尚未綁定過工單
         * 2. 指示需求尚未被滿足
         */
        const shouldShowInstructionTooltip =
          !hasAnyBoundStage && !isOrderInstructionFulfilled

        return (
          <Space direction='vertical'>
            {evalOrder && (
              <div>
                <CustomizedTooltip
                  hasTooltip={shouldShowInstructionTooltip}
                  title={buttonDisabledTooltipMessage}
                >
                  <Button
                    size='small'
                    type='primary'
                    onClick={() =>
                      handleModalButtonClick({
                        order: record,
                        patientId: record.patient.id,
                        stageType: StageType.Eval,
                      })
                    }
                    disabled={
                      evalProvideCount >= (evalOrder?.number ?? 0) ||
                      shouldShowInstructionTooltip
                    }
                    danger
                  >
                    報告
                  </Button>
                </CustomizedTooltip>
                <span style={{ marginLeft: '8px' }}>
                  {evalProvideCount < evalOrder.number ? (
                    <UnProvidedText>{evalProvideCount}</UnProvidedText>
                  ) : (
                    evalProvideCount
                  )}{' '}
                  / {evalOrder.number}
                </span>
              </div>
            )}
            {printOrder && (
              <div>
                <CustomizedTooltip
                  hasTooltip={shouldShowInstructionTooltip}
                  title={buttonDisabledTooltipMessage}
                >
                  <Button
                    size='small'
                    type='primary'
                    disabled={
                      printProvideCount >= (printOrder?.number ?? 0) ||
                      shouldShowInstructionTooltip
                    }
                    onClick={() =>
                      handleModalButtonClick({
                        order: record,
                        patientId: record.patient.id,
                        stageType: StageType.Print,
                      })
                    }
                  >
                    製造
                  </Button>
                </CustomizedTooltip>
                <span style={{ marginLeft: '8px' }}>
                  {printProvideCount < printOrder.number ? (
                    <UnProvidedText>{printProvideCount}</UnProvidedText>
                  ) : (
                    printProvideCount
                  )}{' '}
                  / {printOrder.number}
                </span>
              </div>
            )}
          </Space>
        )
      },
    },
    {
      title: columnkeyTranslationMap[ColumnKey.StageProvide],
      key: ColumnKey.StageProvide,
      align: 'center',
      width: 60,
      render: (_text, record) => {
        const { patient, stageProvide, stageOrder } = record
        const evalOrder = find(
          (stage) => stage.stageType === StageType.Eval,
          stageOrder
        )
        const printOrder = find(
          (stage) => stage.stageType === StageType.Print,
          stageOrder
        )
        const evalProvide = find(
          (stage) => stage.stageType === StageType.Eval,
          stageProvide
        )
        const printProvide = find(
          (stage) => stage.stageType === StageType.Print,
          stageProvide
        )
        return (
          <StageProvideContainer>
            {map((index) => {
              const stage = evalProvide?.stages && evalProvide.stages[index]
              return (
                <StageProvideRow>
                  {stage ? (
                    <Link to={`/patients/${patient.id}/stages/${stage.id}`}>
                      <StageName item={stage} showType={false} />
                    </Link>
                  ) : (
                    <UnProvidedText>待綁定</UnProvidedText>
                  )}
                </StageProvideRow>
              )
            }, range(0, evalOrder?.number ?? 0))}
            {map((index) => {
              const stage = printProvide?.stages && printProvide.stages[index]
              return (
                <StageProvideRow>
                  {stage ? (
                    <Link to={`/patients/${patient.id}/stages/${stage.id}`}>
                      <StageName item={stage} showType={false} />
                    </Link>
                  ) : (
                    <UnProvidedText>待綁定</UnProvidedText>
                  )}
                </StageProvideRow>
              )
            }, range(0, printOrder?.number ?? 0))}
          </StageProvideContainer>
        )
      },
    },
  ]

  return (
    <Table
      rowKey='id'
      loading={loading}
      columns={columns}
      dataSource={docs}
      locale={{ emptyText: '系統中無任何訂單' }}
      onChange={handleTableChange}
      pagination={TableQuery.getAntdPagination({ page, total, limit })}
    />
  )
}

OrderTable.fragments = {
  OrderTable: gql`
    fragment OrderTable on Order {
      id
      status
      isLatest
      type
      description
      instruction
      created
      patient {
        id
        name
        sales {
          id
          name
        }
        clinic {
          ...ClinicLink
        }
        ...PatientWithDoctorLink
      }
      appointment {
        id
        startDate
      }
      stageOrder {
        stageType
        number
        items
      }
      stageProvide {
        stageType
        stages {
          ...StageName
        }
      }
    }
    ${ClinicLink.fragment}
    ${PatientWithDoctorLink.fragment}
    ${StageName.fragment}
  `,
}

export default OrderTable

import { gql, useMutation, useQuery } from '@apollo/client'
import { useErrorHandling } from '@sov/common/src/errorHandling'
import { StageName } from '@sov/ui'
import { Form, message, Select, Spin } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { DocumentNode } from 'graphql'
import moment from 'moment'
import { apply } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  CreatePrintStageMutation,
  CreatePrintStageMutationVariables,
  CreatePrintStageQueryQuery,
  CreatePrintStageQueryVariables,
  EvalStage,
  PatientStatus,
  PrintFormDocs,
  PrintFormFragment,
  StageStatus,
  StageType,
} from '../../../../graphql/types'
import BaseModal, {
  BaseModalFormFields,
  BaseModalProps,
  formItemLayout,
} from './BaseModal'

const FormItem = Form.Item

const createPrintStage = gql`
  mutation createPrintStage($payload: CreatePrintStageInput!) {
    createPrintStage(payload: $payload) {
      id
      ...ForwardStageTaskForm
    }
  }
  ${BaseModal.fragments.ForwardStageTaskForm}
`

interface PrintFormFields {
  currentEvalStage: string
  serialNumber: number
}

interface PrintFormProps {
  form: FormInstance<PrintFormFields>
  patientItem?: PrintFormFragment
}

interface StyledStageNameProps {
  isCurrentEvalStage: boolean
}

const StyledStageName = styled(StageName)<StyledStageNameProps>`
  color: ${(props) => props.isCurrentEvalStage && `#1890FF; `};
`

const DateTimeLabel = styled.span<StyledStageNameProps>`
  color: ${(props) => (props.isCurrentEvalStage ? `#1890FF; ` : `black;`)};
`

type EvalStageItem = Extract<PrintFormDocs, Pick<EvalStage, '__typename'>>

const getEvalStageUpdateTimeLabel = (
  item: EvalStageItem,
  isCurrentEvalStage: boolean
) => {
  return (
    <DateTimeLabel isCurrentEvalStage={isCurrentEvalStage}>{`(${moment(
      item.updated
    ).format('YYYY-MM-DD')})`}</DateTimeLabel>
  )
}

const PrintForm: React.FC<PrintFormProps> & { fragment: DocumentNode } = (
  props
) => {
  const { form, patientItem } = props
  const { t } = useTranslation()
  if (!patientItem) {
    return null
  }
  const stageItems = patientItem.stages?.docs ?? []
  const currentEvalStage = patientItem.currentEvalStage
  const isPatientOnEval =
    patientItem.status === PatientStatus.OnEval
      ? true
      : patientItem.status !== PatientStatus.OnPrint
  const evalStageItems = stageItems.filter(
    (item) => item.__typename === 'EvalStage'
  ) as EvalStageItem[]

  const printStageItemserialNumberList = stageItems
    .filter(
      (item) =>
        item.__typename === 'PrintStage' && item.status !== StageStatus.Dropped
    )
    // @ts-ignore
    .map((item) => item.serialNumber)
  const nextPrintStageSerialNumber =
    printStageItemserialNumberList.length > 0
      ? apply(Math.max, printStageItemserialNumberList) + 1
      : 1
  return (
    <Form
      {...formItemLayout}
      form={form}
      initialValues={{
        serialNumber: nextPrintStageSerialNumber,
        currentEvalStage: currentEvalStage?.id,
      }}
      requiredMark={false}
    >
      <FormItem name='serialNumber' label='編號'>
        <div style={{ fontWeight: 'bold' }}>{`${t(
          `stage.type.${StageType.Print}`
        )} Step${nextPrintStageSerialNumber}`}</div>
      </FormItem>
      {isPatientOnEval && (
        <>
          <FormItem
            name='currentEvalStage'
            rules={[{ required: true, message: '請選擇報告' }]}
            label='請選擇報告'
            {...formItemLayout}
          >
            <Select style={{ width: 200 }}>
              {evalStageItems.map((item) => {
                const isCurrentEvalStage = currentEvalStage?.id === item.id
                return (
                  <Select.Option key={item.id} value={item.id}>
                    <StyledStageName
                      item={item}
                      isCurrentEvalStage={isCurrentEvalStage}
                    />
                    <span style={{ marginLeft: 8 }}>
                      {getEvalStageUpdateTimeLabel(item, isCurrentEvalStage)}
                    </span>
                  </Select.Option>
                )
              })}
            </Select>
          </FormItem>
          <FormItem wrapperCol={{ span: 12, offset: 6 }}>
            <div>注意: 醫師同意由此報告開始製造</div>
          </FormItem>
        </>
      )}
    </Form>
  )
}

PrintForm.fragment = gql`
  fragment PrintForm on Patient {
    id
    status
    currentEvalStage {
      id
    }
    stages(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        status
        ...StageName
        ... on EvalStage {
          isBeforePrint
          updated
        }
        ... on PrintStage {
          serialNumber
        }
      }
    }
  }
  ${StageName.fragment}
`

const createPrintStageQuery = gql`
  query CreatePrintStageQuery(
    $id: ID!
    $query: StagesQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    patient(id: $id) {
      ...PrintForm
      ...BaseModal
    }
  }
  ${PrintForm.fragment}
  ${BaseModal.fragments.BaseModal}
`

interface CreatePrintStageModalProps
  extends Pick<BaseModalProps, 'visible' | 'onCancel' | 'onSubmit'> {
  orderId?: string
  patientId: string
}

const CreatePrintStageModal = (props: CreatePrintStageModalProps) => {
  const { visible, onCancel, onSubmit, orderId, patientId } = props

  const { toErrorPage } = useErrorHandling()
  const [create] = useMutation<
    CreatePrintStageMutation,
    CreatePrintStageMutationVariables
  >(createPrintStage)

  const { data, loading } = useQuery<
    CreatePrintStageQueryQuery,
    CreatePrintStageQueryVariables
  >(createPrintStageQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    fetchPolicy: 'no-cache',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: patientId,
      query: {
        type: [StageType.Print, StageType.Eval],
        status: [StageStatus.Completed, StageStatus.Started],
      },
      limit: 300, // 目前 patient 總工單數不會超過
      sort: '-expectedShippingDate',
    },
    skip: !visible || !patientId,
  })

  const handleCreate = async (
    fieldsValue: PrintFormFields & BaseModalFormFields
  ) => {
    let stageItem
    try {
      await create({
        variables: {
          payload: {
            ...fieldsValue,
            patient: patientId,
            ...(orderId ? { order: orderId } : {}),
          },
        },
        update: async (cache, { data }) => {
          if (data?.createPrintStage) {
            message.info('已新增製造單，請選擇下個任務負責人')
            stageItem = data.createPrintStage
          }
        },
      })
      return stageItem
    } catch (error) {
      message.error(`新增失敗: ${error.graphQLErrors[0].message}`, 3)
      onCancel({ shouldRefresh: false })
    }
  }

  const patientItem = data?.patient

  return (
    <>
      <BaseModal
        visible={visible}
        loading={loading}
        stageType={StageType.Print}
        patientItem={patientItem}
        onCancel={onCancel}
        onSubmit={onSubmit}
        getCreatedStage={handleCreate}
      >
        {({ form }) =>
          loading ? (
            <Spin />
          ) : (
            <PrintForm form={form} patientItem={patientItem} />
          )
        }
      </BaseModal>
    </>
  )
}

export default CreatePrintStageModal

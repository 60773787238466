import { gql, useMutation, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Button, Form, Row, Space, message } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'

import {
  updateInvoiceWithStageMutation,
  updateInvoiceWithoutStageMutation,
} from '../../../graphql/invoice/mutation/update'
import {
  InvoiceDetailQueryQuery,
  InvoiceDetailQueryQueryVariables,
  UpdateInvoiceWithStageMutation,
  UpdateInvoiceWithStageVariables,
  UpdateInvoiceWithoutStageMutation,
  UpdateInvoiceWithoutStageVariables,
} from '../../../graphql/types'
import InvoiceTimeLine from '../../components/common/invoice/InvoiceTimeLine'
import {
  FormInvoice,
  FormInvoiceFields,
  FormInvoiceProps,
  InvoiceFormType,
  MailButton,
  UpdateButton,
  basicInvoicePayloadTransformer,
  invoiceMailFragments,
  loadingCompleteState,
  loadingState,
  sendMail,
} from '../../components/form/invoice'
import { InvoiceClientDisplayInfo } from '../../components/form/invoice/InvoiceClientInfo'
import BreadcrumbCreator from '../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../components/layout/Page'
import Title from '../../components/layout/Title'
import InvoicePrintModal from '../../components/modal/InvoicePrint'
import { useLoadingLayer } from '../../helpers/hooks'

const invoiceDetailQuery = gql`
  query InvoiceDetailQuery($id: ID!) {
    invoice(id: $id) {
      id
      ...FormInvoiceItem
      ...InvoicePrintModal
      ...InvoiceMailInvoiceItem
    }
  }
  ${FormInvoice.fragments.FormInvoiceItem}
  ${InvoicePrintModal.fragment}
  ${invoiceMailFragments.InvoiceMailInvoiceItem}
`

interface RouteProps {
  invoiceId: string
}

export const InvoiceDetail = () => {
  const match = useRouteMatch<RouteProps>()
  const invoiceId = match.params.invoiceId

  const [form] = Form.useForm()
  const { t } = useTranslation()
  const { loading, setLoadingLayer } = useLoadingLayer(loadingCompleteState)

  const [isModalVisible, setIsModalVisible] = useState(false)

  /** 出貨單 */
  const variables = {
    id: invoiceId,
  }
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, refetch } = useQuery<
    InvoiceDetailQueryQuery,
    InvoiceDetailQueryQueryVariables
  >(invoiceDetailQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    /** 如果不使用 'no-cache' 的話從 invoice table 的連結連過來 UI 不會被更新 */
    fetchPolicy: 'no-cache',
    variables,
    skip: !invoiceId,
  })

  const invoiceItem = data?.invoice

  /** 更新出貨單 */
  const [updateInvoiceWithStage] = useMutation<
    UpdateInvoiceWithStageMutation,
    UpdateInvoiceWithStageVariables
  >(updateInvoiceWithStageMutation)
  const [updateInvoiceWithoutStage] = useMutation<
    UpdateInvoiceWithoutStageMutation,
    UpdateInvoiceWithoutStageVariables
  >(updateInvoiceWithoutStageMutation)

  if (loading) {
    return <Page loading />
  }
  if (!invoiceItem) {
    return null
  }
  const formType =
    invoiceItem.__typename === 'InvoiceWithoutStage'
      ? InvoiceFormType.CLINIC_ONLY
      : InvoiceFormType.PATIENT_STAGE

  const displayInfo: InvoiceClientDisplayInfo = {
    patientInfo:
      invoiceItem.__typename === 'InvoiceWithStage'
        ? invoiceItem.patient
        : undefined,
    stageInfo:
      invoiceItem.__typename === 'InvoiceWithStage'
        ? invoiceItem.stage
        : undefined,
    clinicInfo: invoiceItem.clinic,
    doctorInfo: invoiceItem.doctor,
    salesInfo: invoiceItem.sales,
  }
  const formFields = {
    patient:
      invoiceItem.__typename === 'InvoiceWithStage'
        ? invoiceItem.patient.id
        : undefined,
    stage:
      invoiceItem.__typename === 'InvoiceWithStage'
        ? invoiceItem.stage.id
        : undefined,
    clinic: invoiceItem.clinic.id,
    doctor: invoiceItem.doctor.id,
    sales: invoiceItem.sales.id,
  }
  const initialValues: FormInvoiceProps['initialValues'] = {
    ...invoiceItem,
    ...displayInfo,
    ...formFields,
    shippingDate: invoiceItem.shippingDate
      ? moment(invoiceItem.shippingDate)
      : undefined,
    creditDate: moment(invoiceItem.creditDate),
    hasTax: invoiceItem.tax,
    formType,
  }

  const handleInvoiceUpdate = async () => {
    try {
      const formValues = (await form.validateFields()) as FormInvoiceFields
      setLoadingLayer(loadingState)

      if (formType === InvoiceFormType.PATIENT_STAGE) {
        await updateInvoiceWithStage({
          variables: {
            id: invoiceId,
            payload: basicInvoicePayloadTransformer(formValues),
          },
          update: (cache, { data }) => {
            setLoadingLayer(loadingCompleteState)
            if (data) {
              refetch(variables)
              message.info('已更新出貨單')
            }
          },
        })
      }

      if (formType === InvoiceFormType.CLINIC_ONLY) {
        await updateInvoiceWithoutStage({
          variables: {
            id: invoiceId,
            payload: basicInvoicePayloadTransformer(formValues),
          },
          update: (cache, { data }) => {
            setLoadingLayer(loadingCompleteState)
            if (data) {
              refetch(variables)
              message.info('已更新出貨單')
            }
          },
        })
      }
    } catch (error) {
      if (error?.message) {
        /** graphQL errors */
        const hintMessage = `新增出貨單失敗: ${error.message}`
        message.error(hintMessage)
      } else {
        /** form errors or other errors */
        console.log(error)
      }
    } finally {
      setLoadingLayer(loadingCompleteState)
    }
  }

  return (
    <Page
      header={
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'InvoiceDetail' }]}
          />
          <Title route={{ key: 'InvoiceDetail' }} />
        </>
      }
      loading={loading}
    >
      <>
        <Section>
          <Space>
            <UpdateButton onClick={handleInvoiceUpdate} type='primary' />
            {invoiceItem.__typename === 'InvoiceWithStage' && (
              <MailButton
                loading={loading}
                onClick={sendMail(
                  {
                    invoiceItem,
                    clinicItem: invoiceItem.clinic,
                    doctorItem: invoiceItem.doctor,
                    salesItem: invoiceItem.sales,
                  },
                  t
                )}
              />
            )}
            <Button onClick={() => setIsModalVisible(true)}>預覽列印</Button>
            <InvoicePrintModal
              handleModalClose={() => setIsModalVisible(false)}
              isModalVisible={isModalVisible}
              invoiceItem={invoiceItem}
            />
          </Space>
          <Row>
            <FormInvoice form={form} initialValues={initialValues} />
            {invoiceItem.__typename === 'InvoiceWithStage' && (
              <InvoiceTimeLine patientId={invoiceItem.patient.id} />
            )}
          </Row>
        </Section>
      </>
    </Page>
  )
}

export default InvoiceDetail

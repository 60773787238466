import { gql, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Select } from 'antd'
import { SelectProps, SelectValue } from 'antd/lib/select'
import { find, map, propEq, union } from 'ramda'
import React, { useEffect, useState } from 'react'

import {
  ClinicDoctorSelectDocs,
  ClinicDoctorSelectQuery,
  ClinicDoctorSelectQueryVariables,
} from '../../../../graphql/types'
import CustomSelect from './CustomSelect'

const fragment = gql`
  fragment DoctorInClinicDoctorSelect on Doctor {
    id
    name
  }
`

const clinicDoctorSelectQuery = gql`
  query ClinicDoctorSelect(
    $query: DoctorsQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    doctors(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...DoctorInClinicDoctorSelect
      }
    }
  }
  ${fragment}
`

type ClinicDoctorSelectProps = SelectProps<ClinicDoctorSelectDocs> & {
  handleItemSelect?: (selectedItem: ClinicDoctorSelectDocs) => void
  initItem?: ClinicDoctorSelectDocs
  query: {
    clinic: string | undefined
  }
}

const ClinicDoctorSelect = (props: ClinicDoctorSelectProps) => {
  const {
    handleItemSelect,
    initItem,
    query = { clinic: undefined },
    ...restProps
  } = props
  const [fetchedItems, setFetchItems] = useState<ClinicDoctorSelectDocs[]>([])

  const variables = {
    query,
    page: 1,
    limit: 100,
    sort: '-updated',
  }

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const result = useQuery<
    ClinicDoctorSelectQuery,
    ClinicDoctorSelectQueryVariables
  >(clinicDoctorSelectQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    skip: true,
    variables,
  })

  const getAndSetSelectDoctors = async () => {
    const response = await result.refetch(variables)
    if (response.data?.doctors) {
      setFetchItems(response.data.doctors.docs)
    }
  }

  useEffect(() => {
    if (query.clinic) {
      getAndSetSelectDoctors()
    }
  }, [query.clinic])

  const handleSelect = (id: SelectValue) => {
    if (fetchedItems) {
      const selectedItem = find(propEq('id', id), fetchedItems)
      if (selectedItem && handleItemSelect) {
        handleItemSelect(selectedItem)
      }
    }
  }
  const items = initItem ? union(fetchedItems, [initItem]) : fetchedItems
  return (
    <CustomSelect onSelect={handleSelect} {...restProps}>
      {map(
        (item) => (
          <Select.Option value={item.id} key={item.id}>
            {item.name}
          </Select.Option>
        ),
        items
      )}
    </CustomSelect>
  )
}

ClinicDoctorSelect.fragments = {
  DoctorInClinicDoctorSelect: fragment,
}

export default ClinicDoctorSelect

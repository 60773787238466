import { getEmptyText } from '@sov/common'
import { isEmpty, pathOr } from 'ramda'
import React from 'react'
import { Link } from 'react-router-dom'
import { DeepPartial } from 'utility-types'

import { Clinic } from '../../../../graphql/types'

type IClinic = DeepPartial<Clinic>

interface Props {
  item?: IClinic
  target?: string
  style?: { [key: string]: string | number }
  children?: React.ReactNode
}

const ClinicLink = ({
  item,
  target = '_self',
  style = { color: 'black' },
  children,
}: Props) => {
  const id = pathOr('', ['id'], item) || pathOr('', ['_id'], item)

  return item && !isEmpty(item) ? (
    <Link to={`/clinics/${id}`} target={target} style={style}>
      {children || item.name}
    </Link>
  ) : (
    <span style={style}>{children || getEmptyText()}</span>
  )
}

export default ClinicLink

import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import ArrowRightIcon from './ArrowRightIcon'

const CustomizedLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 16px;
  border: 1px solid rgba(0, 0, 0, 0.45);
  box-sizing: border-box;
  border-radius: 4px;
`

const Label = styled.div`
  color: rgba(0, 0, 0, 0.45);
`

interface LinkButtonProps {
  to: string
}

const LinkButton = (props: LinkButtonProps) => {
  const { to } = props

  return (
    <CustomizedLink to={to} target='_blank'>
      <Label>檢視詳情</Label>
      <ArrowRightIcon />
    </CustomizedLink>
  )
}

export default LinkButton

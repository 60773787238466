import 'moment/locale/zh-tw'

import { useQuery } from '@apollo/client'
import { ErrorHandling, LeaveTools } from '@sov/common'
import { Button, Calendar, Card, Checkbox, Modal } from 'antd'
import moment, { Moment } from 'moment'
import { compose, filter, includes, map } from 'ramda'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { leavesForCalendarQuery } from '../../../graphql/leave/query/forCalendar'
import {
  LeaveInfoFragment,
  LeavesForCalendarQueryQuery,
  LeavesForCalendarQueryVariables,
  LeaveType,
} from '../../../graphql/types'
import BreadcrumbCreator from '../layout/BreadcrumbCreator'
import Page from '../layout/Page'
import Title from '../layout/Title'

const objectMap = (object, mapFn) => {
  return Object.keys(object).reduce(function (result, key) {
    result[key] = mapFn(object[key])
    return result
  }, {})
}

const CheckboxGroup = Checkbox.Group
moment.locale('zh-tw')

export const OpenModalButton = ({ onclick }) => (
  <Button onClick={onclick}>該月總覽</Button>
)

// 結算當月請假資訊
// {名字: {類型: 次數, ...}}
interface LeaveInfoByName {
  [name: string]: LeaveInfo
}
type LeaveInfo = { [key in LeaveType]?: number }
type Time = 'am' | 'pm' | 'full'

interface DateCell {
  type: LeaveType
  name: string
  timeType: Time
}

interface TypeOption {
  label: string
  value: Time
}

const typeOptions: TypeOption[] = [
  { label: '全天', value: 'full' },
  { label: '上午', value: 'am' },
  { label: '下午', value: 'pm' },
]

const defaultFilter: Time[] = typeOptions.map((item) => item.value)

const getListData = (
  currentDateOnCalendar: Moment,
  data: LeaveInfoFragment[] = []
) => {
  const mid = moment(currentDateOnCalendar)
    .startOf('day')
    .add(13, 'hours')
    .add(30, 'minutes')

  return compose<LeaveInfoFragment[], LeaveInfoFragment[], DateCell[]>(
    map((item) => {
      let timeType: 'am' | 'pm' | 'full'
      const mStartDate = moment(item.startDate)
      const mEndDate = moment(item.endDate)
      // 結束時間為當日 13:30 表示請上午
      if (mEndDate.isSame(mid)) {
        timeType = 'am'
        // 開始時間為當日 13:30 表示請下午
      } else if (mStartDate.isSame(mid)) {
        timeType = 'pm'
        // 否則就為整天
      } else {
        timeType = 'full'
      }
      return { type: item.type, name: item.employee.name, timeType }
    }),
    // 選出請假區間有涵蓋 currentDateOnCalendar 的
    filter<LeaveInfoFragment>((item: LeaveInfoFragment) =>
      currentDateOnCalendar.isBetween(
        item.startDate,
        item.endDate,
        'date',
        '[]'
      )
    )
  )(data)
}

const getTimeType = (timeType) => {
  let timeTypeName
  switch (timeType) {
    case 'full':
      timeTypeName = '全天'
      break
    case 'am':
      timeTypeName = '上午'
      break
    case 'pm':
      timeTypeName = '下午'
      break
  }
  return timeTypeName
}

export const sumLeaveInfo = (baseDate: Moment, items: LeaveInfoFragment[]) => {
  const start = moment(baseDate).startOf('month').add(9, 'hours')
  const end = moment(baseDate).endOf('month').add(18, 'hours')
  const leaveObj = items
    .filter((item) => {
      // 要先過濾掉結束時間在月初之前 && 開始時間在月底之後
      return (
        !moment(item.endDate).isBefore(start) &&
        !moment(item.startDate).isAfter(end)
      )
    })
    .map((item) => {
      // 假期跨月，修改 startDate or endDate 方便計算天數
      if (
        moment(item.startDate).isBefore(start) &&
        moment(item.endDate).isAfter(start)
      ) {
        return {
          ...item,
          startDate: start,
        }
      } else if (
        moment(item.startDate).isBefore(end) &&
        moment(item.endDate).isAfter(end)
      ) {
        return {
          ...item,
          endDate: end,
        }
      }

      return item
    })
    .reduce((pre, cur) => {
      // 根據名稱合併
      const name = cur?.employee.name

      if (!name) return pre

      if (!pre[name]) {
        return {
          ...pre,
          [name]: [cur],
        }
      } else {
        return {
          ...pre,
          [name]: [...pre[name], cur],
        }
      }
    }, {})

  const result = objectMap(leaveObj, (item) => {
    // 根據 type 去計算天數
    return item.reduce((result, item) => {
      const days = LeaveTools.getLeaveDays(
        moment(item.startDate),
        moment(item.endDate)
      )
      if (!result[item.type]) {
        result[item.type] = 0
      }
      result[item.type] += days
      return result
    }, {})
  })

  return result
}

interface ModalContentProps {
  monthLeaveInfo: LeaveInfoByName
}

const ModalContent = ({ monthLeaveInfo }: ModalContentProps) => {
  const { t } = useTranslation()

  return (
    <>
      {Object.entries(monthLeaveInfo).map(([name, leaveInfo]) => (
        <div key={name}>
          <span style={{ marginRight: '8px' }}>{name}</span>
          {Object.entries(leaveInfo).map(([type, number]) => (
            <span style={{ marginRight: '8px' }} key={type}>
              {t(`leave.${type}`)} - {number}
            </span>
          ))}
        </div>
      ))}
    </>
  )
}

export const LeaveCalendar = () => {
  const { t } = useTranslation()
  const [filters, setFilter] = useState(defaultFilter)
  const [monthLeaveInfo, setMonthLeaveInfo] = useState<LeaveInfoByName>({})
  const [modalVisible, setModalVisible] = useState(false)
  const [startOfMonth, setStartOfMonth] = useState(moment().startOf('month'))

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const list = useQuery<
    LeavesForCalendarQueryQuery,
    LeavesForCalendarQueryVariables
  >(leavesForCalendarQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      startDate: startOfMonth.toISOString(),
    },
  })
  const leavesForCalendar = list.data?.leavesForCalendar
    ? list.data.leavesForCalendar
    : []

  // antd Calendar 使用
  const dateCellRender = (value: Moment) => {
    return (
      <>
        {getListData(value, leavesForCalendar)
          .filter((item) => includes(item.timeType, filters))
          .map((item) => (
            <div key={item.name}>
              <span className={item.timeType}>{item.name}</span>
              <span> / {getTimeType(item.timeType)}</span>
              <span> / {t(`leave.${item.type}`)}</span>
            </div>
          ))}
      </>
    )
  }

  const triggerModal = () => {
    setMonthLeaveInfo(sumLeaveInfo(startOfMonth, leavesForCalendar))
    setModalVisible(true)
  }

  return (
    <Page
      header={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <BreadcrumbCreator
              routes={[{ key: 'Home' }, { key: 'LeaveCalendar' }]}
            />
            <Title route={{ key: 'LeaveCalendar' }} />
          </div>
          <div>
            <CheckboxGroup
              options={typeOptions}
              defaultValue={defaultFilter}
              onChange={(value) => setFilter(value as Time[])}
            />
            <OpenModalButton onclick={triggerModal} />
          </div>
        </div>
      }
    >
      <Card size='small'>
        <Calendar
          dateCellRender={dateCellRender}
          onChange={(value) => {
            if (value && !value.startOf('month').isSame(startOfMonth)) {
              setStartOfMonth(value)
            }
          }}
        />
        <Modal
          title={`${moment(startOfMonth).format('YYYY 年 MM 月')}`}
          visible={modalVisible}
          onOk={() => setModalVisible(false)}
          onCancel={() => setModalVisible(false)}
          style={{ top: '30px' }}
        >
          <ModalContent monthLeaveInfo={monthLeaveInfo} />
        </Modal>
      </Card>
    </Page>
  )
}

export default LeaveCalendar

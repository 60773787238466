import { gql } from '@apollo/client'
import { GqlUpload } from '@sov/ui'
import { Modal } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import React from 'react'
import styled from 'styled-components'

import {
  CollageModalFragment,
  PhotoContainerFragment,
} from '../../../../../graphql/types'
import {
  extraOralPhotos,
  intraOralPhotos,
  xRayPhotos,
} from './patientPhotoMapping'

const PhotoList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 640px;
`

interface PhotoCardProps {
  key: string
  aspect: number
  image?: string
  placeholder?: string
  width: number
}

const Photo = styled.div<PhotoCardProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${(props) => props.width}px;
  height: ${(props) => props.width / props.aspect}px;
  margin: 0 10px 10px 0;
  padding: 8px;
  color: white;
  cursor: pointer;

  &:after {
    content: '';
    ${(props) =>
      props.image
        ? `background-image: url(${props.image});`
        : `background-image: url(${props.placeholder});`}
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

interface PhotoContainerProps {
  item?: PhotoContainerFragment
  photo: {
    key: string
    title: string
    placeholder: string
    aspect: number
  }
  width: number
}

const PhotoContainer = (props: PhotoContainerProps) => {
  const { photo, item, width } = props
  const file = GqlUpload.initialValueTransformer(item?.photos[photo.key])?.[0]
    ?.response

  return (
    <Photo
      key={photo.key}
      aspect={photo.aspect}
      image={file?.path}
      placeholder={photo.placeholder}
      width={width}
    />
  )
}

PhotoContainer.fragment = gql`
  fragment PhotoContainer on Patient {
    id
    photos {
      # 口外照(外部照片)
      # 正面照
      frontFace {
        id
        filename
        path
        thumbnailPath
      }
      # 露齒照
      frontFaceWithTeeth {
        id
        filename
        path
        thumbnailPath
      }
      # 45度側面照
      sideFace45 {
        id
        filename
        path
        thumbnailPath
      }
      sideFace45WithTeeth {
        id
        filename
        path
        thumbnailPath
      }
      # 側面照
      sideFace {
        id
        filename
        path
        thumbnailPath
      }
      sideFaceWithTeeth {
        id
        filename
        path
        thumbnailPath
      }

      # 口內照(口腔內部)
      # 正面觀
      frontInside {
        id
        filename
        path
        thumbnailPath
      }
      # 左面觀
      leftInside {
        id
        filename
        path
        thumbnailPath
      }
      # 右面觀
      rightInside {
        id
        filename
        path
        thumbnailPath
      }
      # 上顎咬合面
      upperJawInside {
        id
        filename
        path
        thumbnailPath
      }
      # 下顎咬合面
      lowerJawInside {
        id
        filename
        path
        thumbnailPath
      }

      # X光照
      # Pano X光片
      pano {
        id
        filename
        path
        thumbnailPath
      }
      # Ceph X光片
      ceph {
        id
        filename
        path
        thumbnailPath
      }
    }
  }
`

interface Props extends ModalProps {
  item?: CollageModalFragment
}

const CollageModal = (props: Props) => {
  const { item, ...restProps } = props
  return (
    <Modal width={720} title={null} {...restProps}>
      <PhotoList>
        {extraOralPhotos.map((photo) => (
          <PhotoContainer
            key={photo.key}
            photo={photo}
            item={item}
            width={180}
          />
        ))}
        {intraOralPhotos.map((photo) => (
          <PhotoContainer
            key={photo.key}
            photo={photo}
            item={item}
            width={180}
          />
        ))}
        <PhotoContainer
          key={xRayPhotos[0].key}
          photo={xRayPhotos[0]}
          item={item}
          width={180}
        />
        <PhotoContainer
          key={xRayPhotos[1].key}
          photo={xRayPhotos[1]}
          item={item}
          width={560}
        />
      </PhotoList>
    </Modal>
  )
}

CollageModal.fragments = {
  CollageModal: gql`
    fragment CollageModal on Patient {
      id
      ...PhotoContainer
    }
    ${PhotoContainer.fragment}
  `,
}

export default CollageModal

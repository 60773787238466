import Icon from '@ant-design/icons'
import React from 'react'

const Svg = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      // eslint-disable-next-line max-len
      d='M5.62977 17.3702C5.28196 17.718 5.23744 18.2374 5.53033 18.5303C5.82322 18.8232 6.34262 18.7787 6.69043 18.4309L12 13.1213L17.7442 18.8655C18.092 19.2133 18.6113 19.2578 18.9042 18.9649C19.1971 18.672 19.1526 18.1526 18.8048 17.8048L13.0607 12.0607L18.8659 6.2554C19.2137 5.90759 19.2583 5.3882 18.9654 5.09531C18.6725 4.80241 18.1531 4.84693 17.8053 5.19474L12 11L6.62974 5.62975C6.28195 5.28195 5.76256 5.23744 5.46967 5.53033C5.17678 5.82323 5.22129 6.34261 5.56908 6.69041L10.9393 12.0607L5.62977 17.3702Z'
      fill='black'
      fillOpacity='0.65'
    />
  </svg>
)

const CloseIcon = (props) => <Icon {...props} component={Svg} />

export default CloseIcon

import { gql } from '@apollo/client'
import { Col, Row, Space } from 'antd'
import React from 'react'

import { PatientProfileFragment } from '../../../../../graphql/types'
import { Label, SectionTitle } from './components'

interface PatientProfileProps {
  orderItem: PatientProfileFragment
}

const PatientProfile = (props: PatientProfileProps) => {
  const { orderItem } = props
  const { creator, patient } = orderItem
  const { doctor } = patient

  return (
    <>
      <SectionTitle>病患基本資料</SectionTitle>
      <Space direction='vertical' size={16} style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col span={4}>
            <Label>姓名:</Label>
          </Col>
          <Col span={6}>
            <div>{patient.name}</div>
          </Col>
          <Col span={6}>
            <Label>下單者:</Label>
          </Col>
          <Col span={6}>{creator.name}</Col>
          <Col span={2} />
        </Row>
        <Row gutter={16}>
          <Col span={4}>
            <Label>負責醫生:</Label>
          </Col>
          <Col span={6}>
            <div>{doctor.name}</div>
          </Col>
        </Row>
      </Space>
    </>
  )
}

PatientProfile.fragments = {
  PatientProfile: gql`
    fragment PatientProfile on Order {
      id
      creator {
        id
        name
        __typename
      }
      patient {
        id
        name
        doctor {
          id
          name
        }
      }
    }
  `,
}

export default PatientProfile

import { MenuOutlined } from '@ant-design/icons'
import { useLogout } from '@sov/ui'
import { Avatar, Dropdown, Menu, Space } from 'antd'
import React, { useContext } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { authContext } from '../../context'
import { getAccountFromAuth, getAccountIdFromAuth } from '../../utils'
import MessageInboxIcon from '../common/MessageInboxIcon'
import NoticeIcon from '../common/NoticeIcon'
import {
  GLOBAL_HEADER_HEIGHT,
  SIDER_MENU_WIDTH,
  SIDER_MENU_WIDTH_COLLAPSED,
} from './constant'

interface Props {
  isDesktop: boolean
  collapsed: boolean
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  setOpenKeys: React.Dispatch<React.SetStateAction<string[]>>
}

const StyledCustomHeader = styled.div`
  max-height: ${GLOBAL_HEADER_HEIGHT}px;
  height: ${GLOBAL_HEADER_HEIGHT}px;
  display: flex;
  background: #fff;
  padding: 0 16px;
  top: 0;
  box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
  position: relative;
  z-index: 800;
  width: ${(props: Props) =>
    props.isDesktop
      ? props.collapsed
        ? `calc(100vw - ${SIDER_MENU_WIDTH_COLLAPSED}px)`
        : `calc(100vw - ${SIDER_MENU_WIDTH}px)`
      : '100%'};

  .icon {
    font-size: 14px;
    width: 48px;
    height: ${GLOBAL_HEADER_HEIGHT}px;
    line-height: ${GLOBAL_HEADER_HEIGHT}px;
    cursor: pointer;
    color: grey;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .blank {
    flex: 1;
  }

  .user-container {
    padding: 0 16px;
    height: ${GLOBAL_HEADER_HEIGHT}px;
    line-height: ${GLOBAL_HEADER_HEIGHT}px;
    cursor: pointer;
    display: flex;
    align-items: center;

    .user-name {
      margin-left: 8px;
      color: initial;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  @media print {
    display: none;
  }
`

const CustomHeader = (props: Props) => {
  const { collapsed, setCollapsed, setOpenKeys } = props
  const auth = useContext(authContext)

  const logout = useLogout()
  const accountId = getAccountIdFromAuth(auth)
  const account = getAccountFromAuth(auth)

  const handleLogout = async () => {
    await logout()
  }

  const handleCollapse = () => {
    setCollapsed(!collapsed)
    setOpenKeys([])
  }

  const isDesktop = useMediaQuery({
    query: '(min-width: 576px)',
  })

  if (auth) {
    return (
      <StyledCustomHeader
        isDesktop={isDesktop}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        setOpenKeys={setOpenKeys}
      >
        <MenuOutlined className='icon' onClick={handleCollapse} />
        <div style={{ flex: 1 }} />
        <Space>
          <MessageInboxIcon />
          <NoticeIcon />
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu>
                <Menu.Item>
                  <Link to={`/accounts/${accountId}`}>設定</Link>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item onClick={handleLogout}>登出</Menu.Item>
              </Menu>
            }
          >
            <div className='user-container'>
              <Avatar
                className='user-avatar'
                size='small'
                src={account?.avatar?.thumbnailPath}
              />
              <span className='user-name'>{account?.nickname}</span>
            </div>
          </Dropdown>
        </Space>
      </StyledCustomHeader>
    )
  } else {
    return null
  }
}

export default CustomHeader

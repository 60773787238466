import { gql, useMutation, useQuery } from '@apollo/client'
import { ErrorHandling, LeaveTools } from '@sov/common'
import { Button, Form, message, Spin } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import moment from 'moment'
import { includes, map, pathOr } from 'ramda'
import React, { useContext, useEffect } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { removeLeaveMutation } from '../../../graphql/leave/mutation/remove'
import { updateLeaveMutation } from '../../../graphql/leave/mutation/update'
import { leaveQuery } from '../../../graphql/leave/query/item'
import { leavesQuery } from '../../../graphql/leave/query/list'
import {
  AllPrivilege,
  Employee,
  LeaveQueryQuery,
  LeaveQueryVariables,
  LeavesQueryQuery,
  LeavesQueryVariables,
  RemoveLeaveMutation,
  RemoveLeaveVariables,
  Role,
  UpdateLeaveMutation,
  UpdateLeaveVariables,
} from '../../../graphql/types'
import {
  ConfirmButtonDropdownMenu,
  ConfirmButtonProps,
  OnceButton,
  OnceButtonProps,
} from '../../components/common/button'
import FormLeave, {
  FormGeneralInitialValues,
} from '../../components/form/leave'
import Page, { Section } from '../../components/layout/Page'
import EmployeeLink from '../../components/link/employee'
import LeaveMenu from '../../components/pageHeader/leave'
import { authContext } from '../../context'
import { useLoadingLayer } from '../../helpers/hooks'
import { getUserEntityIdFromAuth, isItemOwner, isRole } from '../../utils'

type SubmitButtonProps = OnceButtonProps & {
  isVisible: boolean
}

const SubmitButton = ({
  disabled,
  isVisible,
  label,
  loading,
  onClick,
}: SubmitButtonProps) => {
  if (!isVisible) {
    return null
  }

  return (
    <OnceButton
      disabled={disabled}
      label={label}
      loading={loading}
      onClick={onClick}
      style={{ marginRight: 16 }}
      type='primary'
    />
  )
}

interface HandleApproveArgs {
  id: string
  isApproved: boolean
}

const BackLinkButton = (props: ButtonProps) => (
  <Button {...props}>回請假清單</Button>
)

interface RouteProps {
  leaveId: string
}
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
}
export const LeaveDetail = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const location = useLocation<any>()
  const match = useRouteMatch<RouteProps>()
  const fromRoute = location.state?.from ? location.state.from : '/leaves'
  const leaveId = match.params.leaveId
  const auth = useContext(authContext)
  const { loading, tip, setLoadingLayer } = useLoadingLayer({
    loading: true,
    tip: '載入中...',
  })
  const [update] = useMutation<UpdateLeaveMutation, UpdateLeaveVariables>(
    updateLeaveMutation
  )
  const [remove] = useMutation<RemoveLeaveMutation, RemoveLeaveVariables>(
    removeLeaveMutation
  )

  const myEmployeeId = getUserEntityIdFromAuth(auth)

  // 拉該假勤資料
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const {
    data,
    loading: queryLoading,
    refetch,
  } = useQuery<LeaveQueryQuery, LeaveQueryVariables>(leaveQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (!data?.leave) {
        toErrorPage({
          message: '不存在的假單',
          redirect: {
            name: '假單總覽',
            url: `/employees/${myEmployeeId}/leaves`,
          },
        })
      }
    },
    onError: (error) => {
      toErrorPage({
        message: error.message,
        redirect: {
          name: '假單總覽',
          url: `/employees/${myEmployeeId}/leaves`,
        },
      })
    },
    variables: {
      id: leaveId,
    },
  })

  useEffect(() => {
    setLoadingLayer({ loading: false, tip: '' })
  }, [queryLoading])

  const handleSubmit = async () => {
    setLoadingLayer({ loading: true, tip: '更新中...' })
    try {
      const value = await form.getFieldsValue()
      const { startDate, from, days, type, reason } = value as any
      const payload: any = {
        ...LeaveTools.getDatesFromDateField(startDate, days, from),
        type,
        reason,
      }
      await update({
        variables: { id: leaveId, payload },
        update: (cache, { data }) => {
          if (data?.updateLeave) {
            setLoadingLayer({ loading: false, tip: '' })

            message.info('已更新假單')
            refetch()
          }
        },
      })
    } catch (error) {
      if (error?.message) {
        message.info(error?.message)
      } else {
        console.log(error)
      }
    }
    setLoadingLayer({ loading: false, tip: '載入中...' })
  }

  const handleRemove = async () => {
    setLoadingLayer({ loading: true, tip: '刪除中...' })
    await remove({
      variables: {
        id: leaveId,
      },
      update: (cache, { data }) => {
        if (data) {
          // 從 cache 讀之前的 leaves 列表資料，然後修改
          try {
            const query = leavesQuery
            const variables = {
              query: {},
              page: 1,
              limit: 100,
              sort: '-startDate',
            }
            const previous = cache.readQuery<
              LeavesQueryQuery,
              LeavesQueryVariables
            >({ query, variables })
            if (previous?.leaves) {
              const newdata = {
                leaves: {
                  ...previous!.leaves!,
                  docs: (previous?.leaves.docs ?? []).filter(
                    (item) => item.id !== leaveId
                  ),
                  total: previous?.leaves.total ?? 1 - 1,
                },
              }
              cache.writeQuery({ query, variables, data: newdata })
            }
          } catch (err) {
            // 如果之前沒有發過 cache.readQuery 所要查詢的 query
            // cache.readQuery 會 throw error
            // 但不影響整體流程所以不處理
          }
          message.info('已刪除假單')
          history.push(fromRoute)
        }
      },
    })
  }

  const handleApprove = async ({ id, isApproved }: HandleApproveArgs) => {
    const value = await form.getFieldsValue()
    const { startDate, from, days, type, reason } = value as any
    const payload: any = {
      ...LeaveTools.getDatesFromDateField(startDate, days, from),
      type,
      reason,
    }

    return update({
      variables: {
        id,
        payload: {
          ...payload,
          isApproved,
        },
      },
      update: (cache, { data }) => {
        // or use writeQuery
        if (data?.updateLeave) {
          cache.writeFragment({
            id: `Leave:${id}`,
            fragment: gql`
              fragment LeaveInfoo on Leave {
                isApproved
              }
            `,
            data: {
              __typename: 'Leave',
              isApproved: data.updateLeave.isApproved,
            },
          })
        }
        message.info('已更新假單')
        history.push(fromRoute)
      },
    })
  }

  if (queryLoading) {
    return <Page loading />
  }

  if (!data?.leave) {
    return null
  }

  const leaveItem = data.leave

  const isGod = isRole(Role.God, auth)
  const isOwner = isItemOwner(leaveItem.employee.id, auth)
  const isApprover = includes(
    myEmployeeId,
    map((x: Employee) => x.id, leaveItem.employee.approvers as Employee[])
  )

  // 可以更改的只有 god + 自己
  const canUpdate = isGod || isOwner

  // 可以審核的只有 god + approver
  const canApprove = isGod || isApprover

  const confirmButtonDropdownMenuItemsProps: ConfirmButtonProps[] = [
    {
      label: '刪除',
      modalProps: {
        onOk: handleRemove,
      },
      requiredInputText: '刪除假單',
      requiredPrivilege: AllPrivilege.LeaveDelete,
    },
  ]
  const startDate = moment(leaveItem.startDate)
  const endDate = moment(leaveItem.endDate)
  const formInitialValues: FormGeneralInitialValues = {
    startDate: startDate,
    from: LeaveTools.getAMOrPM(startDate),
    type: leaveItem.type,
    days: LeaveTools.getLeaveDays(startDate, endDate),
    reason: leaveItem.reason,
  }
  const maxRemainAnnual = pathOr(
    30,
    ['leaveStatus', 'remainAnnual'],
    leaveItem.employee
  )

  return (
    <Page
      header={<LeaveMenu item={data.leave.id} />}
      loading={loading}
      loadingComponent={<Spin size='large' tip={tip} />}
    >
      <Section>
        <Form.Item label='員工' {...formItemLayout}>
          <EmployeeLink item={leaveItem.employee} />
        </Form.Item>

        <FormLeave
          initialValues={formInitialValues}
          form={form}
          isApproved={leaveItem.isApproved}
          maxRemainAnnual={maxRemainAnnual}
        />
        <Form.Item label='狀態' {...formItemLayout}>
          <div>{leaveItem.isApproved ? '已審核' : '待審核'}</div>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 16, offset: 6 }}>
          <SubmitButton
            disabled={leaveItem.isApproved}
            isVisible={canUpdate}
            label={leaveItem.isApproved ? '已通過審核無法更改' : '更新資料'}
            loading={loading}
            onClick={handleSubmit}
          />
          <SubmitButton
            isVisible={canApprove}
            label={leaveItem.isApproved ? '取消審核' : '審核'}
            loading={loading}
            onClick={() =>
              handleApprove({
                id: leaveItem.id,
                isApproved: !leaveItem.isApproved,
              })
            }
          />
          <BackLinkButton
            style={{ marginRight: 16 }}
            onClick={() => history.push(fromRoute)}
          />
          <ConfirmButtonDropdownMenu
            confirmButtonDropdownMenuItemsProps={
              confirmButtonDropdownMenuItemsProps
            }
          />
        </Form.Item>
      </Section>
    </Page>
  )
}

export default LeaveDetail

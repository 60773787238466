import React from 'react'
import styled from 'styled-components'

import { TabKey, accountSubRoutes } from './TabConfig'

interface Props {
  tabKey: TabKey
}

const Title = styled.div`
  font-size: 20px;
  line-height: 1.4;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 8px;
`
const TabTitle = (props: Props) => {
  const { tabKey } = props

  return <Title>{accountSubRoutes[tabKey].text}</Title>
}

export default TabTitle

import { gql } from '@apollo/client'
import { AntSorterType, TableQuery } from '@sov/common'
import { Link } from '@sov/ui'
import { Table } from 'antd'
import { ColumnProps, TableProps } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'

import { EventTableFragment, EventType } from '../../../../graphql/types'
import ClinicLink from '../../link/clinic'
import DoctorLink from '../../link/doctor'
import EmployeeLink from '../../link/employee'

// 這個 filter 是多餘的，但之後如果要加就可以直接放這 (跟其他地方慣例相同)
export type EventFilterType = any
export type EventSorterField = 'created'

const { PatientLink, StageLink } = Link

interface Props<T> {
  source?: {
    docs: T[]
    page?: number
    total: number
    limit: number
  }
  loading?: boolean
  filterInfo?: EventFilterType
  sortInfo?: AntSorterType<EventSorterField>
  handleChange: TableProps<T>['onChange']
}

const defaultSource = {
  docs: [],
  page: 1,
  total: 1,
  limit: 10,
}

type TableFields = EventTableFragment

function EventTable<T extends TableFields>(props: Props<T>) {
  const { sortInfo, source = defaultSource, loading, handleChange } = props
  const columns: ColumnProps<T>[] = [
    {
      title: '#',
      width: 10,
      key: '#',
      render: (text, record, index) => <div>{index + 1}</div>,
    },
    {
      title: '新增日期',
      width: 60,
      dataIndex: 'created',
      key: 'created',
      sorter: true,
      sortOrder: sortInfo?.field === 'created' ? sortInfo.order : undefined,
      render: (text, record) =>
        `${moment(record.created).format('YYYY-MM-DD HH:mm')}`,
    },
    {
      title: '人員',
      width: 60,
      dataIndex: 'creator',
      key: 'creator',
      render: (text, record) => {
        switch (record.creator.__typename) {
          case 'Employee':
            return <EmployeeLink item={record.creator} />
          case 'Clinic':
            return <ClinicLink item={record.creator} />
          case 'Doctor':
            return <DoctorLink item={record.creator} />
          default:
            return null
        }
      },
    },
    {
      title: '事件',
      width: 200,
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => {
        switch (record.type) {
          case EventType.CreatePatient:
            return (
              record.data.patient && (
                <>
                  <span>新增了病患 </span>
                  <PatientLink item={record.data.patient} />
                </>
              )
            )
          case EventType.CreateStage:
            return (
              record.data.stage && (
                <>
                  <span>新增了工單 </span>
                  <StageLink item={record.data.stage} />
                </>
              )
            )
          case EventType.CompleteStage:
            return (
              record.data.stage && (
                <>
                  <span>完成了工單 </span>
                  <StageLink item={record.data.stage} />
                </>
              )
            )
          case EventType.CreateOrder:
            return (
              <>
                <span>新增了訂單</span>
              </>
            )
          case EventType.UpdateCurrentStage:
            return (
              record.data.stage && (
                <>
                  <span>設定了主要報告 </span>
                  <StageLink item={record.data.stage} />
                </>
              )
            )
          default:
            return null
        }
      },
    },
  ]

  const { docs, page, total, limit } = source

  return (
    <Table<T>
      rowKey='id'
      size='small'
      columns={columns as any}
      dataSource={docs}
      loading={loading}
      locale={{ emptyText: '尚無任何事件' }}
      onChange={handleChange}
      pagination={TableQuery.getAntdPagination({
        page: page || 1,
        total,
        limit,
      })}
    />
  )
}

EventTable.fragment = gql`
  fragment EventTable on Event {
    id
    type
    creator {
      id
      name
    }
    data {
      patient {
        ...PatientLink
      }
      stage {
        ...StageLink
      }
      order {
        id
      }
    }
    created
  }
  ${PatientLink.fragment}
  ${StageLink.fragment}
`

export default EventTable

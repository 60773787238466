import { Column } from '@ant-design/charts'
import { QuestionCircleFilled } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import {
  filteredWorkingTaskTypeList,
  getStageTypeFromTaskType,
} from '@sov/common'
import { StageType, TaskType } from '@sov/common/src/types'
import { Spin, Tooltip } from 'antd'
import moment, { Moment } from 'moment'
import business from 'moment-business'
import { dropRepeats, isNil } from 'ramda'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  NextTaskAssigneeWorkingTaskStatisticQuery,
  NextTaskAssigneeWorkingTaskStatisticQueryVariables,
  NextTaskAssigneeWorkingTaskStatisticWorkingTaskStatistic,
} from '../../../../../../graphql/types'
import EmployeeLink from '../../../../../components/link/employee'
import CustomizedRangePicker, {
  CustomizedRangePickerProps,
} from './CustomizedRangePicker'
import TooltipTitle from './TooltipTitle'

const stageTypeListOfTaskStatistic = dropRepeats(
  filteredWorkingTaskTypeList.map(
    (taskType) => getStageTypeFromTaskType(taskType)!
  )
)

const stageTypeToColor: Record<
  typeof stageTypeListOfTaskStatistic[number],
  string
> = {
  [StageType.Design]: 'rgba(214, 124, 181, 1)',
  [StageType.Eval]: 'rgba(227, 105, 106, 1)',
  [StageType.Mold]: 'rgba(67, 186, 213, 1)',
  [StageType.Print]: 'rgba(60, 55, 166, 1)',
}

type ChartItem = Pick<
  NextTaskAssigneeWorkingTaskStatisticWorkingTaskStatistic,
  'count' | 'taskType'
> & {
  ownerName: string
  stageType: StageType
}

const xField: keyof ChartItem = 'ownerName'
const yField: keyof ChartItem = 'count'
const groupField: keyof ChartItem = 'stageType'
const seriesField: keyof ChartItem = 'taskType'

const RangeDescription = styled.div`
  span {
    color: rgba(0, 0, 0, 0.45);
  }
`

/**
 * ant design y 軸間距當最大值小於等於 1 的時候會出現小數點
 * 這邊做一些處理避免出現這種情況
 */
const getTickInterval = (originalMax: number) => {
  return originalMax <= 1 ? 1 : undefined
}

const defaultOffsetDay = 7

const defaultExpectedShippingDateInterval = [
  moment().startOf('day'),
  // @ts-ignore
  business.addWeekDays(moment().endOf('day'), defaultOffsetDay),
] as [Moment, Moment]

const nextTaskAssignerWorkingTaskStatisticQuery = gql`
  query NextTaskAssigneeWorkingTaskStatistic(
    $workingTaskQuery: WorkingTaskStatisticQuery!
  ) {
    workingTaskStatistic(query: $workingTaskQuery) {
      owner {
        ...EmployeeLink
      }
      taskType
      count
    }
  }
  ${EmployeeLink.fragment}
`

type Props = {
  currentTask: any
}

const TaskStatistics = (props: Props) => {
  const { currentTask } = props
  const { t } = useTranslation()
  const [expectedShippingDateInterval, setExpectedShippingDateInterval] =
    useState(defaultExpectedShippingDateInterval)
  const expectedShippingDateISOStringInterval =
    expectedShippingDateInterval.map((date) => date.toISOString())

  const handleDateIntervalChange: CustomizedRangePickerProps['handleDateIntervalChange'] =
    (dates) => {
      if (isNil(dates)) {
        return
      }

      const updateDates = dates as [Moment, Moment]
      setExpectedShippingDateInterval(updateDates)
    }

  const {
    data: nextTaskAssignerWorkingTaskStatisticQueryData,
    loading: nextTaskAssignerWorkingTaskStatisticQueryLoading,
  } = useQuery<
    NextTaskAssigneeWorkingTaskStatisticQuery,
    NextTaskAssigneeWorkingTaskStatisticQueryVariables
  >(nextTaskAssignerWorkingTaskStatisticQuery, {
    variables: {
      workingTaskQuery: {
        /**
         * 沒有 nextTask 的時候不會觸發 query (因為滿足 skip 條件)
         * 但是以下這行程式碼還是會執行
         * 加上 optional chaining 避免 runtime error
         * 加上符合 type 的 default value 使其通過 type check
         */
        assignableEmployeeTaskType:
          currentTask.nextTask?.type ?? TaskType.CompleteAlign,
        filteredTaskTypeList: filteredWorkingTaskTypeList,
        startDate: expectedShippingDateISOStringInterval[0],
        endDate: expectedShippingDateISOStringInterval[1],
      },
    },
  })

  const workingTaskStatisticList =
    nextTaskAssignerWorkingTaskStatisticQueryData?.workingTaskStatistic ?? []

  const chartItemList = workingTaskStatisticList.map(
    (workingTaskStatistic) => ({
      ownerName: workingTaskStatistic.owner.name,
      count: workingTaskStatistic.count,
      taskType: workingTaskStatistic.taskType,
      stageType: getStageTypeFromTaskType(workingTaskStatistic.taskType)!,
    })
  ) as ChartItem[]

  const sortedChartItemList = chartItemList.sort((a, b) =>
    a.count > b.count ? -1 : 1
  )

  const maxCount = Math.max(...sortedChartItemList.map((x) => x.count))

  /**
   * ant design 堆疊分組柱狀圖
   * 參考官方範例：https://charts.ant.design/demos/column/#%E5%A0%86%E5%8F%A0%E5%88%86%E7%BB%84%E6%9F%B1%E7%8A%B6%E5%9B%BE
   * codesandbox 範例: https://codesandbox.io/s/ant-design-chartsstack-and-group-column-with-zero-stuffing-transformation-1ml2j
   */
  const config = {
    data: sortedChartItemList,
    xField,
    yField,
    isGroup: true,
    groupField,
    isStack: true,
    seriesField,
    xAxis: {
      label: {
        formatter: (text) => {
          const ownerName = text as ChartItem[typeof xField]
          const maxLengthOfXAxisLabel = 5
          const truncatedOwnerName =
            ownerName.length > maxLengthOfXAxisLabel
              ? `${ownerName.substring(0, maxLengthOfXAxisLabel)}...`
              : ownerName
          return truncatedOwnerName
        },
      },
    },
    yAxis: {
      tickInterval: getTickInterval(maxCount),
    },
    legend: {
      position: 'right-top',
      itemName: {
        formatter: (text) => {
          const taskType = text as ChartItem[typeof seriesField]
          const stageType = getStageTypeFromTaskType(taskType)!
          const translatedTaskType = t(`task.type.${taskType}`)
          const translatedStageType = t(`stage.type.${stageType}`)
          const itemName = `${translatedStageType}工單 (${translatedTaskType})`
          return itemName
        },
      },
    },
    color: (args) => {
      /** 這邊從 args 只能取到被傳入 data 陣列中每個元素的 seriesField，沒辦法拿到傳入資料的全部欄位 */
      const { taskType } = args as Pick<ChartItem, typeof seriesField>
      const stageType = getStageTypeFromTaskType(taskType)!
      const color = stageTypeToColor[stageType]
      return color
    },
    tooltip: {
      formatter: (args) => {
        /** 這邊從 args 只能取到被傳入 data 陣列中每個元素的 xField, yField, seriesField，沒辦法拿到傳入資料的全部欄位 */
        const { count, taskType } = args as Pick<
          ChartItem,
          typeof xField | typeof yField | typeof seriesField
        >
        const stageType = getStageTypeFromTaskType(taskType)!
        const translatedTaskType = t(`task.type.${taskType}`)
        const translatedStageType = t(`stage.type.${stageType}`)
        const name = `${translatedStageType}工單 (${translatedTaskType})`
        return { name, value: count }
      },
    },
  }

  return (
    <>
      {nextTaskAssignerWorkingTaskStatisticQueryLoading && <Spin />}
      {!nextTaskAssignerWorkingTaskStatisticQueryLoading && (
        <div>
          <RangeDescription>
            <span>預計出貨在</span>
            <CustomizedRangePicker
              defaultValue={expectedShippingDateInterval}
              handleDateIntervalChange={handleDateIntervalChange}
            />
            <span>的工單數量</span>
            <Tooltip
              placement='right'
              title={
                <TooltipTitle taskTypeList={filteredWorkingTaskTypeList} />
              }
            >
              <QuestionCircleFilled
                style={{
                  marginLeft: '10px',
                  fontSize: '16px',
                  color: 'rgba(0, 0, 0, 0.2)',
                }}
              />
            </Tooltip>
          </RangeDescription>
          <Column {...(config as any)} />
        </div>
      )}
    </>
  )
}

export default TaskStatistics

import { Column } from '@ant-design/charts'
import { gql, useQuery } from '@apollo/client'
import { Card, Spin } from 'antd'
import moment, { Moment } from 'moment'
import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import {
  BarChartDataQuery,
  BarChartDataQueryVariables,
  PatientSource,
} from '../../../../graphql/types'
import CustomizedRangePicker from './CustomizedRangePicker'

const BarChartDescription = styled.div`
  margin-top: 8px;
`

interface ClinicPatientCreatedNumberTableProps {
  columns: number
}

const ClinicPatientCreatedNumberTable = styled.div<ClinicPatientCreatedNumberTableProps>`
  margin-top: 8px;
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 72px)`};
  grid-template-rows: repeat(4, 1fr);
  grid-auto-flow: column;
  overflow: scroll;
`

const TableCell = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
`

const TableDescription = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
`

const defaultInterval: [Moment, Moment] = [
  moment().subtract(1, 'quarter'),
  moment(),
]

const barChartDataQuery = gql`
  query BarChartData($id: ID!, $query: GroupedCreatedPatientCountMapListQuery) {
    groupedCreatedPatientCountMapList(id: $id, query: $query) {
      clinic {
        id
        name
      }
      source
      createdPatientCount
    }
  }
`

const Date = styled.span`
  color: #1890ff;
  margin: 0 4px;
`

interface RouteParams {
  employeeId: string
}

const CreatedPatientCountCard = () => {
  const match = useRouteMatch<RouteParams>()
  const { employeeId } = match.params
  const [createdInterval, setCreatedInterval] =
    useState<[Moment, Moment]>(defaultInterval)

  const { data, loading } = useQuery<
    BarChartDataQuery,
    BarChartDataQueryVariables
  >(barChartDataQuery, {
    errorPolicy: 'none',
    variables: {
      id: employeeId,
      query: {
        createdInterval,
      },
    },
  })

  const countList = data?.groupedCreatedPatientCountMapList ?? []

  const totalCount = countList
    .filter((x) => !x.source)
    .reduce((acc, cur) => acc + cur.createdPatientCount, 0)

  const refCount = countList
    .filter((x) => x.source === PatientSource.Sov)
    .reduce((acc, cur) => acc + cur.createdPatientCount, 0)

  const selfCount = countList
    .filter((x) => x.source === PatientSource.Clinic)
    .reduce((acc, cur) => acc + cur.createdPatientCount, 0)

  const countListByClinic = countList
    .reduce((acc, cur) => {
      const index = acc.findIndex((x: any) => x.clinic.id === cur.clinic.id)

      if (index === -1) {
        // eslint-disable-next-line prefer-const
        let inserted = { clinic: cur.clinic } as any

        if (cur.source === PatientSource.Sov) {
          inserted.refCount = cur.createdPatientCount
        } else if (cur.source === PatientSource.Clinic) {
          inserted.selfCount = cur.createdPatientCount
        }
        return [...acc, inserted]
      } else {
        // eslint-disable-next-line prefer-const
        let updated = { ...acc[index] }

        if (cur.source === PatientSource.Sov) {
          updated.refCount = cur.createdPatientCount
        } else if (cur.source === PatientSource.Clinic) {
          updated.selfCount = cur.createdPatientCount
        }
        return [...acc.slice(0, index), updated, ...acc.slice(index + 1)]
      }
    }, [] as any[])
    .sort((a, b) =>
      a.refCount + a.selfCount > b.refCount + b.selfCount ? -1 : 1
    )

  console.log(countListByClinic)

  return (
    <Card
      title='診所新增病患數'
      extra={
        <CustomizedRangePicker
          value={createdInterval}
          onChange={setCreatedInterval}
        />
      }
    >
      {loading ? (
        <Spin />
      ) : (
        <>
          <Column
            {...{
              style: { padding: '30px' },
              data: countListByClinic.slice(0, 10).flatMap((x) => [
                {
                  type: '轉介',
                  name: x.clinic.name,
                  count: x.refCount,
                },
                {
                  type: '自約',
                  name: x.clinic.name,
                  count: x.selfCount,
                },
              ]),
              xField: 'name',
              yField: 'count',
              seriesField: 'type',
              isStack: true,
              color: (_ref) => {
                if (_ref.type === '自約') {
                  return '#E11854'
                } else {
                  return '#18C9E1'
                }
              },
            }}
          />
          {createdInterval ? (
            <BarChartDescription>
              於
              <Date>
                {moment(createdInterval[0]).format('YYYY/MM/DD')} -{' '}
                {moment(createdInterval[1]).format('YYYY/MM/DD')}
              </Date>
              區間，總共新增 {totalCount} 名病患，包含轉介病患 {refCount}{' '}
              人，自約病患 {selfCount} 人。
            </BarChartDescription>
          ) : (
            '請選擇區間'
          )}
          <TableDescription>
            長條圖僅顯示前 10 名診所，詳細資料請參照以下表格
          </TableDescription>
          {countList.length !== 0 && (
            <ClinicPatientCreatedNumberTable columns={countList.length + 1}>
              <>
                <TableCell>病患數/診所</TableCell>
                <TableCell>合計病患</TableCell>
                <TableCell>轉介病患</TableCell>
                <TableCell>自約病患</TableCell>
              </>
              {countListByClinic.map((x) => (
                <React.Fragment key={x.clinic.id}>
                  <TableCell>{x.clinic.name}</TableCell>
                  <TableCell>{x.refCount + x.selfCount}</TableCell>
                  <TableCell>{x.refCount}</TableCell>
                  <TableCell>{x.selfCount}</TableCell>
                </React.Fragment>
              ))}
            </ClinicPatientCreatedNumberTable>
          )}
        </>
      )}
    </Card>
  )
}

export default CreatedPatientCountCard

import { Evolver, evolve } from 'ramda'

import {
  CreatePatientInput,
  UpdatePatientInput,
} from '../../../../graphql/types'
import { FormGeneralFields } from './Basic'
import { FormDoctorInstructionFields } from './DoctorInstruction'
import { FormIntraOralFields } from './IntraOral'
import { FormPhotoFields, mapItemPhotosToResponses } from './Photo'
import { FormProfileFields } from './Profile'
import { FormSymptomFields } from './Symptoms'

export * from './DoctorInstruction'
export * from './Basic'
export * from './IntraOral'
export * from './Photo'
export * from './Profile'
export * from './Symptoms'
export * from './Payment'

interface FormTransformer extends Evolver {
  photos: (value: any) => any
}

export type PatientForm = FormGeneralFields &
  FormPhotoFields &
  FormProfileFields &
  FormIntraOralFields &
  FormSymptomFields &
  FormDoctorInstructionFields

export const getPayloadTransformer = (
  formValue: PatientForm
): CreatePatientInput & UpdatePatientInput => {
  return evolve<FormTransformer, PatientForm>(
    {
      photos: mapItemPhotosToResponses,
    },
    formValue
  )
}

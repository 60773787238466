import Icon from '@ant-design/icons'
import React from 'react'

const Svg = () => (
  <svg
    width='164'
    height='164'
    viewBox='0 0 164 164'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g opacity='0.3'>
      <circle cx='82' cy='82' r='82' fill='#C4C4C4' />
      <circle
        cx='82'
        cy='82'
        r='82'
        fill='url(#paint0_linear)'
        fillOpacity='0.8'
      />
    </g>
    <g opacity='0.5'>
      <circle cx='82' cy='82' r='75' fill='#C4C4C4' />
      <circle
        cx='82'
        cy='82'
        r='75'
        fill='url(#paint1_linear)'
        fillOpacity='0.8'
      />
    </g>
    <circle cx='82' cy='82' r='67' fill='#C4C4C4' />
    <circle
      cx='82'
      cy='82'
      r='67'
      fill='url(#paint2_linear)'
      fillOpacity='0.8'
    />
    <g filter='url(#filter0_d)'>
      <rect
        x='51'
        y='105.969'
        width='62'
        height='7.75'
        rx='2.38462'
        fill='#FAAD14'
      />
    </g>
    <mask
      id='mask0'
      mask-type='alpha'
      maskUnits='userSpaceOnUse'
      x='60'
      y='44'
      width='44'
      height='66'
    >
      <path
        // eslint-disable-next-line max-len
        d='M79.2255 46.0868C80.074 43.3652 83.926 43.3652 84.7745 46.0868L103.476 106.072C104.06 107.944 102.662 109.844 100.702 109.844H63.2983C61.3385 109.844 59.9405 107.944 60.5238 106.072L79.2255 46.0868Z'
        fill='#FAAD14'
      />
    </mask>
    <g mask='url(#mask0)'>
      <path
        // eslint-disable-next-line max-len
        d='M79.227 46.3277C80.0786 43.6123 83.9215 43.6123 84.7731 46.3277L103.532 106.142C104.119 108.014 102.721 109.918 100.759 109.918H63.2408C61.2789 109.918 59.8807 108.014 60.4678 106.142L79.227 46.3277Z'
        fill='#FAAD14'
      />
      <rect
        x='47.125'
        y='86.5938'
        width='67.8125'
        height='12.5938'
        fill='white'
      />
      <rect
        x='48.0938'
        y='64.3125'
        width='67.8125'
        height='12.5938'
        fill='white'
      />
    </g>
    <rect x='33' y='94.4849' width='2.09091' height='19.5152' fill='#C4C4C4' />
    <rect
      x='53.9092'
      y='94.4849'
      width='2.09091'
      height='19.5152'
      fill='#C4C4C4'
    />
    <rect
      x='35.0908'
      y='95.8788'
      width='18.8182'
      height='9.06061'
      fill='black'
      fillOpacity='0.1'
    />
    <path
      d='M35.7878 104.939H39.2727L42.0606 95.8788H38.5757L35.7878 104.939Z'
      fill='black'
      fillOpacity='0.1'
    />
    <path
      d='M41.3635 104.939H44.8484L47.6363 95.8788H44.1514L41.3635 104.939Z'
      fill='black'
      fillOpacity='0.1'
    />
    <path
      d='M46.9395 104.939H50.4243L53.2122 95.8788H49.7273L46.9395 104.939Z'
      fill='black'
      fillOpacity='0.1'
    />
    <defs>
      <filter
        id='filter0_d'
        x='41.4615'
        y='101.2'
        width='81.0769'
        height='26.8269'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='4.76923' />
        <feGaussianBlur stdDeviation='4.76923' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow'
          result='shape'
        />
      </filter>
      <linearGradient
        id='paint0_linear'
        x1='21'
        y1='164'
        x2='104.5'
        y2='-3.31909e-07'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.571675' stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0.5' />
      </linearGradient>
      <linearGradient
        id='paint1_linear'
        x1='26.2073'
        y1='157'
        x2='274.53'
        y2='-58.3963'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint2_linear'
        x1='32.1585'
        y1='149'
        x2='253.994'
        y2='-43.4207'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
    </defs>
  </svg>
)

const DevelopingIcon = (props) => <Icon {...props} component={Svg} />

export default DevelopingIcon

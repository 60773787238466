import { Form } from '@ant-design/compatible'
import { gql } from '@apollo/client'
import StageName from '@sov/ui/src/components/StageName'
import { Select } from 'antd'
import { find, map, prop, propEq } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BindingStagesQuery, StageType } from '../../../../graphql/types'
import { ColumnKeys, IOrder } from '../Order'
import { EditContextType } from './TableRow'

const getBindingInitialValue = (order: IOrder, fieldStageType: StageType) => {
  if (!order.stageProvide) {
    return []
  }
  const provide = find(propEq('stageType', fieldStageType), order.stageProvide)
  return provide ? map(prop('id'), provide.stages) : []
}

const getBindingRule = (order: IOrder, fieldStageType: StageType) => {
  const getOrderStageCount = (stageType: StageType) => {
    if (!order.stageOrder) {
      return 0
    }
    const stageOrder = find(propEq('stageType', stageType), order.stageOrder)
    return stageOrder ? stageOrder.number : 0
  }

  return [
    {
      validator: (rule, value, cb) =>
        value && value.length > getOrderStageCount(fieldStageType)
          ? cb('綁定的數量和訂單不符')
          : cb(),
    },
  ]
}

interface BindingStageSelectProps {
  form: EditContextType['form']
  stageType: StageType
  order: IOrder
  stages: NonNullable<BindingStagesQuery['stages']>['docs']
  loading: boolean
}

const BindingStageSelect = (props: BindingStageSelectProps) => {
  const { form, stageType, order, stages, loading } = props
  const { t } = useTranslation()

  return (
    <Form.Item
      key={stageType}
      label={t(`stage.type.${stageType}`)}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      {form.getFieldDecorator(`${ColumnKeys.STAGEPROVIDE}.${stageType}`, {
        initialValue: getBindingInitialValue(order, stageType),
        rules: getBindingRule(order, stageType),
      })(
        <Select
          mode='multiple'
          filterOption={false}
          loading={loading}
          dropdownMatchSelectWidth={false}
        >
          {map(
            (stage) => (
              <Select.Option key={stage.id} value={stage.id}>
                <StageName item={stage} />
                {stage.currentTask &&
                  ` - ${t(`task.type.${stage.currentTask.type}`)}`}
              </Select.Option>
            ),
            stages
          )}
        </Select>
      )}
    </Form.Item>
  )
}

BindingStageSelect.fragments = {
  BindingStageSelect: gql`
    fragment BindingStageSelect on Stage {
      ...StageName
      currentTask {
        type
      }
    }
    ${StageName.fragment}
  `,
}

export default BindingStageSelect

import { gql } from '@apollo/client'
import { getEmptyText } from '@sov/common'
import React from 'react'
import { Link } from 'react-router-dom'

import { EmployeeLinkFragment } from '../../../../graphql/types'

interface Props {
  item?: EmployeeLinkFragment
  target?: string
  style?: { [key: string]: string | number }
  children?: React.ReactNode
  isSelf?: boolean
}

const EmployeeLink = ({
  item,
  target = '_self',
  style = { color: 'black' },
  children,
}: Props) => {
  if (item) {
    const id = item.id || ''

    return (
      <Link target={target} style={style} to={`/employees/${id}`}>
        {children || item.name}
      </Link>
    )
  } else {
    return <span>{children || getEmptyText()}</span>
  }
}

EmployeeLink.fragment = gql`
  fragment EmployeeLink on Employee {
    id
    name
  }
`

export default EmployeeLink

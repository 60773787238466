import { gql, useMutation, useQuery } from '@apollo/client'
import { getNotificationTypesFromCategory } from '@sov/common'
import { Notification } from '@sov/ui'
import { Col, Form, Row, message } from 'antd'
import React, { useContext } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import {
  AllPrivilege,
  NotificationCategory,
  NotificationSettingFormFragment,
  NotificationSettingQuery,
  NotificationSettingQueryVariables,
  NotificationType,
  UpdateNotificationSettingMutation,
  UpdateNotificationSettingMutationVariables,
} from '../../../../../../graphql/types'
import { authContext } from '../../../../../context'
import UnAuthorizedPage from '../../../../employee/Dashboard/UnAuthorizedPage'

const Wrapper = styled.div`
  background-color: white;
`
const displayNotificationSettingListByCategory: Partial<
  Record<NotificationCategory, NotificationType[]>
> = {
  [NotificationCategory.Task]: getNotificationTypesFromCategory(
    NotificationCategory.Task
  ),
  [NotificationCategory.System]: getNotificationTypesFromCategory(
    NotificationCategory.System
  ),
  [NotificationCategory.Patient]: getNotificationTypesFromCategory(
    NotificationCategory.Patient
  ),
  [NotificationCategory.Order]: getNotificationTypesFromCategory(
    NotificationCategory.Order
  ),
  [NotificationCategory.Track]: getNotificationTypesFromCategory(
    NotificationCategory.Track
  ),
  [NotificationCategory.Report]: getNotificationTypesFromCategory(
    NotificationCategory.Report
  ),
}
const notificationSettingQuery = gql`
  query NotificationSetting($id: ID!) {
    account(id: $id) {
      id
      notificationSetting {
        ...NotificationSettingForm
      }
    }
  }
  ${Notification.SettingForm.fragment}
`

const updateNotificationSettingMutation = gql`
  mutation UpdateNotificationSetting($id: ID!, $payload: UpdateAccountInput!) {
    updateAccount(id: $id, payload: $payload) {
      notificationSetting {
        ...NotificationSettingForm
      }
    }
  }
  ${Notification.SettingForm.fragment}
`
interface RouteParams {
  accountId: string
}

const AccountSettingsForm = () => {
  const match = useRouteMatch<RouteParams>()
  const auth = useContext(authContext)
  const { accountId } = match.params

  const [form] = Form.useForm()

  if (!auth) {
    return null
  }

  const canUserViewThisPage =
    auth?.privileges.includes(AllPrivilege.AccountUpdate) ||
    auth.id === accountId

  const { data, loading } = useQuery<
    NotificationSettingQuery,
    NotificationSettingQueryVariables
  >(notificationSettingQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    variables: {
      id: accountId,
    },
    skip: !canUserViewThisPage,
  })

  if (!canUserViewThisPage) {
    return <UnAuthorizedPage />
  }
  const [updateNotificationSetting] = useMutation<
    UpdateNotificationSettingMutation,
    UpdateNotificationSettingMutationVariables
  >(updateNotificationSettingMutation)

  const handleUpdate = async (
    notificationSetting: NotificationSettingFormFragment
  ) => {
    try {
      const id = accountId
      await updateNotificationSetting({
        variables: {
          id,
          payload: {
            notificationSetting,
          },
        },
        update: (cache, { data }) => {
          if (data) {
            message.success('已更新通知設定')
          }
        },
      })
    } catch (error) {
      message.error(`更新失敗: ${error.message}`)
    }
  }

  if (loading) {
    return null
  }

  const account = data?.account
  if (!account) {
    return null
  }

  return (
    <Wrapper>
      <Row>
        <Col offset={4} span={16}>
          <Notification.SettingForm
            form={form}
            notificationSetting={account.notificationSetting}
            handleUpdate={handleUpdate}
            displayNotificationSettingListByCategory={
              displayNotificationSettingListByCategory
            }
          />
        </Col>
      </Row>
    </Wrapper>
  )
}

export default AccountSettingsForm

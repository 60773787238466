import { gql } from '@apollo/client'
import { Menu } from 'antd'
import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'

import { EmployeeMenuFragment } from '../../../../graphql/types'
import BreadcrumbCreator from '../../layout/BreadcrumbCreator'
import Title from '../../layout/Title'

export enum EmployeeMenuKey {
  EMPLOYEE_DASHBOARD = 'employee-dashboard',
  EMPLOYEE_PATIENTS = 'employee-patients',
  EMPLOYEE_ORDERS = 'employee-orders',
  EMPLOYEE_STAGES = 'employee-stages',
  EMPLOYEE_TASKS = 'employee-tasks',
  EMPLOYEE_LEAVES = 'employee-leaves',
  EMPLOYEE_DETAIL = 'employee-detail',
}

interface Props {
  item: EmployeeMenuFragment | undefined
  selectedKeys: EmployeeMenuKey[]
  title?: ReactElement | string
  subtitle?: ReactElement | string
}

const EmployeeMenu = (props: Props) => {
  const { item, selectedKeys, title = item?.name, subtitle } = props

  if (!item) {
    return null
  }

  return (
    <div style={{ marginBottom: -16 }}>
      <BreadcrumbCreator
        routes={[
          { key: 'Home' },
          { key: 'EmployeeList' },
          {
            key: 'EmployeeDetail',
            render: () => <Link to={`/employees/${item.id}`}>{item.name}</Link>,
          },
        ]}
      />
      <Title
        route={{
          key: 'EmployeeDetail',
          render: () => title,
          renderSubtitle: () => subtitle,
        }}
      />
      <Menu mode='horizontal' selectedKeys={selectedKeys}>
        <Menu.Item key={EmployeeMenuKey.EMPLOYEE_DASHBOARD}>
          <Link to={`/employees/${item.id}/dashboard`}>儀錶板</Link>
        </Menu.Item>
        <Menu.Item key={EmployeeMenuKey.EMPLOYEE_PATIENTS}>
          <Link to={`/employees/${item.id}/patients`}>追蹤病患列表</Link>
        </Menu.Item>
        <Menu.Item key={EmployeeMenuKey.EMPLOYEE_ORDERS}>
          <Link to={`/employees/${item.id}/orders`}>追蹤訂單列表</Link>
        </Menu.Item>
        <Menu.Item key={EmployeeMenuKey.EMPLOYEE_STAGES}>
          <Link to={`/employees/${item.id}/stages`}>追蹤工單列表</Link>
        </Menu.Item>
        <Menu.Item key={EmployeeMenuKey.EMPLOYEE_TASKS}>
          <Link to={`/employees/${item.id}/tasks`}>個人任務列表</Link>
        </Menu.Item>
        <Menu.Item key={EmployeeMenuKey.EMPLOYEE_LEAVES}>
          <Link to={`/employees/${item.id}/leaves`}>個人假勤列表</Link>
        </Menu.Item>
        <Menu.Item key={EmployeeMenuKey.EMPLOYEE_DETAIL}>
          <Link to={`/employees/${item.id}`}>個人基本資料</Link>
        </Menu.Item>
      </Menu>
    </div>
  )
}

EmployeeMenu.fragments = {
  EmployeeMenu: gql`
    fragment EmployeeMenu on Employee {
      id
      name
    }
  `,
}

export default EmployeeMenu

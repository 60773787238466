import React from 'react'

import { OrderType } from '../../../../../graphql/types'
import RemodelRadioButtonGroup from './RemodelRadioButtonGroup'
import ReportRadioButtonGroup from './ReportRadioButtonGroup'
import { ModalType } from '..'

export type HandleInstructionChange = (instruction: string) => void
export interface RadioButtonGroupProps {
  handleInstructionChange: HandleInstructionChange
  modalType: ModalType
  selectedOrderType: OrderType
}

const RadioButtonGroup = (props: RadioButtonGroupProps) => {
  const { handleInstructionChange, modalType, selectedOrderType } = props

  if (modalType === ModalType.Edit) {
    return null
  }

  return (
    <>
      {selectedOrderType === OrderType.Remodel && (
        <RemodelRadioButtonGroup
          handleInstructionChange={handleInstructionChange}
        />
      )}
      {selectedOrderType === OrderType.Report && (
        <ReportRadioButtonGroup
          handleInstructionChange={handleInstructionChange}
        />
      )}
    </>
  )
}

export default RadioButtonGroup

import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form'
import { gql, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { SortOrder } from 'antd/lib/table/interface'
import {
  append,
  equals,
  find,
  findIndex,
  includes,
  insert,
  map,
  omit,
  pipe,
  prop,
  propEq,
  reject,
} from 'ramda'
import { useEffect, useState } from 'react'

import { ordersQuery } from '../../../graphql/order/query/list'
import {
  BindingStagesQuery,
  BindingStagesQueryVariables,
  OrdersQueryQuery,
  OrdersQueryQueryVariables,
  StageStatus,
  StageType,
} from '../../../graphql/types'
import { IOrder } from '../../components/table/Order'
import BindingCell from '../../components/table/Order/BindingCell'
import { OrderForm } from '../../components/table/Order/TableCells'
import {
  advanceSearch,
  AdvanceSearchOption,
  getEmptyOrder,
  getNewOrder,
  sortByAppointmentDate,
} from './utils'

const bindingStagesQuery = gql`
  query BindingStages(
    $query: StagesQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    stages(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...BindingCell
        patient {
          id
        }
      }
    }
  }
  ${BindingCell.fragments.BindingCell}
`
interface UseOrderParam {
  variables: OrdersQueryQueryVariables
  appointmentSortOrder?: SortOrder
  selectedOptions: AdvanceSearchOption[]
  shouldQueryBindStages: boolean
}

const useOrder = (param: UseOrderParam) => {
  const {
    variables,
    appointmentSortOrder,
    selectedOptions,
    shouldQueryBindStages,
  } = param
  const [orders, setOrders] = useState<IOrder[]>([])
  const [forms, setForms] = useState<WrappedFormUtils<OrderForm>[]>([])

  const clearOrders = () => {
    setOrders([])
    setForms([])
  }

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading, refetch } = useQuery<
    OrdersQueryQuery,
    OrdersQueryQueryVariables
  >(ordersQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables,
    onCompleted: (data) => {
      clearOrders()
      if (data.orders) {
        setOrders(data.orders.docs)
      }
    },
  })

  const rawDocs = data && data.orders ? data.orders.docs : []
  const source = data && data.orders && omit(['docs'], data.orders)

  const patientIdList = (orders ?? []).map((order) => order.patient.id)

  const bindingStageListResult = useQuery<
    BindingStagesQuery,
    BindingStagesQueryVariables
  >(bindingStagesQuery, {
    variables: {
      query: {
        patientIdList,
        status: [StageStatus.Started],
        type: [StageType.Eval, StageType.Print, StageType.Accessory],
      },
      page: 1,
      limit: 100,
      sort: 'updated',
    },
    skip: !shouldQueryBindStages || loading,
  })

  const ordersWithBinding: IOrder[] = orders.map((order) => {
    const bindingStageList = (
      bindingStageListResult.data?.stages?.docs ?? []
    ).filter((stage) => stage.patient.id === order?.patient.id)
    return {
      ...order,
      bindingStageList,
      isBindingStageListLoading: bindingStageListResult.loading,
    }
  })

  const isOrderEdited = (orderId: string) =>
    includes(
      orderId,
      map((form) => prop('id', form.getFieldValue('id')), forms)
    )

  const isOrderNew = (orderId: string) =>
    !includes(orderId, map(prop('id'), rawDocs))

  const editOrder = (form: WrappedFormUtils<OrderForm>) =>
    setForms(append(form))

  const undoOrder = (orderId: string) =>
    setForms(
      reject<WrappedFormUtils<OrderForm>>((form) =>
        equals(orderId, form.getFieldValue('id'))
      )
    )

  const createOrder = () => {
    const emptyOrder = getEmptyOrder()
    setOrders(insert(0, emptyOrder))
  }

  const copyOrder = (orderId: string) => {
    // 點擊目前已有的 order，orderId 一定存在
    const copiedOrder = find(propEq('id', orderId), orders) as IOrder
    const copiedOrderIndex = findIndex(propEq('id', orderId), orders)
    const newOrder = getNewOrder(copiedOrder)

    setOrders(insert(copiedOrderIndex + 1, newOrder))
  }

  const removeOrder = (orderId: string) => {
    setOrders(reject(propEq('id', orderId)))
    undoOrder(orderId)
  }

  useEffect(() => {
    if (data && data.orders) {
      const filterAndSort = pipe<IOrder[], IOrder[], IOrder[]>(
        advanceSearch(selectedOptions),
        sortByAppointmentDate(appointmentSortOrder)
      )
      setOrders(filterAndSort(data.orders.docs))
    }
  }, [data, selectedOptions, appointmentSortOrder])

  return {
    source,
    refetch,
    loading,
    orders: ordersWithBinding,
    forms,
    editedCount: forms.length,
    isOrderEdited,
    isOrderNew,
    editOrder,
    undoOrder,
    createOrder,
    copyOrder,
    removeOrder,
    clearOrders,
  }
}

export default useOrder

import {
  BarChartOutlined,
  CalendarOutlined,
  HomeOutlined,
  PlusOutlined,
  SettingOutlined,
  TableOutlined,
  ToolOutlined,
} from '@ant-design/icons'
import { Menu } from 'antd'
import { any, includes, isEmpty } from 'ramda'
import React, { useContext } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { AllPrivilege, Role } from '../../../graphql/types'
import { authContext } from '../../context'
import { RouteKey, routeMap } from '../../routes/routes'
import { getAuthPrivileges, getUserEntityIdFromAuth } from '../../utils'

const SubMenu = Menu.SubMenu

// 確認是否有權限
const checkPrivileges = (
  requiredPrivileges: AllPrivilege[],
  userPrivileges: AllPrivilege[]
) => {
  // 不需權限則回傳 true
  if (isEmpty(requiredPrivileges)) {
    return true
  }

  // 需要權限則一個個檢查才回傳 true
  return any<AllPrivilege>(
    (p) => includes(p, userPrivileges),
    requiredPrivileges
  )
}

interface MenuItem {
  route?: RouteKey
  render?: () => JSX.Element
  roles?: Role[]
  children?: MenuItem[]
  // 跟 routing 的 權限不同這邊是額外給 menu 用, 只要有一個就給顯示
  menuPrivileges?: AllPrivilege[]
}

const renderMenu = (items: MenuItem[], keyPrefix?: string) => {
  const auth = useContext(authContext)
  if (!auth) return null
  const userPrivileges = getAuthPrivileges(auth)

  return items
    .filter((item) => {
      const { route, menuPrivileges } = item

      return route
        ? // 一般的 route
          checkPrivileges(routeMap[route].privileges || [], userPrivileges)
        : // 非 route 但是是 menu
          checkPrivileges(menuPrivileges || [], userPrivileges)
    })
    .map((item, index) => {
      const { route = 'Home', render, children } = item
      if (children) {
        return (
          <SubMenu
            key={keyPrefix ? `${keyPrefix}-${index}` : `${index}`}
            title={
              <span>
                {render ? (
                  render()
                ) : (
                  <Link to={routeMap[route].path}>{routeMap[route].title}</Link>
                )}
              </span>
            }
          >
            {renderMenu(
              children,
              keyPrefix ? `${keyPrefix}-${index}` : `${index}`
            )}
          </SubMenu>
        )
      } else {
        return (
          <Menu.Item key={keyPrefix ? `${keyPrefix}-${index}` : `${index}`}>
            {render ? (
              render()
            ) : (
              <Link to={routeMap[route].path}>{routeMap[route].title}</Link>
            )}
          </Menu.Item>
        )
      }
    })
}

const StyledCustomSiderMenu = styled(Menu)`
  &.ant-menu-inline,
  &.ant-menu-vertical {
    border: none;
  }

  .ant-menu-item {
    margin: 0;
  }
`

interface Props {
  collapsed: boolean
  openKeys: string[]
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  setOpenKeys: React.Dispatch<React.SetStateAction<string[]>>
}

const CustomSiderMenu = (props: Props) => {
  const { collapsed, openKeys, setCollapsed, setOpenKeys } = props

  const auth = useContext(authContext)
  if (!auth) return null

  const isDesktop = useMediaQuery({
    query: '(min-width: 576px)',
  })

  const menuConfig: MenuItem[] = [
    {
      render: () => (
        <>
          <HomeOutlined />
          <span>個人中心</span>
        </>
      ),
      children: [
        { route: 'PatientList' },
        {
          route: 'EmployeeTaskList',
          render: () => (
            <Link to={`/employees/${getUserEntityIdFromAuth(auth)}/tasks`}>
              我的個人任務
            </Link>
          ),
        },
        { route: 'MoldStageList' },
        { route: 'OrderList' },
        { route: 'StatisticEmployeeTask' },
      ],
    },
    {
      render: () => (
        <>
          <PlusOutlined />
          <span>新增資料</span>
        </>
      ),
      children: [
        { route: 'PatientCreate' },
        { route: 'InvoiceCreate' },
        { route: 'ClinicCreate' },
        { route: 'DoctorCreate' },
        { route: 'AccountCreate' },
        { route: 'EmployeeCreate' },
        { route: 'ProductCreate' },
      ],
      menuPrivileges: [
        AllPrivilege.PatientCreate,
        AllPrivilege.InvoiceCreate,
        AllPrivilege.ClinicCreate,
        AllPrivilege.DoctorCreate,
        AllPrivilege.AccountCreate,
        AllPrivilege.EmployeeCreate,
        AllPrivilege.ProductCreate,
      ],
    },
    {
      render: () => (
        <>
          <TableOutlined />
          <span>資料總覽</span>
        </>
      ),
      children: [
        {
          render: () => <>外部醫診病</>,
          children: [
            { route: 'PatientList' },
            { route: 'ClinicList' },
            { route: 'DoctorList' },
            { route: 'AppointmentList' },
          ],
        },
        {
          render: () => <>內部工作流</>,
          children: [
            { route: 'OrderList' },
            { route: 'StageList' },
            { route: 'TaskList' },
            { route: 'InvoiceList' },
            { route: 'ProductList' },
          ],
        },
        {
          render: () => <>帳戶與員工</>,
          children: [
            { route: 'AccountList' },
            { route: 'EmployeeList' },
            { route: 'LeaveList' },
          ],
        },
        {
          render: () => <>財會相關</>,
          children: [{ route: 'PatientListPayment' }, { route: 'InvoiceList' }],
        },
        {
          render: () => <>APP 相關</>,
          children: [
            { route: 'AccountList' },
            { route: 'TrackList' },
            { route: 'DiaryList' },
          ],
        },
      ],
      menuPrivileges: [
        AllPrivilege.PatientRead,
        AllPrivilege.StageRead,
        AllPrivilege.TaskRead,
        AllPrivilege.InvoiceRead,
        AllPrivilege.ClinicRead,
        AllPrivilege.DoctorRead,
        AllPrivilege.AccountRead,
        AllPrivilege.EmployeeRead,
        AllPrivilege.ProductRead,
        AllPrivilege.FeedbackRead,
        AllPrivilege.LeaveRead,
      ],
    },

    {
      render: () => (
        <>
          <CalendarOutlined />
          <span>行事曆</span>
        </>
      ),
      children: [
        { route: 'AppointmentCalendar' },
        { route: 'StageCalendar' },
        { route: 'LeaveCalendar' },
      ],
    },
    {
      render: () => (
        <>
          <ToolOutlined />
          <span>工廠相關</span>
        </>
      ),
      children: [
        { route: 'StageReportRp' },
        { route: 'StageReportQc' },
        {
          route: 'FactoryOrders',
          render: () => (
            <Link to={routeMap.FactoryOrders.path}>GMP 工作紀錄表</Link>
          ),
        },
      ],
    },
    {
      render: () => (
        <>
          <BarChartOutlined />
          <span>統計資料</span>
        </>
      ),
      children: [
        { route: 'Statistic' },
        { route: 'StatisticInvoice' },
        { route: 'StatisticPatient' },
        { route: 'StatisticStage' },
        { route: 'StatisticFinishedPatient' },
        { route: 'InvoiceReportAccount' },
        { route: 'ProductReport' },
      ],
      menuPrivileges: [AllPrivilege.StatisticRead],
    },
    {
      render: () => (
        <>
          <SettingOutlined />
          <span>設定</span>
        </>
      ),
      children: [
        { route: 'EmployeeTaskOwnerSetting' },
        { route: 'PatientTransfer' },
        { route: 'SystemSetting' },
        { route: 'PrivilegesConfig' },
        { route: 'MinimumRequiredAppVersion' },
      ],
      menuPrivileges: [
        // 原本是用 role 屬於 [God, Manager], 這邊用權限替代
        AllPrivilege.SystemUpdate,
      ],
    },
  ]

  return (
    <StyledCustomSiderMenu
      mode='inline'
      openKeys={openKeys}
      onOpenChange={(openKeys) => {
        setOpenKeys(openKeys.length > 0 ? (openKeys as string[]) : [])
      }}
      defaultSelectedKeys={['0']}
      onClick={() => (isDesktop ? null : setCollapsed(!collapsed))}
    >
      {renderMenu(menuConfig)}
    </StyledCustomSiderMenu>
  )
}

export default CustomSiderMenu

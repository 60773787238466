import { gql } from '@apollo/client'
import { StageName } from '@sov/ui'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  RecentStageTimelineFragment,
  StageStatus,
} from '../../../../../graphql/types'
import braceIcon from './brace.svg'

const EmptyTimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

const TimelineContainer = styled.div`
  background-color: #fafafa;
  width: 235px;
  padding: 40px 24px;
  display: flex;
  flex-direction: column;
  > :last-child {
    flex-grow: 1;
  }
`

const StageTimelineContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const VerticalLine = styled.div`
  flex: 1;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
`

const ColorDot = styled.div<{ status: StageStatus }>`
  height: 14px;
  width: 14px;
  border: 2px solid #ffffff;
  background: ${(props) =>
    props.status === StageStatus.Completed ? ' #52c41a' : '#1890ff'};
  border-radius: 50%;
`

const StageTimeLine = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const StageInfoContainer = styled.div`
  margin-left: 12px;
  width: 100%;
  & > * {
    margin-bottom: 5px;
  }
  :last-child {
    margin-bottom: 5px;
  }
`

const InfoRow = styled.div`
  font-size: 11px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const InfoField = styled.div`
  font-weight: 300;
  /* line-height: 18px; */
  color: rgba(0, 0, 0, 0.45);
`

const InfoValue = styled.div`
  color: rgba(0, 0, 0, 0.65);
`

interface RecentStageTimelineProps {
  recentStages: RecentStageTimelineFragment[]
}

export const RecentStageTimeline = (props: RecentStageTimelineProps) => {
  const { recentStages } = props
  const { t } = useTranslation()
  return (
    <TimelineContainer>
      {recentStages.length > 0 ? (
        recentStages.map((stage) => (
          <StageTimelineContainer key={stage.id}>
            <StageTimeLine>
              <ColorDot status={stage.status} />
              <VerticalLine />
            </StageTimeLine>
            <StageInfoContainer>
              <div>
                <StageName item={stage} />
              </div>
              <InfoRow>
                <InfoField>預計出貨</InfoField>
                <InfoValue>
                  {stage.expectedShippingDate
                    ? moment(stage.expectedShippingDate).format('YYYY-MM-DD')
                    : 'n/a'}
                </InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoField>狀態</InfoField>
                <InfoValue>{t(`stage.status.${stage.status}`)}</InfoValue>
              </InfoRow>
            </StageInfoContainer>
          </StageTimelineContainer>
        ))
      ) : (
        <EmptyTimelineContainer>
          <img src={braceIcon} />
          <div>新病患，目前尚無報告</div>
        </EmptyTimelineContainer>
      )}
    </TimelineContainer>
  )
}

RecentStageTimeline.fragment = gql`
  fragment RecentStageTimeline on Stage {
    id
    status
    expectedShippingDate
    ...StageName
  }
  ${StageName.fragment}
`

import { gql, useQuery } from '@apollo/client'
import {
  Currency,
  DisplayPatientBrand,
  DisplayPatientStatus,
  Link as CustomLink,
} from '@sov/ui'
import {
  Descriptions,
  Menu,
  message,
  PageHeader,
  Skeleton,
  Space,
  Tag,
} from 'antd'
import moment from 'moment'
import { isNil } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'

import {
  PatientPageHeaderQueryQuery,
  PatientPageHeaderQueryQueryVariables,
  PatientPageHeaderStageItemQueryQuery,
  PatientPageHeaderStageItemQueryQueryVariables,
  StageType,
} from '../../../../graphql/types'
import BreadcrumbCreator from '../../layout/BreadcrumbCreator'
import EmployeeLink from '../../link/employee'
import { getMatchRoute } from '../utils'

const { ClinicLink, DoctorLink } = CustomLink

const patientPageHeaderQuery = gql`
  query PatientPageHeaderQuery($id: ID!) {
    patient(id: $id) {
      id
      status
      name
      patientCode
      cabinetCode
      gender
      nextAppointmentDate
      payment {
        brand
        source
        type
        patientPrice
        base
        referral
        extension
        discount
      }
      photos {
        frontFace {
          id
          thumbnailPath
        }
      }
      clinic {
        ...ClinicLink
      }
      doctor {
        ...DoctorLink
      }
      accountManager {
        id
        name
      }
      sales {
        id
        name
      }
      technician {
        id
        name
      }
      customerService {
        id
        name
      }
      currentSubStage {
        id
        stage {
          id
          serialNumber
        }
        serialNumber
      }
      meta {
        currentStepNumber
        finalStepNumber
        risk
      }
    }
  }
  ${ClinicLink.fragment}
  ${DoctorLink.fragment}
`

const stageItemQuery = gql`
  query PatientPageHeaderStageItemQuery($stageId: ID!) {
    stage(id: $stageId) {
      id
      type
    }
  }
`

const getSubStageTitle = (subStage) => {
  return `${subStage.stage.serialNumber}-${subStage.serialNumber}`
}

interface Params {
  patientId: string
  stageId: string
}

const PatientPageHeader = () => {
  const { t } = useTranslation()

  const match = useRouteMatch<Params>()
  const { patientId } = match.params

  const matchRoute = getMatchRoute<Params>(useLocation().pathname)
  const selectedKeys = matchRoute ? [matchRoute.key] : undefined

  const stageId = matchRoute?.matchResult?.params?.stageId

  const { data, loading } = useQuery<
    PatientPageHeaderQueryQuery,
    PatientPageHeaderQueryQueryVariables
  >(patientPageHeaderQuery, {
    onError: (error) => message.error(error.message),
    variables: {
      id: patientId,
    },
  })

  const stageItemResult = useQuery<
    PatientPageHeaderStageItemQueryQuery,
    PatientPageHeaderStageItemQueryQueryVariables
  >(stageItemQuery, {
    onError: (error) => message.error(error.message),
    variables: {
      stageId: stageId as string,
    },
    skip: isNil(stageId),
  })

  const item = data?.patient
  const stageItem = stageItemResult.data?.stage

  if (loading) {
    return <Skeleton active />
  }
  if (!item) {
    return null
  }

  return (
    <PageHeader
      breadcrumbRender={() => (
        <BreadcrumbCreator
          routes={[
            { key: 'Home' },
            { key: 'PatientList' },
            {
              key: 'PatientDetail',
              render: () => (
                <Link to={`/patients/${item.id}`}>{item.name}</Link>
              ),
            },
          ]}
        />
      )}
      avatar={{ src: item.photos.frontFace?.thumbnailPath }}
      title={item.name}
      tags={
        <Space>
          <DisplayPatientStatus value={item.status} />
          {item.currentSubStage && (
            <Tag style={{ margin: 0 }}>
              {getSubStageTitle(item.currentSubStage)}
            </Tag>
          )}
          <DisplayPatientBrand value={item.payment.brand} />
          <Tag style={{ margin: 0 }}>
            {t(`patient.source.${item.payment.source}`)}
          </Tag>
          <Tag style={{ margin: 0 }}>
            {t(`patient.type.${item.payment.type}`)}
          </Tag>
          {item.meta.risk >= 60 && (
            <Tag color='#f50' style={{ margin: 0 }}>
              高風險
            </Tag>
          )}
          <span>
            下次約診：
            {item.nextAppointmentDate
              ? moment(item.nextAppointmentDate).format('YYYY-MM-DD HH:mm')
              : '無約診'}
          </span>
        </Space>
      }
      extra={
        <Space>
          <span
            style={{ fontSize: 20 }}
          >{`Step ${item.meta.currentStepNumber} / Step ${item.meta.finalStepNumber}`}</span>
        </Space>
      }
      footer={
        <Menu
          mode='horizontal'
          selectedKeys={selectedKeys}
          style={{ marginLeft: -20 }}
        >
          <Menu.Item key='PatientDetail'>
            <Link to={`/patients/${item.id}`}>病患總覽</Link>
          </Menu.Item>
          <Menu.Item key='PatientStageList'>
            <Link to={`/patients/${item.id}/stages`}>工單列表</Link>
          </Menu.Item>
          <Menu.Item key='PatientAnalysisList'>
            <Link to={`/patients/${item.id}/analyses`}>報告列表</Link>
          </Menu.Item>
          <Menu.Item key='PatientInvoiceList'>
            <Link to={`/patients/${item.id}/invoices`}>出貨列表</Link>
          </Menu.Item>
          <Menu.Item key='PatientAppointmentsList'>
            <Link to={`/patients/${item.id}/appointments`}>約診列表</Link>
          </Menu.Item>
          <Menu.Item key='PatientOrdersList'>
            <Link to={`/patients/${item.id}/orders`}>訂單列表</Link>
          </Menu.Item>
          <Menu.Item key='PatientTracksList'>
            <Link to={`/patients/${item.id}/tracks`}>追蹤列表</Link>
          </Menu.Item>
          <Menu.Item key='PatientTaskList'>
            <Link to={`/patients/${item.id}/tasks`}>任務列表</Link>
          </Menu.Item>
          <Menu.Item key='PatientEventList'>
            <Link to={`/patients/${item.id}/events`}>事件列表</Link>
          </Menu.Item>
          <Menu.Item key='PatientTreatment'>
            <Link to={`/patients/${item.id}/treatment`}>病患療程</Link>
          </Menu.Item>
          {stageItem && (
            <Menu.Item key='StageDetail'>
              <Link to={`/patients/${item.id}/stages/${stageItem.id}`}>
                工單詳情
              </Link>
            </Menu.Item>
          )}
          {stageItem && stageItem.type === StageType.Eval && (
            <Menu.Item key='StageAnalysis'>
              <Link to={`/patients/${item.id}/stages/${stageItem.id}/analysis`}>
                工單報告編輯
              </Link>
            </Menu.Item>
          )}
          {stageItem && (
            <Menu.Item key='StageInvoiceDetail'>
              <Link to={`/patients/${item.id}/stages/${stageItem.id}/invoice`}>
                工單出貨資訊
              </Link>
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <Descriptions
        size='small'
        column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 2 }}
      >
        <Descriptions.Item label='診所'>
          <ClinicLink item={item.clinic} />
        </Descriptions.Item>
        <Descriptions.Item label='醫生'>
          <DoctorLink item={item.doctor} showLabel />
        </Descriptions.Item>
        <Descriptions.Item label='AM'>
          <EmployeeLink item={item.accountManager} />
        </Descriptions.Item>
        <Descriptions.Item label='技師'>
          <EmployeeLink item={item.technician} />
        </Descriptions.Item>
        <Descriptions.Item label='代號'>{item.patientCode}</Descriptions.Item>
        <Descriptions.Item label='塔位'>{item.cabinetCode}</Descriptions.Item>
        <Descriptions.Item label='付款'>
          <Currency
            value={
              item.payment.base +
              item.payment.referral +
              item.payment.extension -
              item.payment.discount
            }
          />
          <span>(技</span>
          <Currency value={item.payment.base} />
          <span>+轉</span>
          <Currency value={item.payment.referral} />
          <span>+延</span>
          <Currency value={item.payment.extension} />
          <span>-折</span>
          <Currency value={item.payment.discount} />
          <span>)</span>
        </Descriptions.Item>
      </Descriptions>
    </PageHeader>
  )
}

export default PatientPageHeader

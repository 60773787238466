import { Form } from '@ant-design/compatible'
import { FormItemProps } from '@ant-design/compatible/lib/form'
import { UploadOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { GqlUpload } from '@sov/ui'
import { Button, Popconfirm, Row, message } from 'antd'
import { UploadFileStatus } from 'antd/lib/upload/interface'
import { assoc, map } from 'ramda'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  DesignStageViewerFilesFragment,
  EvalStageViewerFilesFragment,
  FileInfoFragment,
  JawPosition,
  MoldStageViewerFilesFragment,
  RemoveViewerFileMutation,
  RemoveViewerFileMutationVariables,
  StageStatus,
  ViewerStageInfoFragment,
} from '../../../../graphql/types'
import { RefetchContext } from '../../../pages/stage/StageDetail/utils'
import ViewerFilesTable from '../../common/ViewerFilesTable'
import ViewerFilesUpload, {
  CustomFileType,
} from '../../modal/ViewerFilesUpload'

type FormViewerFilesFragment =
  | MoldStageViewerFilesFragment
  | EvalStageViewerFilesFragment
  | DesignStageViewerFilesFragment

/** query string */
const removeViewerFile = gql`
  mutation RemoveViewerFile($id: ID!, $payload: ViewerFilesInput!) {
    updateViewerFiles(id: $id, payload: $payload) {
      id
    }
  }
`

const TotalCount = styled.span`
  line-height: normal;
  margin: 16px 0;
`

const formItemLayout: FormItemProps = {
  labelCol: { span: 18, offset: 3 },
  wrapperCol: { span: 18, offset: 3 },
  labelAlign: 'left',
}

export const getInitialToothFiles = (
  teeth?: FormViewerFilesFragment['teeth']
): CustomFileType[] =>
  map(
    (tooth) => ({
      ...GqlUpload.initialValueTransformer(tooth.file)[0],
      position: tooth.position,
    }),
    teeth || []
  )

export const getInitialJawFile = (
  position: JawPosition,
  jawFile?: FileInfoFragment
): CustomFileType[] => {
  const initJawFile = GqlUpload.initialValueTransformer(jawFile)
  return map(assoc('position', position), initJawFile)
}

const getEmptyViewerFilesPayload = (): any => ({
  upperJawModel: null,
  lowerJawModel: null,
  teeth: [],
})

interface FormViewerFilesProps {
  stage: ViewerStageInfoFragment
  patientId: string
  viewerUrl: string
  files?: FormViewerFilesFragment
  isReadOnly?: boolean
}

const FormViewerFiles = (props: FormViewerFilesProps) => {
  const { patientId, stage, files, isReadOnly, viewerUrl } = props

  const [visible, setVisible] = useState(false)
  const [update] = useMutation<
    RemoveViewerFileMutation,
    RemoveViewerFileMutationVariables
  >(removeViewerFile)
  const refetch = useContext(RefetchContext)

  const defaultFiles = [
    ...getInitialToothFiles(files?.teeth),
    ...getInitialJawFile(JawPosition.Upper, files?.upperJawModel),
    ...getInitialJawFile(JawPosition.Lower, files?.lowerJawModel),
  ]
  const defaultFilesWithPosition = map(
    (file) => ({
      position: file.position,
      status: 'done' as UploadFileStatus,
    }),
    defaultFiles
  )

  const hasFilesForViewer =
    stage.__typename === 'MoldStage' ||
    stage.__typename === 'EvalStage' ||
    stage.__typename === 'DesignStage'
      ? stage.hasFilesForViewer
      : false

  const getLabel = () => {
    if (stage.__typename === 'MoldStage') return '上下顎排牙檔（original）'
    if (stage.__typename === 'EvalStage') return '上下顎排牙檔（final）'
    if (stage.__typename === 'DesignStage')
      return `上下顎排牙檔（D${stage.serialNumber}）`
    return '上下顎排牙檔'
  }

  const handleClose = () => setVisible(false)
  const handleRemoveAllFile = async () => {
    await update({
      variables: {
        id: stage.id,
        payload: getEmptyViewerFilesPayload(),
      },
      update: async (cache, { data }) => {
        if (data) {
          message.info('已更新工單')
          refetch && refetch()
        }
      },
    })
  }

  return (
    <Form.Item {...formItemLayout} label={getLabel()}>
      <Row align='middle'>
        <TotalCount>
          共計 <b>{defaultFilesWithPosition.length}</b> 個檔案
        </TotalCount>
        {stage.status !== StageStatus.Dropped && (
          <Link to={viewerUrl}>
            <Button type='primary' style={{ margin: '0 8px' }}>
              Viewer 預覽
            </Button>
          </Link>
        )}
        {!isReadOnly && (
          <>
            <Button
              onClick={() => setVisible(true)}
              style={{ margin: '0 8px' }}
            >
              <UploadOutlined />
              上傳檔案
            </Button>
            <Popconfirm
              title='確定要刪除全部檔案？'
              icon={null}
              onConfirm={() => handleRemoveAllFile()}
            >
              <Button type='link' style={{ color: 'red' }}>
                全部刪除
              </Button>
            </Popconfirm>
          </>
        )}
      </Row>
      <ViewerFilesUpload
        patientId={patientId}
        stageId={stage.id}
        visible={visible}
        defaultFiles={defaultFiles}
        hasFilesForViewer={hasFilesForViewer}
        handleClose={handleClose}
      />
      <ViewerFilesTable files={defaultFilesWithPosition} />
    </Form.Item>
  )
}

FormViewerFiles.fragment = gql`
  fragment MoldStageViewerFiles on MoldStageFiles {
    teeth {
      position
      file {
        ...fileInfo
      }
    }
    upperJawModel {
      ...fileInfo
    }
    lowerJawModel {
      ...fileInfo
    }
  }
  fragment EvalStageViewerFiles on EvalStageFiles {
    teeth {
      position
      file {
        ...fileInfo
      }
    }
    upperJawModel {
      ...fileInfo
    }
    lowerJawModel {
      ...fileInfo
    }
  }
  fragment DesignStageViewerFiles on DesignStageFiles {
    teeth {
      position
      file {
        ...fileInfo
      }
    }
    upperJawModel {
      ...fileInfo
    }
    lowerJawModel {
      ...fileInfo
    }
  }
  fragment ViewerStageInfo on Stage {
    id
    status
    __typename
    ... on MoldStage {
      serialNumber
      hasFilesForViewer
    }
    ... on EvalStage {
      serialNumber
      hasFilesForViewer
    }
    ... on DesignStage {
      serialNumber
      hasFilesForViewer
    }
    ... on PrintStage {
      serialNumber
    }
  }
  ${GqlUpload.Upload.fragment}
`

export default FormViewerFiles

import { isEmpty } from 'ramda'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

import ArrowDownIcon from './ArrowDownIcon'
import ArrowUpIcon from './ArrowUpIcon'
import HorizontalLineIcon from './HorizontalLineIcon'

interface GetDisplayContentArgs {
  current: number
  previous: number
}

interface DisplayContent {
  color: string
  symbol: ReactNode
  text: string
}

type GetDisplayContent = (args: GetDisplayContentArgs) => DisplayContent

const getDisplayContent: GetDisplayContent = (args) => {
  const { current, previous } = args
  if (current > previous) {
    return {
      color: '#52C41A',
      symbol: <ArrowUpIcon />,
      text: '成長',
    }
  }

  if (current < previous) {
    return {
      color: '#F5222D',
      symbol: <ArrowDownIcon />,
      text: '下降',
    }
  }

  if (current === previous) {
    return {
      color: 'rgba(0, 0, 0, 0.85);',
      symbol: <HorizontalLineIcon />,
      text: '持平',
    }
  }

  return {
    color: '',
    symbol: null,
    text: '',
  }
}

interface TextProps {
  color: string
}

const Text = styled.span<TextProps>`
  color: ${(props) => props.color};
`

const Symbol = styled.span`
  margin-left: 8px;
`

const ComparisonLabelContainer = styled.span``

interface ComparisonLabelProps {
  current: number
  previous: number
}

const ComparisonLabel = (props: ComparisonLabelProps) => {
  const { current, previous } = props

  const { color, symbol, text } = getDisplayContent({ current, previous })

  if (isEmpty(text)) {
    return null
  }

  return (
    <ComparisonLabelContainer>
      <Text color={color}>{text}</Text>
      <Symbol>{symbol}</Symbol>
    </ComparisonLabelContainer>
  )
}

export default ComparisonLabel

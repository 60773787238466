import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form'
import { DateIntervalParam, MyStringParam } from '@sov/common'
import { Checkbox, Dropdown, Menu } from 'antd'
import Button, { ButtonProps } from 'antd/lib/button'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import { length, mapObjIndexed, pipe, prop, values } from 'ramda'
import React from 'react'
import ReactToPrint from 'react-to-print'

import RangePicker from '../../../common/RangePicker'
import { PrintType, lastDayInterval } from '../QC'

export interface QCFormFields {
  startedByEmployeeInterval: [moment.Moment, moment.Moment]
  printType: PrintType
}

interface QCFormProps extends FormComponentProps<QCFormFields> {
  formQuery: QCFormFields
  selectedTaskIds: (string | number)[]
  componentRef: React.MutableRefObject<any>
  isShowingOnlySelected: boolean
  hiddenColumnKeys: ColumnProps<any>['key'][]
  handleSearch: (form: WrappedFormUtils<QCFormFields>) => void
  handleRefresh: () => void
  handleTypeChange: (printType: string) => void
  handleIsShowingOnlySelectedToggle: () => void
  handleColumn: () => void
}

interface PrintMenuProps {
  handleClick: (printType: string) => void
}

const FormItem = Form.Item

const printTypeText = {
  table: '一般表單',
  bagLabel: '夾鏈袋',
  boxLabel: '外盒標籤',
  instruction: '指示卡',
  stageBrief: '牙套工單',
  newBagLabel: '新版夾鏈袋',
}

const todayInterval: [moment.Moment, moment.Moment] = [
  moment().startOf('day'),
  moment().add(1, 'days').startOf('day'),
]

const RefreshButton = (props: ButtonProps) => (
  <Button style={props.style} onClick={props.onClick}>
    清除篩選並刷新
  </Button>
)

const ShowSelectedButton = (
  props: ButtonProps & {
    isShowingOnlySelected: boolean
    number: number
  }
) => (
  <Button style={props.style} onClick={props.onClick}>
    {props.isShowingOnlySelected ? '顯示全部' : `顯示已勾選 (${props.number})`}
  </Button>
)

const PrintMenu = ({ handleClick, ...props }: PrintMenuProps) => (
  <Menu {...props}>
    {pipe(
      mapObjIndexed<string, JSX.Element>((text, key) => (
        <Menu.Item key={key} onClick={() => handleClick(key)}>
          {text}
        </Menu.Item>
      )),
      values
    )(printTypeText)}
  </Menu>
)

export const formInput = {
  startedByEmployeeInterval: DateIntervalParam,
  printType: MyStringParam,
}

const QCForm = (props: QCFormProps) => {
  const {
    form,
    formQuery,
    selectedTaskIds,
    componentRef,
    isShowingOnlySelected,
    hiddenColumnKeys,
    handleSearch,
    handleRefresh,
    handleTypeChange,
    handleIsShowingOnlySelectedToggle,
    handleColumn,
  } = props

  return (
    <Form layout='inline'>
      <FormItem label='啟動日'>
        {form.getFieldDecorator('startedByEmployeeInterval', {
          initialValue: formQuery.startedByEmployeeInterval,
        })(
          <RangePicker
            style={{ width: 250 }}
            ranges={{
              前一工作日到今日: lastDayInterval,
              今日急單: todayInterval,
            }}
          />
        )}
      </FormItem>
      <FormItem>
        <Button
          onClick={() => handleSearch(form)}
          disabled={
            length(form.getFieldValue('startedByEmployeeInterval')) !== 2
          }
        >
          搜索
        </Button>
      </FormItem>
      <FormItem>
        <RefreshButton onClick={() => handleRefresh()} />
      </FormItem>
      <FormItem>
        <ShowSelectedButton
          isShowingOnlySelected={isShowingOnlySelected}
          onClick={handleIsShowingOnlySelectedToggle}
          number={length<string | number>(selectedTaskIds)}
        />
      </FormItem>
      <FormItem>
        <Dropdown
          overlay={<PrintMenu handleClick={handleTypeChange} />}
          placement='bottomLeft'
        >
          <Button type='primary'>
            {prop(formQuery.printType, printTypeText)}
          </Button>
        </Dropdown>
      </FormItem>
      <FormItem>
        {formQuery.printType === PrintType.instruction ? (
          <ReactToPrint
            trigger={() => <Button>列印</Button>}
            content={() => componentRef.current}
          />
        ) : (
          <Button onClick={() => window.print()}>列印</Button>
        )}
      </FormItem>
      <FormItem>
        <Checkbox value={hiddenColumnKeys.length > 0} onClick={handleColumn}>
          隱藏非列印欄位
        </Checkbox>
      </FormItem>
    </Form>
  )
}

export default QCForm

import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { PlusOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import {
  DateIntervalParam,
  ErrorHandling,
  FormQuery,
  MyStringParam,
  TableQuery,
} from '@sov/common'
import { Button, Col, Row } from 'antd'
import React, { useState } from 'react'

import {
  AppointmentFragment,
  AppointmentListQueryQuery,
  AppointmentListQueryQueryVariables,
} from '../../../../graphql/types'
import BreadcrumbCreator from '../../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../../components/layout/Page'
import Title from '../../../components/layout/Title'
import AppointmentModal from '../../../components/modal/AppointmentModal'
import AppointmentTable from '../../../components/table/Appointment'
import QueryForm from './QueryForm'

const appointmentListQuery = gql`
  query AppointmentListQuery(
    $query: AppointmentsQuery = {}
    $page: Int
    $limit: Int
    $sort: String
  ) {
    appointments(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...AppointmentTable
      }
      limit
      total
      page
    }
  }
  ${AppointmentTable.fragment.AppointmentTable}
`

const formInput = {
  clinicName: MyStringParam,
  doctorName: MyStringParam,
  patientName: MyStringParam,
  startDateInterval: DateIntervalParam,
}

const AppointmentList = () => {
  const [selectedAppointment, setSelectedAppointment] =
    useState<AppointmentFragment>()
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const { tableQuery, paginateQuery, handleTableChange, handleTableReset } =
    TableQuery.useTableQuery<any, string>({ limit: 100, sort: '-startDate' })
  const { formQuery, handleFormChange, handleFormReset } =
    FormQuery.useFormQuery(formInput)
  const { toErrorPage } = ErrorHandling.useErrorHandling()

  const { data, loading, refetch } = useQuery<
    AppointmentListQueryQuery,
    AppointmentListQueryQueryVariables
  >(appointmentListQuery, {
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {
        clinicName: formQuery.clinicName,
        doctorName: formQuery.doctorName,
        patientName: formQuery.patientName,
        startDateInterval: formQuery.startDateInterval,
        ...tableQuery.filters,
      },
      ...paginateQuery,
    },
  })

  const header = (
    <Row style={{ display: 'flex' }}>
      <Col style={{ flex: 1 }}>
        <BreadcrumbCreator
          routes={[{ key: 'Home' }, { key: 'AppointmentList' }]}
        />
        <Title route={{ key: 'AppointmentList' }} />
      </Col>
      <Button
        type='primary'
        onClick={() => setModalVisible(true)}
        style={{ float: 'right' }}
      >
        <PlusOutlined />
        新增約診
      </Button>
    </Row>
  )

  const handleSearch = (form: FormComponentProps['form']) => {
    handleFormChange(form.getFieldsValue())
  }

  const handleClear = (form: FormComponentProps['form']) => {
    form.resetFields()
    handleTableReset()
    handleFormReset()
  }

  const handleSelect = (item: AppointmentFragment) => {
    setSelectedAppointment(item)
    setModalVisible(true)
  }

  const handleReset = () => {
    setSelectedAppointment(undefined)
    setModalVisible(false)
  }

  return (
    <Page header={header}>
      <Section>
        <QueryForm
          formQuery={formQuery}
          handleSearch={handleSearch}
          handleClear={handleClear}
        />
        <br />
        <AppointmentTable
          source={data?.appointments}
          loading={loading}
          handleTableChange={handleTableChange}
          filterInfo={tableQuery.filters}
          sortInfo={tableQuery.sort}
          handleSelect={handleSelect}
        />
        <AppointmentModal
          item={selectedAppointment}
          visible={modalVisible}
          reset={handleReset}
          refetch={refetch}
        />
      </Section>
    </Page>
  )
}

export default AppointmentList

import { QuestionCircleOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import {
  DateIntervalParam,
  ErrorHandling,
  FormQuery,
  MyStringParam,
  TableQuery,
  getDateIntervalQueryString,
} from '@sov/common'
import { Button, Card, Col, Form, Input, Row, Space, Tooltip } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { useForm } from 'antd/lib/form/Form'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'

import {
  PatientListQueryQuery,
  PatientListQueryQueryVariables,
} from '../../../graphql/types'
import RangePicker from '../../components/common/RangePicker'
import BreadcrumbCreator from '../../components/layout/BreadcrumbCreator'
import Page from '../../components/layout/Page'
import Title from '../../components/layout/Title'
import { PatientCreateModal } from '../../components/modal/patient/PatientCreate'
import PatientTable, {
  PatientFilterType,
  PatientSorterField,
} from '../../components/table/Patient'

const patientListQuery = gql`
  query PatientListQuery(
    $query: PatientsQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    patients(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...PatientTable
      }
      total
      limit
      page
    }
  }
  ${PatientTable.fragment}
`

export interface PatientFormInput {
  name?: string
  clinicName?: string
  doctorName?: string
  accountManagerName?: string
  technicianName?: string
  salesName?: string
  customerServiceName?: string
  patientCode?: string
  cabinetCode?: string
  lastEvalStageAtInterval?: [moment.Moment, moment.Moment]
  lastPrintStageAtInterval?: [moment.Moment, moment.Moment]
}

const formInput = {
  name: MyStringParam,
  clinicName: MyStringParam,
  doctorName: MyStringParam,
  accountManagerName: MyStringParam,
  technicianName: MyStringParam,
  salesName: MyStringParam,
  customerServiceName: MyStringParam,
  patientCode: MyStringParam,
  cabinetCode: MyStringParam,
  lastEvalStageAtInterval: DateIntervalParam,
  lastPrintStageAtInterval: DateIntervalParam,
}

interface PatientFormProps {
  form: FormInstance<PatientFormInput>
  formQuery: PatientFormInput
  handleSearch: () => void
  handleReset: () => void
}

export const PatientForm: FC<PatientFormProps> = (props) => {
  const { form, formQuery, handleSearch, handleReset } = props
  const [advanced, setAdvanced] = useState(false)

  return (
    <Form form={form} initialValues={formQuery}>
      <Row gutter={8}>
        <Col flex='1'>
          <Row gutter={8}>
            <Col>
              <Form.Item label='病患' name='name'>
                <Input style={{ width: 80 }} onPressEnter={handleSearch} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label='診所' name='clinicName'>
                <Input style={{ width: 80 }} onPressEnter={handleSearch} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label='醫師' name='doctorName'>
                <Input style={{ width: 80 }} onPressEnter={handleSearch} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label='AM' name='accountManagerName'>
                <Input style={{ width: 80 }} onPressEnter={handleSearch} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label='代號' name='patientCode'>
                <Input style={{ width: 80 }} onPressEnter={handleSearch} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label='塔位' name='cabinetCode'>
                <Input style={{ width: 80 }} onPressEnter={handleSearch} />
              </Form.Item>
            </Col>
            {advanced && (
              <Col>
                <Form.Item
                  label={
                    <Tooltip title='搜索上次報告的出貨時間區間'>
                      上次報告 <QuestionCircleOutlined />
                    </Tooltip>
                  }
                  name='lastEvalStageAtInterval'
                >
                  <RangePicker
                    ranges={{
                      '過去 ~ 一個月前': [
                        moment('2017-01-01'),
                        moment().subtract(1, 'month'),
                      ],
                      '過去 ~ 二個月前': [
                        moment('2017-01-01'),
                        moment().subtract(2, 'month'),
                      ],
                      '過去 ~ 三個月前': [
                        moment('2017-01-01'),
                        moment().subtract(3, 'month'),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
            )}
            {advanced && (
              <Col>
                <Form.Item
                  label={
                    <Tooltip title='搜索上次 Step 的出貨時間區間'>
                      上次 Step <QuestionCircleOutlined />
                    </Tooltip>
                  }
                  name='lastPrintStageAtInterval'
                >
                  <RangePicker
                    ranges={{
                      '過去 ~ 一個月前': [
                        moment('2017-01-01'),
                        moment().subtract(1, 'month'),
                      ],
                      '過去 ~ 二個月前': [
                        moment('2017-01-01'),
                        moment().subtract(2, 'month'),
                      ],
                      '過去 ~ 三個月前': [
                        moment('2017-01-01'),
                        moment().subtract(3, 'month'),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
            )}
            {advanced && (
              <Col>
                <Form.Item label='技師' name='technicianName'>
                  <Input style={{ width: 80 }} onPressEnter={handleSearch} />
                </Form.Item>
              </Col>
            )}
            {advanced && (
              <Col>
                <Form.Item label='業務' name='salesName'>
                  <Input style={{ width: 80 }} onPressEnter={handleSearch} />
                </Form.Item>
              </Col>
            )}
            {advanced && (
              <Col>
                <Form.Item label='客服' name='customerServiceName'>
                  <Input style={{ width: 80 }} onPressEnter={handleSearch} />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Col>
        <Col flex='160px'>
          <Space>
            <Button onClick={handleSearch} type='primary'>
              搜索
            </Button>
            <Button onClick={handleReset}>重置</Button>
          </Space>
        </Col>
        <Col flex='80px'>
          <Form.Item>
            <Button type='link' onClick={() => setAdvanced(!advanced)}>
              {advanced ? '收合' : '展開'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

interface Props {
  className: any
}

const PatientList: FC<Props> = (props) => {
  const { className } = props
  const [form] = useForm<PatientFormInput>()
  const [modalVisible, setModalVisible] = useState(false)
  const { tableQuery, paginateQuery, handleTableChange, handleTableReset } =
    TableQuery.useTableQuery<PatientFilterType, PatientSorterField>({
      limit: 30,
    })
  const { formQuery, handleFormChange, handleFormReset } =
    FormQuery.useFormQuery(formInput)
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading, refetch } = useQuery<
    PatientListQueryQuery,
    PatientListQueryQueryVariables
  >(patientListQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {
        name: formQuery.name,
        clinicName: formQuery.clinicName,
        doctorName: formQuery.doctorName,
        accountManagerName: formQuery.accountManagerName,
        technicianName: formQuery.technicianName,
        salesName: formQuery.salesName,
        customerServiceName: formQuery.customerServiceName,
        patientCode: formQuery.patientCode,
        cabinetCode: formQuery.cabinetCode,
        lastEvalStageAtInterval: getDateIntervalQueryString(
          formQuery.lastEvalStageAtInterval
        ),
        lastPrintStageAtInterval: getDateIntervalQueryString(
          formQuery.lastPrintStageAtInterval
        ),
        ...tableQuery.filters,
      },
      ...paginateQuery,
    },
  })

  const handleSearch = () => {
    const value = form.getFieldsValue()
    handleFormChange(value)
  }

  const handleReset = () => {
    handleFormReset()
    handleTableReset()
  }

  useEffect(() => {
    form.setFieldsValue(formQuery)
  }, [formQuery])

  useEffect(() => {
    if (modalVisible === false) {
      refetch()
    }
  }, [modalVisible])

  return (
    <div className={className}>
      <Page
        header={
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <BreadcrumbCreator
                routes={[{ key: 'Home' }, { key: 'PatientList' }]}
              />
              <Title route={{ key: 'PatientList' }} />
            </div>
            <div>
              <Button type='primary' onClick={() => setModalVisible(true)}>
                快速新增病患
              </Button>
              <PatientCreateModal
                visible={modalVisible}
                setVisible={setModalVisible}
                onCancel={() => setModalVisible(false)}
              />
            </div>
          </div>
        }
      >
        <Card size='small'>
          <PatientForm
            form={form}
            formQuery={formQuery}
            handleSearch={handleSearch}
            handleReset={handleReset}
          />
          <PatientTable
            source={data?.patients}
            sortInfo={tableQuery.sort}
            filterInfo={tableQuery.filters}
            handleChange={handleTableChange}
            loading={loading}
          />
        </Card>
      </Page>
    </div>
  )
}

export default PatientList

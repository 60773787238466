import { useQuery } from '@apollo/client'
import { ErrorHandling, TableQuery } from '@sov/common'
import { Button, Col, Radio, Row } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { employeesQuery } from '../../../graphql/employee/query/list'
import {
  EmployeesQueryDocs,
  EmployeesQueryQuery,
  EmployeesQueryVariables,
} from '../../../graphql/types'
import BreadcrumbCreator from '../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../components/layout/Page'
import Title from '../../components/layout/Title'
import EmployeeTable from '../../components/table/Employee'

const CreateButtonLink = () => (
  <Button type='primary' style={{ marginLeft: 16 }}>
    <Link to='/employees/create'>新增員工</Link>
  </Button>
)

export const EmployeeIndex = () => {
  const [resigned, setResigned] = useState(false)
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { paginateQuery, handleTableChange } = TableQuery.useTableQuery<
    any,
    any
  >({ limit: 200, sort: '-onBoardDate' })
  const { data, loading } = useQuery<
    EmployeesQueryQuery,
    EmployeesQueryVariables
  >(employeesQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: { resigned },
      ...paginateQuery,
    },
  })

  const handleResignedChange = (e: RadioChangeEvent) => {
    setResigned(e.target.value)
  }

  const source = data?.employees

  return (
    <Page
      header={
        <Row justify='space-between'>
          <Col>
            <BreadcrumbCreator
              routes={[{ key: 'Home' }, { key: 'EmployeeList' }]}
            />
            <Title route={{ key: 'EmployeeList' }} />
          </Col>
          <Col>
            <Radio.Group
              defaultValue={false}
              onChange={handleResignedChange}
              style={{ marginRight: 8 }}
            >
              <Radio.Button value={false}>在職中</Radio.Button>
              <Radio.Button value>已離職</Radio.Button>
            </Radio.Group>
            <CreateButtonLink />
          </Col>
        </Row>
      }
    >
      <Section>
        <EmployeeTable<EmployeesQueryDocs>
          source={source}
          loading={loading}
          handleChange={handleTableChange}
        />
      </Section>
    </Page>
  )
}

export default EmployeeIndex

import { gql } from '@apollo/client'
import { Space } from 'antd'
import React from 'react'

import { AccountManagerStageListRowFragment } from '../../../../../graphql/types'
import Row from './Row'

interface StageListProps {
  stageList: AccountManagerStageListRowFragment[]
}

const StageList = (props: StageListProps) => {
  const { stageList } = props

  return (
    <Space direction='vertical' size={24} style={{ width: '100%' }}>
      {stageList.map((stage) => (
        <Row key={stage.id} stage={stage} />
      ))}
    </Space>
  )
}

StageList.fragments = {
  AccountManagerStage: gql`
    fragment AccountManagerStage on Stage {
      ...AccountManagerStageListRow
    }
    ${Row.fragments.AccountManagerStageListRow}
  `,
}

export default StageList

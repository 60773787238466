import React, { useEffect, useState } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'

import Page, { Section } from '../../../components/layout/Page'
import AccountMenu from './components/AccountMenu'
import { TabKey, isTabkey, tabKeys } from './components/TabConfig'
import TabContent from './components/TabContent'

interface RouteProps {
  accountId: string
}

const AccountDetail = () => {
  const match = useRouteMatch<RouteProps>()
  const accountId = match.params.accountId
  const { hash } = useLocation()
  const [tabKey, setTabKey] = useState<TabKey>(tabKeys[0])

  useEffect(() => {
    if (hash === '') {
      setTabKey(tabKeys[0])
    } else {
      const key = hash.slice(1)
      if (isTabkey(key)) {
        setTabKey(key)
      }
    }
  }, [hash])

  return (
    <div>
      <Page
        header={<AccountMenu accountId={accountId} selectedKeys={[tabKey]} />}
      >
        <Section>
          <TabContent accountId={accountId} tabKey={tabKey} />
        </Section>
      </Page>
    </div>
  )
}

export default AccountDetail

import { LinkOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import {
  DateIntervalParam,
  FormQuery,
  MyArrayParam,
  MyStringParam,
} from '@sov/common'
import { Col, Form, Radio, Row, Space, Spin, Table, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import gql from 'graphql-tag'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  Role,
  StatisticEmployeeTaskQueryQuery,
  StatisticEmployeeTaskQueryVariables,
  StatisticInterval,
  TaskType,
} from '../../../../graphql/types'
import RangePicker from '../../../components/common/RangePicker'
import BreadcrumbCreator from '../../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../../components/layout/Page'
import Title from '../../../components/layout/Title'
import { dateText, defaultInterval } from '../utils'

const RadioButton = Radio.Button
const RadioGroup = Radio.Group
const FormItem = Form.Item

const formInput = {
  interval: MyStringParam,
  startDateInterval: DateIntervalParam,
  brands: MyArrayParam,
  sources: MyArrayParam,
  type: MyStringParam,
}

const statisticEmployeeTaskQuery = gql`
  query StatisticEmployeeTaskQuery(
    $query: EmployeeTaskStatisticQuery!
    $sort: String = "onBoardDate"
  ) {
    employeeTaskStatistic(query: $query) {
      startDate
      endDate
      employee {
        id
        name
      }
      taskType
      count
    }
    employees(sort: $sort) {
      docs {
        id
        name
        role
      }
    }
  }
`

const StatisticEmployeeTask = () => {
  const [form] = useForm()
  const { formQuery, handleFormChange } = FormQuery.useFormQuery(formInput)
  const { t } = useTranslation()

  const initialValues = {
    ...formQuery,
    interval: (formQuery.interval ??
      StatisticInterval.Day) as StatisticInterval,
    startDateInterval: formQuery.startDateInterval ?? [
      moment().startOf('month'),
      moment().startOf('day'),
    ],
  }

  const taskTypes = [
    TaskType.CompleteAlign,
    TaskType.CompletePredesign,
    TaskType.CompleteDesign,
  ]

  const { data, loading } = useQuery<
    StatisticEmployeeTaskQueryQuery,
    StatisticEmployeeTaskQueryVariables
  >(statisticEmployeeTaskQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    variables: {
      query: {
        interval: initialValues.interval as StatisticInterval,
        startDate: initialValues.startDateInterval[0],
        endDate: initialValues.startDateInterval[1],
        taskTypes: taskTypes,
      },
    },
  })

  const employees =
    data?.employees.docs.filter(
      (employee) =>
        employee.role === Role.Technician || employee.name === '葉東憲'
    ) ?? []

  const columns = [
    {
      title: '時間',
      align: 'center',
      dataIndex: 'date',
      width: 80,
    },
    ...employees.map((employee) => {
      return {
        title: () => (
          <Space>
            {employee.name}
            <Link to={`/employees/${employee.id}/tasks`} target='_blank'>
              <LinkOutlined />
            </Link>
          </Space>
        ),
        align: 'center',
        children: [
          ...taskTypes.map((taskType) => {
            return {
              title: `${t(`task.type.${taskType}`)}`,
              align: 'center',
              width: 40,
              dataIndex: `${employee.name} - ${taskType}`,
            }
          }),
          {
            title: '總分',
            align: 'center',
            width: 40,
            render: (value, record) => {
              const totalPoint = taskTypes.reduce((acc, cur) => {
                let point = 0

                if (cur === TaskType.CompleteDesign) {
                  point = 1
                } else if (cur === TaskType.CompletePredesign) {
                  point = 2
                } else if (cur === TaskType.CompleteAlign) {
                  point = 3
                }

                return acc + record[`${employee.name} - ${cur}`] * point
              }, 0)

              return (
                <Typography.Text type='danger'>{totalPoint}</Typography.Text>
              )
            },
          },
        ],
      }
    }),
  ]

  const tableData = (data?.employeeTaskStatistic ?? []).reduce(
    (acc: any[], cur) => {
      const index = acc.findIndex(
        (x: any) => x.date === dateText(initialValues.interval, cur)
      )

      if (index >= 0) {
        const insert = {
          ...acc[index],
          [`${cur.employee.name} - ${cur.taskType}`]: cur.count,
        }
        return [...acc.slice(0, index), insert, ...acc.slice(index + 1)]
      } else {
        const insert = {
          date: dateText(initialValues.interval, cur),
          [`${cur.employee.name} - ${cur.taskType}`]: cur.count,
        }
        return [...acc, insert]
      }
    },
    []
  )

  return (
    <Page
      header={
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <BreadcrumbCreator
              routes={[{ key: 'Home' }, { key: 'StatisticEmployeeTask' }]}
            />
            <Title
              route={{
                key: 'StatisticEmployeeTask',
                renderSubtitle: () =>
                  '即時數據，其中黑字數字為單量，紅色數字為分數',
              }}
            />
          </div>
        </div>
      }
    >
      <Section>
        <Form
          form={form}
          initialValues={initialValues}
          onValuesChange={(changedValues, values) => {
            // 更新區間類型的話，要自動更新區間
            if (changedValues.interval) {
              const startDateInterval = defaultInterval(changedValues.interval)
              form.setFieldsValue({
                startDateInterval: startDateInterval,
              })
              handleFormChange({
                ...values,
                startDateInterval: startDateInterval,
              })
            } else {
              handleFormChange(values)
            }
          }}
        >
          <Row gutter={16}>
            <Col>
              <FormItem name='interval' label='單位'>
                <RadioGroup>
                  <RadioButton value={StatisticInterval.Year}>年</RadioButton>
                  <RadioButton value={StatisticInterval.Quarter}>
                    季
                  </RadioButton>
                  <RadioButton value={StatisticInterval.Month}>月</RadioButton>
                  <RadioButton value={StatisticInterval.Day}>日</RadioButton>
                </RadioGroup>
              </FormItem>
            </Col>
            <Col>
              <FormItem name='startDateInterval' label='區間'>
                <RangePicker
                  allowClear={false}
                  disabledDate={(date) =>
                    date.isBefore(moment('2017-01-01')) ||
                    date.isAfter(moment('2023-01-01'))
                  }
                  picker={
                    (form.getFieldValue('interval') === StatisticInterval.Year
                      ? 'year'
                      : form.getFieldValue('interval') ===
                        StatisticInterval.Quarter
                      ? 'quarter'
                      : form.getFieldValue('interval') ===
                        StatisticInterval.Month
                      ? 'month'
                      : 'date') as any
                  }
                />
              </FormItem>
            </Col>
          </Row>
        </Form>
        {loading ? (
          <Spin size='large' tip='讀取中...' style={{ marginTop: 24 }} />
        ) : (
          <Table
            columns={columns as any}
            dataSource={tableData}
            bordered
            size='middle'
            pagination={false}
          />
        )}
      </Section>
    </Page>
  )
}

export default StatisticEmployeeTask

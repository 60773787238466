import Icon from '@ant-design/icons'
import React from 'react'

const Svg = () => (
  <svg
    width='56'
    height='56'
    viewBox='0 0 56 56'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='28' cy='28' r='28' fill='black' fillOpacity='0.05' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      // eslint-disable-next-line max-len
      d='M17.1667 16.3333C17.1667 14.9526 18.286 13.8333 19.6667 13.8333H29.1193C29.3049 13.8333 29.4884 13.854 29.6667 13.894V19.6667C29.6667 21.5076 31.1591 23 33 23H38.7727C38.8127 23.1783 38.8333 23.3618 38.8333 23.5474V39.6667C38.8333 41.0474 37.714 42.1667 36.3333 42.1667H19.6667C18.286 42.1667 17.1667 41.0474 17.1667 39.6667V16.3333ZM29.7048 12.208C30.5935 12.3341 31.4235 12.745 32.0656 13.3871L39.2796 20.6011C40.061 21.3825 40.5 22.4423 40.5 23.5474V39.6667C40.5 41.9678 38.6345 43.8333 36.3333 43.8333H19.6667C17.3655 43.8333 15.5 41.9679 15.5 39.6667V16.3333C15.5 14.0321 17.3655 12.1667 19.6667 12.1667H29.1193C29.3034 12.1667 29.4862 12.1788 29.6667 12.2028V12.1667L29.7048 12.208ZM31.3333 19.6667V15.5L37.1667 21.3333H33C32.0795 21.3333 31.3333 20.5871 31.3333 19.6667ZM21.3333 28.8333C21.3333 28.3731 21.7064 28 22.1667 28H33.8333C34.2936 28 34.6667 28.3731 34.6667 28.8333C34.6667 29.2936 34.2936 29.6667 33.8333 29.6667H22.1667C21.7064 29.6667 21.3333 29.2936 21.3333 28.8333ZM22.1667 33C21.7064 33 21.3333 33.3731 21.3333 33.8333C21.3333 34.2936 21.7064 34.6667 22.1667 34.6667H33.8333C34.2936 34.6667 34.6667 34.2936 34.6667 33.8333C34.6667 33.3731 34.2936 33 33.8333 33H22.1667Z'
      fill='black'
      fillOpacity='0.2'
    />
  </svg>
)

const DocumentIcon = (props) => <Icon {...props} component={Svg} />

export default DocumentIcon

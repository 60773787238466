import { gql } from '@apollo/client'
import { isEmptyOrNil } from '@sov/common'
import { Col, Row, Space } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { ItemListFragment, StageType } from '../../../../../graphql/types'
import OrderDetailStageLink from '../OrderDetailStageLink'
import braceIcon from './brace.svg'
import { Label, SectionTitle } from './components'
import reportIcon from './report.svg'

const viewableStageTypeList = [StageType.Eval, StageType.Print]

const StageTypeCellContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  img {
    margin-right: 8px;
  }
`

interface StageTypeCellProps {
  type: StageType
}

const StageTypeCell = (props: StageTypeCellProps) => {
  const { type } = props

  if (!viewableStageTypeList.includes(type)) {
    return null
  }

  if (type === StageType.Eval) {
    return (
      <StageTypeCellContainer>
        <img src={reportIcon} />
        <Label>報告:</Label>
      </StageTypeCellContainer>
    )
  }

  if (type === StageType.Print) {
    return (
      <StageTypeCellContainer>
        <img src={braceIcon} />
        <Label>牙套:</Label>
      </StageTypeCellContainer>
    )
  }

  return null
}

const TableColumnHeader = styled.div`
  font-weight: 500;
`

interface ItemListProps {
  orderItem: ItemListFragment
}

const ItemList = (props: ItemListProps) => {
  const { orderItem } = props

  const stageItemList = orderItem.stageProvide.map((x) => x.stages).flat()

  return (
    <>
      <SectionTitle>項目詳情</SectionTitle>
      <Space direction='vertical' size={16} style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col offset={8} span={12}>
            <TableColumnHeader>貨品名稱與數量</TableColumnHeader>
          </Col>
          <Col span={4}>
            <TableColumnHeader>小計</TableColumnHeader>
          </Col>
        </Row>
        {stageItemList.map((stageItem) => (
          <div key={stageItem.id}>
            <Row gutter={16}>
              <Col span={4}>
                <StageTypeCell type={stageItem.type} />
              </Col>
              <Col span={4}>
                <OrderDetailStageLink stageItem={stageItem} />
              </Col>
              <Col span={16}>
                {stageItem.__typename === 'PrintStage' &&
                !isEmptyOrNil(stageItem.invoice) &&
                stageItem.invoice.products.length !== 0 ? (
                  stageItem.invoice.products.map((product) => {
                    return (
                      <Row key={product.name} gutter={16}>
                        <Col span={18}>
                          <div>{`${product.name}(${product.count} * ${product.price})`}</div>
                        </Col>
                        <Col span={6}>
                          <div>{product.count * product.price}</div>
                        </Col>
                      </Row>
                    )
                  })
                ) : (
                  <Row gutter={16}>
                    <Col span={18}>
                      <div>-</div>
                    </Col>
                    <Col span={6}>
                      <div>-</div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        ))}
      </Space>
    </>
  )
}

ItemList.fragments = {
  ItemList: gql`
    fragment ItemList on Order {
      id
      status
      stageProvide {
        stageType
        stages {
          id
          type
          ...OrderDetailStageLink
          ... on PrintStage {
            invoice {
              id
              totalPrice
              products {
                name
                price
                count
              }
            }
            serialNumber
          }
        }
      }
    }
    ${OrderDetailStageLink.fragments.OrderDetailStageLink}
  `,
}

export default ItemList

import React from 'react'
import styled from 'styled-components'

import { RouteKey, routeMap } from '../../../routes/routes'

const Title = styled.div`
  flex: 1;
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
`

const SubTitle = styled.div`
  height: 28px;
  margin-left: 12px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
`

const Container = styled.div`
  display: flex;
  line-height: 1.5;
  align-items: baseline;

  &.ant-menu {
    line-height: 48px;
  }

  @media print {
    display: none;
  }
`

interface Props {
  route: {
    key: RouteKey
    render?: () => JSX.Element | string | undefined
    renderSubtitle?: () => JSX.Element | string | undefined
  }
}

export default (props: Props) => {
  const { route } = props
  const { key, render, renderSubtitle } = route

  return (
    <Container>
      <Title>{render ? render() : routeMap[key].title}</Title>
      {renderSubtitle && <SubTitle>{renderSubtitle()}</SubTitle>}
    </Container>
  )
}

import { useLocalStorage, useLogin } from '@sov/ui'
import { Button, Form, Input } from 'antd'
import { path } from 'ramda'
import React from 'react'
import { Link } from 'react-router-dom'

import SimpleIcon from './SimpleIcon'

const FormItem = Form.Item

type Platform = 'facebook' | 'google' | 'line'

const Login = () => {
  const [form] = Form.useForm()
  const { getFieldsValue } = form
  const [provider] = useLocalStorage('provider')

  const getSocialLoginUrl = (platform: Platform) => {
    const redirect = window.location.origin
    const url = `${process.env.API_HOST}/auth/${platform}?redirect=${redirect}`
    return url
  }
  const login = useLogin()

  const handleSubmit = async () => {
    await login(getFieldsValue())
  }

  const handleClear = () => {
    form.resetFields()
  }

  return (
    <div style={{ padding: 30 }}>
      <h1 className='title'>舒服美 ERP 後台</h1>
      <Form form={form} layout='vertical'>
        <FormItem
          name='username'
          label='Email 或手機'
          rules={[
            {
              required: true,
              message: '必填欄位',
            },
          ]}
        >
          <Input onPressEnter={handleSubmit} placeholder='信箱或手機' />
        </FormItem>
        <FormItem
          name='password'
          label='密碼'
          rules={[
            {
              min: 4,
              max: 16,
              pattern: new RegExp('^[a-zA-Z0-9]'),
              message: '需輸入英文或數字 4 到 16 位',
            },
            {
              required: true,
              message: '必填欄位',
            },
          ]}
        >
          <Input.Password
            type='password'
            placeholder='密碼'
            onPressEnter={handleSubmit}
          />
        </FormItem>
        <FormItem style={{ marginTop: 24 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button type='primary' onClick={handleSubmit}>
              登入
            </Button>
            <Button style={{ marginLeft: 16 }} onClick={handleClear}>
              清除
            </Button>
            <Link
              to='/selectRecoverPasswordMethod'
              style={{ marginLeft: 'auto' }}
            >
              忘記密碼
            </Link>
          </div>
        </FormItem>
        <FormItem style={{ textAlign: 'left' }}>
          <div>
            {(['facebook', 'google', 'line'] as Platform[]).map((platform) => {
              if (path([platform, 'id'], provider)) {
                return (
                  <a key={platform} href={getSocialLoginUrl(platform)}>
                    <SimpleIcon name={platform} />
                  </a>
                )
              }
              return null
            })}
          </div>
        </FormItem>
      </Form>
    </div>
  )
}

export default Login

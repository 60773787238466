import { gql, useMutation, useQuery } from '@apollo/client'
import { Button, Card, Form, InputNumber, message } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  StageCostSystem,
  SystemConfigType,
  SystemStageCostQueryQuery,
  SystemStageCostQueryVariables,
  UpdateSystemStageCostMutation,
  UpdateSystemStageCostMutationVariables,
} from '../../../../graphql/types'

const systemStageCostQuery = gql`
  query SystemStageCostQuery {
    system(configType: STAGE_COST) {
      id
      configType
      ... on StageCostSystem {
        stageCost {
          stageType
          cost
        }
      }
    }
  }
`

const updateSystemStageCostMutation = gql`
  mutation UpdateSystemStageCost(
    $configType: SystemConfigType!
    $payload: UpdateSystemStageCostInput!
  ) {
    updateSystemStageCost(configType: $configType, payload: $payload) {
      id
    }
  }
`

const SystemStageCost = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { data, loading } = useQuery<
    SystemStageCostQueryQuery,
    SystemStageCostQueryVariables
  >(systemStageCostQuery)
  const [updateSystemStageCost] = useMutation<
    UpdateSystemStageCostMutation,
    UpdateSystemStageCostMutationVariables
  >(updateSystemStageCostMutation)

  const handleUpdate = async () => {
    try {
      const hideLoadingMessage = message.loading(
        '更新工單成本設定，並重設所有病患成本中，請勿關閉視窗',
        0
      )
      const fieldsValue =
        (await form.validateFields()) as UpdateSystemStageCostMutationVariables['payload']
      await updateSystemStageCost({
        variables: {
          configType: SystemConfigType.StageCost,
          payload: fieldsValue,
        },
        update: async () => {
          hideLoadingMessage()
          await message.info('已更新系統設定中的工單成本')
        },
      })
    } catch (e) {
      if (e?.message) {
        /** graphQL errors */
        message.error(e.message)
      } else {
        /** form errors or other errors */
        message.error(e.toString())
      }
    }
  }

  if (loading) return <>讀取中</>

  const initialValues = data?.system as StageCostSystem

  return (
    <Card
      title='工單成本'
      style={{ width: 300, height: 600 }}
      extra={
        <Button danger onClick={handleUpdate}>
          更新
        </Button>
      }
    >
      <Form form={form} initialValues={initialValues}>
        {initialValues.stageCost.map((x, index) => {
          return (
            <div key={x.stageType}>
              <Form.Item>{t(`stage.type.${x.stageType}`)}</Form.Item>
              <Form.Item
                hidden
                name={['stageCost', index, 'stageType']}
              ></Form.Item>
              <Form.Item label='成本' name={['stageCost', index, 'cost']}>
                <InputNumber min={0} />
              </Form.Item>
            </div>
          )
        })}
      </Form>
    </Card>
  )
}

export default SystemStageCost

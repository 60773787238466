import { gql, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Select } from 'antd'
import { SelectProps, SelectValue } from 'antd/lib/select'
import { DocumentNode } from 'graphql'
import { equals, find, propEq, sort, union } from 'ramda'
import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { DeepPartial } from 'utility-types'

import {
  ClinicSelectDocs,
  ClinicSelectQuery,
  ClinicSelectQueryVariables,
  ClinicType,
  ClinicsQuery,
} from '../../../../graphql/types'
import CustomSelect from './CustomSelect'

const fragment = gql`
  fragment ClinicInClinicSelect on Clinic {
    id
    type
    name
    specialContract
    accountManagers {
      id
      name
      role
      taskOwner
    }
    technicians {
      id
      name
      role
      taskOwner
    }
    # clinic.sales 是陣列，和 Patient 中的 sales type 不同
    # 如果同時使用兩個 fragment 的話
    # code gen 會跳出 conflicting types 的錯誤
    # 這邊刻意做出不同的命名 (salesList) 來避免此錯誤
    salesList: sales {
      id
      name
      role
      taskOwner
    }
    customerService {
      id
      name
      role
      taskOwner
    }
  }
`

const clinicSelectQuery = gql`
  query ClinicSelect(
    $query: ClinicsQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    clinics(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...ClinicInClinicSelect
      }
    }
  }
  ${fragment}
`

export type ClinicSelectProps = SelectProps<ClinicSelectDocs> & {
  handleItemSelect?: (selectedItem: ClinicSelectDocs) => void
  initItem?: DeepPartial<ClinicSelectDocs>
  query?: ClinicsQuery
}

const ClinicSelectWithoutMemo = (props: ClinicSelectProps) => {
  const { t } = useTranslation()
  const { handleItemSelect, initItem, query = {}, ...restProps } = props
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data } = useQuery<ClinicSelectQuery, ClinicSelectQueryVariables>(
    clinicSelectQuery,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      onError: (error) => {
        toErrorPage(error.message)
      },
      variables: {
        query,
        page: 1,
        limit: 500,
        sort: '-updated',
      },
    }
  )

  const names = ['沐光', '蒔光', '日亞美']

  const fetchedItems = data?.clinics
    ? sort(
        (a, b) =>
          names.findIndex((x) => x === a.name) >
          names.findIndex((x) => x === b.name)
            ? -1
            : 1,
        data.clinics.docs
      )
    : []

  const handleSelect = (id: SelectValue) => {
    if (fetchedItems) {
      const selectedItem = find(propEq('id', id), fetchedItems)
      if (selectedItem && handleItemSelect) {
        handleItemSelect(selectedItem)
      }
    }
  }
  const items = initItem
    ? (union(fetchedItems, [initItem]) as ClinicSelectDocs[])
    : fetchedItems

  return (
    <CustomSelect onSelect={handleSelect} {...restProps}>
      {[ClinicType.Chain, ClinicType.Franchise, ClinicType.Normal].map(
        (type) => (
          <Select.OptGroup key={type} label={t(`clinic.type.${type}`)}>
            {items
              .filter((item) => item.type === type)
              .map((item) => (
                <Select.Option value={item.id} key={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select.OptGroup>
        )
      )}
    </CustomSelect>
  )
}

interface ClinicSelectFragmentMap {
  ClinicInClinicSelect: DocumentNode
}

const ClinicSelect: FC<ClinicSelectProps> & {
  fragments?: ClinicSelectFragmentMap
} = memo(ClinicSelectWithoutMemo, (prevProps, nextProps) =>
  equals(prevProps, nextProps)
)

ClinicSelect.fragments = {
  ClinicInClinicSelect: fragment,
}

export default ClinicSelect

import { compose, flatten, groupBy, reduce, sum, values } from 'ramda'

import { RpTasksQueryDocs } from '../../../../graphql/types'

type TaskItem = RpTasksQueryDocs | undefined

/** 計算總模型數量 */
export const getTotalSelectedModelNumber = reduce<TaskItem, number>(
  (accumulateModelNumber: number, currentTaskItem: TaskItem) => {
    const printStage = currentTaskItem?.stage
    if (printStage?.__typename === 'PrintStage') {
      const currentTaskItemStageTotalModelNumber = sum([
        printStage.designStage?.upperModelCount ?? 0,
        printStage.designStage?.lowerModelCount ?? 0,
      ])
      return accumulateModelNumber + currentTaskItemStageTotalModelNumber
    }
    return accumulateModelNumber
  },
  0
)

/** 依診所名稱排序 */
export const sortTaskItemsByClinic = (
  selectedTaskItems: RpTasksQueryDocs[]
) => {
  const getClinicName = (selectedTaskItem: RpTasksQueryDocs) => {
    if (!selectedTaskItem) {
      return ''
    }
    return selectedTaskItem.patient.clinic.name
  }

  /** 這邊 type 標 any 是因為該 type 只是一個幫助 row key 排列時暫時出現的物件，type 不重要 */
  const groupedSelectedTaskItems = compose<
    RpTasksQueryDocs[],
    any,
    RpTasksQueryDocs[],
    RpTasksQueryDocs[]
  >(
    flatten,
    values,
    groupBy(getClinicName)
  )(selectedTaskItems)

  return groupedSelectedTaskItems
}

import { gql } from '@apollo/client'
import { getInvoiceProductPriceInfo } from '@sov/common'
import { DatePicker, Form, Input, InputNumber, Radio, Switch } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { map, values } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  CreateInvoiceWithStageInput,
  HomeDeliveryType,
  Payment,
} from '../../../../graphql/types'
import { UseLoadingLayerParams } from '../../../helpers/hooks'
import InvoiceClientInfo, {
  InvoiceClientDisplayInfo,
} from './InvoiceClientInfo'
import { InvoiceProductSelection } from './InvoiceProductSelection'

/** @todo: 重構完出貨單中的出貨項目後，這個元件會需要重構，這邊暫時先註解 */
// import { PriceChangingProducts } from './PriceChangingProducts'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
}

const getComputedValues = (form: FormInstance<FormInvoiceFields>) =>
  getInvoiceProductPriceInfo({
    discount: form.getFieldValue('discount'),
    hasTax: form.getFieldValue('hasTax'),
    middlemanFee: form.getFieldValue('middlemanFee'),
    invoiceProducts: form.getFieldValue('products'),
  })

export enum InvoiceFormType {
  PATIENT_STAGE = 'PATIENT_STAGE',
  CLINIC_ONLY = 'CLINIC_ONLY',
}

export type FormInvoiceFields = Pick<
  CreateInvoiceWithStageInput,
  | 'shippingDate'
  | 'creditDate'
  | 'products'
  | 'middlemanFee'
  | 'discount'
  | 'payment'
  | 'homeDeliveryType'
  | 'homeDeliveryId'
  | 'invoiceNumber'
  | 'note'
> & {
  hasTax: boolean
  formType: InvoiceFormType
  patient: string
  stage: string
  clinic: string
  doctor: string
  sales: string
}

export interface FormInvoiceProps {
  isFormTypeChangable?: boolean
  form: FormInstance<FormInvoiceFields>
  initialValues?: Partial<FormInvoiceFields> & InvoiceClientDisplayInfo
}

const FormInvoice = (props: FormInvoiceProps) => {
  const { form, initialValues, isFormTypeChangable } = props
  const { t } = useTranslation()

  return (
    <Form
      {...formItemLayout}
      form={form}
      initialValues={initialValues}
      style={{ flex: 1 }}
    >
      <Form.Item
        dependencies={['products', 'hasTax', 'middlemanFee', 'discount']}
        noStyle
      >
        {() => {
          const { totalPrice, subtotal, tax, middlemanFee, discount } =
            getComputedValues(form)
          return (
            <h3>
              <span>${totalPrice} (合計) = </span>
              <span>
                {/** @todo: 重構完出貨單中的出貨項目後，這邊會需要重構，這邊暫時先註解 */}
                {/*
                 * ${subtotal} (小計)
                 * + ${tax} (税)
                 * + ${middlemanFee} (轉介費)
                 * + ${priceChangingProductsTotal} (其他項目)
                 * - ${discount} (折扣)
                 */}
                {subtotal} (小計) + ${tax} (税) + ${middlemanFee} (轉介費) - $
                {discount} (折扣)
              </span>
            </h3>
          )
        }}
      </Form.Item>
      <InvoiceClientInfo
        isFormTypeChangable={isFormTypeChangable}
        patientInfo={initialValues?.patientInfo}
        stageInfo={initialValues?.stageInfo}
        clinicInfo={initialValues?.clinicInfo}
        doctorInfo={initialValues?.doctorInfo}
        salesInfo={initialValues?.salesInfo}
      />
      <Form.Item name='creditDate' label='入帳日' rules={[{ required: true }]}>
        <DatePicker />
      </Form.Item>
      <Form.Item name='shippingDate' label='出貨日'>
        <DatePicker />
      </Form.Item>
      <Form.Item name='products' label='出貨項目' trigger='setValue'>
        <InvoiceProductSelection isProductEditable />
      </Form.Item>
      <Form.Item dependencies={['products']} noStyle>
        {() => (
          <Form.Item label='出貨項目小計'>
            ${getComputedValues(form).subtotal}
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item name='hasTax' label='印花稅 (5%)' valuePropName='checked'>
        <Switch />
      </Form.Item>
      <Form.Item dependencies={['hasTax', 'products']} noStyle>
        {() => (
          <Form.Item label='稅額'>${getComputedValues(form).tax}</Form.Item>
        )}
      </Form.Item>
      <Form.Item
        name='middlemanFee'
        label='轉介費'
        rules={[
          {
            required: true,
            message: '不可為空白',
          },
        ]}
      >
        <InputNumber />
      </Form.Item>
      {/** @todo: 重構完出貨單中的出貨項目後會移除這個欄位，這邊先暫時註解 */}
      {/* <Form.Item label='其他項目(非固定價格)'>
              {getFieldDecorator('priceChangingProducts', {
                initialValue: (invoiceItem && invoiceItem.priceChangingProducts) ? invoiceItem.priceChangingProducts : [],
                trigger: 'setValue'
              })(<PriceChangingProducts />)}
            </Form.Item> */}
      <Form.Item
        name='discount'
        label='折扣'
        rules={[
          {
            required: true,
            message: '不可為空白',
          },
        ]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        dependencies={['products', 'hasTax', 'middlemanFee', 'discount']}
        noStyle
      >
        {() => (
          <Form.Item label='合計金額'>
            ${getComputedValues(form).totalPrice}
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item name='payment' label='付款情況'>
        <Radio.Group>
          {map(
            (paymentType) => (
              <Radio value={paymentType} key={paymentType}>
                {t(`invoice.payment.${paymentType}`)}
              </Radio>
            ),
            values(Payment)
          )}
        </Radio.Group>
      </Form.Item>
      <Form.Item name='homeDeliveryType' label='宅配種類'>
        <Radio.Group>
          {map(
            (homeDeliveryType) => (
              <Radio value={homeDeliveryType} key={homeDeliveryType}>
                {t(`invoice.homeDeliveryType.${homeDeliveryType}`)}
              </Radio>
            ),
            values(HomeDeliveryType)
          )}
        </Radio.Group>
      </Form.Item>
      <Form.Item name='homeDeliveryId' label='宅配單號'>
        <Input />
      </Form.Item>
      <Form.Item name='invoiceNumber' label='發票單號'>
        <Input />
      </Form.Item>
      <Form.Item name='note' label='出貨單備註'>
        <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
      </Form.Item>
    </Form>
  )
}

FormInvoice.fragments = {
  FormInvoiceStageItem: gql`
    fragment FormInvoiceStageItem on Stage {
      id
      ...InvoiceClientInfoStageItem
    }
    ${InvoiceClientInfo.fragments.InvoiceClientInfoStageItem}
  `,
  FormInvoicePatientItem: gql`
    fragment FormInvoicePatientItem on Patient {
      id
      ...InvoiceClientInfoPatientItem
    }
    ${InvoiceClientInfo.fragments.InvoiceClientInfoPatientItem}
  `,
  FormInvoiceItem: gql`
    fragment FormInvoiceItem on Invoice {
      id
      __typename
      ...InvoiceClientInfoInvoiceItem
      shippingDate
      creditDate
      products {
        productId
        serialNumber
        name
        spec
        price
        cost
        count
      }
      tax
      middlemanFee
      discount
      payment
      homeDeliveryId
      homeDeliveryType
      invoiceNumber
      note
      ... on InvoiceWithStage {
        stage {
          ...InvoiceClientInfoStageItem
        }
        patient {
          ...InvoiceClientInfoPatientItem
        }
      }
    }
    ${InvoiceClientInfo.fragments.InvoiceClientInfoInvoiceItem}
    ${InvoiceClientInfo.fragments.InvoiceClientInfoStageItem}
    ${InvoiceClientInfo.fragments.InvoiceClientInfoPatientItem}
  `,
}

export * from './Buttons'
export * from './sendMail'
export * from './transform'

const loadingState: UseLoadingLayerParams = {
  loading: true,
  tip: '載入中...',
}

const loadingCompleteState: UseLoadingLayerParams = {
  loading: false,
  tip: '',
}

export { FormInvoice, loadingCompleteState, loadingState }

import { gql, useMutation, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Link as SOVLink } from '@sov/ui'
import { Button, Form, Space, Typography, message } from 'antd'
import { GraphQLError } from 'graphql'
import { includes, omit } from 'ramda'
import React from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'

import { removeDoctorMutation } from '../../../graphql/doctor/mutation/remove'
import { updateDoctorMutation } from '../../../graphql/doctor/mutation/update'
import {
  AllPrivilege,
  DoctorDetailQuery,
  DoctorDetailQueryVariables,
  RemoveDoctorMutation,
  RemoveDoctorVariables,
  UpdateDoctorMutation,
  UpdateDoctorVariables,
} from '../../../graphql/types'
import {
  ConfirmButtonDropdownMenu,
  ConfirmButtonProps,
  OnceButton,
} from '../../components/common/button'
import FormDoctor, {
  FormDoctorFields,
  FormDoctorInitialValues,
} from '../../components/form/doctor'
import Page, { Section } from '../../components/layout/Page'
import DoctorMenu from '../../components/pageHeader/doctor'
import { useLoadingLayer } from '../../helpers/hooks'

const doctorQuery = gql`
  query DoctorDetail($id: ID!) {
    doctor(id: $id) {
      id
      name
      phone
      note
      email
      level
      clinics {
        ...ClinicLink
      }
    }
  }
  ${SOVLink.ClinicLink.fragment}
`

interface RouteProps {
  doctorId: string
}

export const DoctorDetail = () => {
  const history = useHistory()
  const match = useRouteMatch<RouteProps>()
  const doctorId = match.params.doctorId

  const { loading, setLoadingLayer } = useLoadingLayer({
    loading: true,
    tip: '載入中...',
  })
  const [update] = useMutation<UpdateDoctorMutation, UpdateDoctorVariables>(
    updateDoctorMutation
  )
  const [remove] = useMutation<RemoveDoctorMutation, RemoveDoctorVariables>(
    removeDoctorMutation
  )

  const [form] = Form.useForm<FormDoctorFields>()
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data } = useQuery<DoctorDetailQuery, DoctorDetailQueryVariables>(
    doctorQuery,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      onCompleted: () => setLoadingLayer({ loading: false, tip: '' }),
      onError: (error) => {
        toErrorPage({
          message: error.message,
          redirect: {
            name: '醫師總覽',
            url: '/doctors',
          },
        })
      },
      variables: {
        id: doctorId,
      },
    }
  )

  const handleSubmit = async () => {
    setLoadingLayer({ loading: true, tip: '更新中...' })
    try {
      const formValues = (await form.validateFields()) as FormDoctorFields
      await update({
        variables: {
          id: doctorId,
          payload: formValues,
        },
        update: (cache, { data }) => {
          if (data) {
            message.info('已更新醫師')
          }
        },
      })
    } catch (error) {
      if (error?.message) {
        /** graphQL errors */
        const isDuplicatedDoctorNameError = includes(
          'duplicated doctor name',
          error.message
        )
        if (isDuplicatedDoctorNameError) {
          message.error(`醫師姓名重複，建議在醫師姓名之後加上(診所)做區隔`)
        } else {
          message.error(`新增醫師失敗: ${error.message}`)
        }
      } else {
        /** form errors or other errors */
        console.log(error)
      }
    } finally {
      setLoadingLayer({ loading: false, tip: '' })
    }
  }

  const handleRemove = async () => {
    setLoadingLayer({ loading: true, tip: '刪除中...' })

    try {
      await remove({
        variables: {
          id: doctorId,
        },
        update: (cache, { data }) => {
          if (data) {
            message.info('已刪除醫師')
            history.push('/doctors')
          }
        },
      })
    } catch (error) {
      const e = error as GraphQLError
      message.error(`刪除醫師失敗: ${e.message}`)
    } finally {
      setLoadingLayer({ loading: false, tip: '' })
    }
  }

  if (loading) {
    return <Page loading />
  }

  const doctorItem = data?.doctor
  if (!doctorItem) {
    toErrorPage({
      message: '不存在的醫師',
      redirect: {
        name: '醫師總覽',
        url: '/doctors',
      },
    })
    return null
  }

  const clinics = doctorItem.clinics

  const confirmButtonDropdownMenuItemsProps: ConfirmButtonProps[] = [
    {
      label: '刪除',
      modalProps: {
        onOk: handleRemove,
      },
      requiredInputText: '刪除醫師',
      requiredPrivilege: AllPrivilege.DoctorDelete,
    },
  ]

  const initialValues: FormDoctorInitialValues = omit(['clinics'], doctorItem)

  return (
    <Page
      header={<DoctorMenu item={doctorItem} selectedKeys={['doctor-detail']} />}
    >
      <Section>
        <Typography.Title level={3}>編輯醫師資料</Typography.Title>
        <Form.Item
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          label='所屬診所'
        >
          <Space size='small'>
            {clinics.length > 0
              ? clinics.map((clinic) => (
                  <SOVLink.ClinicLink key={clinic.id} item={clinic} />
                ))
              : '尚未添加，請到診所頁面新增'}
          </Space>
        </Form.Item>
        <FormDoctor form={form} initialValues={initialValues} />
        <Form.Item wrapperCol={{ span: 16, offset: 6 }}>
          <Space>
            <OnceButton
              label='更新資料'
              onClick={handleSubmit}
              type='primary'
            />
            <Link to='/doctors'>
              <Button>回醫師清單</Button>
            </Link>
            <ConfirmButtonDropdownMenu
              confirmButtonDropdownMenuItemsProps={
                confirmButtonDropdownMenuItemsProps
              }
            />
          </Space>
        </Form.Item>
      </Section>
    </Page>
  )
}

export default DoctorDetail

import { Button, Form, Input, Space } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import { Link } from 'react-router-dom'

import RangePicker from '../../common/RangePicker'
import BreadcrumbCreator from '../../layout/BreadcrumbCreator'
import Page, { Section } from '../../layout/Page'
import Title from '../../layout/Title'
import FeedbackTable from '../../table/Feedback'
import useFeedbackIndex, { FeedbackFormInput } from './useFeedbackIndex'

const CreateButtonLink = () => (
  <Button>
    <Link to='/feedbacks/create'>新增回饋</Link>
  </Button>
)

interface FeedbackFormProps {
  formQuery: FeedbackFormInput
  handleClear: (form: FormInstance<FeedbackFormInput>) => void
  handleSearch: (form: FormInstance<FeedbackFormInput>) => void
}

export const FeedbackForm = (props: FeedbackFormProps) => {
  const { formQuery, handleClear, handleSearch } = props

  const [form] = Form.useForm<FeedbackFormInput>()

  const initialValues = {
    entityName: formQuery.entityName,
    createdDateInterval: formQuery.createdDateInterval,
  }

  return (
    <Form form={form} layout='inline' initialValues={initialValues}>
      <Form.Item label='帳戶名' name='entityName'>
        <Input style={{ width: 120 }} onPressEnter={() => handleSearch(form)} />
      </Form.Item>
      <Form.Item label='回報日' name='createdDateInterval'>
        <RangePicker style={{ width: 250 }} />
      </Form.Item>
      <Space>
        <Button onClick={() => handleSearch(form)}>搜索</Button>
        <Button onClick={() => handleClear(form)}>清除</Button>
        <CreateButtonLink />
      </Space>
    </Form>
  )
}

export const FeedbackIndex = () => {
  const {
    loading,
    source,
    handleSearch,
    handleClear,
    handleTableChange,
    tableFilterInfo,
    tableSortInfo,
    formQuery,
  } = useFeedbackIndex()

  return (
    <Page
      header={
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'FeedbackList' }]}
          />
          <Title route={{ key: 'FeedbackList' }} />
        </>
      }
    >
      <Section>
        <FeedbackForm
          formQuery={formQuery}
          handleClear={handleClear}
          handleSearch={handleSearch}
        />
        <FeedbackTable
          filterInfo={tableFilterInfo}
          handleChange={handleTableChange}
          loading={loading}
          sortInfo={tableSortInfo}
          source={source}
        />
      </Section>
    </Page>
  )
}

export default FeedbackIndex

import { Bar } from '@ant-design/charts'
import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { useQuery } from '@apollo/client'
import {
  DateMomentParam,
  ErrorHandling,
  FormQuery,
  MyStringParam,
} from '@sov/common'
import { Button, DatePicker } from 'antd'
import moment from 'moment'
import React from 'react'

import { productStatQuery } from '../../../../graphql/product/query/productStat'
import {
  ProductStatQueryQuery,
  ProductStatQueryVariables,
} from '../../../../graphql/types'
import LoadingLayer from '../../common/LoadingLayer'
import { ClinicSelect } from '../../form/Select'
import BreadcrumbCreator from '../../layout/BreadcrumbCreator'
import Page, { Section } from '../../layout/Page'
import Title from '../../layout/Title'

const { MonthPicker } = DatePicker

const FormItem = Form.Item

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 3 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 21 },
  },
}

const formInput = {
  clinic: MyStringParam,
  creditDate: DateMomentParam,
}

/**
 * @todo 分離 product form 並標上 type
 */
const ProductReport = ({ form }: FormComponentProps) => {
  const { getFieldDecorator, getFieldsValue } = form

  const { formQuery, handleFormChange } = FormQuery.useFormQuery(formInput)

  const handleSearch = () => handleFormChange(getFieldsValue())

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const query = useQuery<ProductStatQueryQuery, ProductStatQueryVariables>(
    productStatQuery,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      onError: (error) => {
        toErrorPage(error.message)
      },
      variables: {
        query: {
          clinic: formQuery.clinic,
          creditDate: formQuery.creditDate
            ? formQuery.creditDate.toISOString()
            : undefined,
        },
      },
    }
  )
  const { data, loading } = query
  const productStat = data?.productStat || []

  return (
    <Page
      header={
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'ProductReport' }]}
          />
          <Title route={{ key: 'ProductReport' }} />
        </>
      }
    >
      <Section>
        <Form layout='inline'>
          <FormItem {...formItemLayout}>
            {getFieldDecorator('clinic', {
              initialValue: formQuery.clinic,
            })(<ClinicSelect />)}
          </FormItem>
          <FormItem {...formItemLayout}>
            {getFieldDecorator('creditDate', {
              initialValue: formQuery.creditDate || moment(),
            })(<MonthPicker style={{ maxHeight: 30 }} allowClear={false} />)}
          </FormItem>
          <FormItem {...formItemLayout}>
            <Button style={{ marginLeft: 8 }} onClick={handleSearch}>
              搜索
            </Button>
          </FormItem>
        </Form>
        <LoadingLayer loading={loading} tip='載入中...'>
          {productStat && productStat.length > 0 ? (
            <Bar
              style={{ padding: '12px 12px 12px 12px' }}
              xField='count'
              yField='name'
              height={500}
              data={productStat.map((x) => ({
                count: x?.count,
                name: `${x?.product?.name} [${x?.product?.serialNumber}]`,
              }))}
            />
          ) : (
            <div>無資料</div>
          )}
        </LoadingLayer>
      </Section>
    </Page>
  )
}

export default Form.create<FormComponentProps>()(ProductReport)

import React from 'react'
import styled from 'styled-components'

interface Props {
  value: number
  full?: boolean
}

const Display = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`

const DisplayPatientRisk = (props: Props) => {
  const { value, full } = props

  let color
  let text
  switch (true) {
    case value >= 100:
      color = '#f5222d'
      text = '屬風險屬性『超高』'
      break
    case value >= 80:
      color = '#fa541c'
      text = '屬風險屬性『高』'
      break
    case value >= 60:
      color = '#fa8c16'
      text = '屬風險屬性『中』'
      break
    default:
      color = '#52c41a'
      text = ''
      break
  }

  return (
    <Display style={{ color: color }}>
      {full ? text : Math.round(value)}
    </Display>
  )
}

export default DisplayPatientRisk

import React from 'react'

const DontMoothTeeth = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='58'
    height='54'
    viewBox='0 0 58 54'
  >
    <g fill='none' fillRule='evenodd' strokeWidth='2'>
      <path
        stroke='#000'
        fill='#e5e5e5'
        strokeOpacity='.65'
        d='M27.128.64C17.2-1.2 11.028.928 8.615 7.027 4.994 16.175 0 27.259 0 34.207
         0 41.153 5.558 47.72 13.564 47.72s11.586 3.563 19.551 3.563c7.966 0 22.201-8.512
          22.201-17.077 0-8.564-3.479-29.104-8.264-31.335-3.19-1.487-9.832-2.23-19.924-2.23z'
        transform='translate(1 1)'
      />
    </g>
  </svg>
)

const MissingTeeth = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='58'
    height='54'
    viewBox='0 0 58 54'
  >
    <path
      fill='none'
      fillRule='evenodd'
      stroke='#000'
      strokeOpacity='.25'
      strokeWidth='2'
      d='M28.128 1.64C18.2-.2 12.028 1.928 9.615 8.027 5.994 17.175 1 28.259 1 35.207 1 
      42.153 6.558 48.72 14.564 48.72s11.586 3.563 19.551 3.563c7.966 0 22.201-8.512 22.201-17.077 
      0-8.564-3.479-29.104-8.264-31.335-3.19-1.487-9.832-2.23-19.924-2.23z'
    />
  </svg>
)

export { DontMoothTeeth, MissingTeeth }

import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { ErrorHandling } from '@sov/common'
import { useRequest } from '@sov/ui'
import { Button, Input } from 'antd'
import { includes, values } from 'ramda'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

export enum RecoverPasswordMethods {
  EMAIL = 'email',
  PHONE = 'phone',
}

type SendResetPasswordFormInput = { email: string } | { phone: string }

interface Info {
  title: string
  device: string
  format: string
  type?: string
  regex?: RegExp
}

type MethodInfo = { [key in RecoverPasswordMethods]: Info }

type SendResetPasswordFormProps = {
  handleSubmit: (
    form: FormComponentProps['form'],
    method: RecoverPasswordMethods
  ) => void
  handleFormOnFocus: () => void
  helpMessage?: string
  validateStatus?: 'error'
  loading: boolean
  method: RecoverPasswordMethods
} & FormComponentProps<SendResetPasswordFormInput>

const FormItem = Form.Item

const Title = styled.div`
  font-size: 28px;
`
const FormDescription = styled.div`
  margin-top: 24px;
  margin-bottom: 48px;
  font-size: 16px;
`

const methodInfo: MethodInfo = {
  [RecoverPasswordMethods.EMAIL]: {
    title: '電子郵件驗證',
    device: '信箱',
    format: '信件',
    type: 'email',
  },
  [RecoverPasswordMethods.PHONE]: {
    title: '手機簡訊驗證',
    device: '手機',
    format: '簡訊',
    regex: /^\d{9}$/,
  },
}

const SendResetPasswordForm = Form.create<SendResetPasswordFormProps>()(
  (props: SendResetPasswordFormProps) => {
    const {
      form,
      handleSubmit,
      handleFormOnFocus,
      helpMessage,
      validateStatus,
      loading,
      method,
    } = props
    const { getFieldDecorator } = form
    const { t } = useTranslation()
    return (
      <Form>
        <FormItem
          help={helpMessage}
          validateStatus={validateStatus}
          hasFeedback
        >
          {getFieldDecorator(method as string, {
            rules: [
              {
                pattern: methodInfo[method].regex,
                type: methodInfo[method].type,
                message: t(`validate.${method}`),
              },
              { required: true },
            ],
          })(
            <Input
              placeholder={methodInfo[method].device}
              onFocus={handleFormOnFocus}
              addonBefore={
                method === RecoverPasswordMethods.PHONE ? '+886' : undefined
              }
            />
          )}
        </FormItem>
        <FormItem style={{ marginTop: '24px' }}>
          <Button
            type='primary'
            style={{ width: '100%' }}
            loading={loading}
            onClick={() => handleSubmit(form, method)}
          >
            寄送密碼重設{methodInfo[method].format}
          </Button>
        </FormItem>
      </Form>
    )
  }
)

const RecoverPassword = () => {
  const history = useHistory()
  const params = useParams<{ method: string }>()
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  if (!includes(params.method, values(RecoverPasswordMethods))) {
    toErrorPage('找不到該頁面')
  }

  const method = params.method as RecoverPasswordMethods
  const [helpMessage, setHelpMessage] = useState<string | undefined>(undefined)
  const [validateStatus, setValidateStatus] = useState<'error' | undefined>(
    undefined
  )
  const [loading, setLoading] = useState(false)
  const request = useRequest()

  const handleFormOnFocus = () => {
    setHelpMessage(undefined)
    setValidateStatus(undefined)
  }

  const sendEmail = async (email: string) => {
    const res = await request.post('/auth/sendResetPasswordEmail', {
      email,
      hostname: window.location.origin,
    })
    setLoading(false)
    if (res.status === 200) {
      history.push('/resetPasswordEmailSent')
    } else if (res.status === (404 || 502)) {
      const errorMessage = res.message
      setValidateStatus('error')
      setHelpMessage(errorMessage)
    } else {
      toErrorPage(res.message)
    }
  }

  const sendSMS = async (phone: string) => {
    const res = await request.post('/auth/sendResetPasswordSMS', {
      phone: '+886' + phone,
    })
    setLoading(false)
    if (res.status === 200) {
      history.push(`/recoverPasswordSMSCheck/${phone}`)
    } else if (res.status === 404) {
      const errorMessage = res.message
      setValidateStatus('error')
      setHelpMessage(errorMessage)
    } else {
      toErrorPage(res.message)
    }
  }

  const handleSubmit = async (
    form: FormComponentProps['form'],
    method: RecoverPasswordMethods
  ) => {
    const { getFieldValue, validateFields } = form
    validateFields(async (err) => {
      if (err) {
        return null
      }
      const payload = getFieldValue(method)
      setLoading(true)
      if (method === RecoverPasswordMethods.EMAIL) {
        await sendEmail(payload)
      }
      if (method === RecoverPasswordMethods.PHONE) {
        await sendSMS(payload)
      }
      return null
    })
  }

  return (
    <>
      <Title>{methodInfo[method].title}</Title>
      <FormDescription>
        請填寫{methodInfo[method].device}來重設密碼
      </FormDescription>
      <SendResetPasswordForm
        handleSubmit={handleSubmit}
        handleFormOnFocus={handleFormOnFocus}
        helpMessage={helpMessage}
        validateStatus={validateStatus}
        loading={loading}
        method={method}
      />
      <Link to='/login'>{'<返回'}</Link>
    </>
  )
}

export default RecoverPassword

/* global fetch, File */
import './index.less'

import { gql, useMutation } from '@apollo/client'
import { Editor, IAllProps } from '@tinymce/tinymce-react'
import { notification } from 'antd'
import React, { FC, useRef } from 'react'

import {
  UploadAnalysisImageMutation,
  UploadAnalysisImageMutationVariables,
} from '../../../../graphql/types'

const uploadAnalysisImageMutation = gql`
  mutation UploadAnalysisImage($file: Upload!, $options: FileUploadOption!) {
    uploadFile(file: $file, options: $options) {
      id
      path
    }
  }
`

const tinyApiKey = process.env.TINY_API_KEY
export const EDITOR_HEIGHT = 500

/**
 * 預設 imagetools_fetch_image 使用的 fetch 沒有 header
 * 這邊將之覆寫
 */
const customImageFetch = (img: HTMLImageElement) => {
  const imageUrl = img.src
  return new Promise((resolve, reject) => {
    fetch(imageUrl, {
      headers: {
        'Access-Control-Allow-Origin': window.location.origin,
        'Access-Control-Allow-Credentials': 'true',
      },
      method: 'GET',
    })
      .then((response) => {
        const blob = response.blob()
        resolve(blob)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

interface RichTextEditorProps extends IAllProps {
  setUploadingImageCount?: React.Dispatch<React.SetStateAction<number>>
  setValue?: (value: string) => void
  value?: string
}

const RichTextEditor: FC<RichTextEditorProps> = (props) => {
  const { setUploadingImageCount, setValue, value = '', ...restProps } = props
  const editorRef = useRef<any>(null)

  const [uploadImage] = useMutation<
    UploadAnalysisImageMutation,
    UploadAnalysisImageMutationVariables
  >(uploadAnalysisImageMutation)

  /** 參考: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler 的 Example */
  const handleImageUpload = async (
    blobInfo,
    resolve,
    reject,
    handleProgress
  ) => {
    const blob = blobInfo.blob()
    const name = blobInfo.name()
    const file = new File([blob], name)

    uploadImage({
      context: {
        fetchOptions: {
          useUpload: true,
          onStart: () => {
            setUploadingImageCount?.((v) => v + 1)
          },
          onProgress: (ev: ProgressEvent) => {
            handleProgress(
              Number(Math.round((ev.loaded / ev.total) * 100).toFixed(2))
            )
          },
        },
      },
      variables: {
        file,
        options: {
          prefix: 'analysis',
          needThumbnail: false,
        },
      },
      update: async (cache, { data }) => {
        if (data) {
          notification.info({ message: '圖片已上傳完成' })
          setUploadingImageCount?.((v) => v - 1)
          resolve(data.uploadFile.path)
        }
      },
    }).catch((error) => {
      notification.error({ message: `圖片上傳失敗: ${error}` })
      setUploadingImageCount?.((v) => v - 1)
      reject(error)
    })
  }

  const handleEditorStateChange = () => {
    if (editorRef && editorRef.current) {
      const content = editorRef.current.getContent()
      setValue?.(content)
    }
  }

  return (
    <Editor
      apiKey={tinyApiKey}
      onInit={(evt, editor) => (editorRef.current = editor)}
      init={{
        block_formats:
          'Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Preformatted=pre',
        branding: false,
        height: EDITOR_HEIGHT,
        menubar: false,
        images_upload_handler: handleImageUpload,
        imagetools_fetch_image: customImageFetch,
        /** 暫時移除 imagetools 工具列中的旋轉與鏡射選項，避免使用者點擊該功能後忘記 focus editor 導致無法更新診斷報告 */
        imagetools_toolbar: 'editimage imageoptions',
        language: 'zh_TW',
        /** 翻譯文字需另外下載: https://www.tiny.cloud/get-tiny/language-packages/ */
        language_url: `${process.env.API_HOST}/public/langs/zh_TW.js`,
        pagebreak_separator: '&lt;!-- pagebreak --&gt;',
        paste_as_text: true,
        paste_data_images: true,
        plugins: [
          'advlist autolink lists link image imagetools charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
          'pagebreak',
        ],
        toolbar:
          'undo redo | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image | pagebreak | help',
      }}
      initialValue={value}
      onEditorChange={handleEditorStateChange}
      {...restProps}
    />
  )
}

export default RichTextEditor

import './RP.less'

import { gql, useQuery } from '@apollo/client'
import {
  ErrorHandling,
  FormQuery,
  MyStringParam,
  TableQuery,
} from '@sov/common'
import { Button, Row } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import moment from 'moment'
import business from 'moment-business'
import { map, prop, without } from 'ramda'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  RpTasksQueryDocs,
  RpTasksQueryQuery,
  RpTasksQueryVariables,
  TaskStatus,
  TaskType,
} from '../../../../graphql/types'
import BreadcrumbCreator from '../../layout/BreadcrumbCreator'
import Page, { Section } from '../../layout/Page'
import Title from '../../layout/Title'
import ModalTaskUpdateBatch, {
  taskUpdateBatchFragment,
} from '../../modal/TaskUpdateBatch'
import RPListTable from '../../table/RPList'
import { getTotalSelectedModelNumber, sortTaskItemsByClinic } from './utils'

const rpTasksQuery = gql`
  query RPTasksQuery(
    $query: TasksQuery = {}
    $page: Int = 1
    $limit: Int = 50
    $sort: String = "expectedShippingDate"
  ) {
    tasks(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...RPListTable
        ...TaskUpdateBatch
      }
      total
      limit
      page
    }
  }
  ${RPListTable.fragment}
  ${taskUpdateBatchFragment}
`

export enum TeamType {
  RP = 'RP',
  BRACES = 'BRACES',
}

export type RPTableSorterColumn = 'expectedShippingDate'

/** 預設為 RP 組 */
const defaultTeamType = TeamType.RP

const ShowSelectedButton = (
  props: ButtonProps & {
    isShowingOnlySelected: boolean
    totalSelectedModelNumber: number
  }
) => (
  <Button style={{ marginLeft: 12 }} onClick={props.onClick}>
    {props.isShowingOnlySelected
      ? '顯示全部'
      : `顯示已勾選 (${props.totalSelectedModelNumber}) 並按診所排序`}
  </Button>
)

const ShowPrintButton = (
  props: ButtonProps & {
    isPrintMode: boolean
  }
) => (
  <Button style={{ marginLeft: 12 }} onClick={props.onClick}>
    {props.isPrintMode ? '顯示全部欄位' : '顯示列印欄位'}
  </Button>
)

const formInput = {
  teamType: MyStringParam,
}

const RPList = () => {
  const { t } = useTranslation()

  /* 已選取任務 */
  const [selectedTaskItems, setSelectedTaskItems] = useState<
    RpTasksQueryDocs[]
  >([])
  /* 僅顯示已選取任務 */
  const [isShowingOnlySelected, setIsShowingOnlySelected] = useState(false)
  /* 顯示列印欄位 */
  const [isPrintMode, setIsPrintMode] = useState(false)
  /* 顯示任務進行 modal */
  const [isTaskUpdateModalVisible, setIsTaskUpdateModalVisible] =
    useState(false)

  const { tableQuery, paginateQuery, handleTableChange, handleTableReset } =
    TableQuery.useTableQuery<any, RPTableSorterColumn>({
      limit: 100,
      sort: 'expectedShippingDate',
    })
  const { formQuery, handleFormChange } = FormQuery.useFormQuery(formInput)
  const teamType = (formQuery.teamType ?? defaultTeamType) as TeamType

  const variables = {
    query: {
      expectedShippingDateInterval: [
        // @ts-ignore
        business.subtractWeekDays(moment().startOf('day'), 10),
      ],
      status: [TaskStatus.Working],
      /** 只有「製造、配件」有這兩個任務 */
      type:
        teamType === TeamType.BRACES
          ? [TaskType.CompleteBraces]
          : [TaskType.CompleteRp],
    },
    ...paginateQuery,
  }
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading, refetch } = useQuery<
    RpTasksQueryQuery,
    RpTasksQueryVariables
  >(rpTasksQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables,
  })
  const taskItems = data?.tasks?.docs ?? []

  const handleOnlySelectedToggle = () => {
    setIsShowingOnlySelected((isShowingOnlySelected) => !isShowingOnlySelected)
    setSelectedTaskItems(sortTaskItemsByClinic)
  }

  const handlePageReset = () => {
    setIsShowingOnlySelected(false)
    setIsPrintMode(false)
    setSelectedTaskItems([])
    handleTableReset()
  }

  const handleChangeTeamType = (teamType: TeamType) => {
    setSelectedTaskItems([])
    handleFormChange({ teamType })
  }

  const handleTogglePrintMode = () =>
    setIsPrintMode((isPringMode) => !isPringMode)

  const handleBatchUpdate = () => {
    handlePageReset()
    refetch()
  }

  const selectedRowKeys = map(prop('id'), selectedTaskItems)
  const rowSelection = {
    selectedRowKeys,
    onSelect: (record: RpTasksQueryDocs, selected: boolean) => {
      const selectedRow = record
      if (selected) {
        setSelectedTaskItems((originalSelectedTaskItems) => [
          ...originalSelectedTaskItems,
          selectedRow,
        ])
      } else {
        setSelectedTaskItems((originalSelectedTaskItems) =>
          without([selectedRow], originalSelectedTaskItems)
        )
      }
    },
    onSelectAll: (selected: boolean, selectedRows: RpTasksQueryDocs[]) =>
      setSelectedTaskItems(selectedRows),
  }
  return (
    <Page
      header={
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'StageReportRp' }]}
          />
          <Title route={{ key: 'StageReportRp' }} />
        </>
      }
    >
      <Section>
        <Row justify='space-between'>
          <h3>
            條件：Step 或配件預計出貨過期 10 日內，且進行到 RP 或 牙套任務
          </h3>
          <div>
            <Button
              disabled={selectedTaskItems.length === 0}
              id='batch-update-button'
              onClick={() => {
                setIsTaskUpdateModalVisible(true)
              }}
              style={{ marginRight: 16 }}
            >
              批次更新
            </Button>
            <Button.Group>
              <Button
                data-testid='rp-link-button'
                type={teamType === TeamType.RP ? 'primary' : undefined}
                onClick={() => handleChangeTeamType(TeamType.RP)}
              >
                RP 組
              </Button>
              <Button
                data-testid='braces-link-button'
                type={teamType === TeamType.BRACES ? 'primary' : undefined}
                onClick={() => handleChangeTeamType(TeamType.BRACES)}
              >
                牙套 組
              </Button>
            </Button.Group>
            <Button style={{ marginLeft: 12 }} onClick={handlePageReset}>
              清除篩選並刷新
            </Button>
            <ShowSelectedButton
              isShowingOnlySelected={isShowingOnlySelected}
              onClick={handleOnlySelectedToggle}
              totalSelectedModelNumber={getTotalSelectedModelNumber(
                selectedTaskItems
              )}
            />
            <ShowPrintButton
              isPrintMode={isPrintMode}
              onClick={() => handleTogglePrintMode()}
            />
          </div>
        </Row>
        <div style={{ flex: 1, marginTop: 12 }}>
          <RPListTable
            source={data?.tasks}
            sortInfo={tableQuery.sort}
            data={isShowingOnlySelected ? selectedTaskItems : taskItems}
            handleChange={handleTableChange}
            isPrintMode={isPrintMode}
            isShowingOnlySelected={isShowingOnlySelected}
            loading={loading}
            rowSelection={rowSelection}
            selectedRowKeys={selectedRowKeys}
          />
        </div>
        <ModalTaskUpdateBatch
          handleBatchButtonClick={handleBatchUpdate}
          handleModalClose={() => {
            setIsTaskUpdateModalVisible(false)
          }}
          isModalVisible={isTaskUpdateModalVisible}
          selectedTasks={selectedTaskItems}
          t={t}
        />
      </Section>
    </Page>
  )
}

export { RPList }

export default RPList

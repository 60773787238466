import { FolderOpenOutlined, FolderOutlined } from '@ant-design/icons'
import { gql } from '@apollo/client'
import { Tooltip } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

import { ViewerFileIconFragment } from '../../../graphql/types'
import ViewerFilesOverview from './ViewerFilesOverview'

export const ActivableIcon = styled.span<{ active: boolean }>`
  margin: 0 auto;
  border-radius: 50%;

  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  background-color: ${(props) =>
    props.active ? '#52c41a' : 'rgba(0, 0, 0, 0.05)'};

  .anticon {
    color: ${(props) => (props.active ? 'white' : 'rgba(0, 0, 0, 0.25)')};
    font-size: 16px;
  }
`

interface ViewerFileIconProps {
  hasFilesForViewer: boolean
  stageItem: ViewerFileIconFragment
}

const ViewerFileIcon = (props: ViewerFileIconProps) => {
  const { hasFilesForViewer, stageItem } = props

  const [visible, setVisible] = useState(false)

  const handleClick = () => setVisible(true)
  const handleCloseModal = () => setVisible(false)

  return (
    <>
      <ActivableIcon active={hasFilesForViewer} onClick={handleClick}>
        {hasFilesForViewer ? (
          <FolderOutlined />
        ) : (
          <Tooltip title='尚未上傳模型檔案'>
            <FolderOpenOutlined />
          </Tooltip>
        )}
      </ActivableIcon>
      <ViewerFilesOverview
        stageItem={stageItem}
        visible={visible}
        handleCloseModal={handleCloseModal}
      />
    </>
  )
}

ViewerFileIcon.fragments = {
  ViewerFileIcon: gql`
    fragment ViewerFileIcon on Stage {
      id
      ...ViewerFilesOverview
    }
    ${ViewerFilesOverview.fragments.ViewerFilesOverview}
  `,
}

export default ViewerFileIcon

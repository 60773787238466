import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const FormTitle = styled.div`
  font-size: 28px;
`

const Description = styled.div`
  margin-top: 24px;
  margin-bottom: 48px;
  font-size: 16px;
`

const ResetPasswordComplete = () => (
  <>
    <FormTitle>成功更新密碼</FormTitle>
    <Description>
      <div>請返回登入頁，並使用新的密碼登入</div>
    </Description>
    <Link to='/login'>{'<返回登入頁'}</Link>
  </>
)

export default ResetPasswordComplete

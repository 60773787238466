import {
  FileAddOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
  FolderOutlined,
} from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { Auth } from '@sov/common'
import { GqlUpload, InstructionCardPreview, Link, MultiLine } from '@sov/ui'
import {
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd'
import { FormProps } from 'antd/lib/form'
import { isNil } from 'ramda'
import React, { useContext, useState } from 'react'

import {
  AllPrivilege,
  BraceMaterial,
  BraceThickness,
  ContentDesignStageDropStageMutation,
  ContentDesignStageDropStageMutationVariables,
  ContentDesignStageFragment,
  CreateDesignInstructionCardMutation,
  CreateDesignInstructionCardMutationVariables,
  JawPosition,
  StageStatus,
  UpdateDesignStageInTreatmentMutation,
  UpdateDesignStageInTreatmentMutationVariables,
} from '../../../../graphql/types'
import InstructionTagList from '../../../components/common/InstructionTagList'
import { ActivableIcon } from '../../../components/common/ViewerFileIcon'
import {
  getInitialJawFile,
  getInitialToothFiles,
} from '../../../components/form/stage/FormViewerFiles'
import ViewerFilesUpload from '../../../components/modal/ViewerFilesUpload'
import { authContext } from '../../../context'
import useDebounceSave from '../PatientReportEdit/useDebounceSave'
import SelectableStageLink from './SelectableStageLink'
import TagCurrentTask from './TagCurrentTask'
import TagExpectedShippingDate from './TagExpectedShippingDate'
import ToggleForm from './ToggleForm'

const dropStageMutation = gql`
  mutation ContentDesignStageDropStage($id: ID!) {
    dropStage(id: $id) {
      status
    }
  }
`

const createDesignInstructionCard = gql`
  mutation CreateDesignInstructionCard($id: ID!) {
    createInstructionCard(id: $id) {
      id
    }
  }
`

const updateDesignStageInTreatment = gql`
  mutation UpdateDesignStageInTreatment(
    $id: ID!
    $payload: UpdateDesignStageInput!
  ) {
    updateDesignStage(id: $id, payload: $payload) {
      id
    }
  }
`

interface DesignStageForm {
  step: {
    upperStep: string
    lowerStep: string
  }
  note: string
}

interface ContentDesignStageProps {
  isEditing: boolean
  isSelected: boolean
  patientId: string
  designStage: ContentDesignStageFragment
  handleRefetch: () => Promise<void>
  handleEdit: (contentKey: string) => void
  handleSelect: (serialNumber: number) => void
}

const ContentDesignStage = (props: ContentDesignStageProps) => {
  const {
    isEditing,
    isSelected,
    patientId,
    designStage,
    handleRefetch,
    handleEdit,
    handleSelect,
  } = props
  const {
    id: designStageId,
    hasFilesForViewer,
    instructionCard,
    files,
    serialNumber,
  } = designStage

  const hasInstructionCard = !isNil(instructionCard)
  const defaultFiles = [
    ...getInitialToothFiles(files?.teeth),
    ...getInitialJawFile(JawPosition.Upper, files?.upperJawModel),
    ...getInitialJawFile(JawPosition.Lower, files?.lowerJawModel),
  ]

  const auth = useContext(authContext)
  const userPrivileges = Auth.utils.getAuthPrivileges(auth)
  const showDropButton = userPrivileges.includes(AllPrivilege.StageUpdate)

  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dropStage] = useMutation<
    ContentDesignStageDropStageMutation,
    ContentDesignStageDropStageMutationVariables
  >(dropStageMutation)
  const [createInstructionCard] = useMutation<
    CreateDesignInstructionCardMutation,
    CreateDesignInstructionCardMutationVariables
  >(createDesignInstructionCard)
  const [updateDesignStage] = useMutation<
    UpdateDesignStageInTreatmentMutation,
    UpdateDesignStageInTreatmentMutationVariables
  >(updateDesignStageInTreatment)

  const handleOpen = () => setVisible(true)
  const handleClose = () => {
    setVisible(false)
    const messageKey = 'upload-loading'
    message.loading({ content: '更新中', key: messageKey })
    handleRefetch().then(() => {
      message.destroy(messageKey)
    })
  }
  const handleDrop = async () => {
    try {
      setLoading(true)
      await dropStage({
        variables: {
          id: designStageId,
        },
        update: async (_cache, { data }) => {
          if (data) {
            await handleRefetch()
            message.info('已廢棄工單')
          }
        },
      })
    } catch (e) {
      const errorMessage = e?.message ?? '廢棄工單時發生錯誤'
      message.error(errorMessage)
    } finally {
      setLoading(false)
    }
  }
  const handleClickInstructionCard = async () => {
    if (hasInstructionCard) {
      window.open(`/stages/${designStageId}/instruction`, '_blank')
      return
    }
    await createInstructionCard({
      variables: {
        id: designStageId,
      },
      update: async (_cache, { data }) => {
        if (data) {
          message.info('已新增指示卡')
          window.open(`/stages/${designStageId}/instruction`, '_blank')
        }
      },
    })
  }
  const handleUpdate: FormProps['onValuesChange'] = async () => {
    const value = (await form.validateFields()) as DesignStageForm
    await updateDesignStage({
      variables: {
        id: designStageId,
        payload: value,
      },
      update: async (_cache, { errors }) => {
        if (errors) {
          message.error(errors[0].message)
        } else {
          message.info(`已儲存 D${serialNumber} 內容`)
          await handleRefetch()
        }
      },
    })
  }
  const { handleChange } = useDebounceSave({ onSave: handleUpdate })

  return (
    <>
      <Row justify='space-between' style={{ marginBottom: '8px' }}>
        <Space>
          <SelectableStageLink
            isSelected={isSelected}
            stageItem={designStage}
            disabled={designStage.status !== StageStatus.Started}
            handleSelect={() => handleSelect(serialNumber)}
          />
          <TagCurrentTask
            stageItem={designStage}
            handleRefetch={handleRefetch}
          />
          <TagExpectedShippingDate
            stageItem={designStage}
            handleRefetch={handleRefetch}
          />
        </Space>
        <Space size={16}>
          {showDropButton && (
            <Popconfirm
              title={`確定要廢棄 D${serialNumber} 設計單?`}
              onConfirm={handleDrop}
              okButtonProps={{ loading }}
            >
              <Typography.Text type='danger' style={{ cursor: 'pointer' }}>
                廢棄
              </Typography.Text>
            </Popconfirm>
          )}
          <Popover
            content={
              instructionCard ? (
                <InstructionCardPreview instructionItem={instructionCard} />
              ) : (
                '新增指示卡'
              )
            }
            placement='top'
          >
            <ActivableIcon
              active={hasInstructionCard}
              onClick={handleClickInstructionCard}
            >
              {hasInstructionCard ? <FileTextOutlined /> : <FileAddOutlined />}
            </ActivableIcon>
          </Popover>
          <Tooltip title={hasFilesForViewer ? '編輯模型' : '上傳模型'}>
            <ActivableIcon active={hasFilesForViewer} onClick={handleOpen}>
              {hasFilesForViewer ? <FolderOutlined /> : <FolderOpenOutlined />}
            </ActivableIcon>
          </Tooltip>
        </Space>
      </Row>

      <ToggleForm
        active={isEditing}
        form={form}
        initialValues={designStage}
        onValuesChange={handleChange}
        onClick={() => handleEdit(designStageId)}
        style={{ paddingLeft: '16px' }}
      >
        <Typography.Text type='secondary'>治療內容(上顎)</Typography.Text>
        <Form.Item name={['step', 'upperStep']}>
          {isEditing ? (
            <Input.TextArea
              autoSize
              placeholder='填寫 上顎 治療內容'
              style={{ resize: 'none' }}
            />
          ) : (
            <MultiLine text={designStage.step.upperStep} />
          )}
        </Form.Item>
        <Typography.Text type='secondary'>治療內容(下顎)</Typography.Text>
        <Form.Item name={['step', 'lowerStep']}>
          {isEditing ? (
            <Input.TextArea
              autoSize
              placeholder='填寫 下顎 治療內容'
              style={{ resize: 'none' }}
            />
          ) : (
            <MultiLine text={designStage.step.lowerStep} />
          )}
        </Form.Item>
        <Typography.Text type='secondary'>工單注意事項</Typography.Text>
        <Form.Item name='note'>
          {isEditing ? (
            <Input.TextArea autoSize style={{ resize: 'none' }} />
          ) : (
            <MultiLine text={designStage.note} />
          )}
        </Form.Item>
        <Row align='middle' style={{ marginBottom: '24px' }}>
          <Typography.Text type='secondary'>臨床指示：</Typography.Text>
          <InstructionTagList instructionCard={designStage.instructionCard} />
        </Row>
        <Typography.Text type='secondary'>模型編號</Typography.Text>
        <Form.Item name='rpModelNumber'>
          {isEditing ? (
            <Input />
          ) : (
            <MultiLine text={designStage.rpModelNumber} />
          )}
        </Form.Item>
        <Typography.Text type='secondary'>模型數量</Typography.Text>
        <Space style={{ width: '100%' }}>
          <Form.Item name='upperModelCount' label='上顎'>
            {isEditing ? (
              <InputNumber
                size='small'
                style={{ width: '60px', margin: '0 8px' }}
              />
            ) : (
              designStage.upperModelCount
            )}
          </Form.Item>
          <Form.Item name='lowerModelCount' label='下顎'>
            {isEditing ? (
              <InputNumber
                size='small'
                style={{ width: '60px', margin: '0 8px' }}
              />
            ) : (
              designStage.lowerModelCount
            )}
          </Form.Item>
        </Space>
        <Typography.Text type='secondary'>Attachment 模板數量</Typography.Text>
        <Space style={{ width: '100%' }}>
          <Form.Item name='upperAttachmentTemplateCount' label='上顎'>
            {isEditing ? (
              <InputNumber
                size='small'
                style={{ width: '60px', margin: '0 8px' }}
              />
            ) : (
              designStage.upperAttachmentTemplateCount
            )}
          </Form.Item>
          <Form.Item name='lowerAttachmentTemplateCount' label='下顎'>
            {isEditing ? (
              <InputNumber
                size='small'
                style={{ width: '60px', margin: '0 8px' }}
              />
            ) : (
              designStage.lowerAttachmentTemplateCount
            )}
          </Form.Item>
        </Space>
        <Typography.Text type='secondary'>防磨牙套數量</Typography.Text>
        <Space style={{ width: '100%' }}>
          <Form.Item name='upperMouthGuardCount' label='上顎'>
            {isEditing ? (
              <InputNumber
                size='small'
                style={{ width: '60px', margin: '0 8px' }}
              />
            ) : (
              designStage.upperMouthGuardCount
            )}
          </Form.Item>
          <Form.Item name='lowerMouthGuardCount' label='下顎'>
            {isEditing ? (
              <InputNumber
                size='small'
                style={{ width: '60px', margin: '0 8px' }}
              />
            ) : (
              designStage.lowerMouthGuardCount
            )}
          </Form.Item>
        </Space>
        <Typography.Text type='secondary'>牙套/維持器數量</Typography.Text>
        <Space style={{ width: '100%' }}>
          <Form.Item name='upperBraceCount' label='上顎'>
            {isEditing ? (
              <InputNumber
                size='small'
                style={{ width: '60px', margin: '0 8px' }}
              />
            ) : (
              designStage.upperBraceCount
            )}
          </Form.Item>
          <Form.Item name='lowerBraceCount' label='下顎'>
            {isEditing ? (
              <InputNumber
                size='small'
                style={{ width: '60px', margin: '0 8px' }}
              />
            ) : (
              designStage.lowerBraceCount
            )}
          </Form.Item>
        </Space>
        <div style={{ color: 'red' }}>
          提醒：TPU 或 Benq 材質，僅可選 T75 厚度
        </div>
        <Typography.Text type='secondary'>牙套材質</Typography.Text>
        <Space style={{ width: '100%' }}>
          <Form.Item name='upperBraceMaterial' label='上顎'>
            {isEditing ? (
              <Select style={{ width: 120 }}>
                {Object.values(BraceMaterial).map((x, index) => (
                  <Select.Option key={index} value={x}>
                    {x}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              designStage.upperBraceMaterial
            )}
          </Form.Item>
          <Form.Item name='lowerBraceMaterial' label='下顎'>
            {isEditing ? (
              <Select style={{ width: 120 }}>
                {Object.values(BraceMaterial).map((x, index) => (
                  <Select.Option key={index} value={x}>
                    {x}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              designStage.lowerBraceMaterial
            )}
          </Form.Item>
        </Space>
        <Typography.Text type='secondary'>牙套厚度</Typography.Text>
        <Space style={{ width: '100%' }}>
          <Form.Item name='upperBraceThickness' label='上顎'>
            {isEditing ? (
              <Select style={{ width: 120 }}>
                {Object.values(BraceThickness).map((x, index) => (
                  <Select.Option key={index} value={x}>
                    {x}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              designStage.upperBraceThickness
            )}
          </Form.Item>
          <Form.Item name='lowerBraceThickness' label='下顎'>
            {isEditing ? (
              <Select style={{ width: 120 }}>
                {Object.values(BraceThickness).map((x, index) => (
                  <Select.Option key={index} value={x}>
                    {x}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              designStage.lowerBraceThickness
            )}
          </Form.Item>
        </Space>
      </ToggleForm>
      <ViewerFilesUpload
        patientId={patientId}
        stageId={designStageId}
        visible={visible}
        defaultFiles={defaultFiles}
        hasFilesForViewer={hasFilesForViewer}
        handleClose={handleClose}
      />
    </>
  )
}

ContentDesignStage.fragments = {
  ContentDesignStage: gql`
    fragment ContentDesignStage on DesignStage {
      ...StageLink
      ...TagCurrentTaskStageItem
      ...TagExpectedShippingDateStageItem
      id
      note
      status
      hasFilesForViewer
      rpModelNumber
      upperModelCount
      lowerModelCount
      upperAttachmentTemplateCount
      lowerAttachmentTemplateCount
      upperMouthGuardCount
      lowerMouthGuardCount
      upperBraceCount
      upperBraceMaterial
      upperBraceThickness
      lowerBraceCount
      lowerBraceMaterial
      lowerBraceThickness
      instructionCard {
        ...InstructionCardPreview
        ...InstructionTagList
      }
      step {
        upperStep
        lowerStep
      }
      files {
        teeth {
          position
          file {
            ...fileInfo
          }
        }
        upperJawModel {
          ...fileInfo
        }
        lowerJawModel {
          ...fileInfo
        }
      }
    }
    ${Link.StageLink.fragment}
    ${InstructionCardPreview.fragments.InstructionCardPreview}
    ${TagCurrentTask.fragments.TagCurrentTaskStageItem}
    ${TagExpectedShippingDate.fragments.TagExpectedShippingDateStageItem}
    ${InstructionTagList.fragments.InstructionTagList}
    ${GqlUpload.Upload.fragment}
  `,
}

export default ContentDesignStage

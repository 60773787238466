import { Tag } from 'antd'
import {
  find,
  findIndex,
  isEmpty,
  join,
  map,
  prop,
  update,
  without,
} from 'ramda'
import React, { useEffect, useState } from 'react'

import { OnceButton } from '../../common/button'
import {
  ProductSelection as ProductSelectionModal,
  SelectedInvoiceProduct,
} from '../../modal/InvoiceProductSelection'

interface Props {
  isProductEditable: boolean
  setValue?: (products: SelectedInvoiceProduct[]) => void
  value?: SelectedInvoiceProduct[]
}

export const InvoiceProductSelection = (props: Props) => {
  const { isProductEditable, setValue, value = [] } = props
  const [selectedInvoiceProducts, setSelectedInvoiceProducts] = useState<
    SelectedInvoiceProduct[]
  >([])
  const [isModalVisible, setIsModalVisible] = useState(false)

  /** 參考：https://inventingwithmonster.io/20190207-how-to-fix-final-argument-passed-to-useeffect-changed-size-between-renders/ */
  useEffect(() => {
    if (!isEmpty(value)) {
      setSelectedInvoiceProducts(value)
    }
  }, [join(',', map(prop('serialNumber'), value))])

  const handleModalOpen = () => setIsModalVisible(true)
  const handleModalClose = () => setIsModalVisible(false)

  const handleInvoiceProductAdd = (
    selectedInvoiceProduct: SelectedInvoiceProduct
  ) => {
    const index = findIndex(
      (invoiceProduct) =>
        invoiceProduct.productId === selectedInvoiceProduct.productId,
      selectedInvoiceProducts
    )
    const isSelectedInvoiceProductInList = index < 0

    const updatedInvoiceProducts = isSelectedInvoiceProductInList
      ? [selectedInvoiceProduct, ...selectedInvoiceProducts]
      : update(index, selectedInvoiceProduct, selectedInvoiceProducts)
    setSelectedInvoiceProducts(updatedInvoiceProducts)
    if (setValue) {
      setValue(updatedInvoiceProducts)
    }
  }

  const handleInvoiceProductRemove = (id: string) => {
    const removedInvoiceProduct = find(
      (invoiceProduct) => invoiceProduct.productId === id,
      selectedInvoiceProducts
    )
    const removedInvoiceProducts = removedInvoiceProduct
      ? [removedInvoiceProduct]
      : []
    const updatedInvoiceProducts = without(
      removedInvoiceProducts,
      selectedInvoiceProducts
    )
    setSelectedInvoiceProducts(updatedInvoiceProducts)
    if (setValue) {
      setValue(updatedInvoiceProducts)
    }
  }

  return (
    <div>
      {map(
        (selectedInvoiceProduct) => (
          <div
            key={selectedInvoiceProduct.productId}
            style={{ marginBottom: 8 }}
          >
            <Tag
              closable={isProductEditable}
              onClose={() =>
                handleInvoiceProductRemove(selectedInvoiceProduct.productId)
              }
            >
              {`[${selectedInvoiceProduct.serialNumber}] ${selectedInvoiceProduct.name} (${selectedInvoiceProduct.price} * ${selectedInvoiceProduct.count})`}
            </Tag>
          </div>
        ),
        selectedInvoiceProducts
      )}
      <OnceButton
        disabled={!isProductEditable}
        label='增加項目'
        type='primary'
        onClick={handleModalOpen}
      />
      <ProductSelectionModal
        handleModalClose={handleModalClose}
        handleInvoiceProductAdd={handleInvoiceProductAdd}
        isModalVisible={isModalVisible}
      />
    </div>
  )
}

import './ViewerFilesUpload.less'

import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import {
  InboxOutlined,
  PaperClipOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { gql, useApolloClient, useMutation } from '@apollo/client'
import { GqlUpload } from '@sov/ui'
import { Alert, Button, Modal, Progress, Steps, Table, message } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { UploadFile } from 'antd/lib/upload/interface'
import {
  assoc,
  compose,
  eqProps,
  filter,
  find,
  isEmpty,
  isNil,
  join,
  length,
  map,
  prop,
  propEq,
  reject,
  sort,
  uniqWith,
  values,
} from 'ramda'
import React, { useEffect, useState } from 'react'

import {
  FileInfoFragment,
  JawPosition,
  ToothPosition,
  UpdateViewerFilesMutation,
  UpdateViewerFilesMutationVariables,
} from '../../../../graphql/types'
import ViewerFilesTable from '../../common/ViewerFilesTable'
import {
  getPosition,
  getUpdateViewerFilesPayload,
  handleCheckFileName,
  isNewUploaded,
} from './utils'

/** query string */
const updateViewerFilesMutation = gql`
  mutation UpdateViewerFiles(
    $id: ID!
    $payload: ViewerFilesInput!
    $cpSerialNumber: Int
  ) {
    updateViewerFiles(
      id: $id
      payload: $payload
      cpSerialNumber: $cpSerialNumber
    ) {
      id
    }
  }
`

/** types and components */
const START_STEP = 0
const FINISH_STEP = 2

export type CustomFileType = UploadFile<FileInfoFragment> & {
  position: ToothPosition | JawPosition
}

interface FileListColumnType {
  position: string
  file?: CustomFileType
}

interface ModalFooterProps {
  step: number
  canNext: boolean
  handleConfirmCancel: () => void
  handleSubmit: () => Promise<void>
  handleNext: () => void
  handlePrev: () => void
}

const ModalFooter = (props: ModalFooterProps) => {
  const {
    step,
    canNext,
    handleConfirmCancel,
    handleSubmit,
    handleNext,
    handlePrev,
  } = props

  return (
    <>
      {step === START_STEP && (
        <Button key='cancel' onClick={handleConfirmCancel}>
          取消
        </Button>
      )}
      {step > START_STEP && (
        <Button key='prev' onClick={handlePrev}>
          上一步
        </Button>
      )}
      {step < FINISH_STEP && step > START_STEP && (
        <Button
          key='next'
          type='primary'
          onClick={handleNext}
          disabled={!canNext}
        >
          下一步
        </Button>
      )}
      {step === FINISH_STEP && (
        <Button key='finish' type='primary' onClick={handleSubmit}>
          送出
        </Button>
      )}
    </>
  )
}

interface FileProcessProps {
  file?: CustomFileType
  showProgress?: boolean
}

/** 上傳進度條 */
const FileProcess = (props: FileProcessProps) => {
  const { file, showProgress = true } = props
  if (isNil(file)) {
    return (
      <div className='file-process-wrapper'>
        <div className='no-file align-left secondary'>無對應檔案</div>
      </div>
    )
  }

  const isNewFile = isNewUploaded(file)
  return (
    <div className='file-process-wrapper'>
      <div className='file-name'>
        <PaperClipOutlined className='secondary' />
        <div className={`${isNewFile ? 'secondary' : 'light'} text-overflow`}>
          <a href={file.response?.path}>
            {!isNewFile && '（原有檔案）'}
            {file.response?.filename}
          </a>
        </div>
      </div>
      {isNewFile && showProgress && (
        <Progress
          className='align-left'
          percent={Math.round(file.percent || 0)}
        />
      )}
    </div>
  )
}

interface StepOneProps extends FormComponentProps {
  handleSetInvalidFileName: (file: UploadFile) => void
}

const StepOne = (props: StepOneProps) => {
  const { form, handleSetInvalidFileName } = props
  const client = useApolloClient()

  return (
    <>
      <div className='upload-info secondary'>
        為了提供給醫師正確的 Viewer
        檢視，請上傳此工單對應的分牙檔案及牙肉檔案，系統將依照檔案名稱自動配對牙齒對應位置。
      </div>
      <Form>
        {form.getFieldDecorator('viewerFiles', {
          valuePropName: 'fileList',
          getValueFromEvent: prop('fileList'),
        })(
          <GqlUpload.Upload
            client={client}
            fileType='model'
            uploadOptions={{ prefix: 'stage', needThumbnail: false }}
            accept='.stl'
            beforeUpload={(file: UploadFile) =>
              handleCheckFileName(file, handleSetInvalidFileName)
            }
            showUploadList={false}
            multiple
            dragger
          >
            <>
              <p className='ant-upload-drag-icon'>
                <InboxOutlined />
              </p>
              <p className='ant-upload-text'>點選瀏覽檔案 或 拖曳檔案</p>
              <div className='secondary'>
                <p>命名請依照：</p>
                <p>Tooth_1 ~ Tooth_32、Tooth_UpperJaw、Tooth_LowerJaw</p>
                <p>支援檔案類型：.stl</p>
              </div>
            </>
          </GqlUpload.Upload>
        )}
      </Form>
    </>
  )
}

interface StepTwoProps extends FormComponentProps {
  isAllFilesDone: boolean
  uploadingCount: number
  viewerFilesWithPosition: CustomFileType[]
  invalidFileNamesUtils: {
    invalidFileNames: string[]
    handleSetInvalidFileName: (file: UploadFile) => void
    handleClearInvalidFileNames: () => void
  }
  handleRemoveFile: (file: CustomFileType) => void
}

const StepTwo = (props: StepTwoProps) => {
  const {
    form,
    isAllFilesDone,
    uploadingCount,
    viewerFilesWithPosition,
    invalidFileNamesUtils,
    handleRemoveFile,
  } = props
  const {
    invalidFileNames,
    handleSetInvalidFileName,
    handleClearInvalidFileNames,
  } = invalidFileNamesUtils

  const client = useApolloClient()

  const dataSource = map(
    (position) => ({
      position,
      file: find(propEq('position', position), viewerFilesWithPosition),
    }),
    [...values(ToothPosition), ...values(JawPosition)]
  )

  const columns: ColumnProps<FileListColumnType>[] = [
    {
      title: '牙位',
      key: 'position',
      width: 100,
      render: (text, record) => (
        <div className='secondary'>{record.position}</div>
      ),
    },
    {
      title: '匹配檔案',
      key: 'file',
      render: (text, record) => <FileProcess file={record.file} />,
    },
    {
      title: '操作',
      key: 'action',
      width: 100,
      render: (text, record) => {
        const file = record.file
        if (isNil(file)) return null
        return (
          <Button type='link' onClick={() => handleRemoveFile(file)}>
            刪除
          </Button>
        )
      },
    },
  ]

  return (
    <>
      <div className='secondary'>
        <div
          className={`upload-info add-files ${
            isAllFilesDone ? '' : 'invisible'
          }`}
        >
          <span>
            請確認上傳的檔案與指定牙位相對應，若對應錯誤請更改檔案命名後重新上傳。
          </span>
          <span onClick={() => handleClearInvalidFileNames()}>
            <Form>
              {form.getFieldDecorator('viewerFiles', {
                valuePropName: 'fileList',
                getValueFromEvent: prop('fileList'),
              })(
                <GqlUpload.Upload
                  client={client}
                  fileType='model'
                  uploadOptions={{ prefix: 'stage', needThumbnail: false }}
                  accept='.stl'
                  beforeUpload={(file: UploadFile) =>
                    handleCheckFileName(file, handleSetInvalidFileName)
                  }
                  showUploadList={false}
                  multiple
                >
                  <Button>
                    <UploadOutlined />
                    瀏覽檔案
                  </Button>
                </GqlUpload.Upload>
              )}
            </Form>
          </span>
        </div>
        {!isAllFilesDone && (
          <div className='upload-info'>
            <span className='uploading-file-count'>
              {viewerFilesWithPosition.length - uploadingCount}/
              {viewerFilesWithPosition.length}
            </span>
            檔案上傳中，請勿關閉此視窗。
          </div>
        )}
        {!isEmpty(invalidFileNames) && (
          <Alert
            className='upload-info'
            message={`${join('、', invalidFileNames)} 上傳失敗`}
            type='error'
            onClose={() => handleClearInvalidFileNames()}
            closable
            showIcon
          />
        )}
        <div className='upload-info'>
          <ViewerFilesTable files={viewerFilesWithPosition} />
        </div>
      </div>
      <Table
        rowKey='position'
        size='middle'
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ y: 200 }}
      />
    </>
  )
}

interface StepThreeProps {
  viewerFilesWithPosition: CustomFileType[]
}

const StepThree = (props: StepThreeProps) => {
  const { viewerFilesWithPosition } = props

  return (
    <div>
      <div className='upload-info secondary'>以下為病患上傳的牙位檔案</div>
      <ViewerFilesTable files={viewerFilesWithPosition} />
    </div>
  )
}

interface ViewerFilesUploadModalProps extends FormComponentProps {
  stageId: string
  patientId: string
  visible?: boolean
  defaultFiles?: UploadFile[]
  hasFilesForViewer?: boolean
  cpSerialNumber?: number
  handleClose: () => void
}

const ViewerFilesUploadModal = Form.create()(
  (props: ViewerFilesUploadModalProps) => {
    const {
      form,
      stageId,
      cpSerialNumber,
      visible = false,
      defaultFiles = [],
      hasFilesForViewer = false,
      handleClose,
    } = props

    const [step, setStep] = useState(
      hasFilesForViewer ? START_STEP + 1 : START_STEP
    )
    const [invalidFileNames, setInvalidFileNames] = useState<string[]>([])
    const [update] = useMutation<
      UpdateViewerFilesMutation,
      UpdateViewerFilesMutationVariables
    >(updateViewerFilesMutation)

    const viewerFiles: UploadFile[] | undefined =
      form.getFieldValue('viewerFiles')
    /**
     * 將檔案都加上 `position` property
     * 若相同牙位有新舊檔案時，以新上傳的檔案取代舊有檔案
     */
    const viewerFilesWithPosition = compose<
      UploadFile[],
      CustomFileType[],
      CustomFileType[],
      CustomFileType[]
    >(
      uniqWith(eqProps('position')),
      sort((a) => (isNewUploaded(a) ? -1 : 1)),
      map((file) => assoc('position', getPosition(file), file))
    )(viewerFiles || [])

    const uploadingCount = length(
      filter(propEq('status', 'uploading'), viewerFilesWithPosition)
    )
    const isAllFilesDone = uploadingCount === 0

    const handleNext = () =>
      setStep((state) => (state < FINISH_STEP ? state + 1 : state))
    const handlePrev = () =>
      setStep((state) => (state > START_STEP ? state - 1 : state))
    const handleCancel = () => {
      handleClose()
      form.resetFields()
      setStep(hasFilesForViewer ? START_STEP + 1 : START_STEP)
    }
    const handleConfirmCancel = () => {
      Modal.confirm({
        className: 'confirm-cancel',
        title: '離開',
        content: (
          <div>
            <div>關閉視窗，會喪失所有進行中的檔案。</div>
            <div className='red'>確定要離開嗎？</div>
          </div>
        ),
        onOk: () => handleCancel(),
        okButtonProps: { danger: true },
        autoFocusButton: null,
        centered: true,
      })
    }
    const handleSubmit = async () => {
      await update({
        variables: {
          id: stageId,
          payload: getUpdateViewerFilesPayload(viewerFilesWithPosition),
          cpSerialNumber,
        },
        update: async (cache, { data }) => {
          if (data) {
            handleCancel()
            message.info('已更新檔案')
          }
        },
      })
    }
    const handleSetInvalidFileName = (file: UploadFile) =>
      setInvalidFileNames((state) => [...state, file.name])
    const handleClearInvalidFileNames = () => setInvalidFileNames([])
    const handleRemoveFile = (removeFile: CustomFileType) =>
      form.setFieldsValue({
        viewerFiles: reject(
          (file) => file.uid === removeFile.uid,
          viewerFiles || []
        ),
      })

    useEffect(() => {
      /** 每次開啟 modal 時觸發 */
      if (visible) {
        /** 將之前已上傳的牙齒檔案設為 initial value */
        form.getFieldDecorator('viewerFiles')
        form.setFieldsValue({
          viewerFiles: defaultFiles,
        })
      }
    }, [visible])

    useEffect(() => {
      /** 在 step 1，且有新的檔案上傳時，切換到 step 2 */
      if (
        !isNil(viewerFiles) &&
        viewerFiles.length > defaultFiles.length &&
        step === 0
      ) {
        handleNext()
      }
    }, [viewerFiles])

    return (
      <Modal
        title='上傳檔案'
        visible={visible}
        width='744px'
        onCancel={handleConfirmCancel}
        footer={
          <ModalFooter
            step={step}
            canNext={isAllFilesDone}
            handleConfirmCancel={handleConfirmCancel}
            handleSubmit={handleSubmit}
            handlePrev={handlePrev}
            handleNext={handleNext}
          />
        }
        centered
      >
        <div className='modal-body'>
          <Steps className='steps' current={step}>
            <Steps.Step title='選擇檔案' />
            <Steps.Step title='載入檔案' />
            <Steps.Step title='確認送出' />
          </Steps>
          <div className={step === START_STEP ? '' : 'invisible'}>
            <StepOne
              form={form}
              handleSetInvalidFileName={handleSetInvalidFileName}
            />
          </div>
          <div className={step === START_STEP + 1 ? '' : 'invisible'}>
            <StepTwo
              form={form}
              isAllFilesDone={isAllFilesDone}
              uploadingCount={uploadingCount}
              viewerFilesWithPosition={viewerFilesWithPosition}
              invalidFileNamesUtils={{
                invalidFileNames,
                handleSetInvalidFileName,
                handleClearInvalidFileNames,
              }}
              handleRemoveFile={handleRemoveFile}
            />
          </div>
          <div className={step === FINISH_STEP ? '' : 'invisible'}>
            <StepThree viewerFilesWithPosition={viewerFilesWithPosition} />
          </div>
        </div>
      </Modal>
    )
  }
)

export default Form.create<ViewerFilesUploadModalProps>()(
  ViewerFilesUploadModal
)

import { DatePicker } from 'antd'
import { RangePickerProps } from 'antd/lib/date-picker'
import moment, { Moment } from 'moment'
import React from 'react'

const CustomRangePicker = DatePicker.RangePicker

// 轉換 picker 時間單位到 moment 的時間單位
const pickerUnitTimeMap: Record<
  NonNullable<RangePickerProps['picker']>,
  moment.unitOfTime.StartOf
> = {
  year: 'year',
  quarter: 'quarter',
  month: 'month',
  week: 'week',
  date: 'day',
  time: 'hour',
}

const RangePicker = (props: RangePickerProps) => {
  const { picker, onChange: formOnChange } = props
  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (!formOnChange) return

    if (dates) {
      const [start, end] = dates
      if (start && end) {
        const unitTime = picker
          ? pickerUnitTimeMap[picker]
          : pickerUnitTimeMap.date
        const values = [
          moment(start).startOf(unitTime),
          moment(end).endOf(unitTime),
        ] as [Moment, Moment]
        formOnChange(values, dateStrings)
      } else {
        formOnChange(dates, dateStrings)
      }
    } else {
      formOnChange(dates, dateStrings)
    }
  }
  const newProps = { ...props, onChange }
  return <CustomRangePicker {...newProps} />
}

export default RangePicker

import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { gql, useQuery } from '@apollo/client'
import {
  ErrorHandling,
  FormQuery,
  MyStringParam,
  TableQuery,
} from '@sov/common'
import { Button, Input } from 'antd'
import React from 'react'

import {
  DiariesQueryQuery,
  DiariesQueryQueryVariables,
} from '../../../graphql/types'
import BreadcrumbCreator from '../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../components/layout/Page'
import Title from '../../components/layout/Title'
import DiaryTable from '../../components/table/Diary'

/* ----- query string ----- */
const diariesQuery = gql`
  query DiariesQuery(
    $query: DiariesQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    diaries(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...DiaryTable
      }
      limit
      total
      page
    }
  }
  ${DiaryTable.fragment}
`

export type DiarySorterField = '-diaryDate'

const formInput = {
  patientName: MyStringParam,
  clinicName: MyStringParam,
}

interface DiaryFormInput {
  patientName?: string
  clinicName?: string
}

interface DiaryFormProps extends FormComponentProps {
  formQuery: DiaryFormInput
  handleSearch: (formValues: DiaryFormInput) => void
}

const DiaryForm = Form.create<DiaryFormProps>()(
  ({ form, formQuery, handleSearch }: DiaryFormProps) => {
    const { getFieldDecorator, getFieldsValue } = form

    return (
      <Form layout='inline' style={{ paddingBottom: '16px' }}>
        <Form.Item label='診所名稱'>
          {getFieldDecorator('clinicName', {
            initialValue: formQuery.clinicName,
          })(<Input />)}
        </Form.Item>
        <Form.Item label='病患姓名'>
          {getFieldDecorator('patientName', {
            initialValue: formQuery.patientName,
          })(<Input />)}
        </Form.Item>
        <Form.Item>
          <Button onClick={() => handleSearch(getFieldsValue())}>搜索</Button>
        </Form.Item>
      </Form>
    )
  }
)

export const DiaryIndex = () => {
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { tableQuery, paginateQuery, handleTableChange } =
    TableQuery.useTableQuery<any, DiarySorterField>({
      limit: 100,
      sort: '-diaryDate',
    })
  const { formQuery, handleFormChange } = FormQuery.useFormQuery(formInput)
  const { data, loading } = useQuery<
    DiariesQueryQuery,
    DiariesQueryQueryVariables
  >(diariesQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {
        patientName: formQuery.patientName,
        clinicName: formQuery.clinicName,
      },
      ...paginateQuery,
    },
  })

  const handleSearch = (formValues) => handleFormChange(formValues, 'pushIn')
  const diaries = data?.diaries

  return (
    <Page
      header={
        <>
          <BreadcrumbCreator routes={[{ key: 'Home' }, { key: 'DiaryList' }]} />
          <Title
            route={{ key: 'DiaryList', render: () => <div>日記列表</div> }}
          />
        </>
      }
    >
      <Section>
        <DiaryForm formQuery={formQuery} handleSearch={handleSearch} />
        <DiaryTable
          loading={loading}
          source={diaries}
          sortInfo={tableQuery.sort}
          handleChange={handleTableChange}
        />
      </Section>
    </Page>
  )
}

export default DiaryIndex

import { gql, useMutation } from '@apollo/client'
import { Form, message, Modal, Spin } from 'antd'
import moment, { Moment } from 'moment'
import { compose, sort, take } from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'

import {
  BaseModalFragment,
  ForwardStageTaskFormFragment,
  ForwardStageTaskMutation,
  ForwardStageTaskMutationVariables,
  RecentStageTimelineFragment,
  StageType,
} from '../../../../../graphql/types'
import StageCapacityChart from '../../../chart/StageCapacityChart'
import BackgroundImage from './BackgroundImage'
import {
  BasicFormFields,
  CreateStageGeneralForm,
} from './CreateStageGeneralForm'
import { ForwardStageTaskForm } from './ForwardStageTaskForm'
import { RecentStageTimeline } from './RecentStageTimeline'

export { formItemLayout } from './CreateStageGeneralForm'

export type BaseModalFormFields = BasicFormFields

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const FormContainer = styled.div`
  padding: 24px;
  flex: 1;
`

const forwardStageTaskMutation = gql`
  mutation ForwardStageTask($id: ID!, $payload: ForwardTaskInput!) {
    forwardTask(id: $id, payload: $payload) {
      id
    }
  }
`

export interface BaseModalProps {
  children: React.ReactElement | ((props: any) => JSX.Element)
  getCreatedStage: (
    fieldsValue: any
  ) => Promise<ForwardStageTaskFormFragment | undefined>
  loading?: boolean
  onCancel: (options?: { shouldRefresh: boolean }) => void
  onSubmit?: (newStageId: string) => void
  patientItem?: BaseModalFragment
  stageType: StageType
  visible: boolean
}

const BaseModal = (props: BaseModalProps) => {
  const {
    children,
    getCreatedStage,
    loading = false,
    onCancel,
    onSubmit,
    patientItem,
    stageType,
    visible,
  } = props
  const [stageForm] = Form.useForm()
  const [taskForm] = Form.useForm()
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [createdStage, setCreatedStage] =
    useState<ForwardStageTaskFormFragment>()
  const [currentStep, setCurrentStep] = useState(1)
  const [noCapacityDates, setNoCapacityDates] = useState<Moment[]>([])
  const [forwardStageTask] = useMutation<
    ForwardStageTaskMutation,
    ForwardStageTaskMutationVariables
  >(forwardStageTaskMutation)

  const resetModal = () => {
    setCreatedStage(undefined)
    setCurrentStep(1)
    stageForm.resetFields()
    taskForm.resetFields()
  }

  const handleStageCreate = async () => {
    setIsButtonLoading(true)
    try {
      const values = await stageForm.validateFields()
      const createdStage = await getCreatedStage(values)
      setCurrentStep(2)

      setCreatedStage(createdStage)
    } catch (errors) {
      message.error('欄位有缺')
    } finally {
      setIsButtonLoading(false)
    }
  }

  const handleTaskForward = async () => {
    /** 第二步 */
    setIsButtonLoading(true)
    try {
      if (createdStage?.currentTask) {
        const { nextOwner } = await taskForm.validateFields()
        const variables = {
          id: createdStage.currentTask.id,
          payload: {
            nextOwner: nextOwner as string,
          },
        }
        await forwardStageTask({ variables })

        resetModal()
        if (onSubmit) {
          onSubmit(createdStage?.id)
        }
      }
    } catch (errors) {
      message.error('無法進行任務')
    } finally {
      setIsButtonLoading(false)
    }
  }

  const handleOk = async () => {
    if (currentStep === 1) {
      await handleStageCreate()
    } else if (currentStep === 2) {
      await handleTaskForward()
    }
  }

  const handleCancel = () => {
    resetModal()
    /** 如果有新增工單，關掉 modal 時需要重整頁面以更新 cache */
    if (onCancel) {
      onCancel({ shouldRefresh: currentStep === 2 })
    }
  }

  const stageItems = patientItem?.stages?.docs ?? []
  const recentStages = compose<
    RecentStageTimelineFragment[],
    RecentStageTimelineFragment[],
    RecentStageTimelineFragment[]
  >(
    take(5),
    sort((a, b) =>
      moment(a.expectedShippingDate).isBefore(moment(b.expectedShippingDate))
        ? 1
        : -1
    )
  )(stageItems)

  return (
    <Modal
      style={{ top: 30 }}
      bodyStyle={{
        padding: 0,
      }}
      width={1024}
      closable={false}
      maskClosable={false}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: isButtonLoading || loading }}
      cancelButtonProps={{ disabled: isButtonLoading || loading }}
    >
      <Spin spinning={loading}>
        {(stageType === StageType.Eval || stageType === StageType.Print) && (
          <StageCapacityChart
            stageType={stageType}
            setNoCapacityDates={setNoCapacityDates}
          />
        )}
        <Container>
          {currentStep === 1 && (
            <>
              <RecentStageTimeline recentStages={recentStages} />
              <FormContainer>
                <CreateStageGeneralForm
                  form={stageForm}
                  stageType={stageType}
                  patientItem={patientItem}
                  noCapacityDates={noCapacityDates}
                >
                  {children}
                </CreateStageGeneralForm>
              </FormContainer>
            </>
          )}
          {createdStage && currentStep === 2 && (
            <>
              <BackgroundImage />
              <FormContainer>
                <ForwardStageTaskForm item={createdStage} form={taskForm} />
              </FormContainer>
            </>
          )}
        </Container>
      </Spin>
    </Modal>
  )
}

BaseModal.fragments = {
  ForwardStageTaskForm: ForwardStageTaskForm.fragment,
  BaseModal: gql`
    fragment BaseModal on Patient {
      id
      ...CreateStageGeneralForm
      stages(query: $query, page: $page, limit: $limit, sort: $sort) {
        docs {
          id
          ...RecentStageTimeline
        }
      }
    }
    ${RecentStageTimeline.fragment}
    ${CreateStageGeneralForm.fragment}
  `,
}
export default BaseModal

import { Form, Input, Radio } from 'antd'
import { evolve } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { EntityType } from '../../../../graphql/types'
import { AccountFilterType } from './AccountTable'

const FormItem = Form.Item

export interface FormInputType {
  entityName?: string
  filters?: AccountFilterType
}

type AccountQueryFormProps = {
  handleSearch: (x: FormInputType) => void
  formQuery?: FormInputType
}

const AccountQueryForm = (props: AccountQueryFormProps) => {
  const { handleSearch, formQuery } = props
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const optionValueToQueryParams = (value: string | number | undefined) => {
    if (value === 'all') {
      return undefined
    }
    return [value]
  }

  const handleSubmit = () => {
    const formValues = form.getFieldsValue()
    const { entityName, ...restFormValues } = formValues
    const formInput = {
      entityName,
      filters: evolve({
        entityType: optionValueToQueryParams,
        isActive: optionValueToQueryParams,
      })(restFormValues),
    }
    handleSearch(formInput)
  }

  const initialValues = {
    entityType: formQuery?.filters?.entityType?.[0] || 'all',
    isActive:
      formQuery?.filters?.isActive === undefined
        ? 'all'
        : String(formQuery?.filters?.isActive),
    entityName: formQuery?.entityName,
  }

  return (
    <Form form={form} initialValues={initialValues} layout='inline'>
      <FormItem name='entityType' label='身份'>
        <Radio.Group onChange={handleSubmit}>
          {[
            EntityType.Patient,
            EntityType.Doctor,
            EntityType.Clinic,
            EntityType.Employee,
          ].map((entityType) => (
            <Radio.Button value={entityType} key={entityType}>
              {t(`account.entityType.${entityType}`)}
            </Radio.Button>
          ))}
        </Radio.Group>
      </FormItem>
      <FormItem name='entityName' label='搜索身份'>
        <Input style={{ width: 105 }} onPressEnter={handleSubmit} />
      </FormItem>
    </Form>
  )
}

export default AccountQueryForm

import React, { FC } from 'react'
import styled from 'styled-components'

const DefaultEditablePlaceholderContainer = styled.div`
  color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
`

const DefaultEditableContainer = styled.div`
  cursor: pointer;
`

interface DefaultEditableProps {
  hasText: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const DefaultEditable: FC<DefaultEditableProps> = (props) => {
  const { children, hasText, onClick } = props
  if (!hasText) {
    return (
      <DefaultEditablePlaceholderContainer onClick={onClick}>
        請填寫醫師指示
      </DefaultEditablePlaceholderContainer>
    )
  }

  return (
    <DefaultEditableContainer onClick={onClick}>
      {children}
    </DefaultEditableContainer>
  )
}

export default DefaultEditable

import { gql, useMutation } from '@apollo/client'
import { Button, Form, Radio, Space, message } from 'antd'
import { isNil, map, values } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  BindAccountEntityInput,
  BindAccountUserMutation,
  BindAccountUserMutationVariables,
  EntityBindFormFragment,
  EntityType,
  PatientStatus,
} from '../../../../../../graphql/types'
import { EntitySelect } from '../../../../../components/form/Select'
import RemoveEntityBindingFromAccountButton from './RemoveEntityBindingFromAccountButton'

const fragment = gql`
  fragment EntityBindForm on Account {
    id
    note
    entityType
    entity {
      ...EntitySelect
    }
    ...RemoveEntityBindingFromAccountButton
  }
  ${EntitySelect.fragments.EntitySelect}
  ${RemoveEntityBindingFromAccountButton.fragments
    .RemoveEntityBindingFromAccountButton}
`

const bindAccountUserMutation = gql`
  mutation BindAccountUser($id: ID!, $payload: BindAccountEntityInput!) {
    bindAccountUser(id: $id, payload: $payload) {
      ...EntityBindForm
    }
  }
  ${fragment}
`

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const CustomizedFormItem = styled(Form.Item)`
  margin-bottom: 0;
`

interface EntityBindFormFields {
  entity: string
}

interface EntityBindFormInitialValues {
  entity?: string
  entityType: EntityType
}

interface EntityBindFormProps {
  account: EntityBindFormFragment
  onBindEntityToAccountCompleted: () => void
  onRemoveEntityBindingFromAccountCompleted: () => void
}

const EntityBindForm = (props: EntityBindFormProps) => {
  const {
    account,
    onBindEntityToAccountCompleted,
    onRemoveEntityBindingFromAccountCompleted,
  } = props
  const { t } = useTranslation()

  const [bindEntityToAccount] = useMutation<
    BindAccountUserMutation,
    BindAccountUserMutationVariables
  >(bindAccountUserMutation)

  const [form] = Form.useForm<EntityBindFormFields>()

  const handleEntityBind = async () => {
    try {
      const formValues = (await form.validateFields()) as EntityBindFormFields

      const payload: BindAccountEntityInput = {
        entity: formValues.entity,
      }

      await bindEntityToAccount({
        variables: {
          id: account.id,
          payload,
        },
        update: (cache, { data, errors }) => {
          if (errors) {
            throw Error(errors[0].message)
          }
          if (!data?.bindAccountUser) {
            throw Error('無法正確取得實體綁定後的帳戶資訊')
          }
          message.success('成功將實體綁定至帳戶！')
          onBindEntityToAccountCompleted()
        },
      })
    } catch (error) {
      if (error?.message) {
        /** graphQL errors */
        message.error(`將實體綁定至帳戶時發生錯誤: ${error.message}`)
      } else {
        /** form errors or other errors */
        console.error(error)
      }
    }
  }

  const isEntityBoundToAccount = !isNil(account.entity)
  const disabled = isEntityBoundToAccount

  const initialValues: EntityBindFormInitialValues = {
    entity: account.entity?.id,
    entityType: account.entityType,
  }

  return (
    <Container>
      <Form form={form} initialValues={initialValues}>
        <Form.Item label='備註'>{account.note}</Form.Item>
        <Form.Item name='entityType' label='實體類型'>
          <Radio.Group disabled={disabled}>
            {map((entityType) => {
              return (
                <Radio key={entityType} value={entityType}>
                  {t(`account.entityType.${entityType}`)}
                </Radio>
              )
            }, values(EntityType))}
          </Radio.Group>
        </Form.Item>
        <Form.Item dependencies={['entityType']} noStyle>
          {(form) => {
            const entityType = form.getFieldValue('entityType')
            /**
             * @todo: 標上正確的 type
             * EntitySelect 是多種 Select 合併
             * 如果要只針對其中某種 Select 給予 query
             * EntitySelect 或是各 Select 的 query type 標記實作需要做調整
             * 這邊先暫時透過把 query 標成 any 的方式來跳過 type 檢查
             */
            const query: any =
              entityType === EntityType.Patient
                ? {
                    status: [PatientStatus.OnPrint],
                  }
                : undefined

            return (
              <Form.Item>
                <CustomizedFormItem
                  name='entity'
                  label='對應實體'
                  rules={[
                    {
                      required: true,
                      message: '必填欄位',
                    },
                  ]}
                >
                  <EntitySelect
                    entityType={entityType}
                    showSearch
                    disabled={disabled}
                    initItem={account?.entity as any}
                    query={query}
                    style={{ width: '100%' }}
                  />
                </CustomizedFormItem>
                <div>註：目前僅可選擇狀態為「療程中」的病患來綁定</div>
              </Form.Item>
            )
          }}
        </Form.Item>
        <Space size={16} direction='vertical'>
          <Button type='primary' disabled={disabled} onClick={handleEntityBind}>
            將對應實體綁定到帳戶
          </Button>
          <RemoveEntityBindingFromAccountButton
            account={account}
            onRemoveEntityBindingFromAccountCompleted={
              onRemoveEntityBindingFromAccountCompleted
            }
          />
        </Space>
      </Form>
    </Container>
  )
}

EntityBindForm.fragments = {
  EntityBindForm: fragment,
}

export default EntityBindForm

import { gql } from '@apollo/client'
import {
  AntSorterType,
  TableQuery,
  getEmptyText,
  isEmptyOrNil,
} from '@sov/common'
import { TablePatientInfo } from '@sov/ui'
import { Badge, Button, Table } from 'antd'
import { ColumnProps, TableProps } from 'antd/lib/table'
import moment, { Moment } from 'moment'
import {
  compose,
  head,
  identity,
  isEmpty,
  isNil,
  map,
  not,
  reject,
  sort,
} from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  TrackTableFragment,
  TracksQueryDocs,
  TracksQueryQuery,
} from '../../../../graphql/types'
import { TrackFilterType, TrackSorterField } from '../../../pages/track'
import EmployeeLink from '../../link/employee'
import PhotoThumbnailList from './PhotoThumbnailList'

type ColumnItem = TrackTableFragment
type Props = TableProps<ColumnItem> & {
  filterInfo?: TrackFilterType
  handleChange?: TableProps<TracksQueryDocs>['onChange']
  loading?: boolean
  sortInfo?: AntSorterType<TrackSorterField>
  source?: TracksQueryQuery['tracks']
  handleOpenReplyModal: (track: TrackTableFragment) => void
  selectedRowKeys: string[] | number[]
}

const defaultSource = {
  docs: [],
  page: 1,
  total: 1,
  limit: 100,
}

export const TrackTable = (props: Props) => {
  const {
    filterInfo = {},
    handleChange = identity,
    loading,
    sortInfo,
    source = defaultSource,
    handleOpenReplyModal,
    rowSelection,
    selectedRowKeys,
  } = props
  const { t } = useTranslation()

  const columns: ColumnProps<TracksQueryDocs>[] = [
    {
      align: 'center',
      title: '病患',
      dataIndex: 'patient',
      key: 'patient',
      width: 120,
      render: (text, record) => <TablePatientInfo patient={record.patient} />,
    },
    {
      align: 'center',
      title: 'AM',
      dataIndex: 'accountManager',
      key: 'accountManager',
      width: 60,
      render: (text, record) => (
        <EmployeeLink item={record.patient.accountManager} />
      ),
    },
    {
      align: 'center',
      title: 'Step',
      dataIndex: 'stage',
      key: 'stage',
      width: 60,
      render: (text, record) => <div>{record.stage.serialNumber}</div>,
    },
    {
      align: 'center',
      title: '開始配戴日期',
      dataIndex: 'startWearDate',
      key: 'startWearDate',
      width: 60,
      render: (text, record) => {
        const { subStage: subStageList } = record.stage

        const startWearDate = compose<
          NonNullable<TracksQueryDocs['stage']['subStage']>,
          Moment[],
          Moment[],
          Moment[],
          Moment
        >(
          head,
          sort((a, b) => (a.isAfter(b) ? 1 : -1)),
          reject(isNil),
          map((subStage) => subStage.startDate && moment(subStage.startDate))
        )(subStageList || [])

        return (
          <div>
            {startWearDate
              ? startWearDate.format('YYYY-MM-DD')
              : getEmptyText()}
          </div>
        )
      },
    },
    {
      align: 'center',
      title: '戴牙套照片',
      key: 'withBrace',
      width: 120,
      render: (text, record) =>
        record.photo?.withBrace && (
          <PhotoThumbnailList photoMap={record.photo?.withBrace} />
        ),
    },
    {
      align: 'center',
      title: '未戴牙套照片',
      key: 'withoutBrace',
      width: 120,
      render: (text, record) =>
        record.photo?.withoutBrace && (
          <PhotoThumbnailList photoMap={record.photo?.withoutBrace} />
        ),
    },
    {
      align: 'center',
      title: '狀況',
      key: 'mainIssues',
      width: 80,
      render: (text, record) => (
        <div>
          {isEmpty(record.mainIssues)
            ? '無'
            : map(
                (issue) => <div>{t(`track.issue.${issue}`)}</div>,
                record.mainIssues
              )}
        </div>
      ),
    },
    {
      align: 'center',
      title: '額外狀況',
      key: 'extraIssue',
      width: 80,
      render: (text, record) => <div>{record.extraIssue || '無'}</div>,
    },
    {
      align: 'center',
      title: '上傳日期',
      key: 'created',
      dataIndex: 'created',
      width: 80,
      sorter: true,
      sortOrder: sortInfo?.field === 'created' ? sortInfo.order : undefined,
      render: (text, record) => (
        <div>{moment(record.created).format('YYYY-MM-DD')}</div>
      ),
    },
    {
      align: 'center',
      title: '狀態',
      key: 'isReply',
      width: 80,
      filters: [
        { text: '未回覆', value: 'false' },
        { text: '已回覆', value: 'true' },
      ],
      filterMultiple: false,
      filteredValue: isNil(filterInfo.isReply) ? [] : [`${filterInfo.isReply}`],
      onFilter: (value, record) =>
        isNil(filterInfo.isReply)
          ? true
          : not(isEmptyOrNil(record.reply)) === filterInfo.isReply,
      render: (text, record) =>
        isNil(record.reply) ? (
          <div>
            <Badge status='error' />
            未回覆
          </div>
        ) : (
          <div>
            <Badge status='success' />
            已回覆
          </div>
        ),
    },
    {
      align: 'center',
      title: '操作',
      key: 'actions',
      width: 80,
      render: (text, record) => (
        <Button
          type='link'
          style={{ display: 'inline-block' }}
          onClick={() => handleOpenReplyModal(record)}
        >
          {isNil(record.reply) ? '回覆' : '檢視'}
        </Button>
      ),
    },
  ]

  const { docs, page, total, limit } = source

  return (
    <Table<TracksQueryDocs>
      rowKey='id'
      columns={columns}
      dataSource={docs}
      loading={loading}
      locale={{ emptyText: '系統中無任何回饋單' }}
      onChange={handleChange}
      pagination={TableQuery.getAntdPagination({
        page: page || 1,
        total,
        limit,
      })}
      rowSelection={rowSelection}
      expandedRowKeys={selectedRowKeys}
    />
  )
}

TrackTable.fragment = gql`
  fragment TrackTable on Track {
    id
    stage {
      id
      serialNumber
      subStage {
        startDate
      }
    }
    patient {
      ...TablePatientInfo
      accountManager {
        id
        name
      }
    }
    photo {
      withBrace {
        ...PhotoThumbnailList
      }
      withoutBrace {
        ...PhotoThumbnailList
      }
    }
    created
    mainIssues
    extraIssue
    reply
    replyDate
  }
  ${TablePatientInfo.fragment}
  ${PhotoThumbnailList.fragments.PhotoThumbnailList}
`

export default TrackTable

import { gql } from '@apollo/client'
import { Space } from 'antd'
import React from 'react'

import { AccountManagerOrderListRowFragment } from '../../../../../graphql/types'
import Row from './Row'

interface OrderListProps {
  orderList: AccountManagerOrderListRowFragment[]
}

const OrderList = (props: OrderListProps) => {
  const { orderList } = props

  return (
    <Space direction='vertical' size={24} style={{ width: '100%' }}>
      {orderList.map((order) => (
        <Row key={order.id} order={order} />
      ))}
    </Space>
  )
}

OrderList.fragments = {
  AccountManagerOrder: gql`
    fragment AccountManagerOrder on Order {
      ...AccountManagerOrderListRow
    }
    ${Row.fragments.AccountManagerOrderListRow}
  `,
}

export default OrderList

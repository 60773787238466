import { gql, useMutation, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Button, Form, Row, Space, message } from 'antd'
import moment from 'moment'
import { omit } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'

import { createInvoiceWithStageMutation } from '../../../graphql/invoice/mutation/create'
import { updateInvoiceWithStageMutation } from '../../../graphql/invoice/mutation/update'
import {
  CreateInvoiceWithStageMutation,
  CreateInvoiceWithStageVariables,
  StageInvoiceQueryQuery,
  StageInvoiceQueryVariables,
  StageStatus,
  UpdateInvoiceWithStageMutation,
  UpdateInvoiceWithStageVariables,
} from '../../../graphql/types'
import InvoiceTimeLine from '../../components/common/invoice/InvoiceTimeLine'
import {
  CreateButton,
  FormInvoice,
  FormInvoiceFields,
  FormInvoiceProps,
  MailButton,
  UpdateButton,
  basicInvoicePayloadTransformer,
  invoiceMailFragments,
  sendMail,
} from '../../components/form/invoice'
import { InvoiceClientDisplayInfo } from '../../components/form/invoice/InvoiceClientInfo'
import Page, { Section } from '../../components/layout/Page'
import InvoicePrintModal from '../../components/modal/InvoicePrint'
import { formInvoiceBasicDefaultValue } from './utils'

const stageInvoiceQuery = gql`
  query StageInvoiceQuery($id: ID!) {
    stage(id: $id) {
      id
      status
      ...InvoiceMailStageItem
      ...FormInvoiceStageItem
      patient {
        id
        ...InvoiceMailPatientItem
        ...FormInvoicePatientItem
      }
      ... on EvalStage {
        invoice {
          ...FormInvoiceItem
          ...InvoicePrintModal
          ...InvoiceMailInvoiceItem
        }
      }
      ... on PrintStage {
        invoice {
          ...FormInvoiceItem
          ...InvoicePrintModal
          ...InvoiceMailInvoiceItem
        }
      }
      ... on AccessoryStage {
        invoice {
          ...FormInvoiceItem
          ...InvoicePrintModal
          ...InvoiceMailInvoiceItem
        }
      }
    }
  }
  ${FormInvoice.fragments.FormInvoiceStageItem}
  ${FormInvoice.fragments.FormInvoicePatientItem}
  ${FormInvoice.fragments.FormInvoiceItem}
  ${invoiceMailFragments.InvoiceMailStageItem}
  ${invoiceMailFragments.InvoiceMailPatientItem}
  ${invoiceMailFragments.InvoiceMailInvoiceItem}
  ${InvoicePrintModal.fragment}
`

interface RouteProps {
  stageId: string
}

export const StageInvoiceDetail = () => {
  const match = useRouteMatch<RouteProps>()

  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [update] = useMutation<
    UpdateInvoiceWithStageMutation,
    UpdateInvoiceWithStageVariables
  >(updateInvoiceWithStageMutation)
  const [create] = useMutation<
    CreateInvoiceWithStageMutation,
    CreateInvoiceWithStageVariables
  >(createInvoiceWithStageMutation)
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const {
    data,
    loading: queryLoading,
    refetch: refetchStage,
  } = useQuery<StageInvoiceQueryQuery, StageInvoiceQueryVariables>(
    stageInvoiceQuery,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      onError: (error) => {
        toErrorPage(error.message)
      },
      /** 如果不使用 'no-cache' 的話，按上一頁回「出貨單清單頁面」的時候，列表的資料不會正確載入 */
      fetchPolicy: 'no-cache',
      variables: {
        id: match.params.stageId,
      },
    }
  )

  const stageItem = data?.stage

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  if (loading) {
    return <Page loading />
  }

  if (!stageItem || !stageItem.patient) {
    return null
  }

  const isStageFinished = stageItem?.status === StageStatus.Completed
  const invoiceItem =
    stageItem.__typename === 'PrintStage' ||
    stageItem.__typename === 'AccessoryStage' ||
    stageItem.__typename === 'EvalStage'
      ? stageItem.invoice
      : undefined
  const patientItem = stageItem.patient

  const displayInfo: InvoiceClientDisplayInfo = {
    patientInfo: stageItem.patient,
    stageInfo: stageItem,
  }
  const commonFields = {
    ...displayInfo,
    patient: stageItem.patient.id,
    stage: stageItem.id,
  }

  const initialValues: FormInvoiceProps['initialValues'] = invoiceItem
    ? {
        ...omit(['clinic', 'doctor', 'sales'], invoiceItem),
        ...commonFields,
        shippingDate: invoiceItem.shippingDate
          ? moment(invoiceItem.shippingDate)
          : undefined,
        creditDate: moment(invoiceItem.creditDate),
        hasTax: invoiceItem.tax,
      }
    : {
        ...formInvoiceBasicDefaultValue,
        ...commonFields,
      }

  const createInvoice = async () => {
    try {
      const formValues = (await form.validateFields()) as FormInvoiceFields
      const payload = {
        ...basicInvoicePayloadTransformer(formValues),
        patient: formValues.patient,
        stage: formValues.stage,
      }

      setLoading(true)
      await create({
        variables: {
          payload,
        },
        update: (cache, { data }) => {
          if (data) {
            refetchStage()
            message.info('已新增出貨單')
          }
        },
      })
    } catch (error) {
      if (error?.message) {
        /** graphQL errors */
        const hintMessage = `新增出貨單失敗: ${error.message}`
        message.error(hintMessage)
      } else {
        /** form errors or other errors */
        console.log(error)
      }
    } finally {
      setLoading(false)
    }
  }

  const updateInvoice = async () => {
    try {
      const formValues = (await form.validateFields()) as FormInvoiceFields

      if (!invoiceItem) {
        throw Error('出貨單 id 不存在')
      }

      setLoading(true)
      await update({
        variables: {
          id: invoiceItem.id,
          payload: basicInvoicePayloadTransformer(formValues),
        },
        update: (cache, { data }) => {
          if (data) {
            refetchStage()
            message.info('已更新出貨單')
          }
        },
      })
    } catch (error) {
      if (error?.message) {
        /** graphQL errors */
        const hintMessage = `更新出貨單失敗: ${error.message}`
        message.error(hintMessage)
      } else {
        /** form errors or other errors */
        console.log(error)
      }
    } finally {
      setLoading(false)
    }
  }

  /* @todo 待工單詳情拆分後，就不需要此判斷 */
  if (
    stageItem.__typename === 'EvalStage' ||
    stageItem.__typename === 'PrintStage' ||
    stageItem.__typename === 'AccessoryStage'
  ) {
    return (
      <Section>
        <Row justify='space-between'>
          <h2>出貨單內容{invoiceItem ? '' : '(尚未新增)'}</h2>
          {stageItem.invoice ? (
            <Space>
              {isStageFinished && <div>此出貨單已完成</div>}
              <UpdateButton onClick={updateInvoice} type='primary' />
              <MailButton
                loading={loading}
                onClick={sendMail(
                  {
                    patientItem,
                    invoiceItem: stageItem.invoice,
                    stageItem,
                    doctorItem: stageItem.invoice.doctor,
                    clinicItem: stageItem.invoice.clinic,
                    salesItem: stageItem.invoice.sales,
                  },
                  t
                )}
              />
              <Button onClick={() => setIsModalVisible(!isModalVisible)}>
                預覽列印
              </Button>
              <InvoicePrintModal
                handleModalClose={() => setIsModalVisible(!isModalVisible)}
                isModalVisible={isModalVisible}
                invoiceItem={stageItem.invoice}
              />
            </Space>
          ) : (
            <CreateButton onClick={createInvoice} type='primary' />
          )}
        </Row>
        <Row>
          <FormInvoice form={form} initialValues={initialValues} />
          <InvoiceTimeLine patientId={stageItem.patient.id} />
        </Row>
      </Section>
    )
  } else {
    return <Section>僅「報告、製造、配件」可新增出貨單</Section>
  }
}

export default StageInvoiceDetail

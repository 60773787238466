import { getInvoiceProductPriceInfo } from '@sov/common'
import { pick, pipe } from 'ramda'

import { FormInvoiceFields } from './index'

export const basicInvoicePayloadTransformer = pipe(
  (formValues: FormInvoiceFields) => {
    const priceInfo = getInvoiceProductPriceInfo({
      discount: formValues.discount,
      hasTax: formValues.hasTax,
      middlemanFee: formValues.middlemanFee,
      invoiceProducts: formValues.products || [],
    })

    return {
      ...formValues,
      ...priceInfo,
      shippingDate: formValues.shippingDate?.toISOString(),
      creditDate: formValues.creditDate.toISOString(),
      tax: formValues.hasTax,
    }
  },
  pick([
    'shippingDate',
    'creditDate',
    'products',
    'middlemanFee',
    'discount',
    'payment',
    'homeDeliveryType',
    'homeDeliveryId',
    'invoiceNumber',
    'note',
    'tax',
  ])
)

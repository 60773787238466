import Loadable from '@loadable/component'
import { map, values } from 'ramda'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Layout from '../components/layout'
import Page from '../components/layout/Page'
import { Route as RouteMap } from './routes'

export const labRouteKeys = ['PatientCost'] as const
export type LabRouteKey = typeof labRouteKeys[number]

export const labRoutes: RouteMap<LabRouteKey> = {
  PatientCost: {
    path: '/lab/patient-cost',
    title: '病患成本估算',
    component: Loadable(() => import('../pages/lab/PatientCost')),
    privileges: [],
  },
}

const LabRoutes = () => (
  <Switch>
    <Layout>
      <Page>
        <Switch>
          {map(
            ({ path, component: Component }) => (
              <Route exact key={path} path={path}>
                {(props: any) => <Component {...props} />}
              </Route>
            ),
            values(labRoutes)
          )}
        </Switch>
      </Page>
    </Layout>
  </Switch>
)

export default LabRoutes

import { ButtonProps } from 'antd/lib/button'
import React from 'react'

import { AllPrivilege } from '../../../../../graphql/types'
import {
  ConfirmButtonDropdownMenu,
  ConfirmButtonProps,
  OnceButton,
} from '../../../../components/common/button'

const UpdateButton = (props: ButtonProps) => {
  return <OnceButton {...props} label='更新資料' />
}

const RemoveButton = ({ handleRemove }) => {
  const confirmButtonDropdownMenuItemsProps: ConfirmButtonProps[] = [
    {
      label: '刪除',
      modalProps: {
        onOk: handleRemove,
      },
      requiredInputText: '刪除工單',
      requiredPrivilege: AllPrivilege.StageDelete,
    },
  ]

  return (
    <ConfirmButtonDropdownMenu
      confirmButtonDropdownMenuItemsProps={confirmButtonDropdownMenuItemsProps}
    />
  )
}

export { RemoveButton, UpdateButton }

import React from 'react'
import { Link } from 'react-router-dom'

import BreadcrumbCreator from '../../layout/BreadcrumbCreator'
import Title from '../../layout/Title'

interface Props {
  item: string
}

const ProductMenu = (props: Props) => {
  const { item } = props

  return (
    <div style={{ marginBottom: -16 }}>
      <BreadcrumbCreator
        routes={[
          { key: 'Home' },
          { key: 'ProductList' },
          {
            key: 'ProductDetail',
            render: () => <Link to={`/products/${item}`}>貨品詳情</Link>,
          },
        ]}
      />
      <Title route={{ key: 'ProductDetail' }} />
    </div>
  )
}

export default ProductMenu

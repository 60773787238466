import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { ErrorHandling } from '@sov/common'
import { useRequest } from '@sov/ui'
import { Button, Input } from 'antd'
import { slice } from 'ramda'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

interface SendCheckSMSFormInput {
  code: string
}

type SendCheckSMSFormProps = {
  handleSubmit: (form: FormComponentProps['form']) => void
  handleFormOnFocus: () => void
  helpMessage?: string
  validateStatus?: 'error'
  loading: boolean
  phone: string
} & FormComponentProps<SendCheckSMSFormInput>

const FormItem = Form.Item

const FormTitle = styled.div`
  font-size: 28px;
`

const FormDescription = styled.div`
  margin-top: 24px;
  margin-bottom: 48px;
  font-size: 16px;
`

const encodePhone = (phone: string) =>
  '0' + slice(0, 3, phone) + '-***-' + slice(6, Infinity, phone)

const SendCheckSMSForm = Form.create<SendCheckSMSFormProps>()(
  (props: SendCheckSMSFormProps) => {
    const {
      form,
      handleSubmit,
      handleFormOnFocus,
      helpMessage,
      validateStatus,
      loading: submitLoading,
      phone,
    } = props
    const { getFieldDecorator } = form
    const { toErrorPage } = ErrorHandling.useErrorHandling()
    const request = useRequest()
    const [resendSMSLoading, setResendSMSLoading] = useState(false)

    const sendSMS = async () => {
      setResendSMSLoading(true)
      const res = await request.post('/auth/sendResetPasswordSMS', {
        phone: '+886' + phone,
      })
      setResendSMSLoading(false)
      if (res.status !== 200) {
        toErrorPage(res.message)
      }
    }
    return (
      <Form layout='inline' style={{ width: '100%' }}>
        <FormItem
          help={helpMessage}
          validateStatus={validateStatus}
          hasFeedback
          style={{ margin: 0, padding: 0, marginRight: '8px' }}
        >
          {getFieldDecorator('code', {
            rules: [
              { pattern: /^\d{6}$/, message: '6位數字驗證碼' },
              { required: true },
            ],
          })(
            <Input
              style={{ width: '300px' }}
              placeholder='數字驗證碼'
              onFocus={handleFormOnFocus}
            />
          )}
        </FormItem>
        <FormItem style={{ width: '120px' }}>
          <Button
            style={{ fontSize: '14px', color: '#1890ff' }}
            onClick={sendSMS}
            loading={resendSMSLoading}
          >
            重發驗證碼
          </Button>
        </FormItem>
        <Button
          type='primary'
          style={{ width: '100%', marginTop: '24px' }}
          loading={submitLoading}
          onClick={() => handleSubmit(form)}
        >
          送出
        </Button>
      </Form>
    )
  }
)

const RecoverPasswordSMSCheck = () => {
  const history = useHistory()
  const params = useParams<{ phone: string }>()
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const [helpMessage, setHelpMessage] = useState<string | undefined>(undefined)
  const [validateStatus, setValidateStatus] = useState<'error' | undefined>(
    undefined
  )
  const [loading, setLoading] = useState(false)
  const request = useRequest()

  const handleFormOnFocus = () => {
    setHelpMessage(undefined)
    setValidateStatus(undefined)
  }

  const checkSMS = async (code: string) => {
    const res = await request.post('/auth/confirmResetPasswordSMSCode', {
      phone: '+886' + params.phone,
      verificationCode: code,
    })
    setLoading(false)
    if (res.status === 200) {
      const { accountId, resetPasswordToken } = res
      history.push(`/resetPassword/${accountId}/${resetPasswordToken}`)
    } else if (res.status === 400) {
      const errorMessage = res.message
      setValidateStatus('error')
      setHelpMessage(errorMessage)
    } else {
      toErrorPage(res.message)
    }
  }

  const handleSubmit = async (form: FormComponentProps['form']) => {
    const { getFieldValue, validateFields } = form
    validateFields(async (err) => {
      if (err) {
        return null
      }
      const code = getFieldValue('code')
      setLoading(true)
      await checkSMS(code)
      return null
    })
  }

  return (
    <>
      <FormTitle>簡訊驗證碼</FormTitle>
      <FormDescription>
        <div>簡訊驗證碼已寄送至 {encodePhone(params.phone)} </div>
        <div>
          請確認簡訊內容並輸入
          <span style={{ color: '#159bff' }}>六位驗證碼</span>。
        </div>
      </FormDescription>
      <SendCheckSMSForm
        handleSubmit={handleSubmit}
        handleFormOnFocus={handleFormOnFocus}
        helpMessage={helpMessage}
        validateStatus={validateStatus}
        loading={loading}
        phone={params.phone}
      />
    </>
  )
}

export default RecoverPasswordSMSCheck

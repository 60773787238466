import moment from 'moment'
import { flatten, includes, isNil, length, map, sum } from 'ramda'

import {
  PatientCostDocs,
  PatientCostTasks,
  TaskType,
} from '../../../graphql/types'

export const workOrderTypes = [
  'mold',
  'eval',
  'compare',
  'design',
  'print',
  'accessory',
] as const
type WorkOrderTypes = typeof workOrderTypes
export type WorkOrderType = WorkOrderTypes[number]

const taskTypeMap: Record<WorkOrderType, TaskType[]> = {
  mold: [TaskType.CompleteScan, TaskType.CompleteSplit],
  eval: [
    TaskType.CompleteAlign,
    TaskType.CompletePredesign,
    TaskType.CompleteViewer,
  ],
  compare: [
    TaskType.CompleteAlign,
    TaskType.CompletePredesign,
    TaskType.CompleteViewer,
  ],
  design: [TaskType.CompleteDesign, TaskType.CompleteUpload],
  print: [TaskType.CompleteRp, TaskType.CompleteBraces],
  accessory: [TaskType.CompleteRp, TaskType.CompleteBraces],
}

const getTaskWorkTime = (started: string, completed: string) => {
  if (isNil(started) || isNil(completed)) {
    return 0
  }
  const isSameDay = moment(completed).isSame(moment(started), 'day')

  if (!isSameDay) {
    const workStart = moment(completed).clone().set('hour', 9).set('minute', 30)
    const minuteDiff = moment(completed).diff(workStart, 'minute')
    return minuteDiff
  }

  const minuteDiff = moment(completed).diff(moment(started), 'minute')
  return minuteDiff
}

export const getSalaryRate: (monthlySalary: number) => number = (
  monthlySalary = 0
) => Math.round((monthlySalary / 22 / 8 / 60) * 100) / 100

const sumWorkTime = (timeList: number[][]) => sum(flatten<number>(timeList))

const getWorkTime = (tasks: PatientCostTasks[], selectedTasks: TaskType[]) =>
  map(
    (task) => getTaskWorkTime(task.startedByEmployee, task.completedByOwner),
    tasks.filter((task) =>
      selectedTasks ? includes(task.type, selectedTasks) : true
    )
  )

interface GetWorkTimeAndCostMapArgs {
  salaryRate: number
  stagesMap: Record<WorkOrderType, PatientCostDocs[]>
  workOrderType: WorkOrderType
}
export const getWorkTimeAndCostMap = (args: GetWorkTimeAndCostMapArgs) => {
  const { salaryRate, stagesMap, workOrderType } = args

  const count = length(stagesMap[workOrderType])
  const totalWorkTime = sumWorkTime(
    map(
      (stage) => getWorkTime(stage.tasks, taskTypeMap[workOrderType]),
      stagesMap[workOrderType]
    )
  )
  const averageWorkTime =
    Math.round(totalWorkTime / length(stagesMap[workOrderType])) || 0
  const averageLaborCost = Math.round(averageWorkTime * salaryRate)

  return {
    count,
    totalWorkTime,
    averageWorkTime,
    averageLaborCost,
  }
}

import {
  CloseCircleFilled,
  DeleteOutlined,
  UndoOutlined,
} from '@ant-design/icons'
import { gql } from '@apollo/client'
import { getStageName } from '@sov/common'
import { StageName } from '@sov/ui'
import { Button, Modal, Popover, Typography } from 'antd'
import { ModalFuncProps } from 'antd/lib/modal'
import { PopoverProps } from 'antd/lib/popover'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import {
  DropPopoverFragment,
  StageStatus,
  StageType,
} from '../../../../graphql/types'
import { authContext } from '../../../context'
import { isInternalRoles } from '../../../utils'

const { Text } = Typography

const dropModalProps: ModalFuncProps = {
  icon: <CloseCircleFilled style={{ fontSize: '20px', color: 'red' }} />,
  content: (
    <span style={{ fontSize: '14px' }}>一旦廢棄將無法復原，是否要繼續？</span>
  ),
  okText: '廢棄',
}

const unDropModalProps: ModalFuncProps = {
  content: (
    <span style={{ fontSize: '14px' }}>
      若報告中尚有同樣編號的工單存在將無法復原，是否要繼續？
    </span>
  ),
  okText: '還原廢棄',
}
interface DropPopoverProps extends PopoverProps {
  stage: DropPopoverFragment
  handleDrop: (stageId: string) => Promise<void>
  handleUnDrop: (stageId: string) => Promise<void>
}

const DropPopover = (props: DropPopoverProps) => {
  const { stage, handleUnDrop, handleDrop, children } = props
  const { t } = useTranslation()
  const auth = useContext(authContext)

  const isDroppableStageType = [
    StageType.Design,
    StageType.Mold,
    StageType.Print,
  ].includes(stage.type)
  const isStageDropped = stage.status === StageStatus.Dropped

  const title = (
    <Text>
      確定要{isStageDropped ? '還原' : '廢棄'}「
      <Text mark>{getStageName(t, props.stage)}</Text>」工單嗎？
    </Text>
  )

  const showConfirm = () => {
    Modal.confirm({
      ...(isStageDropped ? unDropModalProps : dropModalProps),
      title,
      okType: 'danger',
      maskClosable: true,
      width: 450,
      onOk: () =>
        isStageDropped ? handleUnDrop(stage.id) : handleDrop(stage.id),
    })
  }

  const DropButton = (
    <>
      <DeleteOutlined style={{ color: 'red' }} />
      <Button
        type='link'
        onClick={showConfirm}
        style={{ color: 'red', paddingRight: 0 }}
      >
        廢棄
      </Button>
    </>
  )

  const UnDropButton = (
    <>
      <UndoOutlined style={{ color: 'grey' }} />
      <Button
        type='link'
        onClick={showConfirm}
        style={{ color: 'grey', paddingRight: 0 }}
      >
        還原廢棄
      </Button>
    </>
  )

  /* @TODO 確定這邊的權限，目前先不讓業務/外部使用者廢棄 */
  return isInternalRoles(auth) && isDroppableStageType ? (
    <Popover
      content={isStageDropped ? UnDropButton : DropButton}
      trigger='hover'
    >
      {children}
    </Popover>
  ) : (
    <>{children}</>
  )
}

DropPopover.fragment = gql`
  fragment DropPopover on Stage {
    id
    type
    status
    ...StageName
  }
  ${StageName.fragment}
`

export default DropPopover

import './index.less'

import { red } from '@ant-design/colors'
import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import {
  CloseCircleFilled,
  DownOutlined,
  InfoCircleFilled,
  PlusOutlined,
  UpOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import {
  DateIntervalParam,
  FormQuery,
  MyStringParam,
  TableQuery,
} from '@sov/common'
import {
  Button,
  Card,
  DatePicker,
  Modal,
  PageHeader,
  Row,
  Space,
  Tag,
  Typography,
  message,
} from 'antd'
import { SortOrder } from 'antd/lib/table/interface'
import moment from 'moment'
import {
  always,
  append,
  cond,
  equals,
  find,
  forEach,
  includes,
  isEmpty,
  map,
  propEq,
  reduce,
  reject,
} from 'ramda'
import React, { FC, useEffect, useState } from 'react'

import { createOrdersMutation } from '../../../graphql/order/mutation/create'
import { removeOrderMutation } from '../../../graphql/order/mutation/remove'
import { updateOrdersMutation } from '../../../graphql/order/mutation/update'
import {
  CreateOrdersMutation,
  CreateOrdersMutationVariables,
  OrderStatus,
  OrdersQuery,
  PatientStatus,
  RemoveOrderMutation,
  RemoveOrderMutationVariables,
  Role,
  UpdateOrdersMutation,
  UpdateOrdersMutationVariables,
} from '../../../graphql/types'
import { ControlledTab } from '../../components/common/TabLink'
import {
  ClinicSelect,
  EmployeeSelect,
  PatientSelect,
} from '../../components/form/Select'
import BreadcrumbCreator from '../../components/layout/BreadcrumbCreator'
import Page from '../../components/layout/Page'
import Title from '../../components/layout/Title'
import OrderTable, { ColumnKeys, IOrder } from '../../components/table/Order'
import { OrderForm } from '../../components/table/Order/TableCells'
import useOrder from './useOrder'
import {
  AdvanceSearchOption,
  SeprateResultType,
  advanceSearchOptions,
  getCreatePayload,
  getUpdatePayload,
} from './utils'

export enum TabTypes {
  LATEST = 'LATEST',
  TOCREATE = 'TOCREATE',
  HISTORY = 'HISTORY',
}

export type OrderFilterType = Pick<OrdersQuery, 'type' | 'status'>

export type OrderSorterField = ColumnKeys.DUEDATE

interface SearchFormQuery {
  clinicId?: string
  salesId?: string
  patientId?: string
  accountManagerId?: string
  dueDateInterval?: [moment.Moment, moment.Moment]
}

interface UrlFormQuery {
  accountManagerId?: string
}

type SearchFormProps = {
  formQuery: SearchFormQuery & UrlFormQuery
  showAdvanceSearch: boolean
  handleSearch: (formValues: SearchFormQuery & UrlFormQuery) => void
  handleToggleAdvanceSearch: () => void
} & FormComponentProps<SearchFormQuery & UrlFormQuery>

const tabList = [
  {
    key: TabTypes.LATEST,
    label: '最新訂單',
  },
  {
    key: TabTypes.TOCREATE,
    label: '需綁定工單的訂單',
  },
  {
    key: TabTypes.HISTORY,
    label: '歷史訂單',
  },
]

const presetRanges: any = {
  一季: [moment().subtract(1, 'quarter'), moment()],
  二季: [moment().subtract(2, 'quarter'), moment()],
  三季: [moment().subtract(3, 'quarter'), moment()],
  四季: [moment().subtract(4, 'quarter'), moment()],
}

const SearchForm = Form.create<SearchFormProps>()((props: SearchFormProps) => {
  const {
    form,
    formQuery,
    showAdvanceSearch,
    handleToggleAdvanceSearch,
    handleSearch,
  } = props
  const { getFieldDecorator } = form

  return (
    <Form layout='inline'>
      <Form.Item label='診所名稱'>
        {getFieldDecorator('clinicId', {
          initialValue: formQuery.clinicId,
        })(<ClinicSelect style={{ width: '120px' }} allowClear />)}
      </Form.Item>
      <Form.Item label='業務名稱'>
        {getFieldDecorator('salesId', {
          initialValue: formQuery.salesId,
        })(
          <EmployeeSelect
            style={{ width: '120px' }}
            allowClear
            query={{ roles: [Role.Sales] }}
          />
        )}
      </Form.Item>
      <Form.Item label='病患名稱'>
        {getFieldDecorator('patientId', {
          initialValue: formQuery.patientId,
        })(<PatientSelect style={{ width: '120px' }} allowClear />)}
      </Form.Item>
      <Form.Item label='預計出貨'>
        {getFieldDecorator('dueDateInterval', {
          initialValue: formQuery.dueDateInterval,
        })(
          <DatePicker.RangePicker
            ranges={presetRanges}
            style={{ width: '240px' }}
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button onClick={() => handleSearch(form.getFieldsValue())}>
          搜尋
        </Button>
      </Form.Item>
      <Form.Item>
        <div style={{ cursor: 'pointer' }} onClick={handleToggleAdvanceSearch}>
          進階搜尋 {showAdvanceSearch ? <UpOutlined /> : <DownOutlined />}
        </div>
      </Form.Item>
    </Form>
  )
})

const formInput = {
  clinicId: MyStringParam,
  salesId: MyStringParam,
  patientId: MyStringParam,
  dueDateInterval: DateIntervalParam,
}

const OrderIndex: FC = () => {
  const [tab, setTab] = useState(TabTypes.LATEST)
  const [appointmentSortOrder, setAppointmentSortOrder] = useState<SortOrder>()
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<AdvanceSearchOption[]>(
    []
  )
  const [isMutating, setIsMutating] = useState(false)

  const { formQuery, handleFormChange } = FormQuery.useFormQuery(formInput)
  const { tableQuery, paginateQuery, handleTableChange } =
    TableQuery.useTableQuery<OrderFilterType, OrderSorterField>({
      limit: formQuery.clinicId ? 400 : 100,
    })

  const query: OrdersQuery = {
    ...formQuery,
    status:
      tab === TabTypes.TOCREATE
        ? [OrderStatus.Pending]
        : tableQuery.filters?.status ?? undefined,
    isLatest: tab === TabTypes.LATEST ? true : undefined,
    type: tableQuery.filters?.type,
    patientStatus: [
      PatientStatus.Initial,
      PatientStatus.OnEval,
      PatientStatus.OnPrint,
    ],
  }

  const variables = {
    query,
    ...paginateQuery,
  }

  const handleSearch = (formValues: SearchFormQuery & UrlFormQuery) =>
    handleFormChange(formValues)

  const handleSortAppointment = () =>
    setAppointmentSortOrder(
      cond<SortOrder | undefined, SortOrder | undefined>([
        [equals<SortOrder | undefined>('ascend'), always('descend')],
        [equals<SortOrder | undefined>('descend'), always(undefined)],
        [equals<SortOrder | undefined>(undefined), always('ascend')],
      ])
    )

  const {
    source,
    refetch,
    loading,
    orders,
    forms,
    editedCount,
    isOrderNew,
    editOrder,
    undoOrder,
    copyOrder,
    createOrder,
    removeOrder,
    clearOrders,
  } = useOrder({
    variables,
    appointmentSortOrder,
    selectedOptions,
    shouldQueryBindStages: tab === TabTypes.TOCREATE,
  })
  const hasEdited = editedCount > 0

  const [create] = useMutation<
    CreateOrdersMutation,
    CreateOrdersMutationVariables
  >(createOrdersMutation)
  const [update] = useMutation<
    UpdateOrdersMutation,
    UpdateOrdersMutationVariables
  >(updateOrdersMutation)
  const [remove] = useMutation<
    RemoveOrderMutation,
    RemoveOrderMutationVariables
  >(removeOrderMutation)

  const handleSubmit = async () => {
    const formErrors: any[] = []
    forEach((form) => {
      form.validateFieldsAndScroll((err) => {
        if (err) {
          formErrors.push(err)
        }
      })
    }, forms)

    if (formErrors.length > 0) {
      return
    }

    const formValues = map((form) => form.getFieldsValue() as OrderForm, forms)
    const { ordersToBeCreate, ordersToBeUpdate } = reduce<
      OrderForm,
      SeprateResultType
    >(
      (seprateResult, formValue) => {
        const orderId = formValue.id
        const isNew = isOrderNew(orderId)
        // form 裡面出現的 orderId， orders 中也一定存在
        const order = find(propEq('id', orderId), orders) as IOrder

        return isNew
          ? {
              ...seprateResult,
              ordersToBeCreate: [
                ...seprateResult.ordersToBeCreate,
                getCreatePayload(order, formValue),
              ],
            }
          : {
              ...seprateResult,
              ordersToBeUpdate: [
                ...seprateResult.ordersToBeUpdate,
                getUpdatePayload(formValue, orderId),
              ],
            }
      },
      { ordersToBeCreate: [], ordersToBeUpdate: [] },
      formValues
    )

    if (isMutating) return
    setIsMutating(true)

    try {
      // Create mutation
      if (!isEmpty(ordersToBeCreate)) {
        await create({
          variables: {
            payloads: ordersToBeCreate,
          },
          update: async (cache, { data }) => {
            await refetch()
            if (data?.createOrders) {
              message.info(`已新增 ${data.createOrders.length} 筆訂單`)
            }
          },
        })
      }

      // Update mutation
      if (!isEmpty(ordersToBeUpdate)) {
        await update({
          variables: {
            payloads: ordersToBeUpdate,
          },
          update: async (cache, { data }) => {
            await refetch()
            if (data?.updateOrders) {
              message.info(`已更新 ${data.updateOrders.length} 筆訂單`)
            }
          },
        })
      }
    } catch (error) {
      message.error(error.message)
    }
    setIsMutating(false)
  }

  const handleRemove = async (orderId: string) => {
    try {
      // Remove mutation
      await remove({
        variables: {
          id: orderId,
        },
        update: async (cache, { data }) => {
          await refetch()
          if (data?.removeOrder) {
            message.info(data.removeOrder.message)
          }
        },
      })
    } catch (error) {
      message.error(`刪除訂單失敗: ${error.message}`)
    }
  }

  const deleteOrder = (orderId: string) => {
    const deleteOrder = find(propEq('id', orderId), orders) as IOrder

    // 刪除複製出來的訂單，要刪除時不顯示 confirm modal
    if (isOrderNew(deleteOrder.id)) {
      removeOrder(orderId)
      return
    }
    const patientName = deleteOrder.patient.name

    const content = (
      <>
        刪除 <Typography.Text mark>{patientName}</Typography.Text> 的訂單？
        <br />
        訂單一旦刪除則無法復原，確定刪除嗎？
      </>
    )

    Modal.confirm({
      title: '確定要刪除這個訂單嗎？',
      content,
      icon: <CloseCircleFilled style={{ color: red[5] }} />,
      onOk: () => handleRemove(orderId),
      maskClosable: true,
    })
  }

  const handleTabChange = (tabType) => {
    setTab(tabType)
    clearOrders()
  }

  const handleToggleAdvanceSearch = () =>
    setShowAdvanceSearch((state) => !state)
  const handleSelectOption = (selected: AdvanceSearchOption) =>
    setSelectedOptions((options) =>
      includes<AdvanceSearchOption>(selected, options)
        ? reject(equals(selected), options)
        : append(selected, options)
    )

  useEffect(() => {
    const unload = (e: BeforeUnloadEvent) => {
      e.returnValue = '確定離開訂單列表頁面？'
    }
    window.addEventListener('beforeunload', unload)
    return () => window.removeEventListener('beforeunload', unload)
  }, [])

  return (
    <Page
      header={
        <PageHeader
          breadcrumbRender={() => (
            <>
              <BreadcrumbCreator
                routes={[{ key: 'Home' }, { key: 'OrderList' }]}
              />
              <Title route={{ key: 'OrderList' }} />
            </>
          )}
          footer={
            <ControlledTab
              tab={tab}
              tabList={tabList}
              onChange={handleTabChange}
            />
          }
        ></PageHeader>
      }
      headerStyle={{ padding: 0 }}
    >
      <Card size='small'>
        <Space direction='vertical' style={{ width: '100% ' }}>
          <div className='sticky-row'>
            <SearchForm
              formQuery={formQuery}
              handleSearch={handleSearch}
              showAdvanceSearch={showAdvanceSearch}
              handleToggleAdvanceSearch={handleToggleAdvanceSearch}
            />
            {showAdvanceSearch && (
              <Row align='middle'>
                <Typography.Text>特殊條件: &nbsp;&nbsp;</Typography.Text>
                {Object.entries(advanceSearchOptions).map(([key, label]) => (
                  <Tag.CheckableTag
                    key={key}
                    checked={includes<AdvanceSearchOption>(
                      // @ts-ignore
                      key,
                      selectedOptions
                    )}
                    // @ts-ignore
                    onChange={() => handleSelectOption(key)}
                    style={{
                      cursor: 'pointer',
                      marginTop: '4px',
                      border: '1px solid #d9d9d9',
                    }}
                  >
                    {label}
                  </Tag.CheckableTag>
                ))}
              </Row>
            )}
            <Row>
              <Button
                type='ghost'
                disabled={!hasEdited}
                onClick={() => handleSubmit()}
              >
                更新
              </Button>
              {hasEdited && (
                <Tag className='edited-count' color='blue'>
                  <InfoCircleFilled /> 已修改 {editedCount} 筆資料
                </Tag>
              )}
              {tab === TabTypes.LATEST && (
                <Button
                  className='add-button'
                  type='primary'
                  onClick={createOrder}
                >
                  <PlusOutlined /> 新增訂單
                </Button>
              )}
            </Row>
          </div>
          <OrderTable
            loading={loading}
            tab={tab}
            source={source}
            filterInfo={tableQuery.filters}
            sortInfo={tableQuery.sort}
            appointmentSortOrder={appointmentSortOrder}
            handleSortAppointment={handleSortAppointment}
            handleTableChange={handleTableChange}
            /**
             * props from useOrder
             * 有些 props 是要傳給 TableRow 非 Table，是為了讓 table 能夠使用 memo 提升 render 效能，選擇用 props 傳遞，而非 context
             */
            orders={orders}
            isOrderNew={isOrderNew}
            editOrder={editOrder}
            undoOrder={undoOrder}
            copyOrder={copyOrder}
            deleteOrder={deleteOrder}
          />
        </Space>
      </Card>
    </Page>
  )
}

export default OrderIndex

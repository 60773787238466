import { useMutation } from '@apollo/client'
import { GqlUpload } from '@sov/ui'
import { Button, Form, Spin, message } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'
import { all, has, lensPath } from 'ramda'
import React, { useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { createFeedbackMutation } from '../../../graphql/feedback/mutation/create'
import {
  AllPrivilege,
  CreateFeedbackInput,
  CreateFeedbackMutation,
  CreateFeedbackVariables,
  EntityType,
  FeedbackCategory,
  FeedbackStatus,
} from '../../../graphql/types'
import { authContext } from '../../context'
import { useLoadingLayer } from '../../helpers/hooks'
import { getUserEntityFromAuth, getUserEntityIdFromAuth } from '../../utils'
import { OnceButton } from '../common/button'
import FormFeedback from '../form/feedback'
import BreadcrumbCreator from '../layout/BreadcrumbCreator'
import Page, { Section } from '../layout/Page'
import Title from '../layout/Title'

const Feedback = () => {
  const auth = useContext(authContext)
  const [submitDisabled, setSubmitDisabled] = useState(true)

  const history = useHistory()
  const [form] = Form.useForm()
  const [create] = useMutation<CreateFeedbackMutation, CreateFeedbackVariables>(
    createFeedbackMutation
  )
  const { loading, tip, setLoadingLayer } = useLoadingLayer()

  const initialValues = {
    entity: getUserEntityIdFromAuth(auth),
    entityType: EntityType.Employee,
    category: FeedbackCategory.Bug,
    status: FeedbackStatus.Pending,
  }

  const handleUploadScreenshots = (info: UploadChangeParam) => {
    if (all(has('response'), info.fileList)) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }

  const handleSubmit = async () => {
    try {
      const fieldsValue = await form.validateFields()
      const payload = GqlUpload.filesFieldTransformer<CreateFeedbackInput>(
        lensPath(['screenshotList']),
        fieldsValue
      )

      setLoadingLayer({ loading: true, tip: '新增中...' })
      await create({
        variables: {
          payload,
        },
        update: async (cache, { data }) => {
          if (data) {
            message.info('已新增回饋')
            history.push('/feedbacks')
          }
        },
      })
    } catch (e) {
      if (e.errorFields) {
        form.scrollToField(e.errorFields[0].name)
      }
      if (e.message) {
        message.error(e.message)
      }
    }
  }

  return (
    <Page
      header={
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'FeedbackCreate' }]}
          />
          <Title route={{ key: 'FeedbackCreate' }} />
        </>
      }
      loading={loading}
      loadingComponent={<Spin size='large' tip={tip} />}
    >
      <Section>
        <FormFeedback
          form={form}
          initialValues={initialValues}
          entityName={getUserEntityFromAuth(auth)?.name}
          onUploadScreenShots={handleUploadScreenshots}
        />
        <Form.Item wrapperCol={{ span: 16, offset: 6 }}>
          <OnceButton
            disabled={submitDisabled}
            label='新增資料'
            onClick={handleSubmit}
            requiredPrivilege={AllPrivilege.FeedbackCreate}
            type='primary'
          />
          <Button style={{ marginLeft: 24 }}>
            <Link to='/feedbacks'>回饋清單</Link>
          </Button>
        </Form.Item>
      </Section>
    </Page>
  )
}

export default Feedback

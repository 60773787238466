import { gql } from '@apollo/client'
import { MultiLine } from '@sov/ui'
import { Col, Row } from 'antd'
import { find, isNil, join, propEq, reject, sort, split, tail } from 'ramda'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import {
  BraceAuditFragment,
  TaskType,
  ToothPosition,
  ToothStatusType,
} from '../../../../../graphql/types'
import GoodsCode from '../../../../components/common/GoodsCode'
import { getIsClinicNearCompany } from '../../../../utils'

const basicRowHeight = '11.5mm'
const getEmptyElement = (amount: number) =>
  new Array(amount).fill(null).map((item, index) => <div key={index} />)
const getFDILabel = (fdi: ToothPosition) => join('', tail(split('_', fdi)))

interface Props {
  className?: string
  item: BraceAuditFragment
}

/** 牙套工單 */
const BraceAudit: FC<Props> = (props) => {
  const { className, item } = props
  const { t } = useTranslation()

  if (
    item.__typename === 'MoldStage' ||
    item.__typename === 'DesignStage' ||
    item.__typename === 'EvalStage'
  ) {
    return null
  }

  const designStage = item.__typename === 'PrintStage' ? item.designStage : null

  const taskOwnerName = find(
    propEq('type', TaskType.CompleteDesign),
    designStage?.tasks || []
  )?.owner?.name
  const auxiliaryList = designStage
    ? reject(isNil, [
        designStage.step.ipr ? 'IPR' : null,
        designStage.step.attachment ? 'Att' : null,
        designStage.instructionCard ? 'Card' : null,
      ])
    : []

  const missingTeeth =
    item.patient.intraOral.teethStatus
      .filter((tooth) => tooth.type === ToothStatusType.Missing)
      .map((tooth) => getFDILabel(tooth.position)) ?? []
  const isClinicNearCompany = getIsClinicNearCompany(
    item.patient.clinic.deliveryMethod
  )
  /** 依照 FDI 象限，以順時鐘排序 */
  const sortedMissingTeeth = sort((a, b) => {
    /** 同象限內的排序 */
    if (a.slice(0, 1) === b.slice(0, 1)) {
      if (a.slice(0, 1) === '1' || a.slice(0, 1) === '3') {
        /** 一、三象限 */
        return Number(b.slice(1, 2)) - Number(a.slice(1, 2))
      } else {
        /** 二、四象限 */
        return Number(a.slice(1, 2)) - Number(b.slice(1, 2))
      }
    }
    /** 不同象限的排序 */
    return Number(a.slice(0, 1)) - Number(b.slice(0, 1))
  }, missingTeeth)

  return (
    <div className={className}>
      <div className='audit-checks'>
        <div className='full-width no-border text-center'>
          <div>牙套生產與檢驗記錄表</div>
          <div>表號：RP-024-002</div>
        </div>

        <div className='center small-font'>貨號</div>
        <div className='center small-font' style={{ gridColumn: 'span 5' }}>
          {item.__typename === 'PrintStage' && (
            <GoodsCode
              patientCode={item.patient.patientCode}
              serialNumber={item.serialNumber}
              expectedShippingDate={item.expectedShippingDate}
            />
          )}
        </div>

        <div className='center three-column'>項目</div>
        <div className='center'>上顎</div>
        <div className='center'>下顎</div>
        <div className='center'>蓋章</div>

        <div className='ipqc group-header'>
          <div className='center'>I</div>
          <div className='center'>P</div>
          <div className='center'>Q</div>
          <div className='center'>C</div>
        </div>

        <div className='two-row center'>
          <div>模型</div>
          <div>檢查</div>
        </div>
        <div className='center'>支撐材</div>
        {getEmptyElement(2)}
        <div className='two-row' />
        <div className='center'>完整度</div>
        {getEmptyElement(2)}

        <div className='four-column center'>熱壓(機器)</div>
        {getEmptyElement(1)}

        <div className='four-column center'>熱壓(人)</div>
        {getEmptyElement(1)}

        <div className='center'>脱膜</div>
        <div className='center'>氣泡</div>
        {getEmptyElement(3)}

        <div className='two-row center'>
          <div>外型</div>
          <div>修剪</div>
        </div>
        <div className='center'>變形</div>
        {getEmptyElement(2)}
        <div className='two-row' />
        <div className='center'>吸好</div>
        {getEmptyElement(2)}

        <div className='three-row center'>研磨</div>
        <div className='center'>毛邊</div>
        {getEmptyElement(2)}
        <div className='three-row' />
        <div className='center'>長度</div>
        {getEmptyElement(2)}
        <div className='center'>銳利</div>
        {getEmptyElement(2)}

        <div className='center'>清洗</div>
        <div className='center'>粉塵</div>
        {getEmptyElement(3)}

        <div className='two-column center'>日期</div>
        <div className='three-column' />

        <div className='fqc group-header'>
          <div className='center'>F</div>
          <div className='center'>Q</div>
          <div className='center'>C</div>
        </div>

        <div className='two-column center'>工單</div>
        {getEmptyElement(2)}

        <div className='fqc' style={{ gridColumnEnd: 'end' }} />

        <div className='two-column center'>注意事項</div>
        {getEmptyElement(2)}
        <div className='two-column center'>模型</div>
        {getEmptyElement(2)}
        <div className='two-column center'>熱壓</div>
        {getEmptyElement(2)}
        <div className='two-column center'>變形</div>
        {getEmptyElement(2)}
        <div className='two-column center'>研磨</div>
        {getEmptyElement(2)}
        <div className='two-column center'>清洗</div>
        {getEmptyElement(2)}

        <div className='three-column center'>日期</div>
        <div className='three-column' />
        <div className='three-column center'>覆核簽章/日期</div>
        <div className='three-column' />
        <div className='full-width'>備註：</div>
        <div className='full-width no-border' />
        <div className='full-width no-border' />
        <div className='full-width no-border' />
      </div>

      <div className='basic-info'>
        <div className='full-width no-border text-center'>
          <div>舒服美生技股份有限公司</div>
          <div>表號：RP-021-004</div>
        </div>

        <div className='full-width center'>牙套工單</div>

        <div className='full-width center'>
          {item.patient.clinic.name}
          {isClinicNearCompany ? '(送)' : ''} / {item.patient.name}
        </div>

        <div className='full-width no-padding'>
          <Row className='no-padding' style={{ height: basicRowHeight }}>
            <Col
              span={4}
              className='center small-font'
              style={{ borderRight: '1px solid black' }}
            >
              代號
            </Col>
            <Col className='center small-font' span={20}>
              {item.patient.patientCode}
            </Col>
          </Row>
        </div>

        <div className='full-width no-padding'>
          <Row className='no-padding' style={{ height: basicRowHeight }}>
            <Col
              span={4}
              className='center small-font'
              style={{ borderRight: '1px solid black' }}
            >
              貨號
            </Col>
            <Col className='center small-font' span={20}>
              {item.__typename === 'PrintStage' && (
                <GoodsCode
                  patientCode={item.patient.patientCode}
                  serialNumber={item.serialNumber}
                  expectedShippingDate={item.expectedShippingDate}
                />
              )}
            </Col>
          </Row>
        </div>

        <div className='label-width center'>列印批號</div>
        <div className='content-width center'>{item.rpBatchNumber}</div>

        <div className='left-half indent'>塔位: {item.patient.cabinetCode}</div>
        <div className='right-half indent'>生產日:</div>

        <div className='left-half indent small-font'>
          下單人員: {taskOwnerName}
        </div>
        <div className='right-half indent'>
          {item.__typename === 'PrintStage'
            ? `製造: ${item.serialNumber}`
            : `配件: ${t(`stage.accessoryCode.${item.accessoryCode}`)}`}
        </div>

        <div className='left-half indent'>
          上/下顎:{' '}
          {item.__typename === 'PrintStage'
            ? `${item.designStage?.upperModelCount ?? 0} / ${
                item.designStage?.lowerModelCount ?? 0
              }`
            : `${item.upperModelCount ?? 0} / ${item.lowerModelCount ?? 0}`}
        </div>
        <div className='right-half indent'>
          貨品: {join(',', auxiliaryList)}
        </div>

        <div className='left-half indent'>
          Atta模板:{' '}
          {item.__typename === 'PrintStage'
            ? `${item.designStage?.upperAttachmentTemplateCount ?? 0} / ${
                item.designStage?.lowerAttachmentTemplateCount ?? 0
              }`
            : `${item.upperAttachmentTemplateCount ?? 0} / ${
                item.lowerAttachmentTemplateCount ?? 0
              }`}
        </div>
        <div className='right-half indent'>
          防磨牙套:{' '}
          {item.__typename === 'PrintStage'
            ? `${item.designStage?.upperMouthGuardCount ?? 0} / ${
                item.designStage?.lowerMouthGuardCount ?? 0
              }`
            : `${item.upperMouthGuardCount ?? 0} / ${
                item.lowerMouthGuardCount ?? 0
              }`}
        </div>

        <div className='full-width indent'>
          <div>牙套/維持器</div>
          {item.__typename === 'PrintStage' ? (
            <div style={{ wordBreak: 'break-word' }}>
              <div>
                上: {item.designStage?.upperBraceCount},{' '}
                {item.designStage?.upperBraceMaterial},{' '}
                {item.designStage?.upperBraceThickness}
              </div>
              <div>
                下: {item.designStage?.lowerBraceCount},{' '}
                {item.designStage?.lowerBraceMaterial},{' '}
                {item.designStage?.lowerBraceThickness}
              </div>
            </div>
          ) : (
            <div style={{ wordBreak: 'break-word' }}>
              <div>
                上: {item.upperBraceCount}, {item.upperBraceMaterial},{' '}
                {item.upperBraceThickness}
              </div>
              <div>
                下: {item.lowerBraceCount}, {item.lowerBraceMaterial},{' '}
                {item.lowerBraceThickness}
              </div>
            </div>
          )}
        </div>

        <div className='full-width indent'>
          <div>Missing Teeth</div>
          <div style={{ wordBreak: 'break-word' }}>
            {join(',', sortedMissingTeeth)}
          </div>
        </div>

        {item.__typename === 'PrintStage' ? (
          <div className='full-width indent'>
            <div>上顎: {designStage?.step.upperStep ?? '--'}</div>
            <div>下顎: {designStage?.step.lowerStep ?? '--'}</div>
          </div>
        ) : (
          ''
        )}

        <div className='full-width indent'>
          注意事項:
          <MultiLine
            style={{ wordBreak: 'break-word' }}
            text={item.patient.note.braces}
          />
        </div>
      </div>
    </div>
  )
}

const commonTableStyle = css`
  /* table style */
  & > div {
    /* cell style */
    overflow: hidden;
    border: 1px solid #000;
    margin: 0 -2px -2px 0;
    padding-top: 8px;
    padding-bottom: 8px;
  }
`

const StyledBraceAudit = styled(BraceAudit)`
  font-weight: bold;
  font-size: 18px;
  color: black;

  /* common style */
  .no-border {
    border: none !important;
  }
  .no-padding {
    padding: 0 !important;
  }
  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .text-center {
    text-align: center;
  }
  .indent {
    padding-left: 8px;
    padding-right: 8px;
  }
  .full-width {
    grid-column: start / end;
  }
  .small-font {
    font-size: 14px;
  }
  .large-font {
    font-size: 20px;
  }

  .audit-checks {
    display: grid;
    grid-template-columns: [start] 10mm [line1] 17mm [line2] 17mm [line3] 12mm [line4] 12mm [line5] 12mm [end];
    grid-template-rows: 16mm repeat(23, 11.5mm) 18mm repeat(3, 11.5mm);

    ${commonTableStyle}
    .group-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .ipqc {
      grid-row: 4 / 16;
    }
    .fqc {
      grid-row: 16 / 23;
    }
    .two-column {
      grid-column: span 2;
    }
    .three-column {
      grid-column: span 3;
    }
    .four-column {
      grid-column: span 4;
    }
    .two-row {
      grid-row: span 2;
    }
    .three-row {
      grid-row: span 3;
    }
  }

  .basic-info {
    display: grid;
    grid-template-columns: [start] 26mm [left] 14mm [center] 40mm [end];
    grid-template-rows:
      auto
      repeat(7, ${basicRowHeight})
      repeat(3, auto);

    ${commonTableStyle}
    .label-width {
      grid-column: start / left;
    }
    .content-width {
      grid-column: left / end;
    }
    .left-half {
      grid-column: start / center;
    }
    .right-half {
      grid-column: center / end;
    }
  }
`

export const braceAuditFragment = gql`
  fragment BraceAudit on Stage {
    id
    __typename
    expectedShippingDate
    patient {
      name
      patientCode
      cabinetCode
      clinic {
        name
        deliveryMethod
      }
      note {
        braces
      }
      intraOral {
        teethStatus {
          type
          position
        }
      }
    }
    ... on AccessoryStage {
      accessoryCode
      rpBatchNumber
      upperModelCount
      lowerModelCount
      upperAttachmentTemplateCount
      lowerAttachmentTemplateCount
      upperMouthGuardCount
      lowerMouthGuardCount
      upperBraceCount
      upperBraceMaterial
      upperBraceThickness
      lowerBraceCount
      lowerBraceMaterial
      lowerBraceThickness
    }
    ... on PrintStage {
      serialNumber
      rpBatchNumber
      designStage {
        tasks {
          type
          owner {
            name
          }
        }
        step {
          ipr
          attachment
          upperStep
          lowerStep
        }
        instructionCard {
          instruction
        }
        upperModelCount
        lowerModelCount
        upperAttachmentTemplateCount
        lowerAttachmentTemplateCount
        upperMouthGuardCount
        lowerMouthGuardCount
        upperBraceCount
        upperBraceMaterial
        upperBraceThickness
        lowerBraceCount
        lowerBraceMaterial
        lowerBraceThickness
      }
    }
  }
`

export default StyledBraceAudit

import React from 'react'
import { Link } from 'react-router-dom'

import BreadcrumbCreator from '../../layout/BreadcrumbCreator'
import Title from '../../layout/Title'

interface Props {
  item: string
}

const FeedbackMenu = (props: Props) => {
  const { item } = props

  return (
    <>
      <BreadcrumbCreator
        routes={[
          { key: 'Home' },
          { key: 'FeedbackList' },
          {
            key: 'FeedbackDetail',
            render: () => <Link to={`/feedbacks/${item}`}>回饋詳情</Link>,
          },
        ]}
      />
      <Title route={{ key: 'FeedbackDetail' }} />
    </>
  )
}

export default FeedbackMenu

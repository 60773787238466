export type PresetContentItem = {
  title: string
  option: {
    title: string
    value: string
  }[]
}[]

const createPresetContentList = (): PresetContentItem => {
  return [
    {
      title: '指示卡說明',
      option: [
        {
          title: '橡皮筋尺寸建議',
          value: '(橡皮筋尺寸建議: 3/16 or 1/8 inch; 3.5 or 6 oz)',
        },
        {
          title: '棕色標記',
          value: '於...頰側面黏著Button，並與...頰側近遠心的cut互拉橡皮筋',
        },
        {
          title: '藍色標記',
          value: '於...遠心，...近遠心及...近心各做...的IPR',
        },
        {
          title: '紅色標記',
          value: '...添加Attachment，其餘保留不動',
        },
      ],
    },
    {
      title: '最終階段',
      option: [
        {
          title: '可不重取模',
          value:
            '本階段為最終階段，如臨床仍須修改請醫師告知業務新指示，可不重取模。',
        },
        {
          title: '重新取模',
          value: '本階段為最終階段，如臨床仍須修改請重新取模。',
        },
      ],
    },

    {
      title: '單顎已完結',
      option: [
        {
          title: '上顎已完結',
          value:
            '此階段上顎為最終階段，故下階段開始，上顎每一階段僅會出一副維持器',
        },
        {
          title: '下顎已完結',
          value:
            '此階段下顎為最終階段，故下階段開始，下顎每一階段僅會出一副維持器。',
        },
      ],
    },
    {
      title: '重取模',
      option: [
        {
          title: '確認橡皮筋狀況',
          value:
            '此階段結束煩請醫師重新取模，確認__拉動橡皮筋狀況，後將輸出新牙套。',
        },
        {
          title: '比對咬合關係',
          value: '此階段結束煩請醫師重新取模，比對咬合關係後將輸出新牙套。',
        },
        {
          title: '比對齒列狀況',
          value: '此階段結束煩請醫師重新取模，比對齒列狀況後將輸出新牙套。',
        },
        {
          title: '比對中線位置',
          value:
            '此階段結束煩請醫師重新取模並附上口外照，比對中線位置及齒列狀況後將輸出新牙套。',
        },
      ],
    },
  ]
}

export { createPresetContentList }

import { Button, Row } from 'antd'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import Page, { Section } from '../../../layout/Page'
import TableTask from '../../../table/Task'
import { usePatientTasks } from './usePatientTasks'

interface RouteProps {
  patientId: string
}

const WPatientTask = () => {
  const match = useRouteMatch<RouteProps>()
  const patientId = match.params.patientId
  const {
    loading,
    tasks,
    tableSortInfo,
    tableFilterInfo,
    handleTableChange,
    handleRefresh,
  } = usePatientTasks({ patientId })

  if (loading) {
    return <Page loading />
  }
  if (!tasks) {
    return null
  }

  return (
    <Section>
      <Row justify='space-between'>
        <Button onClick={handleRefresh}>清除篩選排序</Button>
      </Row>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <TableTask
            source={tasks}
            loading={loading}
            sortInfo={tableSortInfo}
            filterInfo={tableFilterInfo}
            locale={{ emptyText: '該病患無任何任務' }}
            handleTableChange={handleTableChange}
          />
        </div>
      </div>
    </Section>
  )
}

export default WPatientTask

import { gql } from '@apollo/client'
import { Link } from '@sov/ui'
import { Form, Radio } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { map, values } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  InvoiceClientInfoInvoiceItemFragment,
  InvoiceClientInfoPatientItemFragment,
  InvoiceClientInfoStageItemFragment,
  Role,
  StageType,
} from '../../../../graphql/types'
import EmployeeLink from '../../link/employee'
import {
  ClinicDoctorSelect,
  ClinicSelect,
  EmployeeSelect,
  PatientSelect,
  PatientStageSelect,
} from '../Select'
import { InvoiceFormType } from './index'

const { PatientLink, StageLink, DoctorLink, ClinicLink } = Link

interface WithStageClientProps {
  form: FormInstance
  patientInfo?: InvoiceClientInfoPatientItemFragment
  stageInfo?: InvoiceClientInfoStageItemFragment
}

const WithStageClient = (props: WithStageClientProps) => {
  const { form, patientInfo, stageInfo } = props

  const handlePatientSelect = () => {
    form.setFieldsValue({
      stage: undefined,
    })
  }

  return (
    <>
      <Form.Item name='patient' label='病患' rules={[{ required: true }]}>
        {patientInfo ? (
          <PatientLink item={patientInfo} />
        ) : (
          <PatientSelect handleItemSelect={handlePatientSelect} />
        )}
      </Form.Item>
      <Form.Item dependencies={['patient']} noStyle>
        {(form) => {
          const patient = form.getFieldValue('patient')
          return (
            <Form.Item name='stage' label='工單' rules={[{ required: true }]}>
              {stageInfo ? (
                <StageLink item={stageInfo} />
              ) : (
                <PatientStageSelect
                  allowClear
                  disabled={!patient}
                  query={{
                    patient,
                    type: [
                      StageType.Eval,
                      StageType.Print,
                      StageType.Accessory,
                    ],
                  }}
                />
              )}
            </Form.Item>
          )
        }}
      </Form.Item>
    </>
  )
}

interface WithoutStageClientProps {
  form: FormInstance
  clinicInfo?: InvoiceClientInfoInvoiceItemFragment['clinic']
  doctorInfo?: InvoiceClientInfoInvoiceItemFragment['doctor']
  salesInfo?: InvoiceClientInfoInvoiceItemFragment['sales']
}

const WithoutStageClient = (props: WithoutStageClientProps) => {
  const { form, clinicInfo, doctorInfo, salesInfo } = props

  const handleClinicSelect = () => {
    form.setFieldsValue({
      doctor: undefined,
      sales: undefined,
    })
  }

  return (
    <>
      <Form.Item name='clinic' label='診所' rules={[{ required: true }]}>
        {clinicInfo ? (
          <ClinicLink item={clinicInfo} />
        ) : (
          <ClinicSelect handleItemSelect={handleClinicSelect} />
        )}
      </Form.Item>
      <Form.Item dependencies={['clinic']} noStyle>
        {(form) => {
          const clinic = form.getFieldValue('clinic')

          return (
            <>
              <Form.Item
                name='doctor'
                label='醫師'
                rules={[{ required: true }]}
              >
                {doctorInfo ? (
                  <DoctorLink item={doctorInfo} />
                ) : (
                  <ClinicDoctorSelect
                    allowClear
                    disabled={!clinic}
                    query={{ clinic }}
                  />
                )}
              </Form.Item>
              <Form.Item name='sales' label='業務' rules={[{ required: true }]}>
                {salesInfo ? (
                  <EmployeeLink item={salesInfo} />
                ) : (
                  <EmployeeSelect
                    showSearch
                    disabled={!clinic}
                    query={{
                      salesClinic: clinic,
                      roles: [Role.Sales],
                    }}
                  />
                )}
              </Form.Item>
            </>
          )
        }}
      </Form.Item>
    </>
  )
}

export type InvoiceClientDisplayInfo = Omit<
  WithStageClientProps & WithoutStageClientProps,
  'form'
>

interface InvoiceClientInfoProps extends InvoiceClientDisplayInfo {
  isFormTypeChangable?: boolean
}

const InvoiceClientInfo = (props: InvoiceClientInfoProps) => {
  const {
    isFormTypeChangable = false,
    patientInfo,
    stageInfo,
    clinicInfo,
    doctorInfo,
    salesInfo,
  } = props
  const { t } = useTranslation()

  return (
    <>
      <Form.Item
        name='formType'
        label='出貨單類型'
        hidden={!isFormTypeChangable}
      >
        <Radio.Group buttonStyle='solid'>
          {map(
            (type) => (
              <Radio value={type} key={type}>
                {t(`invoice.formType.${type}`)}
              </Radio>
            ),
            values(InvoiceFormType)
          )}
        </Radio.Group>
      </Form.Item>
      <Form.Item dependencies={['formType']} noStyle>
        {(form: FormInstance) =>
          form.getFieldValue('formType') === InvoiceFormType.CLINIC_ONLY ? (
            <WithoutStageClient
              form={form}
              clinicInfo={clinicInfo}
              doctorInfo={doctorInfo}
              salesInfo={salesInfo}
            />
          ) : (
            <WithStageClient
              form={form}
              patientInfo={patientInfo}
              stageInfo={stageInfo}
            />
          )
        }
      </Form.Item>
    </>
  )
}

InvoiceClientInfo.fragments = {
  InvoiceClientInfoStageItem: gql`
    fragment InvoiceClientInfoStageItem on Stage {
      id
      ...StageLink
    }
    ${StageLink.fragment}
  `,
  InvoiceClientInfoPatientItem: gql`
    fragment InvoiceClientInfoPatientItem on Patient {
      id
      ...PatientLink
    }
    ${PatientLink.fragment}
  `,
  InvoiceClientInfoInvoiceItem: gql`
    fragment InvoiceClientInfoInvoiceItem on Invoice {
      clinic {
        ...ClinicLink
      }
      doctor {
        ...DoctorLink
      }
      sales {
        ...EmployeeLink
      }
    }
    ${DoctorLink.fragment}
    ${ClinicLink.fragment}
    ${EmployeeLink.fragment}
  `,
}

export default InvoiceClientInfo

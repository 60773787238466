import { getBadgeImageByLevel } from '@sov/common'
import { Images } from '@sov/ui'
import { Form, Input, Select } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'

import { CreateDoctorInput, DoctorLevel } from '../../../../graphql/types'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
}

export type FormDoctorFields = CreateDoctorInput
export type FormDoctorInitialValues = Partial<FormDoctorFields>

interface Props {
  form: FormInstance
  initialValues?: FormDoctorInitialValues
}

const FormDoctor = (props: Props) => {
  const { form, initialValues } = props

  return (
    <Form {...formItemLayout} form={form} initialValues={initialValues}>
      <Form.Item label='醫師姓名' name='name' rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label='Level' name='level' rules={[{ required: true }]}>
        <Select>
          {Object.values(DoctorLevel).map((level) => (
            <Select.Option key={level} value={level}>
              <img src={getBadgeImageByLevel(Images, level)} alt={level} />
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label='聯絡電話' name='phone'>
        <Input />
      </Form.Item>
      <Form.Item label='電子信箱' name='email'>
        <Input />
      </Form.Item>
      <Form.Item label='醫師備註(或喜好)' name='note'>
        <Input.TextArea autoSize={{ minRows: 2 }} />
      </Form.Item>
    </Form>
  )
}

export default FormDoctor

import { gql, useMutation } from '@apollo/client'
import { Button, Form, Spin, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import moment from 'moment'
import { compose, evolve, includes, omit } from 'ramda'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import {
  AllPrivilege,
  CreateEmployeeMutation,
  CreateEmployeeVariables,
  EmployeeFormInfoFragment,
} from '../../../graphql/types'
import { OnceButton } from '../../components/common/button'
import EmployeeForm from '../../components/form/employee'
import BreadcrumbCreator from '../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../components/layout/Page'
import Title from '../../components/layout/Title'
import { useLoadingLayer } from '../../helpers/hooks'

const createEmployeeMutation = gql`
  mutation CreateEmployee($payload: CreateEmployeeInput!) {
    createEmployee(payload: $payload) {
      ...EmployeeFormInfo
    }
  }
  ${EmployeeForm.fragments.EmployeeFormInfo}
`

const EmployeeCreate = () => {
  const [form] = useForm<EmployeeFormInfoFragment>()
  const history = useHistory()
  const { loading, tip, setLoadingLayer } = useLoadingLayer()
  const [create] = useMutation<CreateEmployeeMutation, CreateEmployeeVariables>(
    createEmployeeMutation
  )

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()
      const payload = compose<any, any, any>(
        evolve({
          birthDate: (x) => x.toISOString(),
          onBoardDate: (x) => x.toISOString(),
        }),
        omit(['rolePrivileges', 'backupEmail'])
      )(values)
      setLoadingLayer({ loading: true, tip: '新增中...' })
      await create({
        variables: {
          payload,
        },
        update: async (cache, { data }) => {
          if (data) {
            message.info('已新增員工')
            history.push('/employees')
          }
        },
      })
    } catch (e) {
      setLoadingLayer({ loading: false, tip: '' })
      if (e.message) {
        const isDuplicatedEmployeeNameError = includes(
          'duplicated employee name',
          e.message
        )
        if (isDuplicatedEmployeeNameError) {
          message.error(`員工姓名重複，建議在員工姓名之後加上(英文名)做區隔`)
        } else {
          message.error(`新增員工失敗: ${e.message}`)
        }
      }
    }
  }

  const initialValues = {
    birthDate: moment(),
    onBoardDate: moment(),
    approvers: [],
    taskOwner: [],
  }

  return (
    <Page
      header={
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'EmployeeCreate' }]}
          />
          <Title route={{ key: 'EmployeeCreate' }} />
        </>
      }
      loading={loading}
      loadingComponent={<Spin size='large' tip={tip} />}
    >
      <Section>
        <EmployeeForm form={form} initialValues={initialValues} />
        <Form.Item wrapperCol={{ span: 16, offset: 6 }}>
          <OnceButton
            label='新增資料'
            onClick={handleSubmit}
            requiredPrivilege={AllPrivilege.EmployeeCreate}
            type='primary'
          />
          <Button style={{ marginLeft: 16 }}>
            <Link to='/employees'>返回列表</Link>
          </Button>
        </Form.Item>
      </Section>
    </Page>
  )
}

export default EmployeeCreate

import moment from 'moment'
import {
  all,
  both,
  complement,
  compose,
  equals,
  filter,
  isEmpty,
  isNil,
  map,
  omit,
  prop,
  toPairs,
} from 'ramda'

import {
  AccessoryCode,
  OrderStatus,
  StageOrderContent,
  StageOrderContentInput,
  StageProvideContentInput,
  StageType,
} from '../../../../graphql/types'
import { TabTypes } from '../../../pages/order'
import { isEmptyOrNil } from '../../../utils'
import { ColumnKeys, IOrder } from '../Order'
import {
  OrderForm,
  StageOrderFieldType,
  StageProvideFieldType,
} from './TableCells'

export const getDisplayColumns = (tab: TabTypes) => {
  const commonDisplay = [
    ColumnKeys.CLINIC,
    ColumnKeys.PATIENT,
    ColumnKeys.APPOINTMENTDATE,
    ColumnKeys.TYPE,
    ColumnKeys.DESCRIPTION,
    ColumnKeys.STAGEORDER,
    ColumnKeys.STAGEPROVIDE,
    ColumnKeys.STATUS,
  ]
  switch (tab) {
    case TabTypes.LATEST:
      return [...commonDisplay, ColumnKeys.DUEDATE, ColumnKeys.ACTION]
    case TabTypes.TOCREATE:
      return [...commonDisplay, ColumnKeys.SALES]
    case TabTypes.HISTORY:
      return [...commonDisplay, ColumnKeys.SALES, ColumnKeys.DUEDATE]

    default:
      return []
  }
}

export const getEditableColumns = (tab: TabTypes, order: IOrder) => {
  switch (tab) {
    case TabTypes.LATEST:
      return [
        ColumnKeys.APPOINTMENTDATE,
        ColumnKeys.DESCRIPTION,
        ColumnKeys.ACTION,
        // clinic 欄位有值時不可編輯，沒值時可以編輯
        ...(isEmpty(order.patient.clinic.id) ? [ColumnKeys.CLINIC] : []),
        // patient 欄位有值時不可編輯，沒值時可以編輯
        ...(isEmpty(order.patient.id) ? [ColumnKeys.PATIENT] : []),
        // 已完成的訂單，不可修改種類、訂單內容
        ...(order.status === OrderStatus.Completed
          ? []
          : [ColumnKeys.TYPE, ColumnKeys.STAGEORDER]),
      ]
    case TabTypes.TOCREATE:
      return [ColumnKeys.STAGEPROVIDE, ColumnKeys.ACTION]
    default:
      return []
  }
}

export const transformStageOrder: (
  args: StageOrderFieldType
) => StageOrderContentInput[] = compose<
  StageOrderFieldType,
  StageOrderFieldType,
  [StageType, AccessoryCode[] | number][],
  StageOrderContentInput[],
  StageOrderContentInput[]
>(
  filter<StageOrderContentInput>((stageOrder) => stageOrder.number > 0),
  map(([type, order]) =>
    order instanceof Array
      ? {
          stageType: type,
          number: order.length,
          items: order,
        }
      : {
          stageType: type,
          number: order,
          items: [],
        }
  ),
  // @ts-ignore: 無法標出 key type 為 StageType，故 ignore
  toPairs,
  filter(both(complement(isNil), complement(isEmpty)))
)

const getPureStageOrderFromOrder = compose(
  map<StageOrderContent, StageOrderContent>(omit(['__typename'])),
  Object.values
)

// @ts-ignore
export const transformStageProvide: (
  args: StageProvideFieldType
) => StageProvideContentInput[] = compose<
  StageProvideFieldType,
  StageProvideFieldType,
  [StageType, string[]][],
  StageProvideContentInput[]
>(
  map(([type, stageIds]) => ({
    stageType: type,
    stages: stageIds,
  })),
  // @ts-ignore: 無法標出 key type 為 StageType，故 ignore
  toPairs,
  filter(both(complement(isNil), complement(isEmpty)))
)

const getPureStageProvideFromOrder = map<
  IOrder['stageProvide'][0],
  StageProvideContentInput
>((provide) => ({
  stageType: provide.stageType,
  stages: map(prop('id'), provide.stages),
}))

const isFieldsSame = (order: IOrder, formValue: OrderForm) =>
  compose(
    all(equals<boolean>(true)),
    map<string, boolean>((fieldKey) => {
      switch (fieldKey) {
        case 'id':
          // 每個 form 都有手動塞入的 id，不比較
          return true
        case ColumnKeys.CLINIC:
          // update 時無此欄位，create 時 form 有值即為「已修改」
          return isNil(formValue.clinic)
        case ColumnKeys.PATIENT:
          // update 時無此欄位，create 時 form 有值即為「已修改」
          return isNil(formValue.patient)
        case ColumnKeys.APPOINTMENTDATE:
          // raw data 無值時檢查 form 是否也無值，有值時檢查是否和 form 值相同
          return isNil(order.appointment)
            ? isNil(formValue.appointmentDate)
            : moment(order.appointment.startDate).isSame(
                formValue.appointmentDate,
                'day'
              )
        case ColumnKeys.STAGEORDER:
          // required field，統一轉成 graphQL input type 比較
          return equals(
            transformStageOrder(formValue.stageOrder),
            getPureStageOrderFromOrder(order.stageOrder)
          )
        case ColumnKeys.STAGEPROVIDE:
          // raw data 無值時檢查 form 是否也無值，有值時統一轉成 graphQL input type 比較是否和 form 值相同
          return isEmptyOrNil(order.stageProvide)
            ? isEmptyOrNil(transformStageProvide(formValue.stageProvide))
            : equals(
                transformStageProvide(formValue.stageProvide),
                getPureStageProvideFromOrder(order.stageProvide)
              )
        default:
          return equals(formValue[fieldKey], order[fieldKey])
      }
    })
  )(Object.keys(formValue))

export const isRowEdited = (formValue: OrderForm, order: IOrder) =>
  isNil(order) ? false : !isFieldsSame(order, formValue)

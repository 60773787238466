import { gql, useQuery } from '@apollo/client'
import {
  ErrorHandling,
  FormQuery,
  MyStringParam,
  TableQuery,
} from '@sov/common'
import { Button, Form } from 'antd'
import { FormInstance, FormProps } from 'antd/lib/form'
import { includes, isNil, map, prop, without } from 'ramda'
import React, { useContext, useState } from 'react'

import {
  AllPrivilege,
  TrackTableFragment,
  TracksQuery,
  TracksQueryDocs,
  TracksQueryQuery,
  TracksQueryQueryVariables,
} from '../../../graphql/types'
import { ClinicSelect, PatientSelect } from '../../components/form/Select'
import BreadcrumbCreator from '../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../components/layout/Page'
import Title from '../../components/layout/Title'
import TrackCreateModal from '../../components/modal/TrackCreate'
import TrackDeleteModal from '../../components/modal/TrackDelete'
import TrackReply from '../../components/modal/TrackReply'
import TrackTable from '../../components/table/Track'
import { authContext } from '../../context'
import { getAuthPrivileges } from '../../utils'

/* ----- query string ----- */
const tracksQuery = gql`
  query TracksQuery(
    $query: TracksQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    tracks(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...TrackTable
      }
      limit
      total
      page
    }
  }
  ${TrackTable.fragment}
`

export type TrackFilterType = Pick<TracksQuery, 'isReply'>

export type TrackSorterField = 'created'

const formInput = {
  patient: MyStringParam,
  clinic: MyStringParam,
}

interface TrackFormInput {
  patient?: string
  clinic?: string
}

interface TrackFormProps extends FormProps {
  formQuery: TrackFormInput
  handleSearch: (formValues: TrackFormInput) => void
  form: FormInstance
  handleOpenCreateModal: () => void
  handleOpenDeleteModal: () => void
  selectedRowKeys: string[]
}

const TrackForm = (props: TrackFormProps) => {
  const {
    formQuery,
    handleSearch,
    form,
    handleOpenCreateModal,
    handleOpenDeleteModal,
    selectedRowKeys,
  } = props
  const initialValues = { clinic: formQuery.clinic, patient: formQuery.patient }
  const auth = useContext(authContext)
  const privilegesOfUser = getAuthPrivileges(auth)

  const hasDeleteTrackPrivileges = includes(
    AllPrivilege.TrackDelete,
    privilegesOfUser
  )
  const hasCreateTrackPrivileges = includes(
    AllPrivilege.TrackCreate,
    privilegesOfUser
  )

  return (
    <>
      <Form
        layout='inline'
        style={{ paddingBottom: '16px' }}
        form={form}
        initialValues={initialValues}
      >
        <Form.Item label='診所名稱' name='clinic'>
          <ClinicSelect
            placeholder='診所名稱'
            onChange={(clinic) =>
              handleSearch({ clinic: clinic as unknown as string })
            }
            allowClear
          />
        </Form.Item>
        <Form.Item label='病患姓名' name='patient'>
          <PatientSelect
            placeholder='病患姓名'
            query={{ clinic: formQuery.clinic }}
            onChange={(patient) =>
              handleSearch({ patient: patient as unknown as string })
            }
            allowClear
          />
        </Form.Item>
        {hasCreateTrackPrivileges && (
          <Form.Item>
            <Button type='primary' onClick={handleOpenCreateModal}>
              新增追蹤
            </Button>
          </Form.Item>
        )}
        {hasDeleteTrackPrivileges && (
          <Form.Item style={{ marginLeft: 'auto', marginRight: '0px' }}>
            <Button
              danger
              onClick={handleOpenDeleteModal}
              disabled={selectedRowKeys.length === 0}
            >
              刪除追蹤
            </Button>
          </Form.Item>
        )}
      </Form>
    </>
  )
}

export const TrackIndex = () => {
  const [selectedTrack, setSelectedTrack] = useState<TrackTableFragment>()
  const [isCreateModalVisible, setIsCreateModalVisible] =
    useState<boolean>(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false)
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { tableQuery, paginateQuery, handleTableChange } =
    TableQuery.useTableQuery<TrackFilterType, TrackSorterField>(
      { limit: 30 },
      { isReply: false }
    )
  const { formQuery, handleFormChange } = FormQuery.useFormQuery(formInput)
  const { data, loading, refetch } = useQuery<
    TracksQueryQuery,
    TracksQueryQueryVariables
  >(tracksQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {
        patientId: formQuery.patient,
        clinicId: formQuery.clinic,
        isReply: tableQuery.filters?.isReply,
      },
      ...paginateQuery,
    },
  })

  const handleOpenReplyModal = (track: TrackTableFragment) =>
    setSelectedTrack(track)
  const handleCloseReplyModal = () => setSelectedTrack(undefined)
  const handleSearch = (formValues: TrackFormInput) =>
    handleFormChange(formValues, 'pushIn')
  const handleCloseCreateModal = () => setIsCreateModalVisible(false)
  const handleOpenCreateModal = () => setIsCreateModalVisible(true)
  const handleCloseDeleteModal = () => setIsDeleteModalVisible(false)
  const handleOpenDeleteModal = () => setIsDeleteModalVisible(true)

  const tracks = data?.tracks
  const [form] = Form.useForm()
  const [selectedTaskItems, setSelectedTaskItems] = useState<TracksQueryDocs[]>(
    []
  )

  const selectedRowKeys = map(prop('id'), selectedTaskItems)
  const rowSelection = {
    selectedRowKeys,
    onSelect: (record: TracksQueryDocs, selected: boolean) => {
      const selectedRow = record
      if (selected) {
        setSelectedTaskItems((originalSelectedTaskItems) => [
          ...originalSelectedTaskItems,
          selectedRow,
        ])
      } else {
        setSelectedTaskItems((originalSelectedTaskItems) =>
          without([selectedRow], originalSelectedTaskItems)
        )
      }
    },
    onSelectAll: (selected: boolean, selectedRows: TracksQueryDocs[]) =>
      setSelectedTaskItems(selectedRows),
  }

  return (
    <Page
      header={
        <>
          <BreadcrumbCreator routes={[{ key: 'Home' }, { key: 'TrackList' }]} />
          <Title
            route={{
              key: 'TrackList',
              render: () => <div>病患配戴追蹤列表</div>,
            }}
          />
        </>
      }
    >
      <Section>
        <TrackForm
          formQuery={formQuery}
          handleSearch={handleSearch}
          form={form}
          handleOpenCreateModal={handleOpenCreateModal}
          handleOpenDeleteModal={handleOpenDeleteModal}
          selectedRowKeys={selectedRowKeys}
        />
        <TrackTable
          loading={loading}
          source={tracks}
          sortInfo={tableQuery.sort}
          filterInfo={tableQuery.filters}
          handleChange={handleTableChange}
          handleOpenReplyModal={handleOpenReplyModal}
          rowSelection={rowSelection}
          selectedRowKeys={selectedRowKeys}
        />
        {!isNil(selectedTrack) && (
          <TrackReply
            visible={!isNil(selectedTrack)}
            track={selectedTrack}
            refetch={refetch}
            handleClose={handleCloseReplyModal}
          />
        )}
        <TrackCreateModal
          visible={isCreateModalVisible}
          handleClose={handleCloseCreateModal}
          refetch={refetch}
        />
        <TrackDeleteModal
          visible={isDeleteModalVisible}
          handleClose={handleCloseDeleteModal}
          selectedRowKeys={selectedRowKeys}
          setSelectedTaskItems={setSelectedTaskItems}
          refetch={refetch}
        />
      </Section>
    </Page>
  )
}

export default TrackIndex

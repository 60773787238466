import { gql, useQuery } from '@apollo/client'
import { ErrorHandling, FormQuery, MyStringParam } from '@sov/common'
import DentalModelViewer from '@sov/ui/src/components/DentalModelViewer'
import { Button, Row, Spin, Typography } from 'antd'
import { isEmpty } from 'ramda'
import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  StageModelQueryQuery,
  StageModelQueryQueryVariables,
} from '../../../graphql/types'

const stageModelQuery = gql`
  query StageModelQuery($id: ID!) {
    stage(id: $id) {
      patient {
        ...DentalModelViewer
      }
    }
  }
  ${DentalModelViewer.fragments.DentalModelViewer}
`

const queryString = {
  patientId: MyStringParam,
  stageId: MyStringParam,
}

const Viewer = () => {
  const history = useHistory()
  const { formQuery, handleFormChange } = FormQuery.useFormQuery(queryString)
  const stageId = formQuery.stageId as string

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading } = useQuery<
    StageModelQueryQuery,
    StageModelQueryQueryVariables
  >(stageModelQuery, {
    errorPolicy: 'none',
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: stageId,
    },
  })
  const stage = data?.stage
  const patient = stage?.patient
  const stages = patient?.stages?.docs ?? []

  const handleChangeStageId = (evalStageId: string) => {
    handleFormChange({ stageId: evalStageId })
  }

  if (loading) {
    return (
      <Row justify='center' align='middle' style={{ height: '100vh' }}>
        <Spin tip='3D Viewer 載入中 ...' size='large' />
      </Row>
    )
  }

  if (!stage || !patient || isEmpty(stages)) {
    return (
      <>
        <Row justify='center'>
          <Typography.Title level={2}>無資料，請返回上頁</Typography.Title>
        </Row>
        <Row justify='center'>
          <Button onClick={() => history.goBack()}>回上一頁</Button>
        </Row>
      </>
    )
  }

  const getReportPreviewLink = (stageId: string) =>
    `stages/${stageId}/analysis/preview`

  return (
    <DentalModelViewer
      patient={patient}
      paramStageId={stageId}
      getReportPreviewLink={getReportPreviewLink}
      handleChangeStageId={handleChangeStageId}
    />
  )
}

export default Viewer

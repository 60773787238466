import { TableQuery } from '@sov/common'
import { Button, Table } from 'antd'
import { ColumnProps, TableProps } from 'antd/lib/table'
import React from 'react'
import { Link } from 'react-router-dom'

import { Product } from '../../../graphql/types'

interface Props<T> {
  source?: {
    docs: T[]
    page?: number
    total: number
    limit: number
  }
  loading?: boolean
  handleTableChange: TableProps<T>['onChange']
}

type TableFields = Product

const defaultSource = {
  docs: [],
  page: 1,
  total: 1,
  limit: 10,
}

function ProductTable<T extends TableFields>({
  source = defaultSource,
  loading,
  handleTableChange,
}: Props<T>) {
  const columns: ColumnProps<T>[] = [
    {
      title: '品項編號',
      width: 100,
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      render: (text) => <div>{text}</div>,
    },
    {
      title: '品項名稱',
      width: 100,
      dataIndex: 'name',
      key: 'name',
      render: (text) => <div>{text}</div>,
    },
    {
      title: '定價',
      width: 100,
      dataIndex: 'price',
      key: 'price',
      render: (text) => <div>{text}</div>,
    },
    {
      title: '規格',
      width: 200,
      dataIndex: 'spec',
      key: 'spec',
      render: (text) => <div>{text}</div>,
    },
    {
      title: '成本',
      width: 200,
      dataIndex: 'cost',
      key: 'cost',
      render: (text) => <div>{text}</div>,
    },
    {
      title: '動作',
      width: 80,
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <Button>
              <Link to={`/products/${record.id}`}>編輯</Link>
            </Button>
          </div>
        )
      },
    },
  ]

  const { docs, page, total, limit } = source

  return (
    <Table<T>
      rowKey='id'
      size='small'
      columns={columns}
      dataSource={docs}
      loading={loading}
      locale={{ emptyText: '系統中無任何出貨品項' }}
      pagination={TableQuery.getAntdPagination({
        page: page || 1,
        total,
        limit,
      })}
      onChange={handleTableChange}
    />
  )
}

export default ProductTable

import { gql, useQuery } from '@apollo/client'
import { ErrorHandling, getStageName } from '@sov/common'
import { StageName } from '@sov/ui'
import { Spin } from 'antd'
import moment from 'moment'
import { ascend, path, reduce, slice, sortWith } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  CustomerServiceReportInvoiceItemFragment,
  ReportInvoiceCustomerServiceQueryQuery,
  ReportInvoiceCustomerServiceQueryQueryVariables,
} from '../../../../graphql/types'

interface NoteTableCellProps {
  invoiceItem: CustomerServiceReportInvoiceItemFragment
}

const NoteTableCell = ({ invoiceItem }: NoteTableCellProps) => {
  const { t } = useTranslation()
  if (invoiceItem.__typename === 'InvoiceWithStage') {
    const [label, affix, serialNumber] = getStageName(t, invoiceItem.stage)
    return (
      <div className='td'>
        {slice(0, 3, invoiceItem.patient.name) +
          ' ' +
          `${label} ${affix}${serialNumber}`}
      </div>
    )
  } else {
    return <div className='td' />
  }
}

const fragments = {
  CustomerServiceReportEmployeeItem: gql`
    fragment CustomerServiceReportEmployeeItem on Employee {
      id
      name
    }
  `,
  CustomerServiceReportInvoiceItem: gql`
    fragment CustomerServiceReportInvoiceItem on Invoice {
      id
      creditDate
      middlemanFee
      doctor {
        id
        name
      }
      clinic {
        id
        name
      }
      ... on InvoiceWithStage {
        patient {
          id
          name
        }
        stage {
          ...StageName
        }
      }
    }
    ${StageName.fragment}
  `,
}

const reportInvoiceCustomerServiceQuery = gql`
  query ReportInvoiceCustomerServiceQuery($id: ID!) {
    employee(id: $id) {
      ...CustomerServiceReportEmployeeItem
    }
  }
  ${fragments.CustomerServiceReportEmployeeItem}
`

interface Props {
  customerServiceId: string
  creditDateInterval: string[]
  invoices: CustomerServiceReportInvoiceItemFragment[]
}

const CustomerServiceReport = (props: Props) => {
  const { customerServiceId, creditDateInterval, invoices } = props
  const { toErrorPage } = ErrorHandling.useErrorHandling()

  const { data, loading } = useQuery<
    ReportInvoiceCustomerServiceQueryQuery,
    ReportInvoiceCustomerServiceQueryQueryVariables
  >(reportInvoiceCustomerServiceQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    fetchPolicy: 'no-cache',
    variables: {
      id: customerServiceId,
    },
  })

  if (loading) {
    return <Spin />
  }

  let currentDoctorId

  // 照時間排
  const invoiceItems = sortWith<CustomerServiceReportInvoiceItemFragment>([
    ascend(path(['doctor', 'id'])),
    ascend(path(['creditDate'])),
  ])(invoices)
  const totalMiddlemanFee = reduce<
    CustomerServiceReportInvoiceItemFragment,
    number
  >(
    (total, item: CustomerServiceReportInvoiceItemFragment) =>
      total + item.middlemanFee,
    0,
    invoices
  )

  return (
    <div>
      <div className='title-container'>
        <div>舒服美牙體技術所</div>
        <span className='title'>客服轉介費明細表</span>
      </div>
      <div className='info-container'>
        <div className='invoice-container'>
          <div>
            交易區間：
            {`${moment(creditDateInterval[0]).format('YYYY-MM-DD')}~${moment(
              creditDateInterval[1]
            ).format('YYYY-MM-DD')}`}
          </div>
          <div>負責客服：{data?.employee.name}</div>
        </div>
      </div>
      <div className='profile-container'>
        <div className='table'>
          <div className='tr'>
            <div className='td field'>入帳日期</div>
            <div className='td field'>階段名稱</div>
            <div className='td field'>診所</div>
            <div className='td field'>醫生</div>
            <div className='td field'>轉介費</div>
          </div>
          {invoiceItems
            .filter((invoiceItem) => invoiceItem.middlemanFee !== 0)
            .map((invoiceItem, index) => {
              // 在列表中醫生換了
              // 就要加上分隔線
              const addTopLine = currentDoctorId !== invoiceItem.doctor.id
              currentDoctorId = invoiceItem.doctor.id

              return (
                <div
                  className={'tr' + (addTopLine ? ' addTop' : '')}
                  key={index}
                >
                  <div className='td'>
                    {moment(invoiceItem.creditDate).format('YYYY-MM-DD')}
                  </div>
                  <NoteTableCell invoiceItem={invoiceItem} />
                  <div className='td'>{invoiceItem.clinic.name}</div>
                  <div className='td'>{invoiceItem.doctor.name}</div>
                  <div className='td'>{invoiceItem.middlemanFee}</div>
                </div>
              )
            })}
        </div>
      </div>
      <div className='pay-container'>
        <div className='total'>
          <div className='row'>
            <span>本期轉介費合計：</span>
            <span>{totalMiddlemanFee} 元整</span>
          </div>
        </div>
      </div>
      <div className='sov-container'>
        <span>
          <b>舒服美牙體技術所</b>
        </span>
        <span>統一編號：42383872</span>
        <span>營業處：104台北市中山區民權西路19號8樓</span>
        <span>電話：02-25505042</span>
      </div>
    </div>
  )
}

CustomerServiceReport.fragments = fragments

export { CustomerServiceReport }

import { reduce } from 'ramda'

import { InvoiceProductInput } from './types'

type InvoiceProductItem = Pick<InvoiceProductInput, 'count' | 'price'>

interface GetInvoiceProductPriceInfoArgs {
  /* 折扣 */
  discount?: number
  hasTax?: boolean
  /* 轉介費 */
  middlemanFee?: number
  invoiceProducts?: InvoiceProductItem[]
}

const TAX_RATIO = 0.05

/** 取得出貨項目價格相關資訊 */
export const getInvoiceProductPriceInfo = (
  args: GetInvoiceProductPriceInfoArgs
) => {
  const {
    discount = 0,
    hasTax = false,
    middlemanFee = 0,
    invoiceProducts = [],
  } = args
  /* 商品小計 */
  const subtotal = reduce(
    (total, item) => total + item.count * item.price,
    0,
    invoiceProducts
  )

  /* 稅 = 商品小計 * 稅率 */
  const tax = Math.round(hasTax ? subtotal * TAX_RATIO : 0)

  /** @todo: 重構完出貨單中的出貨項目後，這邊會需要重構，這邊暫時先註解 */
  /* 非固定價格出貨品項總金額 */
  // const priceChangingProductsTotal = reduce(
  //   (total, item) => total + item.price,
  //   0,
  //   priceChangingProducts
  // )

  return {
    discount,
    middlemanFee,
    subtotal,
    tax,
    totalPrice: subtotal + tax + middlemanFee - discount,
  }
}

import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { gql } from '@apollo/client'
import { Form, Input, InputNumber, Select, Tooltip } from 'antd'
import React from 'react'

import {
  BraceMaterial,
  BraceThickness,
  FormProductFragment,
  ProductSectionFormFragment,
} from '../../../../graphql/types'
import { defaultLayout } from './constant'

export interface StageUpdateProductFields {
  rpModelNumber: string
  rpBatchNumber: string
  upperModelCount: number
  lowerModelCount: number
  upperAttachmentTemplateCount: number
  lowerAttachmentTemplateCount: number
  upperMouthGuardCount: number
  lowerMouthGuardCount: number
  upperBraceCount: number
  upperBraceMaterial: string
  upperBraceThickness: string
  lowerBraceCount: number
  lowerBraceMaterial: string
  lowerBraceThickness: string
}

interface ProductSectionFormProps
  extends FormComponentProps<StageUpdateProductFields> {
  item: ProductSectionFormFragment
  readOnly?: boolean
}

const ProductSectionForm = (props: ProductSectionFormProps) => {
  const {
    form: { getFieldDecorator },
    item,
    readOnly = false,
  } = props

  if (
    item.__typename === 'DesignStage' ||
    item.__typename === 'AccessoryStage'
  ) {
    return (
      <>
        <FormItem
          {...defaultLayout}
          label={
            readOnly ? (
              <span>
                <span>模型代號 </span>
                <Tooltip title='請從參照設計工單修改'>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            ) : (
              '模型代號'
            )
          }
        >
          {readOnly ? (
            <span>{item.rpModelNumber}</span>
          ) : (
            getFieldDecorator('rpModelNumber', {
              initialValue: item.rpModelNumber ?? '',
            })(<Input />)
          )}
        </FormItem>
        <FormItem
          {...defaultLayout}
          label={
            readOnly ? (
              <span>
                <span>模型數量 </span>
                <Tooltip title='請從參照設計工單修改'>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            ) : (
              '模型數量'
            )
          }
        >
          <span style={{ marginRight: 8 }}>上顎</span>
          {readOnly ? (
            <span>{item.upperModelCount}</span>
          ) : (
            getFieldDecorator('upperModelCount', {
              initialValue: item.upperModelCount ?? 3,
            })(<InputNumber min={0} max={24} />)
          )}
          <span style={{ marginLeft: 8, marginRight: 8 }}>下顎</span>
          {readOnly ? (
            <span>{item.lowerModelCount}</span>
          ) : (
            getFieldDecorator('lowerModelCount', {
              initialValue: item.lowerModelCount ?? 3,
            })(<InputNumber min={0} max={24} />)
          )}
        </FormItem>
        <FormItem
          {...defaultLayout}
          label={
            readOnly ? (
              <span>
                <span>Attachment 模板數量</span>
                <Tooltip title='請從參照設計工單修改'>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            ) : (
              'Attachment 模板數量'
            )
          }
        >
          <span style={{ marginRight: 8 }}>上顎</span>
          {readOnly ? (
            <span>{item.upperAttachmentTemplateCount}</span>
          ) : (
            getFieldDecorator('upperAttachmentTemplateCount', {
              initialValue: item.upperAttachmentTemplateCount ?? 3,
            })(<InputNumber min={0} max={24} />)
          )}
          <span style={{ marginLeft: 8, marginRight: 8 }}>下顎</span>
          {readOnly ? (
            <span>{item.lowerAttachmentTemplateCount}</span>
          ) : (
            getFieldDecorator('lowerAttachmentTemplateCount', {
              initialValue: item.lowerAttachmentTemplateCount ?? 3,
            })(<InputNumber min={0} max={24} />)
          )}
        </FormItem>
        <FormItem
          {...defaultLayout}
          label={
            readOnly ? (
              <span>
                <span>防磨牙套數量 </span>
                <Tooltip title='請從參照設計工單修改'>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            ) : (
              '防磨牙套數量'
            )
          }
        >
          <span style={{ marginRight: 8 }}>上顎</span>
          {readOnly ? (
            <span>{item.upperMouthGuardCount}</span>
          ) : (
            getFieldDecorator('upperMouthGuardCount', {
              initialValue: item.upperMouthGuardCount ?? 3,
            })(<InputNumber min={0} max={24} />)
          )}
          <span style={{ marginLeft: 8, marginRight: 8 }}>下顎</span>
          {readOnly ? (
            <span>{item.lowerMouthGuardCount}</span>
          ) : (
            getFieldDecorator('lowerMouthGuardCount', {
              initialValue: item.lowerMouthGuardCount ?? 3,
            })(<InputNumber min={0} max={24} />)
          )}
        </FormItem>
        <FormItem
          {...defaultLayout}
          label={
            readOnly ? (
              <span>
                <span>牙套/維持器數量</span>
                <Tooltip title='請從參照設計工單修改'>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            ) : (
              '牙套/維持器數量'
            )
          }
        >
          <span style={{ marginRight: 8 }}>上顎</span>
          {readOnly ? (
            <span>{item.upperBraceCount}</span>
          ) : (
            getFieldDecorator('upperBraceCount', {
              initialValue: item.upperBraceCount ?? 3,
            })(<InputNumber min={0} max={24} />)
          )}
          <span style={{ marginLeft: 8, marginRight: 8 }}>下顎</span>
          {readOnly ? (
            <span>{item.lowerBraceCount}</span>
          ) : (
            getFieldDecorator('lowerBraceCount', {
              initialValue: item.lowerBraceCount ?? 3,
            })(<InputNumber min={0} max={24} />)
          )}
        </FormItem>
        <div style={{ color: 'red' }}>
          提醒：TPU 或 Benq 材質，僅可選 T75 厚度
        </div>
        <FormItem
          {...defaultLayout}
          label={
            readOnly ? (
              <span>
                <span>牙套材質 </span>
                <Tooltip title='請從參照設計工單修改'>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            ) : (
              '牙套材質'
            )
          }
        >
          <span style={{ marginRight: 8 }}>上顎</span>
          {readOnly ? (
            <span>{item.upperBraceMaterial}</span>
          ) : (
            getFieldDecorator('upperBraceMaterial', {
              initialValue: item.upperBraceMaterial ?? BraceMaterial.Petg,
            })(
              <Select style={{ width: 120 }}>
                {Object.values(BraceMaterial).map((x, index) => (
                  <Select.Option key={index} value={x}>
                    {x}
                  </Select.Option>
                ))}
              </Select>
            )
          )}
          <span style={{ marginLeft: 8, marginRight: 8 }}>下顎</span>
          {readOnly ? (
            <span>{item.lowerBraceMaterial}</span>
          ) : (
            getFieldDecorator('lowerBraceMaterial', {
              initialValue: item.lowerBraceMaterial ?? BraceMaterial.Petg,
            })(
              <Select style={{ width: 120 }}>
                {Object.values(BraceMaterial).map((x, index) => (
                  <Select.Option key={index} value={x}>
                    {x}
                  </Select.Option>
                ))}
              </Select>
            )
          )}
        </FormItem>
        <FormItem
          {...defaultLayout}
          label={
            readOnly ? (
              <span>
                <span>牙套厚度 </span>
                <Tooltip title='請從參照設計工單修改'>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            ) : (
              '牙套厚度'
            )
          }
        >
          <span style={{ marginRight: 8 }}>上顎</span>
          {readOnly ? (
            <span>{item.upperBraceThickness}</span>
          ) : (
            getFieldDecorator('upperBraceThickness', {
              initialValue: item.upperBraceThickness ?? BraceThickness.T75,
            })(
              <Select style={{ width: 120 }}>
                {Object.values(BraceThickness).map((x, index) => (
                  <Select.Option key={index} value={x}>
                    {x}
                  </Select.Option>
                ))}
              </Select>
            )
          )}
          <span style={{ marginLeft: 8, marginRight: 8 }}>下顎</span>
          {readOnly ? (
            <span>{item.lowerBraceThickness}</span>
          ) : (
            getFieldDecorator('lowerBraceThickness', {
              initialValue: item.lowerBraceThickness ?? BraceThickness.T75,
            })(
              <Select style={{ width: 120 }}>
                {Object.values(BraceThickness).map((x, index) => (
                  <Select.Option key={index} value={x}>
                    {x}
                  </Select.Option>
                ))}
              </Select>
            )
          )}
        </FormItem>
      </>
    )
  } else {
    return null
  }
}

ProductSectionForm.fragment = gql`
  fragment ProductSectionForm on Stage {
    ... on DesignStage {
      rpModelNumber
      upperModelCount
      lowerModelCount
      upperAttachmentTemplateCount
      lowerAttachmentTemplateCount
      upperMouthGuardCount
      lowerMouthGuardCount
      upperBraceCount
      upperBraceMaterial
      upperBraceThickness
      lowerBraceCount
      lowerBraceMaterial
      lowerBraceThickness
    }
    ... on AccessoryStage {
      rpModelNumber
      upperModelCount
      lowerModelCount
      upperAttachmentTemplateCount
      lowerAttachmentTemplateCount
      upperMouthGuardCount
      lowerMouthGuardCount
      upperBraceCount
      upperBraceMaterial
      upperBraceThickness
      lowerBraceCount
      lowerBraceMaterial
      lowerBraceThickness
    }
  }
`

interface Props {
  form: FormComponentProps['form']
  item: FormProductFragment
}

const FormItem = Form.Item

const FormProduct = (props: Props) => {
  const { form, item } = props
  const { getFieldDecorator } = form

  /**
   * @todo 待拆分工單詳情頁面
   * 產品表單只跟 設計, 製造, 配件 類型工單有關
   * 設計:
   *  儲存 - 模型數量, 模型編號
   *  顯示 - 模型數量, 模型編號
   * 製造:
   *  儲存 - 列印批號
   *  顯示 - 模型數量(關聯 design 的), 模型編號(關聯 design 的), 列印批號
   * 配件:
   *  儲存 - 列印批號
   *  顯示 - 列印批號
   */
  if (item.__typename === 'DesignStage') {
    return <ProductSectionForm form={form} item={item} />
  }

  if (item.__typename === 'PrintStage') {
    return (
      <>
        {item.designStage ? (
          <ProductSectionForm form={form} item={item.designStage} readOnly />
        ) : (
          '無參照設計工單'
        )}
        <FormItem {...defaultLayout} label='列印批號'>
          {getFieldDecorator('rpBatchNumber', {
            initialValue: item.rpBatchNumber,
          })(<Input />)}
        </FormItem>
      </>
    )
  }

  if (item.__typename === 'AccessoryStage') {
    return (
      <>
        <ProductSectionForm form={form} item={item} />
        <FormItem {...defaultLayout} label='列印批號'>
          {getFieldDecorator('rpBatchNumber', {
            initialValue: item.rpBatchNumber,
          })(<Input />)}
        </FormItem>
      </>
    )
  }

  return null
}

FormProduct.fragment = gql`
  fragment FormProduct on Stage {
    id
    __typename
    ... on DesignStage {
      ...ProductSectionForm
    }
    ... on PrintStage {
      rpBatchNumber
      designStage {
        ...ProductSectionForm
      }
    }
    ... on AccessoryStage {
      rpBatchNumber
      ...ProductSectionForm
    }
  }
  ${ProductSectionForm.fragment}
`

export default FormProduct

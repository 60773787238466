import Icon from '@ant-design/icons'
import React from 'react'

const Svg = () => (
  <svg
    width='56'
    height='56'
    viewBox='0 0 56 56'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='28' cy='28' r='28' fill='black' fillOpacity='0.05' />
    <path
      // eslint-disable-next-line max-len
      d='M22.4021 38C22.925 38 23.3608 37.8148 23.7094 37.4444C23.7481 37.4074 23.7869 37.3704 23.8256 37.3333C23.8643 37.2963 23.9031 37.2593 23.9418 37.2222L42.477 19.3889C42.8256 19.0556 42.9999 18.6389 42.9999 18.1389C42.9999 17.6389 42.8256 17.2222 42.477 16.8889C42.0896 16.5185 41.6442 16.3333 41.1406 16.3333C40.637 16.3333 40.1916 16.5185 39.8042 16.8889L22.373 33.6667L14.529 26.1667C14.1416 25.7963 13.6961 25.6111 13.1926 25.6111C12.689 25.6111 12.2435 25.7963 11.8562 26.1667C11.5076 26.5 11.3333 26.9167 11.3333 27.4167C11.3333 27.9167 11.5076 28.3333 11.8562 28.6667L21.0947 37.4444C21.4433 37.8148 21.8791 38 22.4021 38Z'
      fill='black'
      fillOpacity='0.2'
    />
  </svg>
)

const CheckIcon = (props) => <Icon {...props} component={Svg} />

export default CheckIcon

import { red } from '@ant-design/colors'
import {
  DeleteOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  QuestionCircleFilled,
} from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { CheckPoint, GqlUpload, MultiLine, StageName } from '@sov/ui'
import {
  Checkbox,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Space,
  Tooltip,
  Typography,
  message,
} from 'antd'
import { FormProps } from 'antd/lib/form'
import React, { useState } from 'react'

import {
  AuxiliaryType,
  ContentCheckPointFragment,
  DeleteCheckPointMutation,
  DeleteCheckPointMutationVariables,
  JawPosition,
  UpdateCheckPointMutation,
  UpdateCheckPointMutationVariables,
} from '../../../../graphql/types'
import { ActivableIcon } from '../../../components/common/ViewerFileIcon'
import {
  getInitialJawFile,
  getInitialToothFiles,
} from '../../../components/form/stage/FormViewerFiles'
import ViewerFilesUpload from '../../../components/modal/ViewerFilesUpload'
import {
  deleteCheckPoint,
  updateCheckPoint,
} from '../PatientReportEdit/CheckPointCard'
import useDebounceSave from '../PatientReportEdit/useDebounceSave'
import ToggleForm from './ToggleForm'

interface CheckPointForm {
  upper: string
  lower: string
  note: string
}

interface ContentCheckPointProps {
  isEditing: boolean
  patientId: string
  stageId: string
  checkPoint: ContentCheckPointFragment
  handleRefetch: () => Promise<void>
  handleEdit: (contentKey: string) => void
}

const ContentCheckPoint = (props: ContentCheckPointProps) => {
  const {
    isEditing,
    checkPoint,
    patientId,
    stageId,
    handleRefetch,
    handleEdit,
  } = props

  const { serialNumber, goalStep, isFinal, files, hasFilesForViewer } =
    checkPoint

  const [update] = useMutation<
    UpdateCheckPointMutation,
    UpdateCheckPointMutationVariables
  >(updateCheckPoint)
  const [deleteCP] = useMutation<
    DeleteCheckPointMutation,
    DeleteCheckPointMutationVariables
  >(deleteCheckPoint)
  const [form] = Form.useForm<CheckPointForm>()
  const [visible, setVisible] = useState(false)

  const defaultFiles = [
    ...getInitialToothFiles(files?.teeth),
    ...getInitialJawFile(JawPosition.Upper, files?.upperJawModel),
    ...getInitialJawFile(JawPosition.Lower, files?.lowerJawModel),
  ]

  const handleOpen = () => setVisible(true)
  const handleClose = () => {
    setVisible(false)

    const messageKey = 'upload-loading'
    message.loading({ content: '更新中', key: messageKey })
    handleRefetch().then(() => {
      message.destroy(messageKey)
    })
  }
  const handleUpdate: FormProps['onValuesChange'] = async () => {
    const value = (await form.validateFields()) as CheckPointForm
    await update({
      variables: {
        id: stageId,
        serialNumber,
        payload: {
          goalStep,
          ...value,
        },
      },
      update: async (_cache, { errors }) => {
        if (errors) {
          message.error(errors[0].message)
        } else {
          message.info(`已儲存 CP${serialNumber} 內容`)
          await handleRefetch()
        }
      },
    })
  }
  const { handleChange } = useDebounceSave({ onSave: handleUpdate })

  const handleDeleteCP = async () => {
    await deleteCP({
      variables: {
        id: stageId,
        serialNumber: serialNumber,
      },
      update: async (_cache, { data }) => {
        if (data?.deleteCheckPoint) {
          await handleRefetch()
          message.info('已刪除 check point')
        }
      },
    })
  }

  const goalStepValidator = (rule, value) => {
    const isValid = Number.isInteger(value) && value > 0
    return isValid ? Promise.resolve() : Promise.reject(new Error('需輸入整數'))
  }

  const DeleteConfirmTitle = (
    <div>
      <div>確定要刪除這個 Check Point?</div>
      <div style={{ color: 'red' }}>模型檔案資料將一併刪除</div>
    </div>
  )

  return (
    <>
      <Row justify='space-between'>
        <Typography.Title level={4}>
          {isFinal ? 'Final' : `CP${serialNumber}`} 內容
        </Typography.Title>
        <Space size='large'>
          <ActivableIcon active={hasFilesForViewer} onClick={handleOpen}>
            {hasFilesForViewer ? (
              <FolderOutlined />
            ) : (
              <Tooltip title='尚未上傳模型檔案'>
                <FolderOpenOutlined />
              </Tooltip>
            )}
          </ActivableIcon>
          {!isFinal && (
            <Popconfirm title={DeleteConfirmTitle} onConfirm={handleDeleteCP}>
              <DeleteOutlined style={{ color: red.primary }} />
            </Popconfirm>
          )}
        </Space>
      </Row>
      <ToggleForm
        active={isEditing}
        form={form}
        initialValues={checkPoint}
        onValuesChange={handleChange}
        onClick={() => handleEdit('checkPoint')}
        style={{ paddingLeft: '16px' }}
      >
        <Typography.Text type='secondary'>上顎</Typography.Text>
        <Form.Item name='upper'>
          {isEditing ? (
            <Input.TextArea
              autoSize
              placeholder='填寫 Check point 預計達成目標'
              style={{ resize: 'none' }}
            />
          ) : (
            <MultiLine text={checkPoint.upper} />
          )}
        </Form.Item>
        <Typography.Text type='secondary'>下顎</Typography.Text>
        <Form.Item name='lower'>
          {isEditing ? (
            <Input.TextArea
              autoSize
              placeholder='填寫 Check point 預計達成目標'
              style={{ resize: 'none' }}
            />
          ) : (
            <MultiLine text={checkPoint.lower} />
          )}
        </Form.Item>
        <Typography.Text type='secondary'>內部備註</Typography.Text>
        <Form.Item name='note'>
          {isEditing ? (
            <Input.TextArea
              autoSize
              placeholder='此區域內容作為內部進行設計時的提醒，醫生並不會看到'
              style={{ resize: 'none' }}
            />
          ) : (
            <MultiLine text={checkPoint.note} />
          )}
        </Form.Item>

        <Row align='middle'>
          此 Checkpoint，預計在 Step
          <Form.Item
            name='goalStep'
            style={{ margin: 0 }}
            rules={[
              { required: true, message: '不可空白' },
              { validator: goalStepValidator, message: '需輸入整數' },
            ]}
          >
            {isEditing ? (
              <InputNumber
                size='small'
                defaultValue={goalStep}
                style={{ width: '60px', margin: '0 8px' }}
              />
            ) : (
              goalStep
            )}
          </Form.Item>
          達成
          <Tooltip
            placement='right'
            title='請 Senior 評估預計達成 CheckPoint 數量。此數量僅供預估，不會讓醫生看到，可以在療程進行中隨臨床狀況增減。'
          >
            <QuestionCircleFilled
              style={{
                marginLeft: '8px',
                fontSize: '16px',
                color: 'rgba(0, 0, 0, 0.2)',
              }}
            />
          </Tooltip>
        </Row>

        <Form.Item name='instruction' label='臨床指示'>
          {isEditing ? (
            <Checkbox.Group>
              <Checkbox value={AuxiliaryType.StrippingAll}>IPR</Checkbox>
              <Checkbox value={AuxiliaryType.Button}>Button</Checkbox>
              <Checkbox value={AuxiliaryType.Attachment}>Attachment</Checkbox>
              <Checkbox value={AuxiliaryType.Tad}>TAD</Checkbox>
            </Checkbox.Group>
          ) : (
            <Space>
              {checkPoint.instruction.map((item) => (
                <CheckPoint.InstructionTag key={item} instruction={item} />
              ))}
            </Space>
          )}
        </Form.Item>
      </ToggleForm>
      <ViewerFilesUpload
        patientId={patientId}
        stageId={stageId}
        visible={visible}
        defaultFiles={defaultFiles}
        hasFilesForViewer={hasFilesForViewer}
        cpSerialNumber={serialNumber}
        handleClose={handleClose}
      />
    </>
  )
}

ContentCheckPoint.fragments = {
  ContentCheckPoint: gql`
    fragment ContentCheckPoint on CheckPoint {
      hasFilesForViewer
      serialNumber
      goalStep
      upper
      lower
      note
      isFinal
      instruction
      files {
        teeth {
          position
          file {
            ...fileInfo
          }
        }
        upperJawModel {
          ...fileInfo
        }
        lowerJawModel {
          ...fileInfo
        }
      }
    }
    ${GqlUpload.Upload.fragment}
    ${StageName.fragment}
  `,
}

export default ContentCheckPoint

import { QuestionCircleFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

const CustomizedQuestionIcon = styled(QuestionCircleFilled)`
  color: rgba(0, 0, 0, 0.2);
  font-size: 16px;
  margin-left: 4px;
`

interface QuestionIconWithTooltipProps {
  title: ReactNode
}

const QuestionIconWithTooltip = (props: QuestionIconWithTooltipProps) => {
  const { title } = props

  return (
    <Tooltip overlayStyle={{ maxWidth: 508 }} title={title}>
      <CustomizedQuestionIcon />
    </Tooltip>
  )
}

export default QuestionIconWithTooltip

import { gql } from '@apollo/client'
import { TFunction } from 'i18next'
import {
  always,
  cond,
  equals,
  join,
  lensIndex,
  pipe,
  set,
  slice,
  T,
} from 'ramda'

import {
  InvoiceMailInvoiceItemFragment,
  InvoiceMailPatientItemFragment,
  InvoiceMailStageItemFragment,
} from '../../../../graphql/types'

export const invoiceMailFragments = {
  InvoiceMailStageItem: gql`
    fragment InvoiceMailStageItem on Stage {
      id
      __typename
      ... on MoldStage {
        serialNumber
      }
      ... on EvalStage {
        serialNumber
      }
      ... on DesignStage {
        serialNumber
      }
      ... on PrintStage {
        serialNumber
      }
      ... on AccessoryStage {
        accessoryCode
      }
    }
  `,
  InvoiceMailPatientItem: gql`
    fragment InvoiceMailPatientItem on Patient {
      id
      name
    }
  `,
  InvoiceMailInvoiceItem: gql`
    fragment InvoiceMailInvoiceItem on Invoice {
      id
      homeDeliveryId
      homeDeliveryType
      clinic {
        id
        name
      }
      doctor {
        id
        name
        email
      }
      sales {
        id
        email
      }
    }
  `,
}

interface Params {
  stageItem?: InvoiceMailStageItemFragment
  patientItem?: InvoiceMailPatientItemFragment
  invoiceItem: InvoiceMailInvoiceItemFragment
  clinicItem: InvoiceMailInvoiceItemFragment['clinic']
  doctorItem: InvoiceMailInvoiceItemFragment['doctor']
  salesItem: InvoiceMailInvoiceItemFragment['sales']
}

export const sendMail = (params: Params, t: TFunction) => () => {
  const {
    patientItem,
    invoiceItem,
    stageItem,
    doctorItem,
    clinicItem,
    salesItem,
  } = params
  const stageCode = stageItem
    ? stageItem.__typename === 'AccessoryStage'
      ? t(`stage.accessoryCode.${stageItem.accessoryCode}`)
      : `Step ${stageItem.serialNumber}`
    : ''
  const subject =
    stageItem && patientItem
      ? `[SOV]${clinicItem.name}-${
          patientItem?.name ?? ''
        }-${stageCode}出貨通知`
      : `[SOV]${clinicItem.name}-出貨通知`
  const delivery = [
    '宅配編號：',
    '',
    '%0D%0A',
    '貨物進度查詢請至以下網址：',
    '',
  ]
  const deliveryCond = cond<any, any>([
    [
      equals('t_cat'),
      always(set(lensIndex(4), 'https://www.t-cat.com.tw/inquire/trace.aspx')),
    ],
    [equals('maple'), always(set(lensIndex(4), 'http://www.25431010.tw/'))],
    [
      T,
      always(
        set(lensIndex(4), 'http://postserv.post.gov.tw/pstmail/main_mail.html')
      ),
    ],
  ])

  const invoiceInfo = equals('self', invoiceItem.homeDeliveryType)
    ? '業務將親自送達'
    : join(
        '',
        pipe<any, any, any>(
          set(lensIndex(1), invoiceItem.homeDeliveryId),
          deliveryCond(invoiceItem.homeDeliveryType)
        )(delivery)
      )

  const body =
    'Dear ' +
    slice(0, 1, doctorItem.name) +
    '醫師%0D%0A %0D%0A' +
    '您好%0D%0A %0D%0A' +
    (patientItem && stageItem
      ? `${patientItem.name}的${stageCode}已出貨%0D%0A`
      : '') +
    `%0D%0A===========================%0D%0A %0D%0A` +
    `${invoiceInfo}%0D%0A` +
    `%0D%0A===========================%0D%0A` +
    `%0D%0A若有任何問題請與我們聯繫%0D%0A` +
    `感謝您選用SOV的產品%0D%0A` +
    `敬祝 一切順心`

  if (salesItem.email) {
    window.open(
      `mailto:${doctorItem.email}?cc=${salesItem.email}&subject=${subject}&body=${body}`
    )
  }
}

import { gql, useMutation, useQuery } from '@apollo/client'
import { useErrorHandling } from '@sov/common/src/errorHandling'
import { Form, Select, Typography, message } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { map, values } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  CreateMoldStageMutation,
  CreateMoldStageMutationVariables,
  CreateMoldStageQueryQuery,
  CreateMoldStageQueryVariables,
  MoldType,
  StageStatus,
  StageType,
} from '../../../../graphql/types'
import BaseModal, {
  BaseModalFormFields,
  BaseModalProps,
  formItemLayout,
} from './BaseModal'

const createMoldStage = gql`
  mutation createMoldStage($payload: CreateMoldStageInput!) {
    createMoldStage(payload: $payload) {
      id
      ...ForwardStageTaskForm
    }
  }
  ${BaseModal.fragments.ForwardStageTaskForm}
`

interface MoldFormFields {
  moldType: MoldType
}

interface MoldFormProps {
  form: FormInstance<MoldFormFields>
}

const MoldForm: React.FC<MoldFormProps> = (props) => {
  const { form } = props
  const { t } = useTranslation()

  return (
    <Form {...formItemLayout} form={form} requiredMark={false}>
      <Form.Item label='類型'>
        <Typography.Text>{t(`stage.type.${StageType.Mold}`)}</Typography.Text>
      </Form.Item>
      <Form.Item name='moldType' rules={[{ required: true }]} label='齒模類型'>
        <Select>
          {map(
            (moldType) => (
              <Select.Option value={moldType}>
                {t(`moldType.${moldType}`)}
              </Select.Option>
            ),
            values(MoldType)
          )}
        </Select>
      </Form.Item>
    </Form>
  )
}

const createMoldStageQuery = gql`
  query CreateMoldStageQuery(
    $id: ID!
    $query: StagesQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    patient(id: $id) {
      id
      ...BaseModal
    }
  }
  ${BaseModal.fragments.BaseModal}
`

interface CreateMoldStageModalProps
  extends Pick<BaseModalProps, 'visible' | 'onCancel' | 'onSubmit'> {
  patientId: string
}

const CreateMoldStageModal = (props: CreateMoldStageModalProps) => {
  const { visible, patientId, onCancel, onSubmit } = props

  const { toErrorPage } = useErrorHandling()
  const [create] = useMutation<
    CreateMoldStageMutation,
    CreateMoldStageMutationVariables
  >(createMoldStage)

  const { data, loading } = useQuery<
    CreateMoldStageQueryQuery,
    CreateMoldStageQueryVariables
  >(createMoldStageQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    fetchPolicy: 'no-cache',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: patientId,
      query: {
        type: [
          StageType.Print,
          StageType.Eval,
          StageType.Design,
          StageType.Mold,
        ],
        status: [StageStatus.Completed, StageStatus.Started],
      },
      limit: 5, // 給 stage timeline 使用，只需要 5 個
      sort: '-expectedShippingDate',
    },
    skip: !visible || !patientId,
  })

  const handleCreate = async (
    fieldsValue: MoldFormFields & BaseModalFormFields
  ) => {
    let stageItem
    try {
      await create({
        variables: {
          payload: {
            ...fieldsValue,
            patient: patientId,
          },
        },
        update: async (cache, { data }) => {
          if (data?.createMoldStage) {
            message.info('已新增建模單，請選擇下個任務負責人')
            stageItem = data.createMoldStage
          }
        },
      })
      return stageItem
    } catch (error) {
      message.error(`新增失敗: ${error.graphQLErrors[0].message}`, 3)
      onCancel({ shouldRefresh: false })
    }
  }

  const patientItem = data?.patient

  return (
    <>
      <BaseModal
        loading={loading}
        visible={visible}
        patientItem={patientItem}
        stageType={StageType.Mold}
        onCancel={onCancel}
        getCreatedStage={handleCreate}
        onSubmit={onSubmit}
      >
        {({ form }) => <MoldForm form={form} />}
      </BaseModal>
    </>
  )
}

export default CreateMoldStageModal

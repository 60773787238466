import { Button, Card, PageHeader } from 'antd'
import React from 'react'

import BreadcrumbCreator from '../../../components/layout/BreadcrumbCreator'
import Page from '../../../components/layout/Page'
import Title from '../../../components/layout/Title'
import TableTask from '../../../components/table/Task'
import { useTaskList } from './useTaskList'

const ClearButton = (props) => <Button {...props}>清除篩選排序</Button>

const Task = () => {
  const {
    tasks,
    loading,
    sortInfo,
    filterInfo,
    handleTableChange,
    handleClear,
  } = useTaskList({})

  return (
    <Page
      header={
        <PageHeader
          breadcrumbRender={() => (
            <BreadcrumbCreator
              routes={[{ key: 'Home' }, { key: 'TaskList' }]}
            />
          )}
          title={<Title route={{ key: 'TaskList' }} />}
          extra={<ClearButton onClick={handleClear} />}
        />
      }
      headerStyle={{ padding: 0 }}
      loading={loading}
    >
      <Card size='small'>
        <TableTask
          source={tasks}
          sortInfo={sortInfo}
          filterInfo={filterInfo}
          loading={loading}
          locale={{ emptyText: '系統中無任何任務' }}
          handleTableChange={handleTableChange}
        />
      </Card>
    </Page>
  )
}

export { ClearButton }

export default Task

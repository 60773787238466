import { gql, useQuery } from '@apollo/client'
import {
  DateIntervalParam,
  ErrorHandling,
  FormQuery,
  getDateIntervalQueryString,
  MyStringParam,
  TableQuery,
} from '@sov/common'
import { Button, Col, Form, Input, Row, Select, Space, Spin } from 'antd'
import { Moment } from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'

import {
  DoctorInvoiceQuery,
  DoctorInvoiceQueryVariables,
  PatientSource,
} from '../../../graphql/types'
import RangePicker from '../../components/common/RangePicker'
import { ClinicSelect } from '../../components/form/Select'
import BreadcrumbCreator from '../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../components/layout/Page'
import Title from '../../components/layout/Title'
import InvoiceTable, { InvoiceSortType } from '../../components/table/Invoice'

const doctorInvoice = gql`
  query DoctorInvoice(
    $query: InvoiceQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    invoices(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...InvoiceTable
      }
      total
      limit
      page
    }
  }
  ${InvoiceTable.fragment}
`

const formInput = {
  clinicId: MyStringParam,
  salesName: MyStringParam,
  patientName: MyStringParam,
  customerServiceName: MyStringParam,
  creditDateInterval: DateIntervalParam,
  patientSources: MyStringParam,
}

interface SearchForm {
  clinicId?: string
  salesName?: string
  customerServiceName?: string
  patientName?: string
  creditDateInterval?: [Moment, Moment]
  patientSources?: PatientSource
}

interface RouteProps {
  doctorId: string
}

const DoctorInvoice = () => {
  const match = useRouteMatch<RouteProps>()
  const doctorId = match.params.doctorId
  const { t } = useTranslation()

  const { tableQuery, paginateQuery, handleTableReset, handleTableChange } =
    TableQuery.useTableQuery<any, InvoiceSortType>()
  const { formQuery, handleFormChange, handleFormReset } =
    FormQuery.useFormQuery(formInput)
  const { toErrorPage } = ErrorHandling.useErrorHandling()

  const [searchForm] = Form.useForm<SearchForm>()
  const { creditDateInterval, patientSources, ...restFormQuery } = formQuery

  const { data, loading } = useQuery<
    DoctorInvoiceQuery,
    DoctorInvoiceQueryVariables
  >(doctorInvoice, {
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {
        doctorId,
        creditDateInterval: getDateIntervalQueryString(creditDateInterval),
        patientSources: patientSources
          ? [patientSources as PatientSource]
          : undefined,
        ...restFormQuery,
      },
      ...paginateQuery,
    },
  })

  const doctorInvoices = data?.invoices
  const totalPrice = doctorInvoices?.docs?.reduce(
    (total, item) => total + item.totalPrice,
    0
  )

  const handleSearch = () => {
    handleFormChange(searchForm.getFieldsValue())
  }

  const handleReset = () => {
    searchForm.resetFields()
    handleTableReset()
    handleFormReset()
  }

  if (loading) {
    return <Spin />
  }

  if (!doctorInvoices) {
    return null
  }

  return (
    <Page
      header={
        <Row>
          <Col flex={1}>
            <BreadcrumbCreator
              routes={[
                { key: 'Home' },
                { key: 'DoctorList' },
                { key: 'DoctorInvoiceList' },
              ]}
            />
            <Title route={{ key: 'DoctorInvoiceList' }} />
          </Col>
          <Col>
            <h3>目前列表總金額 ${totalPrice}</h3>
          </Col>
        </Row>
      }
    >
      <Section>
        <Space size='small' style={{ marginBottom: 8 }}>
          <Form form={searchForm} initialValues={formQuery} layout='inline'>
            <Form.Item name='clinicId' label='診所'>
              <ClinicSelect
                query={{ doctor: doctorId }}
                style={{ width: 120 }}
              />
            </Form.Item>
            <Form.Item name='salesName' label='業務'>
              <Input style={{ width: 120 }} onPressEnter={handleSearch} />
            </Form.Item>
            <Form.Item name='customerServiceName' label='客服'>
              <Input style={{ width: 120 }} onPressEnter={handleSearch} />
            </Form.Item>
            <Form.Item name='patientName' label='病患'>
              <Input style={{ width: 120 }} onPressEnter={handleSearch} />
            </Form.Item>
            <Form.Item name='patientSources' label='病患方案'>
              <Select style={{ width: 100 }}>
                {Object.values(PatientSource).map((source) => (
                  <Select.Option key={source} value={source}>
                    {t(`patient.source.${source}`)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name='creditDateInterval' label='入帳時間'>
              <RangePicker />
            </Form.Item>
          </Form>
          <Button onClick={handleSearch}>搜索</Button>
          <Button onClick={handleReset}>清除</Button>
        </Space>
        <InvoiceTable
          source={doctorInvoices}
          sortInfo={tableQuery.sort}
          handleChange={handleTableChange}
        />
      </Section>
    </Page>
  )
}

export default DoctorInvoice
